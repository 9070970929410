import { LanguageService } from '../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentEventType } from '../../contract/equipment-event-type.enum';

@Pipe({
  name: 'equipmentEventTypePipe',
})
export class EquipmentEventTypePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(type: EquipmentEventType): string {
    switch (type) {
      case EquipmentEventType.DAMAGE:
        return this.translate('shared.pipes.equipmentEventType.damage');
      case EquipmentEventType.GEOFENCE:
        return this.translate('shared.pipes.equipmentEventType.geofenceTransition');
      case EquipmentEventType.INCIDENT:
        return this.translate('shared.pipes.equipmentEventType.incident');
      case EquipmentEventType.INSPECTION:
        return this.translate('shared.pipes.equipmentEventType.inspection');
      case EquipmentEventType.MILEAGE:
        return this.translate('shared.pipes.equipmentEventType.mileage');
      case EquipmentEventType.MAINTENANCE:
        return this.translate('shared.pipes.equipmentEventType.maintenance');
      case EquipmentEventType.MACHINE_FAULT:
        return this.translate('shared.pipes.equipmentEventType.machineFault');
      case EquipmentEventType.OPERATING_HOURS:
        return this.translate('shared.pipes.equipmentEventType.operatingHours');
      case EquipmentEventType.RESPONSIBLE:
        return this.translate('shared.pipes.equipmentEventType.responsible');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
