import { LanguageService } from 'app/shared/services/language.service';
import { MatPaginator } from '@angular/material/paginator';
import { ButtonInfo } from 'app/shared/contract/button-info';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { SearchStock } from '../shared/search-stock';
import { StockTypeResolver } from '../../../../shared/pipes/stock-type.resolver';
import { Address } from '../../../organisation/contract/address.interface';
import { AddressService } from '../../../../shared/services/address.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { StockStore } from '../shared/stock.store';
import { StockAddEditComponent } from '../stock-add-edit/stock-add-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { ListType } from '../../../../shared/enums/list-type.enum';
import { TabService } from '../../../../shared/tab.service';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { dialogResults } from '../../../../shared/enums/dialogResults.enum';
import { AssetListActivatedView } from 'app/shared/contract/activated-view.interface';
import { CurrentItemPaginatorUtils } from 'app/shared/directives/current-item-paginator/current-item-paginator-utils.class';
import { StockColumnService } from '../../shared/services/stock-column.service';
import { environment } from 'environments/environment';
import { RequestErrorHandlerStockService } from 'app/core/interceptors/request-error-handlers/request-error-handler-stock.service';

@UntilDestroy()
@Component({
  selector: 'bh-stock-list',
  templateUrl: './stock-list.component.html',
  styleUrls: ['./stock-list.component.scss'],
})
export class StockListComponent implements AssetListActivatedView, OnInit, OnDestroy {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  public detailTab: Observable<string>;
  public searchForm: UntypedFormGroup;
  public stocks: Observable<SearchStock[]>;
  public routerLinkOptions = {exact: false};
  public routeButton: ButtonInfo = {
    route: 'stocks/map',
    tooltip: this.languageService.getInstant('general.map.s'),
    iconName: 'map',
  };
  public readonly isIdentifierOfStock = CurrentItemPaginatorUtils.isIdentifierOfStock;
  public readonly searchSuggestionsField = 'stockListSuggestions';
  private searchTermSubscription = new Subscription();

  constructor(private stockTypeResolver: StockTypeResolver,
              private formBuilder: UntypedFormBuilder,
              public stockStore: StockStore,
              public columnService: StockColumnService,
              private dialog: MatDialog,
              private tabService: TabService,
              private route: ActivatedRoute,
              private router: Router,
              private addressService: AddressService,
              private languageService: LanguageService,
              private errorHandlerStockService: RequestErrorHandlerStockService,
  ) { }

  public ngOnInit(): void {

    /*
    * Workaround for https://github.com/angular/angular/issues/13865
    * Having async values in routerLink does not result in routerLinkActive being updated.
    */
    this.initStockBadRequestListener();
    this.router.events.pipe(untilDestroyed(this)).subscribe(() => this.routerLinkOptions = {exact: false});
    this.tabService.changeSiteTab(ListType.STOCKS);
    this.detailTab = this.stockStore.detailTab;
    this.searchForm = this.formBuilder.group({
      terms: null,
    });
    this.stocks = this.stockStore.searchStocks;
    this.columnService.pageSize.pipe(untilDestroyed(this)).subscribe(pageSize => this.paginator.pageSize = pageSize);

    const create = this.route.snapshot.data['create'];
    if (create) {
      this.dialog.open(StockAddEditComponent, {disableClose: true}).afterClosed().subscribe(result => {
        if (result === dialogResults.ABORT) {
          this.router.navigate(['sites/stocks/list']);
        }
      });
    }

    this.stockStore.selectedStock
      .pipe(
        debounceTime(environment.DELAY_SHORT),
        untilDestroyed(this))
      .subscribe(currentStock => {
        if (currentStock?.stockId) {
          this.navigateToTab(currentStock.stockId, this.stockStore.currentDetailTab);
        } else {
          this.router.navigate(['sites/stocks/list']);
        }
      });
    this.stockStore.update();
    this.onSearchFormType();
  }

  public ngOnDestroy(): void {
  }

  public onSearchFormType(): void {
    this.initSearchTermListener();
  }

  private initStockBadRequestListener(): void {
    this.errorHandlerStockService.selectEventSender(
      sender => sender
        .pipe(untilDestroyed(this))
        .subscribe(error => this.stockStore.handleDispositionGeofenceBadRequest(error))
    );
  }

  private initSearchTermListener(): void {
    this.searchTermSubscription.unsubscribe();
    this.searchTermSubscription = this.searchForm.get('terms').valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this))
      .subscribe(terms => this.stockStore.searchFor(terms));
    this.setSearchTerms(false);
  }

  public onSearchFormClick(): void {
    this.paginator.firstPage();
  }

  public reset(): void {
    this.stockStore.reset();
    this.initSearchTermListener();
  }

  public formatAddress(address?: Address): string {
    return address ? this.addressService.formatAddressSingleLine(address.city, address.street, address.streetNumber) : '-';
  }

  public resolveIcon(stockType: string): IconDefinition {
    return this.stockTypeResolver.resolveIcon(stockType);
  }

  private navigateToTab(stockId: string, tab: string): void {
    this.router.navigate(['sites/stocks/list', stockId, tab]);
  }

  private setSearchTerms(emitEvent: boolean = true): void {
    this.searchForm.patchValue({terms: this.stockStore.searchTerms}, {emitEvent});
  }

}
