import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'app/shared/services/language.service';
import { debounceTime, distinctUntilChanged, Observable } from 'rxjs';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Sort } from '@angular/material/sort';
import { EquipmentViewActiveLogDatasource } from 'app/modules/equipment/shared/services/equipment-view-active-log.datasource';
import { EquipmentViewEventLogDatasource } from 'app/modules/equipment/shared/services/equipment-view-event-log.datasource';
import { EventDetailsDatasource } from 'app/modules/equipment/shared/services/event-details.datasource';
import { EquipmentEventTabs } from 'app/modules/equipment/contract/equipment-event-tabs.enum';
import { EventGroupInfo } from 'app/modules/equipment/contract/event-group-info.interface';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { environment } from 'environments/environment';
import { EquipmentEventStatus } from 'app/modules/equipment/contract/equipment-event-status.enum';
import { EquipmentEventSeverity } from 'app/modules/equipment/contract/equipment-event-severity.enum';
import { EquipmentEventType } from 'app/modules/equipment/contract/equipment-event-type.enum';
import { EQUIPMENT_EVENT_COLUMNS } from 'app/modules/equipment/contract/equipment-event-columns-constants';
import { DateFormatByLocation } from 'app/shared/date-format-by-location';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { matDateFormatFactory } from 'app/shared/mat-date-format-factory';
import { Moment } from 'moment';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-events',
  templateUrl: './equipment-view-events.component.html',
  styleUrls: ['./equipment-view-events.component.scss'],
  providers: [
    {provide: DateAdapter,  useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useFactory: matDateFormatFactory, deps: [LanguageService]},
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EquipmentViewEventsComponent implements OnInit {

  @ViewChild('fromDateInputRef', { static: true }) fromDateInputRef: ElementRef;
  @ViewChild('toDateInputRef', { static: true }) toDateInputRef: ElementRef;

  public eventsList: Observable<EventGroupInfo[]>;
  public displayedColumns = Object.values(EQUIPMENT_EVENT_COLUMNS);
  public eventTabs = EquipmentEventTabs;
  public currentTab: EquipmentEventTabs = null;
  public activeEventCount: number = null;
  public filterForm: UntypedFormGroup;
  public timeRangeForm: UntypedFormGroup;
  public searchFilter: UntypedFormControl = new UntypedFormControl();
  public statusList = EquipmentEventStatus;
  public severityList = EquipmentEventSeverity;
  public TYPE_LIST = EquipmentEventType;
  public COLUMNS = EQUIPMENT_EVENT_COLUMNS;
  public selectedTabIndex = 0;
  public dateFormat = '';

  private get statusControl(): AbstractControl {
    return this.filterForm.get('statusFilters');
  }

  private get severityControl(): AbstractControl {
    return this.filterForm.get('severityFilters');
  }

  private get typeControl(): AbstractControl {
    return this.filterForm.get('typeFilters');
  }

  public get fromDateControl(): AbstractControl {
    return this.timeRangeForm.get('fromDate');
  }

  public get toDateControl(): AbstractControl {
    return this.timeRangeForm.get('toDate');
  }

  public get fromDate(): Moment {
    return this.fromDateControl.value;
  }

  public get toDate(): Moment {
    return this.toDateControl.value;
  }

  constructor(
    protected fb: UntypedFormBuilder,
    private equipmentDatasource: EquipmentsDataSource,
    private activeLogDatasource: EquipmentViewActiveLogDatasource,
    private eventLogDatasource: EquipmentViewEventLogDatasource,
    private eventDetailsDatasource: EventDetailsDatasource,
    private router: Router,
    private languageService: LanguageService,
  ) { }

  public ngOnInit(): void {
    this.checkEquipmentEventsConfiguration();
    this.setInitTabSettings();
    this.setDateFormat();
    this.buildFilterForm();
    this.buildTimeRangeForms();
    this.filterFormValueChanges();
    this.timeRangeFormValueChanges();
    this.currentEquipmentSubscription();
    this.activeEventCountSubscription();
    this.damageEventStatusUpdateTriggerSubscription();
    this.searchFilterValueChanges();
  }

  public tabChangeEvent(index: number): void {
    if (index === 0) {
      this.currentTab = EquipmentEventTabs.ACTIVE;
      this.eventsList = this.activeLogDatasource.activeEventsList;
      this.setTimeRangeFilter(this.activeLogDatasource);
      this.updateEventList();
    }

    if (index === 1) {
      this.currentTab = EquipmentEventTabs.EVENT;
      this.eventsList = this.eventLogDatasource.eventLogList;
      this.setTimeRangeFilter(this.eventLogDatasource);
      this.updateEventList();
    }
  }

  public exportExcel(): void {
    switch(this.currentTab) {
      case EquipmentEventTabs.ACTIVE:
        this.activeLogDatasource.eventsExportExcel();
        break;
      case EquipmentEventTabs.EVENT:
        this.eventLogDatasource.eventsExportExcel();
        break;
    }
  }

  public exportCSV(): void {
    switch(this.currentTab) {
      case EquipmentEventTabs.ACTIVE:
        this.activeLogDatasource.eventsExportCSV();
        break;
      case EquipmentEventTabs.EVENT:
        this.eventLogDatasource.eventsExportCSV();
        break;
    }
  }

  public sortData(sort: Sort): void {
    this.activeLogDatasource.sort = sort;
    this.eventLogDatasource.sort = sort;
    this.updateEventList();
  }

  public selectEvent(event: EventGroupInfo): void {
    this.eventDetailsDatasource.eventGroupId = event.eventGroupId;
    this.equipmentDatasource.navigateBackTab = this.currentTab;
    this.eventDetailsDatasource.getEventDetails(true);
  }

  public getMileageNumber(value: string): number {
    return parseFloat(value);
  }

  private buildFilterForm(): void {
    this.filterForm = new UntypedFormGroup({
      statusFilters: new UntypedFormControl(this.activeLogDatasource.statusFilters),
      severityFilters: new UntypedFormControl(this.activeLogDatasource.severityFilters),
      typeFilters: new UntypedFormControl(this.activeLogDatasource.typeFilters),
    });

    this.searchFilter = new UntypedFormControl(this.activeLogDatasource.terms);
  }

  private buildTimeRangeForms(): void {
    this.timeRangeForm = this.fb.group({
      fromDate: this.activeLogDatasource.fromDate,
      toDate: this.activeLogDatasource.toDate,
    });
  }

  private filterFormValueChanges(): void {
    this.statusControl.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        this.activeLogDatasource.statusFilters = value;
        this.eventLogDatasource.statusFilters = value;
        this.resetPageIndexes();
        this.updateEventList();
      });

    this.severityControl.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        this.activeLogDatasource.severityFilters = value;
        this.eventLogDatasource.severityFilters = value;
        this.resetPageIndexes();
        this.updateEventList();
      });

    this.typeControl.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => {
        this.activeLogDatasource.typeFilters = value;
        this.eventLogDatasource.typeFilters = value;
        this.resetPageIndexes();
        this.updateEventList();
      });
  }

  private timeRangeFormValueChanges(): void {
    this.fromDateControl.valueChanges
      .pipe(untilDestroyed(this), debounceTime(environment.DELAY_SHORTEST))
      .subscribe(value => {
        if (this.toDateControl.valid && this.fromDateControl.valid) {
          switch(this.currentTab) {
            case EquipmentEventTabs.ACTIVE:
              this.activeLogDatasource.fromDate = value;
              break;
            case EquipmentEventTabs.EVENT:
              this.eventLogDatasource.fromDate = value;
              break;
          }

          this.resetPageIndexes();
          this.updateEventList();
        }
      });

    this.toDateControl.valueChanges
      .pipe(untilDestroyed(this), debounceTime(environment.DELAY_SHORTEST))
      .subscribe(value => {
        if (this.toDateControl.valid && this.fromDateControl.valid) {
          switch(this.currentTab) {
            case EquipmentEventTabs.ACTIVE:
              this.activeLogDatasource.toDate = value;
              break;
            case EquipmentEventTabs.EVENT:
              this.eventLogDatasource.toDate = value;
              break;
          }

          this.resetPageIndexes();
          this.updateEventList();
        }
      });
  }

  private setInitTabSettings(): void {
    this.goToActiveLogTab();

    this.resetPageIndexes();
    this.getEquipmentActiveEventCount();

    switch(this.equipmentDatasource.navigateBackTab) {
      case EquipmentEventTabs.ACTIVE:
        this.activeLogDatasource.getEquipmentActiveLogList();
        break;
      case EquipmentEventTabs.EVENT:
        this.eventLogDatasource.getEquipmentEventLogList();
        this.goToEventLogTab();
        break;
    }
  }

  private currentEquipmentSubscription(): void {
    this.equipmentDatasource.currentEquipment
      .pipe(untilDestroyed(this))
      .subscribe(eq => {
        if (eq?.equipmentId &&
            eq?.equipmentId !== this.activeLogDatasource.equipmentId
        ) {
          this.activeLogDatasource.equipmentId = eq.equipmentId;
          this.eventLogDatasource.equipmentId = eq.equipmentId;
          this.eventDetailsDatasource.equipmentId = eq.equipmentId;
          this.activeLogDatasource.equipmentInternalNumber = eq.equipmentCustomerSerialNumber;
          this.eventLogDatasource.equipmentInternalNumber = eq.equipmentCustomerSerialNumber;
          this.resetPageIndexes();
          this.updateEventList();
          this.getEquipmentActiveEventCount();
        }
      })
  }

  private activeEventCountSubscription(): void {
    this.activeLogDatasource.activeEventCount
      .pipe(untilDestroyed(this))
      .subscribe(res => this.activeEventCount = res);
  }

  private damageEventStatusUpdateTriggerSubscription(): void {
    this.eventDetailsDatasource.damageEventStatusChangeTrigger
      .pipe(untilDestroyed(this))
      .subscribe(update => {
        if (update) {
          this.resetPageIndexes();
          this.activeLogDatasource.getEquipmentActiveLogList();
          this.eventLogDatasource.getEquipmentEventLogList();
          this.getEquipmentActiveEventCount();
          this.goToEventLogTab();
        }
      });
  }

  private goToActiveLogTab(): void {
    this.selectedTabIndex = 0;
    this.currentTab = EquipmentEventTabs.ACTIVE;
    this.eventsList = this.activeLogDatasource.activeEventsList;
  }

  private goToEventLogTab(): void {
    this.selectedTabIndex = 1;
    this.currentTab = EquipmentEventTabs.EVENT;
    this.eventsList = this.eventLogDatasource.eventLogList;
  }

  private searchFilterValueChanges(): void {
    this.searchFilter.valueChanges
      .pipe(debounceTime(environment.DELAY_SHORTEST), distinctUntilChanged())
      .subscribe(terms => {
        this.activeLogDatasource.terms = terms;
        this.eventLogDatasource.terms = terms;
        this.resetPageIndexes();
        this.updateEventList();
      });
  }

  private updateEventList(): void {
    this.currentTab === EquipmentEventTabs.ACTIVE
      ? this.activeLogDatasource.getEquipmentActiveLogList()
      : this.eventLogDatasource.getEquipmentEventLogList();
  }

  private resetPageIndexes(): void {
    this.activeLogDatasource.resetPageIndex();
    this.eventLogDatasource.resetPageIndex();
  }

  private getEquipmentActiveEventCount(): void {
    this.activeLogDatasource.getEquipmentActiveEventCount();
  }

  private checkEquipmentEventsConfiguration(): void {
    if (!this.equipmentDatasource.getEquipmentEventsConfiguration().eventsAvailable) {
      this.router.navigate([this.router.url.replace('events', 'general')]);
    }
  }

  private setDateFormat(): void {
    this.dateFormat = DateFormatByLocation(this.languageService.getCurrentLocale());
  }

  private setTimeRangeFilter(datasource: EquipmentViewActiveLogDatasource | EquipmentViewEventLogDatasource): void {
    this.timeRangeForm.patchValue({
      fromDate: datasource.fromDate,
      toDate: datasource.toDate,
    });
  }
}
