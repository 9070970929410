import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EquipmentListComponent } from '../equipments/equipment-list/landscape/equipment-list.component';
import { EquipmentViewComponent } from '../equipments/equipment-list/equipment-view/landscape/equipment-view.component';
import { ContentComponent } from '../../../shared/components/content-component/content.component';
import { CanActivateChildAuthority } from '../../../core/guards/can-activate-child-authority';
import { Authorities } from '../../../shared/enums/authorities.enum';
import { EquipmentMapComponent } from '../equipments/equipment-map/landscape/equipment-map.component';
import { EquipmentInvoiceAddEditComponent } from '../equipments/equipment-invoice-add-edit/equipment-invoice-add-edit.component';
import { EquipmentViewDetailComponent } from '../equipments/equipment-list/equipment-view/equipment-view-detail/landscape/equipment-view-detail.component';
import { EquipmentViewTechnicalFieldComponent } from '../equipments/equipment-list/equipment-view/equipment-view-technical-field/equipment-view-technical-field.component';
import { EquipmentViewTimelineComponent } from '../equipments/equipment-list/equipment-view/equipment-view-timeline/landscape/equipment-view-timeline.component';
import { EquipmentViewCostComponent } from '../equipments/equipment-list/equipment-view/equipment-view-cost/equipment-view-cost.component';
import { EquipmentViewAttachmentComponent } from '../equipments/equipment-list/equipment-view/equipment-view-attachment/landscape/equipment-view-attachment.component';
import { EquipmentViewTelematicComponent } from '../equipments/equipment-list/equipment-view/equipment-view-telematic/equipment-view-telematic.component';
import { EquipmentViewDispositionComponent } from '../equipments/equipment-list/equipment-view/equipment-view-disposition/landscape/equipment-view-disposition.component';
import { EquipmentViewSubEquipmentsComponent } from '../equipments/equipment-list/equipment-view/equipment-view-sub-equipments/equipment-view-sub-equipments.component';
import { EquipmentDamageAddEditComponent } from '../equipments/equipment-damage-add-edit/equipment-damage-add-edit.component';
import { EquipmentIncidentAddEditComponent } from '../equipments/equipment-incident-add-edit/equipment-incident-add-edit.component'
import { CanActivateChildLandscape } from '../../../core/guards/can-activate-child-landscape';
import { EquipmentViewContractsComponent } from '../equipments/equipment-list/equipment-view/equipment-view-contracts/equipment-view-contracts.component';
import { Modules } from '../../../shared/enums/modules.enum';
import { CanActivateModule } from '../../../core/guards/can-activate-module';
import { EquipmentViewCardComponent } from '../equipments/equipment-list/equipment-view/equipment-view-card/equipment-view-card.component';
import { CurrentTabReroute } from './current-tab-reroute';
import { EquipmentViewTasksComponent } from '../equipments/equipment-list/equipment-view/equipment-view-tasks/equipment-view-tasks.component';
import { SearchFilterComponent } from 'app/shared/components/search-filter-component/landscape/search-filter.component';
import {EquipmentViewUsageComponent} from '../equipments/equipment-list/equipment-view/equipment-view-usage/equipment-view-usage.component';
import { EquipmentViewEventsComponent } from '../equipments/equipment-list/equipment-view/equipment-view-events/equipment-view-events.component';
import { EventDetailsComponent } from '../equipments/equipment-list/equipment-view/equipment-view-events/event-details/event-details.component';

const equipmentRoutes: Routes = [
  {
    path: 'equipments',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildLandscape],
    children: [
      {
        path: 'map',
        component: SearchFilterComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
        },
        children: [
          {
            path: '',
            component: EquipmentMapComponent
          }
        ]
      },
      {
        path: ':id/add-invoice',
        component: EquipmentInvoiceAddEditComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.EQUIPMENT_MANAGE_COSTS],
          modules: [Modules.COSTS]
        }
      },
      {
        path: ':id/edit-invoice/:invoiceId',
        component: EquipmentInvoiceAddEditComponent,
        canActivate: [CanActivateModule],
        data: {
          authorities: [Authorities.EQUIPMENT_MANAGE_COSTS],
          modules: [Modules.COSTS]
        }
      },
      {
        path: ':id/add-damage',
        component: EquipmentDamageAddEditComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA,
            Authorities.EQUIPMENT_MANAGE_DAMAGES,
            Authorities.EQUIPMENT_UPDATE]
        }
      },
      {
        path: ':id/edit-damage/:lifecycleId',
        component: EquipmentDamageAddEditComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA,
            Authorities.EQUIPMENT_MANAGE_DAMAGES,
            Authorities.EQUIPMENT_UPDATE]
        }
      },
      {
        path: ':id/add-incident',
        component: EquipmentIncidentAddEditComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA,
            Authorities.EQUIPMENT_MANAGE_LIFECYCLE_EVENTS,
            Authorities.EQUIPMENT_UPDATE]
        }
      },
      {
        path: ':id/update-incident/:lifecycleId',
        component: EquipmentIncidentAddEditComponent,
        data: {
          authorities: [
            Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA,
            Authorities.EQUIPMENT_MANAGE_LIFECYCLE_EVENTS,
            Authorities.EQUIPMENT_UPDATE]
        }
      }
    ]
  },
  {
    path: 'assets',
    component: ContentComponent,
    canActivateChild: [CanActivateChildAuthority, CanActivateChildLandscape],
    children: [
      {
        path: 'equipment',
        component: SearchFilterComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
        },
        children: [
          {
            path: 'add',
            component: EquipmentListComponent,
            data: {
              authorities: [Authorities.EQUIPMENT_VIEW],
              create: true
            },
          },
          {
            path: 'list',
            component: EquipmentListComponent,
            data: {
              authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
            },
            children: [
              {
                path: ':id',
                component: EquipmentViewComponent,
                data: {
                  authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
                },
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    children: [],
                    canActivate: [CurrentTabReroute] // walkaround to achieve dynamic rerouting
                  },
                  {
                    path: 'general',
                    component: EquipmentViewDetailComponent,
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
                    }
                  },
                  {
                    path: 'technical-field',    // TODO: re-check if it is still necessary in the routing
                    component: EquipmentViewTechnicalFieldComponent,
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
                    }
                  },
                  {
                    path: 'timeline',
                    component: EquipmentViewTimelineComponent,
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
                    }
                  },
                  {
                    path: 'cost',
                    component: EquipmentViewCostComponent,
                    canActivate: [CanActivateModule],
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW_COSTS, Authorities.EQUIPMENT_MANAGE_COSTS],
                      modules: [Modules.COSTS]
                    }
                  },
                  {
                    path: 'tasks',
                    component: EquipmentViewTasksComponent,
                    canActivate: [CanActivateModule],
                    data: {
                      authorities: [Authorities.MAINTENANCE_TASK_VIEW, Authorities.MAINTENANCE_TASK_MANAGE],
                      modules: [Modules.SERVICE]
                    }
                  },
                  {
                    path: 'attachment',     // TODO: re-check if it is still necessary in the routing
                    component: EquipmentViewAttachmentComponent,
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
                    }
                  },
                  {
                    path: 'telematic',
                    component: EquipmentViewTelematicComponent,
                    canActivate: [CanActivateModule],
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                      modules: [Modules.LIVE_DATA]
                    }
                  },
                  {
                    path: 'disposition',
                    component: EquipmentViewDispositionComponent,
                    canActivate: [CanActivateModule],
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                      modules: [Modules.DISPOSITION]
                    }
                  },
                  {
                    path: 'usage',
                    component: EquipmentViewUsageComponent,
                    canActivate: [CanActivateModule],
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
                      modules: [Modules.DISPOSITION]
                    }
                  },
                  {
                    path: 'sub-equipments',
                    component: EquipmentViewSubEquipmentsComponent,
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
                    }
                  },
                  {
                    path: 'contracts',
                    component: EquipmentViewContractsComponent,
                    canActivate: [CanActivateModule],
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW_CONTRACTS],
                      modules: [Modules.COSTS]
                    }
                  },
                  {
                    path: 'card',
                    component: EquipmentViewCardComponent,
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
                    }
                  },
                  {
                    path: 'events',
                    component: EquipmentViewEventsComponent,
                    data: {
                      authorities: [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]
                    }
                  },
                  {
                    path: '**',
                    redirectTo: 'general',
                    pathMatch: 'full'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: 'event/details',
        component: EventDetailsComponent,
        data: {
          authorities: [Authorities.EQUIPMENT_VIEW],
        }
      }
    ]
  }
];

export const equipmentRouting: ModuleWithProviders<RouterModule> = RouterModule.forChild(equipmentRoutes);
