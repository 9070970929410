import {
  EquipmentFilterRequestParams
} from 'app/shared/contract/filter/equipment/equipment-filter-request-params.interface';
import {FilterParams} from 'app/shared/contract/filter/filter-params.class';
import {FilterTypes} from 'app/shared/contract/filter/filter-types.enum';
import {
  EquipmentFilterType
} from 'app/shared/contract/filter/filter-view/equipment-filter-collection/equipment-filter-type.interface';
import {
  SingleFilterOption
} from 'app/shared/contract/filter/filter-view/equipment-filter-collection/single-filter-option.enum';
import {EquipmentStatusCategory} from '../equipment-status-category.enum';
import { UserConfigurationService } from '../../../../shared/services/user-configuration.service';

export class EquipmentFilterParams extends FilterParams {
  protected paramValuesMapper: { [key in EquipmentFilterType]?: () => any } = {
    [EquipmentFilterType.ORGANISATION]: () => this.getFilterTypeParams(FilterTypes.ORGANISATION),
    [EquipmentFilterType.ASSIGNED_EMPLOYEE]: () => this.getFilterTypeParams(FilterTypes.RESPONSIBLE_PERSON),
    [EquipmentFilterType.LABEL]: () => this.getFilterTypeParams(FilterTypes.LABEL),
    [EquipmentFilterType.CLASS]: () => this.getFilterTypeParams(FilterTypes.EQUIPMENT_CLASS),
    [EquipmentFilterType.TRANSPORT_TYPES]: () => this.getFilterTypeParams(FilterTypes.TRANSPORT_TYPE),
    [EquipmentFilterType.WITHOUT_SUBEQUIPMENTS]:
      () => !(this.getFilterTypeParams(FilterTypes.SUBEQUIPMENT).some(value => value === SingleFilterOption.WITHOUT_SUBEQUIPMENTS)),
    [EquipmentFilterType.TRANSPORT_VEHICLE_ONLY]:
      () => this.getFilterTypeParams(FilterTypes.TRANSPORT_VEHICLE).some(value => value === SingleFilterOption.VEHICLE_ONLY),
    [EquipmentFilterType.FAVOURITE_ONLY]:
      () => this.getFilterTypeParams(FilterTypes.FAVOURITE_EQUIPMENT).some(value => value === SingleFilterOption.FAVOURITE_ONLY),
    [EquipmentFilterType.MATCH_ALL_LABELS]:
      () => this.getFilterTypeParams(FilterTypes.MATCH_ALL_LABELS).some(value => value === SingleFilterOption.ALL_LABELS),
    [EquipmentFilterType.STATUS]:
      () => this.getFilterTypeParams(FilterTypes.EQUIPMENT_STATE).filter(value => !EquipmentStatusCategory[value]),
    [EquipmentFilterType.ATTENTION_STATUS]: () => this.getFilterTypeParams(FilterTypes.ATTENTION_STATUS),
    [EquipmentFilterType.COMPLETENESS_STATUS]: () => this.getFilterTypeParams(FilterTypes.COMPLETENESS_STATUS),
  };

  constructor(private userConfigurationService: UserConfigurationService) {
    super();
  }

  public getFilterParams(): EquipmentFilterRequestParams {
    let filter = {
      [EquipmentFilterType.ORGANISATION]: this.getEquipmentFilterTypeParams(EquipmentFilterType.ORGANISATION),
      [EquipmentFilterType.ASSIGNED_EMPLOYEE]: this.getEquipmentFilterTypeParams(EquipmentFilterType.ASSIGNED_EMPLOYEE),
      [EquipmentFilterType.LABEL]: this.getEquipmentFilterTypeParams(EquipmentFilterType.LABEL),
      [EquipmentFilterType.CLASS]: this.getEquipmentFilterTypeParams(EquipmentFilterType.CLASS),
      [EquipmentFilterType.TRANSPORT_TYPES]: this.getEquipmentFilterTypeParams(EquipmentFilterType.TRANSPORT_TYPES),
      [EquipmentFilterType.WITHOUT_SUBEQUIPMENTS]: this.getEquipmentFilterTypeSingleOptionParam(EquipmentFilterType.WITHOUT_SUBEQUIPMENTS),
      [EquipmentFilterType.TRANSPORT_VEHICLE_ONLY]:
        this.getEquipmentFilterTypeSingleOptionParam(EquipmentFilterType.TRANSPORT_VEHICLE_ONLY),
      [EquipmentFilterType.FAVOURITE_ONLY]:
        this.getEquipmentFilterTypeSingleOptionParam(EquipmentFilterType.FAVOURITE_ONLY),
      [EquipmentFilterType.MATCH_ALL_LABELS]:
        this.getEquipmentFilterTypeSingleOptionParam(EquipmentFilterType.MATCH_ALL_LABELS),
      [EquipmentFilterType.STATUS]: this.getEquipmentFilterTypeParams(EquipmentFilterType.STATUS),
      [EquipmentFilterType.ATTENTION_STATUS]: this.getEquipmentFilterTypeParams(EquipmentFilterType.ATTENTION_STATUS),
      [EquipmentFilterType.COMPLETENESS_STATUS]: this.getEquipmentFilterTypeParams(EquipmentFilterType.COMPLETENESS_STATUS),
    };

    if (!this.userConfigurationService.getEquipmentSearchV2Configuration()?.useSearchV2) {
      delete filter[EquipmentFilterType.FAVOURITE_ONLY];
    }

    if (!this.userConfigurationService.getEquipmentEventsConfiguration()?.eventsAvailable) {
      delete filter[EquipmentFilterType.ATTENTION_STATUS];
    }

    return filter;
  }

  protected getEquipmentFilterTypeParams(type: EquipmentFilterType): string[] {
    return this.paramValuesMapper[type]();
  }

  protected getEquipmentFilterTypeSingleOptionParam(type: EquipmentFilterType): boolean {
    return this.paramValuesMapper[type]();
  }

  public hasParamValue(type: EquipmentFilterType): boolean {
    // Single-option filters always have param value: true OR false
    return type === EquipmentFilterType.WITHOUT_SUBEQUIPMENTS
      || type === EquipmentFilterType.TRANSPORT_VEHICLE_ONLY
      || type === EquipmentFilterType.MATCH_ALL_LABELS
      || this.getEquipmentFilterTypeParams(type).length > 0;
  }
}
