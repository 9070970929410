import { LanguageService } from '../services/language.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeDifferentPipe',
})
export class TimeDifferentPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(duration: number): string {
    if (duration < 2) {
      return this.getDurationString(duration, this.translate('general.units.time.hour.s'));
    }
    if (duration < 24) {
      return this.getDurationString(duration, this.translate('general.units.time.hour.pl'));
    }
    if (duration < 48) {
      return this.getDurationString(1, this.translate('general.units.time.day.s'));
    }
    return this.getDurationString(Math.floor(duration/24), this.translate('general.units.time.day.pl'));
  }

  private getDurationString(duration: number, unit: string): string {
    return this.translate(
      'shared.pipes.timeDifferent.timeAgo',
      {
        value: duration,
        unit: unit,
      },
    );
  }

  private translate(key: string, params?: Object): string {
    return this.languageService.getInstant(key, params);
  }
}
