import { ViewGeofence } from '../contract/view-geofence.interface';
import { GenericGeofence } from 'app/shared/contract/generic-geofence.interface';

export function convertViewGeofenceToGenericGeofence(geofence: ViewGeofence): GenericGeofence {
  return {
    id: geofence.geofenceId,
    name: geofence.geofenceName,
    color: geofence.geofenceColor,
    alarming: geofence.alarming,
    isDispositionGeofence: geofence.isDispositionGeofence,
    coordinates: geofence.coordinates,
  };
}
