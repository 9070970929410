<div fxFill fxLayout="row" fxLayoutAlign="start stretch">
  <mat-card class="list-view-main-container"
    [ngClass]="{
      'list-item-hidden': displayService.isDetailsFullscreen(),
      'list-item-margin': displayService.isTableAndDetails(),
      'no-rounded-corners-left': hasModule(modules.BULK_ITEM_MANAGEMENT)
    }"
    fxFlex="{{ displayService.isTableFullscreen() ? 100 : 35}}">
    <mat-card-content class="list-view-main" fxFlexFill fxLayout="row" fxLayoutAlign="start stretch">

      <div class="list-view-main__left" fxLayout="column" fxLayoutAlign="start stretch" fxFlex>
        <div class="list-view-main__left-header modern_list-view-main__left-header"
          fxFlex="60px"
          fxLayout="row"
          fxLayoutAlign="start stretch"
          [class.list-without-multiselect-button]="!hasManageLabelsAuthority()">
          <button *ngIf="!multiselectActive && hasManageLabelsAuthority()"
            class="toggle-multiselect"
            fxLayout="row"
            fxLayoutAlign="center center"
            mat-button
            [matTooltip]="'modules.equipment.equipmentList.activateSelection'|translate"
            (click)="toggleMultiselect(); multiMenu.toggle()">
            <mat-icon>playlist_add_check</mat-icon>
          </button>

          <div *ngIf="displayService.isTableFullscreen() && !multiselectActive"
            class="change-display-mode"
            fxLayout="row"
            fxFlex="60px"
            fxLayoutAlign="center center"
            [matTooltip]="'general.labels.detailView.expand'|translate"
            [matTooltipDisabled]="multiselectActive"
            (click)="openDetails()">
            <mat-icon>chevron_left</mat-icon>
          </div>
        </div>
        <div class="list-view-main__left-body scrollable-table-container"
          fxFlex
          fxLayout="row"
          fxLayoutGap="15px"
          fxLayoutAlign="start stretch">
          <div *ngIf="!(equipmentStore.equipments | async)" class="not-found" fxFlex>
            {{'general.labels.noAnyFound'|translate:{ value: 'general.equipm.s' } }}
          </div>

          <mat-table #table
            fxFlex
            [dataSource]="equipmentStore"
            class="pagination-data-table left-body-list scrollable-table mat-table exclude-initial-fixed-basis-size"
            [ngClass]="{'hide-header': !displayService.isTableFullscreen()}"
            matSort
            multiTemplateDataRows
            [matSortActive]="defaultSort.active"
            [matSortDirection]="defaultSort.direction"
            (matSortChange)="sortData($event)"
            bhResizeColumn
            [resizeColumnConfigs]="columnService.columnConfigs | async"
            (resizeColumnEnd)="columnService.updateColumnConfigs($event)">

            <ng-container cdkColumnDef="equipmentOverview">
              <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
              <mat-cell *cdkCellDef="let row" fxFlex class="left-body-list__overview-cell" (click)="selectedCell(row)">
                <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                  <bh-thumbnail *ngIf="row.thumbnailKey" [key]="row.thumbnailKey" [size]="ThumbnailSize.XS">
                  </bh-thumbnail>
                </div>
                <div class="overview-row" fxLayout="row">
                  <div class="left-body-list__info" fxFlex fxLayout="column">
                    <p class="equipment-list-column">
                      <span class="name">{{row | equipmentName}}</span>
                    </p>
                    <bh-equipment-info [equipment]="row"></bh-equipment-info>
                    <mat-chip-set>
                      <mat-chip [disabled]="true" *ngFor="let label of getFirstLabels(row, true)">
                        {{ label }}
                      </mat-chip>
                      <mat-chip *ngIf="getRemainingLabels(row, true).length > 0"
                        matTooltipClass="mat-tooltip-multiline"
                        [disabled]="true"
                        [matTooltip]="getRemainingLabels(row, true).join('\n')">
                        {{ '+' + getRemainingLabels(row, true).length }}
                      </mat-chip>
                    </mat-chip-set>
                  </div>
                  <div *ngIf="row.numberOfSubequipments > 0" class="sub-arrow" (click)="selectEquipment(row)">
                    <span class="badge sub-equipment-number-badge"
                      [ngClass]="{'badge-big': row.numberOfSubequipments >= 100}"
                      matTooltip="{{'modules.equipment.equipmentList.numberOfSubequipments'|translate}}: {{row.numberOfSubequipments}}">
                      {{row.numberOfSubequipments}}
                    </span>
                    <fa-icon [icon]="resolveArrowIcon(row)"></fa-icon>
                  </div>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container [cdkColumnDef]="column.cdkColumnDef" *ngFor="let column of selectedColumns">

              <ng-container *ngIf="column.type === COLUMN_TYPE.SELECT">
                <mat-header-cell *cdkHeaderCellDef class="equipment-list-column select-column table-column-100 header-border-right"></mat-header-cell>
                <mat-cell *cdkCellDef="let row" class="equipment-list-column select-column table-column-100">
                  <mat-checkbox color="primary"
                    (click)="$event.stopPropagation()"
                    (change)="onCheckboxToggle(row, $event)"
                    [checked]="selected(row)"
                    [disabled]="equipmentCheckerService.isInactiveEquipment(row)"
                    [matTooltipDisabled]="!equipmentCheckerService.isInactiveEquipment(row)"
                    [matTooltip]="'modules.equipment.base.inactiveEquipm'|translate">
                  </mat-checkbox>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" class="equipment-list-column" bhResizeColumnCell>
                  <span>{{ column.valueCallback(row) }}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.IMAGE">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" class="equipment-list-column" bhResizeColumnCell>
                  <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                    <bh-thumbnail *ngIf="row.thumbnailKey" [key]="row.thumbnailKey" [size]="ThumbnailSize.XS">
                    </bh-thumbnail>
                  </div>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.ADDRESS">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <bh-table-cell-address [address]="column.valueCallback(row)"></bh-table-cell-address>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.LABELS">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <mat-chip-set>
                    <mat-chip [disabled]="true" *ngFor="let label of getFirstLabels(row)">{{ label }}</mat-chip>
                    <mat-chip *ngIf="getRemainingLabels(row).length > 0"
                      [disabled]="true"
                      matTooltipClass="mat-tooltip-multiline"
                      [matTooltip]="getRemainingLabels(row).join('\n')">
                      {{ '+' + getRemainingLabels(row).length }}
                    </mat-chip>
                  </mat-chip-set>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.DATE">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row"
                  bhResizeColumnCell
                  class="equipment-list-column"
                  [innerHTML]="column.valueCallback(row) | date: 'dd.MM.yyyy'">
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.CURRENT_LOCATION_NAME">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <bh-location-column-data [columnDefName]="GENERAL_COLUMN_DEF.CURRENT_LOCATION_NAME"
                    [value]="row.currentLocationName"
                    [locationType]="row | equipmentLocationTypeResolver"
                    [locationId]="row.projectId ? row.projectId : row.stockId"
                    [isAllowedToSeeLocation]="row.isAllowedToSeeLocation">
                  </bh-location-column-data>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.CURRENT_LOCATION_NUMBER">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <bh-location-column-data [columnDefName]="GENERAL_COLUMN_DEF.CURRENT_LOCATION_NUMBER"
                    [value]="row.currentLocationNumber"
                    [locationType]="row | equipmentLocationTypeResolver"
                    [locationId]="row.projectId ? row.projectId : row.stockId"
                    [isAllowedToSeeLocation]="row.isAllowedToSeeLocation">
                  </bh-location-column-data>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.CURRENT_LOCATION_COST_CENTER">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell class="equipment-list-column">
                  <bh-location-column-data [columnDefName]="GENERAL_COLUMN_DEF.CURRENT_LOCATION_COST_CENTER"
                    [value]="row.currentLocationCostCenter"
                    [locationType]="row | equipmentLocationTypeResolver"
                    [locationId]="row.projectId ? row.projectId : row.stockId"
                    [isAllowedToSeeLocation]="row.isAllowedToSeeLocation">
                  </bh-location-column-data>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_INFO">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell class="equipment-list-column__header-info">
                  <bh-header-cell-title>{{'general.info'|translate}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row"
                  bhResizeColumnCell
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="3px">
                  <mat-icon *ngIf="equipmentCheckerService.isSearchEquipmentLinkedToTelematicsUnit(row)"
                    fontSet="icon-telematics_outline"
                    class="telematics-link-icon"
                    [matTooltip]="(equipmentCheckerService.hasEquipmentCurrentTelematicsData(row)
                      ? 'modules.equipment.equipmentList.telematicData'
                      : 'modules.equipment.equipmentList.telematicNoData') | translate"
                    [ngClass]="{'no-data': !equipmentCheckerService.hasEquipmentCurrentTelematicsData(row)}">
                  </mat-icon>
                  <bh-equipment-transport-types-icon class="font-size-17"
                    [equipment]="row"></bh-equipment-transport-types-icon>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_FAVOURITE">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell class="favourite-main" *cdkCellDef="let row"
                          bhResizeColumnCell>
                  <mat-icon *ngIf="isFavourite(row)" class="favourite-active">star</mat-icon>
                  <mat-icon *ngIf="!isFavourite(row)">star_border</mat-icon>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.STATUS">
                <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell class="equipment-list-column">
                  <bh-header-cell-title>{{'general.status'|translate}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                  <bh-equipment-status-badge [_status]="row.status"></bh-equipment-status-badge>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.DETAILS">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell></mat-header-cell>
                <mat-cell *cdkCellDef="let row"
                  bhResizeColumnCell
                  [routerLink]="[row.equipmentId]"
                  [matTooltip]="'modules.equipment.equipmentList.openDetails'|translate"
                  (click)="openDetails()">
                  <mat-icon *ngIf="!multiselectActive">info</mat-icon>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.ATTENTION_STATUS">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" class="equipment-list-column" bhResizeColumnCell>
                  <bh-color-badge [value]="column.valueCallback(row) | attentionStatusPipe"
                                  [color]="column.valueCallback(row) | statusColorPipe">
                  </bh-color-badge>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column.type === COLUMN_TYPE.CORE_DATA">
                <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                  <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                </mat-header-cell>
                <mat-cell *cdkCellDef="let row" class="equipment-list-column" bhResizeColumnCell>
                  <bh-core-data [percentage]="column.valueCallback(row)"></bh-core-data>
                </mat-cell>
              </ng-container>
            </ng-container>

            <ng-container [cdkColumnDef]="COLUMN_SUB_EQUIPMENT">
              <mat-header-cell class="table-column-80 header-border-right" *cdkHeaderCellDef></mat-header-cell>
              <mat-cell *cdkCellDef="let row" class="table-column-80">
                <div *ngIf="row.numberOfSubequipments > 0" class="sub-arrow" (click)="selectEquipment(row)">
                  <span class="badge sub-equipment-number-badge"
                    [ngClass]="{'badge-big': row.numberOfSubequipments >= 100}"
                    matTooltip="{{'modules.equipment.equipmentList.numberOfSubequipments'|translate}}: {{row.numberOfSubequipments}}">
                    {{row.numberOfSubequipments}}
                  </span>
                  <fa-icon [icon]="resolveArrowIcon(row)"></fa-icon>
                </div>
              </mat-cell>
            </ng-container>

            <ng-container cdkColumnDef="subTable">
              <mat-header-cell class="table-column-100" *cdkHeaderCellDef></mat-header-cell>
              <mat-cell *cdkCellDef="let element"
                class="expandable-cell table-column-100"
                [@rowsExpand]="checkExpanded(element) ? 'expanded' : 'collapsed'">
                <div *ngIf="subequipmentsExist(element)" class="expandable-div">
                  <mat-table class="sub-table pagination-data-table left-body-list"
                    [dataSource]="element.subEquipmentList">

                    <ng-container cdkColumnDef="equipmentOverview">
                      <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                      <mat-cell class="sub-overview-column" *cdkCellDef="let row" fxFlex>
                        <mat-icon>subdirectory_arrow_right</mat-icon>
                        <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                          <bh-thumbnail *ngIf="row.thumbnailKey" [key]="row.thumbnailKey" [size]="ThumbnailSize.XS">
                          </bh-thumbnail>
                        </div>
                        <div class="left-body-list__info" fxFlex fxLayout="column">
                          <p class="equipment-list-column">
                            <span class="name">{{row | equipmentName}}</span>
                          </p>
                          <bh-equipment-info [equipment]="row"></bh-equipment-info>
                          <mat-chip-set>
                            <mat-chip *ngFor="let label of getFirstLabels(row, true)" [disabled]="true">{{label}}</mat-chip>
                            <mat-chip *ngIf="getRemainingLabels(row, true).length > 0"
                              matTooltipClass="mat-tooltip-multiline"
                              [disabled]="true"
                              [matTooltip]="getRemainingLabels(row, true).join('\n')">
                              {{ '+' + getRemainingLabels(row, true).length }}
                            </mat-chip>
                          </mat-chip-set>
                        </div>
                      </mat-cell>
                    </ng-container>

                    <ng-container *ngFor="let column of selectedColumns; let i = index" [cdkColumnDef]="column.cdkColumnDef">

                      <ng-container *ngIf="column.type === COLUMN_TYPE.SELECT">
                        <mat-header-cell *cdkHeaderCellDef
                          class="equipment-list-column select-column table-column-100 header-border-right"></mat-header-cell>
                        <mat-cell *cdkCellDef="let row" bhFirstColumn [orderNumber]="i"
                          class="sub-table-cell equipment-list-column select-column table-column-100">
                          <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                            (change)="onCheckboxToggle(row, $event)" [checked]="selected(row)">
                          </mat-checkbox>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.VALUE">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row" bhFirstColumn [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <span>{{ column.valueCallback(row) }}</span>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.CURRENT_LOCATION_NAME">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row" bhFirstColumn [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <bh-location-column-data [columnDefName]="GENERAL_COLUMN_DEF.CURRENT_LOCATION_NAME"
                            [value]="row.currentLocationName"
                            [locationType]="row | equipmentLocationTypeResolver"
                            [locationId]="row.projectId ? row.projectId : row.stockId"
                            [isAllowedToSeeLocation]="row.isAllowedToSeeLocation">
                          </bh-location-column-data>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.CURRENT_LOCATION_NUMBER">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <bh-location-column-data [columnDefName]="GENERAL_COLUMN_DEF.CURRENT_LOCATION_NUMBER"
                            [value]="row.currentLocationNumber"
                            [locationType]="row | equipmentLocationTypeResolver"
                            [locationId]="row.projectId ? row.projectId : row.stockId"
                            [isAllowedToSeeLocation]="row.isAllowedToSeeLocation">
                          </bh-location-column-data>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.CURRENT_LOCATION_COST_CENTER">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <bh-location-column-data [columnDefName]="GENERAL_COLUMN_DEF.CURRENT_LOCATION_COST_CENTER"
                            [value]="row.currentLocationCostCenter"
                            [locationType]="row | equipmentLocationTypeResolver"
                            [locationId]="row.projectId ? row.projectId : row.stockId"
                            [isAllowedToSeeLocation]="row.isAllowedToSeeLocation">
                          </bh-location-column-data>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.IMAGE">
                        <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <div class="left-body-list__icon" fxLayout="row" fxLayoutAlign="center center">
                            <bh-thumbnail *ngIf="row.thumbnailKey" [key]="row.thumbnailKey" [size]="ThumbnailSize.XS">
                            </bh-thumbnail>
                          </div>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.ADDRESS">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <bh-table-cell-address [address]="column.valueCallback(row)"></bh-table-cell-address>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.LABELS">
                        <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column">
                          <mat-chip-set>
                            <mat-chip [disabled]="true" *ngFor="let label of getFirstLabels(row)">{{ label }}</mat-chip>
                            <mat-chip *ngIf="getRemainingLabels(row).length > 0"
                              [disabled]="true"
                              matTooltipClass="mat-tooltip-multiline"
                              [matTooltip]="getRemainingLabels(row).join('\n')">
                              {{ '+' + getRemainingLabels(row).length }}
                            </mat-chip>
                          </mat-chip-set>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.DATE">
                        <mat-header-cell *cdkHeaderCellDef mat-sort-header bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell equipment-list-column"
                          [innerHTML]="column.valueCallback(row) | date: 'dd.MM.yyyy'">
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_INFO">
                        <mat-header-cell *cdkHeaderCellDef
                          bhResizeColumnCell
                          class="equipment-list-column__header-info">
                          <bh-header-cell-title>{{'general.info'|translate}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhResizeColumnCell
                          fxLayout="row"
                          fxLayoutAlign="start center"
                          fxLayoutGap="3px">
                          <mat-icon *ngIf="equipmentCheckerService.isSearchEquipmentLinkedToTelematicsUnit(row)"
                            fontSet="icon-telematics_outline"
                            class="telematics-link-icon"
                            [matTooltip]="(equipmentCheckerService.hasEquipmentCurrentTelematicsData(row)
                              ? 'modules.equipment.equipmentList.telematicData'
                              : 'modules.equipment.equipmentList.telematicNoData')|translate"
                            [ngClass]="{'no-data': !equipmentCheckerService.hasEquipmentCurrentTelematicsData(row)}">
                          </mat-icon>
                          <bh-equipment-transport-types-icon class="font-size-17" [equipment]="row"></bh-equipment-transport-types-icon>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.STATUS">
                        <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell class="equipment-list-column">
                          <bh-header-cell-title>{{'general.status'|translate}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row" bhResizeColumnCell>
                          <bh-equipment-status-badge [_status]="row.status"></bh-equipment-status-badge>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.DETAILS">
                        <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell></mat-header-cell>
                        <mat-cell *cdkCellDef="let row"
                          bhFirstColumn
                          [orderNumber]="i"
                          bhResizeColumnCell
                          class="sub-table-cell"
                          [routerLink]="[row.equipmentId]"
                          [matTooltip]="'modules.equipment.equipmentList.openDetails'|translate"
                          (click)="openDetails()">
                          <mat-icon *ngIf="!multiselectActive">info</mat-icon>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.EQUIPMENT_FAVOURITE">
                        <mat-header-cell bhResizeColumnCell *cdkHeaderCellDef class="equipment-list-column"l>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell class="favourite-main" *cdkCellDef="let row"
                                  bhResizeColumnCell>
                          <mat-icon *ngIf="isFavourite(row)" class="favourite-active">star</mat-icon>
                          <mat-icon *ngIf="!isFavourite(row)">star_border</mat-icon>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.cdkColumnDef === GENERAL_COLUMN_DEF.ATTENTION_STATUS">
                        <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row" class="equipment-list-column" bhResizeColumnCell>
                          <bh-color-badge [value]="column.valueCallback(row) | attentionStatusPipe"
                                          [color]="column.valueCallback(row) | statusColorPipe">
                          </bh-color-badge>
                        </mat-cell>
                      </ng-container>

                      <ng-container *ngIf="column.type === COLUMN_TYPE.CORE_DATA">
                        <mat-header-cell *cdkHeaderCellDef bhResizeColumnCell>
                          <bh-header-cell-title>{{column.title}}</bh-header-cell-title>
                        </mat-header-cell>
                        <mat-cell *cdkCellDef="let row" class="equipment-list-column" bhResizeColumnCell>
                          <bh-core-data [percentage]="column.valueCallback(row)"></bh-core-data>
                        </mat-cell>
                      </ng-container>
                    </ng-container>

                    <ng-container [cdkColumnDef]="COLUMN_SUB_EQUIPMENT">
                      <mat-header-cell *cdkHeaderCellDef class="table-column-80 header-border-right"></mat-header-cell>
                      <mat-cell *cdkCellDef="let row" class="table-column-80"></mat-cell>
                    </ng-container>

                    <mat-row *cdkRowDef="let row; columns: displayedColumns"
                      class="sub-table-row"
                      [routerLink]="[row.equipmentId]"
                      routerLinkActive="left-body-item--active"
                      (dblclick)="openDetails()">
                    </mat-row>
                  </mat-table>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *cdkRowDef="let row; columns: displayedColumns"
              [routerLink]="[row.equipmentId]"
              routerLinkActive="left-body-item--active"
              (dblclick)="openDetails()">
            </mat-row>
            <mat-row *cdkRowDef="let row; columns: ['subTable']" class="expandable-row"></mat-row>
          </mat-table>
        </div>

        <div class="list-view-main__left-body-paginator" fxLayout="row wrap">
          <div id="list-settings" fxFlex="80px">
            <button id="configBtn"
              mat-icon-button
              class="rotatable"
              [matTooltip]="'general.labels.confColumnArrangement'|translate"
              (click)="openConfiguration()">
              <mat-icon class="rotatable">settings</mat-icon>
            </button>
            <button id="exportBtn"
              mat-icon-button
              [matTooltip]="'modules.equipment.equipmentList.exportEquipmentList'|translate"
              (click)="openEquipmentListExport()">
              <fa-icon [icon]="faFileExcel" size="2x"></fa-icon>
            </button>
          </div>
          <mat-paginator #paginator
            fxFlex="0"
            bhCurrentItemPaginator
            [length]="equipmentStore.length | async"
            [pageIndex]="equipmentStore.pagination.index"
            [pageSize]="equipmentStore.pagination.size"
            [pageSizeOptions]="[5, 25, 50, 100]"
            [data]="equipmentStore.equipments | async"
            [currentItemId]="(this.equipmentStore.currentEquipment | async)?.equipmentId"
            [isIdentifierOfItem]="isIdentifierOfEquipment" (paginationChanges)="onPaginateChange($event)">
          </mat-paginator>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="list-view-main-container"
    fxFlex="{{ displayService.isDetailsFullscreen() ? 100 : 65}}"
    [ngClass]="{'list-item-hidden': displayService.isTableFullscreen()}">
    <mat-card-content class="list-view-main"
      fxFlexFill
      fxLayout="row"
      fxLayoutAlign="start stretch">
      <div fxFlex>
        <router-outlet></router-outlet>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-drawer-container [ngClass]="{'drawer_multiselect': multiselectActive}">
    <mat-drawer #multiMenu mode="side" opened="false">
      <div class="multi-drawer-header" fxLayout="column" fxLayoutAlign="start stretch">
        <button mat-raised-button class="multi-drawer-header_select-all" (click)="selectAll()" color="primary">
          <span>{{'general.buttons.selectAll'|translate}}</span>
        </button>
      </div>
      <div class="functions-buttons" fxLayout="column" fxLayoutAlign="start">
        <div class="drawer_equipment-count">
          {{selectedEquipments.size + ' ' + ('modules.equipment.equipmentList.equipmentsSelected'|translate)}}
        </div>
        <button id="labelButton"
          mat-raised-button
          [disabled]="!elementsSelected() || !hasManageLabelsAuthority()"
          (click)="openLabelDialogue()">
          {{'modules.equipment.equipmentList.addLabel'|translate}}
        </button>
        <button mat-raised-button (click)="emptySelection()" [disabled]="!elementsSelected()">
          {{'general.buttons.deselect'|translate}}
        </button>
        <button mat-raised-button (click)="toggleMultiselect()">
          {{'general.buttons.cancel'|translate}}
        </button>
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>
