import { Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GuardedNavigableInputComponent } from '../../../../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../../../../core/keycloak';
import { Observable } from 'rxjs';
import { ViewBulkItem } from '../../../../../contract/view-bulk-item.interface';
import { BulkItemDatasource } from '../../../../../shared/bulk-item.datasource';
import { BulkItemFieldStore } from '../../../../service/bulk-item-field.store';
import { DocumentLink } from '../../../../../../../shared/contract/document-link.interface';
import { FileUtils } from '../../../../../../../shared/fileUtils';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { FieldLimit } from '../../../../../../../shared/enums/fieldLimit.enum';
import { OrganisationInfoService } from '../../../../../shared/services/organisation-info.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../../../../environments/environment';

@UntilDestroy()
@Directive()
export abstract class BaseBulkItemViewDetailComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public readonly fieldLimit = FieldLimit;
  public imageKeys: string[] = [];
  public bulkItem: ViewBulkItem;

  public customerLabels: Observable<string[]>;
  public bulkItemLabels: Observable<string[]>;

  constructor(protected bulkItemStore: BulkItemDatasource,
              protected bulkItemFieldStore: BulkItemFieldStore,
              protected organisationsService: OrganisationInfoService,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected router: Router,
              protected routerHistory: RouterHistory) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit(): void {
    this.getBulkItem();
    this.customerLabels = this.bulkItemStore.filteredCustomerLabels;
  }

  public ngOnDestroy(): void {
  }

  private getBulkItem(): void {
    setTimeout(() => {
      this.bulkItemStore.currentBulkItem
        .pipe(untilDestroyed(this))
        .subscribe((res: ViewBulkItem) => {
          if (res) {
            this.bulkItem = res;
            this.bulkItemLabels = this.bulkItemStore.bulkItemLabels;
            this.getBulkItemImages(res);
          }
        });
    }, environment.DELAY_SHORT)
  }

  private getBulkItemImages(bulkItem: ViewBulkItem): void {
    this.bulkItemStore.getDocuments(bulkItem.bulkItemId)
      .pipe(
          untilDestroyed(this),
          map(documentLinks => FileUtils.filterAndSortImages(documentLinks, bulkItem?.thumbnail)))
      .subscribe((imageLinks: DocumentLink[]) => {
        this.imageKeys = (imageLinks || []).map(doc => doc.documentKey);
      });
  }
}
