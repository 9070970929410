import { Component, Input } from '@angular/core';
import { KeycloakService } from 'app/core/keycloak';
import { Router } from '@angular/router';
import { EquipmentsDataSource } from '../equipments.datasource';
import { EquipmentCheckerService } from '../services/equipment-checker.service';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import { TelematicsUnitType } from '../../contract/telematics-unit-type.enum';
import { Modules } from 'app/shared/enums/modules.enum';
import { EquipmentLogOperatingHoursComponent } from '../../equipments/equipment-log-lifecycle/equipment-log-operating-hours/landscape/equipment-log-operating-hours.component';
import { EquipmentLogMileageComponent } from '../../equipments/equipment-log-lifecycle/equipment-log-mileage/landscape/equipment-log-mileage.component';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { delay, filter } from 'rxjs/operators';

@Component({
  selector: 'bh-equipment-events-log',
  templateUrl: './equipment-events-log.component.html',
  styleUrls: ['./equipment-events-log.component.scss']
})
export class EquipmentEventsLogComponent {

  @Input() isEventsTab = false;

  public get equipment(): ViewEquipment {
    return this.equipmentDatasource.getCurrentEquipment();
  }

  constructor(
    private authService: KeycloakService,
    private router: Router,
    private dialog: MatDialog,
    private equipmentDatasource: EquipmentsDataSource,
    private equipmentCheckerService: EquipmentCheckerService,
  ) { }

  public canLogEquipmentEvents(): boolean {
    return  this.hasEquipmentEventModule() &&
            this.isActiveEquipment() &&
            this.hasAvailableActions();
  }

  private hasEquipmentEventModule(): boolean {
    return this.isEventsTab ? this.authService.hasModule(Modules.EQUIPMENT_EVENT_MANAGEMENT) : true;
  }

  public isActiveEquipment(): boolean {
    return this.equipmentCheckerService.isActiveEquipment(this.equipment);
  }

  private hasAvailableActions(): boolean {
    return  this.canLogEquipmentIncident() ||
            this.canLogEquipmentOperatingHours() ||
            this.canLogEquipmentMileage() ||
            this.canLogEquipmentDamage();
  }

  public canLogEquipmentIncident(): boolean {
    return this.hasEquipmentAddLifecycleDataOrEquipmentUpdateOrProvidedAuthority(Authorities.EQUIPMENT_MANAGE_LIFECYCLE_EVENTS);
  }

  public canLogEquipmentOperatingHours(): boolean {
    return this.allowManualLifecycle(this.equipment?.hasLiveOperatingHours, Authorities.EQUIPMENT_MANAGE_OPERATING_HOURS);
  }

  public canLogEquipmentMileage(): boolean {
    return this.allowManualLifecycle(this.equipment?.hasLiveMileage, Authorities.EQUIPMENT_MANAGE_MILEAGE);
  }

  public canLogEquipmentDamage(): boolean {
    return this.hasEquipmentAddLifecycleDataOrEquipmentUpdateOrProvidedAuthority(Authorities.EQUIPMENT_MANAGE_DAMAGES);
  }

  private allowManualLifecycle(isLiveData: boolean, authority: Authorities): boolean {
    return !isLiveData &&
            this.hasEquipmentAddLifecycleDataOrEquipmentUpdateOrProvidedAuthority(authority) &&
            this.equipmentCheckerService.isActiveEquipment(this.equipment) &&
            !this.equipmentCheckerService.isRentedEquipment(this.equipment) &&
            !this.hasTelematicsLiveData();
  }

  private hasEquipmentAddLifecycleDataOrEquipmentUpdateOrProvidedAuthority(authority): boolean {
    return  this.authService.hasAuthority(Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA) ||
            this.authService.hasAuthority(Authorities.EQUIPMENT_UPDATE) ||
            this.authService.hasAuthority(authority);
  }

  private hasTelematicsLiveData(): boolean {
    return  this.isAssignedToTeltonikaTelematicUnit() &&
            this.equipmentCheckerService.hasEquipmentCurrentTelematicsData(this.equipment);
  }

  private isAssignedToTeltonikaTelematicUnit(): boolean {
    if (this.equipmentCheckerService.isViewEquipmentLinkedToTelematicsUnit(this.equipment)) {
      return [
        TelematicsUnitType.TELTONIKA_OBD_UNIT,
        TelematicsUnitType.TELTONIKA_CABLE_UNIT,
      ].includes(this.equipment.assignedTelematicsUnits[0].key as TelematicsUnitType);
    }

    return false;
  }

  public equipmentLogIncident(): void {
    this.router.navigate([`/equipments/${this.equipment.equipmentId}/add-incident`]);
  }

  public equipmentRecordOperatingHours(): void {
    this.openLoggingDialog(EquipmentLogOperatingHoursComponent);
  }


  public equipmentRecordMileage(): void {
    this.openLoggingDialog(EquipmentLogMileageComponent);
  }

  public equipmentLogDamage(): void {
    this.router.navigate([`/equipments/${this.equipment.equipmentId}/add-damage`]);
  }

  private openLoggingDialog(component: ComponentType<any>): void {
    const dialogRef = this.dialog.open(component, <MatDialogConfig>{
      disableClose: true,
    });
    dialogRef.componentInstance.dialogRef = dialogRef;
    dialogRef.afterClosed()
      .pipe(
        delay(environment.DELAY_SHORT),
        filter((dialogResult: string) => dialogResult === 'saved'))
      .subscribe(() => {
        this.equipmentDatasource.getLifeCycles(this.equipment.equipmentId);
        this.equipmentDatasource.updateCurrentEquipment();
        this.equipmentDatasource.updateListing();
      });
  }

}
