
import { LanguageService } from '../services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { EQUIPMENT_EVENT_COLUMNS } from 'app/modules/equipment/contract/equipment-event-columns-constants';
import { EVENT_DETAILS_EVENT_COLUMNS } from 'app/modules/equipment/contract/event-details-event-columns-constants';
import {
  DAMAGE_PAYLOAD_COLUMNS,
  EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS,
  GEOFENCE_PAYLOAD_COLUMNS,
  INCIDENT_PAYLOAD_COLUMNS,
  MACHINE_FAULT_PAYLOAD_COLUMNS,
  MAINTENANCE_PAYLOAD_COLUMNS,
  MILEAGE_PAYLOAD_COLUMNS,
} from 'app/modules/equipment/contract/event-details-payload-columns-constants';

@Pipe({
  name: 'columnNamePipe',
})
export class ColumnNamePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(name: string): string {
    switch (name) {
      case DAMAGE_PAYLOAD_COLUMNS.damageDate:
        return this.translate('modules.equipment.damage.damageDate');
      case DAMAGE_PAYLOAD_COLUMNS.damageLocation:
        return this.translate('modules.equipment.damage.damageLocation');
      case DAMAGE_PAYLOAD_COLUMNS.damageType:
        return this.translate('modules.equipment.damage.damageType');
      case DAMAGE_PAYLOAD_COLUMNS.documentation:
        return this.translate('modules.equipment.damage.documentation');
      case DAMAGE_PAYLOAD_COLUMNS.invoiceNumber:
        return this.translate('general.columnNames.invoiceNumber');
      case DAMAGE_PAYLOAD_COLUMNS.mileage:
        return this.translate('general.columnNames.mileage');
      case DAMAGE_PAYLOAD_COLUMNS.operatingHours:
        return this.translate('general.columnNames.operatingHours');
      case DAMAGE_PAYLOAD_COLUMNS.submittedBy:
        return this.translate('general.columnNames.submittedBy');
      case GEOFENCE_PAYLOAD_COLUMNS.geofenceName:
        return this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.geofence')},
        );
      case GEOFENCE_PAYLOAD_COLUMNS.locationName:
        return this.translate(
          'general.columnNames.nameValue',
          {value: this.translate('general.columnNamesValue.location')},
        );
      case GEOFENCE_PAYLOAD_COLUMNS.locationType:
        return this.translate(
          'general.columnNames.typeValue',
          {value: this.translate('general.columnNamesValue.location')},
        );
      case GEOFENCE_PAYLOAD_COLUMNS.interactionType:
        return this.translate(
          'general.columnNames.typeValue',
          {value: this.translate('general.columnNamesValue.interaction')},
        );
      case EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS.employee:
        return this.translate('general.columnNames.employee');
      case EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS.employeeRole:
        return this.translate('general.columnNames.employeeRole');
      case EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS.handOverDate:
        return this.translate('general.columnNames.handOverDate');
      case INCIDENT_PAYLOAD_COLUMNS.description:
        return this.translate('general.columnNames.description');
      case INCIDENT_PAYLOAD_COLUMNS.logDate:
        return this.translate('general.columnNames.logDate');
      case INCIDENT_PAYLOAD_COLUMNS.subject:
        return this.translate('general.columnNames.subject');
      case MAINTENANCE_PAYLOAD_COLUMNS.completedAtDate:
        return this.translate('general.columnNames.completedAtDate');
      case MAINTENANCE_PAYLOAD_COLUMNS.completedAtMileage:
        return this.translate('general.columnNames.completedAtMileage');
      case MAINTENANCE_PAYLOAD_COLUMNS.completedAtOperatingHours:
        return this.translate('general.columnNames.completedAtOperatingHours');
      case MAINTENANCE_PAYLOAD_COLUMNS.dueDate:
        return this.translate('general.columnNames.dueDate');
      case MAINTENANCE_PAYLOAD_COLUMNS.maintenanceResult:
        return this.translate('general.columnNames.maintenanceResult');
      case MAINTENANCE_PAYLOAD_COLUMNS.taskName:
        return this.translate('general.columnNames.taskName');
      case MILEAGE_PAYLOAD_COLUMNS.comment:
        return this.translate('general.columnNames.comment');
      case MACHINE_FAULT_PAYLOAD_COLUMNS.clearedDateTime:
        return this.translate('general.columnNames.clearedDateTime');
      case MACHINE_FAULT_PAYLOAD_COLUMNS.dateTime:
        return this.translate('general.columnNames.dateTime');
      case MACHINE_FAULT_PAYLOAD_COLUMNS.severity:
        return this.translate('general.columnNames.severity');
      case MACHINE_FAULT_PAYLOAD_COLUMNS.source:
        return this.translate('general.columnNamesValue.source');
      case EQUIPMENT_EVENT_COLUMNS.CREATION_DATE:
        return this.translate('general.columnNames.creationDate');
      case EQUIPMENT_EVENT_COLUMNS.OCCURRENCES:
        return this.translate('general.columnNames.occurrences');
      case EQUIPMENT_EVENT_COLUMNS.TYPE:
        return this.translate('general.columnNames.type');
      case EQUIPMENT_EVENT_COLUMNS.STATUS:
        return this.translate('general.columnNames.status');
      case EVENT_DETAILS_EVENT_COLUMNS.TIMESTAMP:
        return this.translate('general.columnNames.date');
    }
    return name;
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
}
