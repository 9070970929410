import { Component, Input } from '@angular/core';
import { faArrowDownUpLock, faArrowDownUpAcrossLine, faLockA } from '@fortawesome/pro-light-svg-icons';
import { AutomatedTransfersStatusList } from 'app/shared/enums/automated-transfers-status-list.enum';

@Component({
  selector: 'bh-equipment-automated-transfers-icon',
  templateUrl: './equipment-automated-transfers-icon.component.html',
  styleUrls: ['./equipment-automated-transfers-icon.component.scss']
})
export class EquipmentAutomatedTransfersIconComponent {

  @Input() status: AutomatedTransfersStatusList;
  @Input() tooltipDisabled = false;

  protected faArrowDownUpAcrossLine = faArrowDownUpAcrossLine;
  protected faArrowDownUpLock = faArrowDownUpLock;
  protected faLockA = faLockA;
  protected AutomatedTransfersStatusList = AutomatedTransfersStatusList;

  constructor() {}
}
