<div class="list-view-main__right" fxFlexFill fxLayout="column">

  <div class="list-view-main__right-header" fxFlex="60px" fxLayout="row"
       fxLayoutAlign="space-between center">
    <div (click)="displayService.displayDetailsFullscreen()" class="full-screen" fxFlex="0 0 70px"
         fxLayout="row"
         fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.enlarge'|translate"
         *ngIf="displayService.isTableAndDetails()">
      <mat-icon fontSet="icon-fullscreen_lauch_outline"></mat-icon>
    </div>
    <div (click)="displayService.displayTableAndDetails()" class="full-screen" fxFlex="0 0 70px"
         fxLayout="row"
         fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.reduce'|translate"
         *ngIf="displayService.isDetailsFullscreen()">
      <mat-icon fontSet="icon-fullscreen_exit_outline"></mat-icon>
    </div>
    <div class="name equipment-list-column" fxFlex="10 1 100%" fxLayoutAlign="start center">
      <bh-equipment-status-state-change-icon class="status-icon"
                                             [equipment]="equipment"
                                             [editable]="hasAnyAuthority([
                                             authorities.EQUIPMENT_UPDATE,
                                             authorities.EQUIPMENT_STATUS_UPDATE_ANY,
                                             authorities.EQUIPMENT_STATUS_UPDATE_ACTIVE_INACTIVE,
                                             authorities.EQUIPMENT_STATUS_UPDATE_CATEGORY])">
      </bh-equipment-status-state-change-icon>
      <span class="text-overflow equipment-details-title" data-osp-test="label-equipment-name">
        {{ equipment | equipmentName }}
      </span>
      <mat-icon *ngIf="equipmentCheckerService.isViewEquipmentLinkedToTelematicsUnit(equipment)"
                fontSet="icon-telematics_outline" class="telematics-link-icon"
                [matTooltip]="createTelematicsTooltip()"
                [matTooltipClass]="'mat-tooltip-linebreak'"
                [ngClass]="{'no-data': !equipmentCheckerService.hasEquipmentCurrentTelematicsData(equipment)}">
      </mat-icon>
      <bh-equipment-transport-types-icon class="equipment-icon" [equipment]="equipment"></bh-equipment-transport-types-icon>
      <bh-equipment-automated-transfers-icon  *ngIf="isAutomatedTransfersStatusAvailable()"
                                              class="equipment-icon"
                                              [status]="equipment.automatedTransferInclusionStatus">
      </bh-equipment-automated-transfers-icon>
    </div>

    <div *ngIf="equipment && isEquipmentEventsEnable()">
      <bh-color-badge [value]="equipment.attentionStatus | attentionStatusPipe"
                      [color]="equipment.attentionStatus | statusColorPipe"
                      [showIcon]="true"
                      [brighter]="true">
      </bh-color-badge>
    </div>

    <div *ngIf="showAddDamageButton()">
      <button mat-raised-button class="log-damage more" fxLayout="row" (click)="addDamage()">
        <bh-icon fontSet="icon-violence_outline" class="info-icon"></bh-icon>
        <span>{{'modules.equipment.damage.logMaliciousDamage'|translate}}</span>
      </button>
    </div>

    <div *ngIf="isPostgresEnabled()"
         (click)="toggleFavourite()" fxFlex="0 0 20px" class="more" [matTooltip]="'general.labels.toggleFavouriteEquipment'|translate">
      <mat-icon *ngIf="equipment?.favourite ?? false" class="favourite-active">star</mat-icon>
      <mat-icon *ngIf="!equipment?.favourite">star_border</mat-icon>
    </div>

    <div fxFlex="0 0 20px" class="more" [matMenuTriggerFor]="equipmentMore" [matTooltip]="'general.buttons.actions'|translate"
         *ngIf="showMoreMenu()" (click)="trackMatomoEvent_EquipmentInfo_EquipmentActions()">
      <mat-icon>more_vert</mat-icon>
    </div>
    <mat-menu class="equipment-custom-mat-menu" #equipmentMore="matMenu">
      <ng-container *ngFor="let buttonConfig of burgerMenuButtonConfigs">
        <bh-mat-menu-item-button [config]="buttonConfig"></bh-mat-menu-item-button>
      </ng-container>
    </mat-menu>

    <div (click)="displayService.displayTableFullscreen()" class="change-display-mode" fxFlex="0 0 60px"
         fxLayout="row"
         fxLayoutAlign="center center" [matTooltip]="'general.labels.detailView.collapse'|translate"
         *ngIf="!displayService.isTableFullscreen()">
      <mat-icon>chevron_right</mat-icon>
    </div>
  </div>
  <div class="list-view-main__right-body" fxFlex fxLayout="row" fxLayoutAlign="start stretch">
    <div #submenu class="submenu" fxFlex="70px" fxLayout="column" fxLayoutAlign="start stretch">
      <div>
        <p [routerLink]="[subMenuRouterLinks.GENERAL]"
           routerLinkActive="submenu--active"
           data-osp-test="submenu-entry-general">
          <mat-icon fontSet="icon-general_outline"></mat-icon>
          <span>{{'general.generalData'|translate}}</span>
        </p>
        <p [routerLink]="[subMenuRouterLinks.TIMELINE]"
           routerLinkActive="submenu--active"
           data-osp-test="submenu-entry-timeline">
          <mat-icon fontSet="icon-timeline_outline"></mat-icon>
          <span fontSet="icon-timeline_outline">{{'modules.equipment.equipmentView.timeline'|translate}}</span>
        </p>
        <p [routerLink]="[subMenuRouterLinks.COST]" routerLinkActive="submenu--active"
           *ngIf="hasAnyAuthority([authorities.EQUIPMENT_VIEW_COSTS, authorities.EQUIPMENT_MANAGE_COSTS])
           && hasModule(modules.COSTS)"
           data-osp-test="submenu-entry-costs">
          <mat-icon fontSet="icon-costs_outline"></mat-icon>
          <span>{{'modules.equipment.base.costs'|translate}}</span>
        </p>
        <p [routerLink]="[subMenuRouterLinks.TASKS]" routerLinkActive="submenu--active"
           *ngIf="hasAnyAuthority([authorities.MAINTENANCE_TASK_VIEW, authorities.MAINTENANCE_TASK_MANAGE])
           && hasModule(modules.SERVICE)"
           data-osp-test="submenu-entry-tasks">
          <mat-icon fontSet="icon-service_outline"></mat-icon>
          <span>{{'general.inspection.pl'|translate}}</span>
          <span>{{'general.maintenance.pl'|translate}}</span>
        </p>
        <p [routerLink]="[subMenuRouterLinks.TELEMATIC]"
           routerLinkActive="submenu--active"
           *ngIf="canSeeTelematics()"
           data-osp-test="submenu-entry-telematic">
          <mat-icon fontSet="icon-telematics_outline"></mat-icon>
          <span>{{'general.telematics'|translate}}</span>
        </p>
        <p [routerLink]="[subMenuRouterLinks.DISPOSITION]"
           routerLinkActive="submenu--active"
           *ngIf="hasModule(modules.DISPOSITION)"
           data-osp-test="submenu-entry-disposition">
          <mat-icon fontSet="icon-disposition_outline"></mat-icon>
          <span>{{'general.disposition.s'|translate}}</span>
        </p>
        <p *ngIf="isEquipmentEventsEnable()"
            [routerLink]="[subMenuRouterLinks.EVENTS]"
            routerLinkActive="submenu--active"
            data-osp-test="submenu-entry-events">
          <fa-icon [icon]="faWavePulse"></fa-icon>
          <span>{{'general.event.pl'|translate}}</span>
        </p>
        <p [routerLink]="[subMenuRouterLinks.USAGE]"
           routerLinkActive="submenu--active"
           *ngIf="hasModule(modules.DISPOSITION) && canSeeUsage()"
           data-osp-test="submenu-entry-usage">
          <mat-icon fontSet="icon-disposition_outline"></mat-icon>
          <span>{{'general.usage'|translate}}</span>
        </p>
        <p [routerLink]="[subMenuRouterLinks.SUB_EQUIPMENTS]"
           routerLinkActive="submenu--active"
           data-osp-test="submenu-entry-sub-equipments">
          <mat-icon>subdirectory_arrow_right</mat-icon>
          <span>{{'general.subEquipm.pl'|translate}}</span>
        </p>
        <p [routerLink]="[subMenuRouterLinks.CONTRACTS]"
           routerLinkActive="submenu--active"
           *ngIf="canSeeContracts()"
           data-osp-test="submenu-entry-contracts">
          <mat-icon fontSet="icon-contract2_outline"></mat-icon>
          <span>{{'general.contract.s'|translate}}</span>
        </p>
        <p [routerLink]="[subMenuRouterLinks.CARD]"
           routerLinkActive="submenu--active"
           *ngIf="canSeeTelematics()"
           data-osp-test="submenu-entry-map">
          <mat-icon fontSet="icon-map_outline"></mat-icon>
          <span>{{'general.map.s'|translate}}</span>
        </p>
      </div>
      <p #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="tabsMoreMenu"
         [ngClass]="{'submenu--active': isTabMoreMenuActive()}"
         data-osp-test="submenu-entry-more">
        <mat-icon>more_horiz</mat-icon>
        <span>{{'general.buttons.more'|translate}}</span>
      </p>
    </div>

    <mat-menu class="equipment-custom-mat-menu" #tabsMoreMenu="matMenu">
      <ng-container *ngFor="let tab of hiddenTabs">
        <button mat-menu-item [routerLink]="[tab.url]">
          <mat-icon *ngIf="tab.matIconFontSet || tab.matIconContent" [fontSet]="tab.matIconFontSet">
            {{ tab.matIconContent }}
          </mat-icon>
          <mat-icon *ngIf="tab.faIcon"><fa-icon [icon]="tab.faIcon"></fa-icon></mat-icon>
          <span>{{ tab.name }}</span>
        </button>
      </ng-container>
    </mat-menu>

    <div class="content" fxFlex>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
