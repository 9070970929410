import { environment } from 'environments/environment';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { MatDialogRef } from '@angular/material/dialog';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { CreateProjectToStockTransferCartCommand } from '../../contract/create-project-to-stock-transfer-cart-command';
import { ViewProjectAmount } from '../../contract/view-project-amount.interface';
import { AmountsService } from '../amounts.service';
import { TransferService } from '../transfer.service';
import { delay, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { ProjectToStockTransferItem } from '../../contract/project-to-stock-transfer-item';
import { numberBiggerThanValidator } from 'app/shared/custom-validators/number-bigger-than.validator';
import { ViewBulkItemAmount } from '../../contract/view-bulk-item-amount.interface';
import { TransferItemType } from '../transfer/model/transfer-item-type.enum';
import { DecimalNumberValidityService } from '../../../../shared/services/decimal-number-validity.service';
import { TransferCartDatasource } from '../transfer-cart.datasource';
import { TransferCartItem } from '../../contract/transfer-cart-item';
import { TransferBackToStockDialogService } from '../services/transfer-back-to-stock-dialog.service';
import { SearchStock } from '../../stocks/shared/search-stock';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';
import { round } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'bh-transfer-back-to-stock',
  templateUrl: './transfer-back-to-stock-dialog.component.html',
  styleUrls: ['./transfer-back-to-stock-dialog.component.scss']
})
export class TransferBackToStockDialogComponent implements OnInit, OnDestroy {

  public currentStockAmount: ViewBulkItemAmount = null;
  public projectAmount: ViewProjectAmount = null;
  public transferItemType: TransferItemType = null;
  public stocks: SearchStock[] = [];
  public form: UntypedFormGroup;
  public filterControl: UntypedFormControl = new UntypedFormControl();
  public projectId: string;
  public stockPageSize = 100;
  public readonly faArrowRight: IconDefinition = faArrowRight;
  private stockAmounts: ViewBulkItemAmount[] = [];


  constructor(public decimalNumberValidityService: DecimalNumberValidityService,
              private transferCartDatasource: TransferCartDatasource,
              private transferBackToStockDialogService: TransferBackToStockDialogService,
              protected formBuilder: UntypedFormBuilder,
              protected dialogRef: MatDialogRef<TransferBackToStockDialogComponent>,
              protected transferService: TransferService,
              protected amountsService: AmountsService,
              protected dimensionUnitConverterPipe: DimensionUnitConverterPipe) { }

  public get amount(): AbstractControl | null {
    return this.form.get('amount');
  }

  public ngOnDestroy(): void {
  }

  public ngOnInit(): void {
    if (this.transferItemType === TransferItemType.BULKITEM) {
      this.getAmounts();
    }
    this.buildForm();
    this.onSearchFormType();
    this.form.get('stockId').valueChanges.subscribe(value => this.setStock(value));
    this.stocksSubscription();
  }

  public save(): void {
    if (this.isValid()) {
      const transferItem = ProjectToStockTransferItem
        .fromViewProjectAmount(this.projectAmount, this.projectId, this.getTransferAmount());
      const cmd = new CreateProjectToStockTransferCartCommand(this.form.get('stockId').value, [transferItem]);

      this.transferService.transferProjectToStock(cmd)
        .pipe(delay(environment.DELAY_LONG))
        .subscribe(() => {
        const currentTransferCartData: Map<string, TransferCartItem> = this.transferCartDatasource._data.getValue();
        Array.from(currentTransferCartData.keys()).forEach((transferId: string) => {
          const foundedTransferIndex: number = cmd.transferItems
            .findIndex((item: ProjectToStockTransferItem) => item.amountId === transferId);
          if (foundedTransferIndex !== -1) {
            currentTransferCartData.delete(transferId);
            this.transferCartDatasource._data.next(currentTransferCartData);
          }
        });
        this.dialogRef.close('update');
      });
    }
  }

  private getTransferAmount(): number {
    const {value} = this.amount;
    const {amountAvailable, transferItemUnit} = this.projectAmount;
    const transferAvailable = this.toUserDimensionUnit(amountAvailable, transferItemUnit);
    return value === transferAvailable ? amountAvailable : this.toSystemDimensionUnit(value);
  }

  public isValid(): boolean {
    return this.form.valid;
  }

  public getAvailable(): number {
    if (this.form.get('stockId').value) {
      return this.currentStockAmount
        ? this.toUserDimensionUnit(this.currentStockAmount.amountAvailable)
        : 0;
    } else {
      return null;
    }
  }

  public getTotal(): number {
    if (this.form.get('stockId').value) {
      return this.currentStockAmount
        ? this.toUserDimensionUnit(this.currentStockAmount.amountTotal)
        : 0;
    } else {
      return null;
    }
  }

  public getNewAvailable(): number {
    if (this.form.get('stockId').value) {
      return this.currentStockAmount
        ? round(this.toUserDimensionUnit(this.currentStockAmount.amountAvailable) + this.amount.value, 3)
        : this.amount.value;
    } else {
      return null;
    }
  }

  public getNewTotal(): number {
    if (this.form.get('stockId').value) {
      if (this.projectAmount.stockId === this.form.get('stockId').value) {
        return this.getTotal();
      }
      return this.currentStockAmount
        ? round(this.toUserDimensionUnit(this.currentStockAmount.amountTotal) + this.amount.value, 3)
        : this.amount.value;
    } else {
      return null;
    }
  }

  public onScroll(): void {
    this.stockPageSize += 100;
    this.transferBackToStockDialogService.setPaginatorSizeAndGetStocks(this.stockPageSize);
  }

  public closeDialog(): void {
    this.transferBackToStockDialogService.clearStocks();
    this.dialogRef.close()
  }

  private getAmounts(): void {
    this.amountsService.getBulkItemAmounts(this.projectAmount.transferItemId, true).pipe(untilDestroyed(this))
      .subscribe(res => {
        if (res) {
          this.stockAmounts = res;
          this.setStock(this.projectAmount.stockId);
        }
      });
  }

  private setStock(stockId: string): void {
    this.currentStockAmount = this.stockAmounts.filter(stockAmount => {
      return stockAmount.stockId === stockId && stockAmount.bulkItemId === this.projectAmount.transferItemId;
    })[0];
  }

  private buildForm(): void {
    let {amountAvailable, transferItemUnit} = this.projectAmount;

    this.form = this.formBuilder.group({
      stockId: ['', Validators.required],
      amount: [this.transferItemType === 'BULKITEM' ? 0 : 1, [
        Validators.max(this.toUserDimensionUnit(amountAvailable, transferItemUnit)),
        numberBiggerThanValidator(0),
      ]],
    });
    this.form.patchValue({'stockId': this.projectAmount.stockId});
  }

  private getStocks(): void {
    this.transferBackToStockDialogService.getStocks(
      this.projectAmount !== null ? [this.projectAmount.stockId] : null);
  }

  private onSearchFormType(): void {
    this.filterControl.valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORTEST),
        distinctUntilChanged(),
        untilDestroyed(this))
      .subscribe(() => this.transferBackToStockDialogService.setSearchValue(this.filterControl.value));
  }


  private stocksSubscription(): void {
    this.transferBackToStockDialogService.stocks
      .pipe(untilDestroyed(this))
      .subscribe(res => this.stocks = res);

    this.getStocks();
  }

  private toUserDimensionUnit(value: number, unit = this.currentStockAmount.bulkItemUnit): number {
    return this.dimensionUnitConverterPipe.toUserDimensionUnit(value, unit, 3);
  }

  private toSystemDimensionUnit(value: number, unit = this.currentStockAmount.bulkItemUnit): number {
    return this.dimensionUnitConverterPipe.toSystemDimensionUnit(value, unit, 3);
  }

}
