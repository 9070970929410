import { environment } from 'environments/environment';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CreateProjectCommand } from '../../contract/create-project-command';
import { Address } from '../../../organisation/contract/address.interface';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { ProjectDataSource } from '../../shared/project.datasource';
import { DatesService } from '../../../../shared/services/dates.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { UserConfigurationService } from '../../../../shared/services/user-configuration.service';
import { MapLoaderService } from 'app/shared/modules/map/services/map-loader.service';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { OrganisationInfo } from '../../shared/services/organisation-info.interface';
import { faMapMarkedAlt, IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { MatDialogRef } from '@angular/material/dialog';
import { ViewProject } from '../../contract/view-project.interface';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import moment from 'moment';
import {
  TransferHistoryStore
} from '../project-list/project-view/project-view-transfer-history/transfer-history.store';
import { UpdateProjectCommand } from '../../contract/update-project-command';
import { GeoLocationLimit } from 'app/shared/enums/geo-location-limit.enum';
import { LatLon } from 'app/shared/geolocation/lat-lon.interface';
import { AddressLocation } from 'app/modules/organisation/contract/address-location';
import {
  MapLocationSelectComponent
} from 'app/shared/modules/map/components/map-location-select/map-location-select.component';
import { RetryGeocoderStatus } from 'app/shared/geolocation/retry-geocoder-status.enum';
import { AddressLocationValidator } from 'app/shared/custom-validators/address-location.validator';
import { RetryGeocoder } from 'app/shared/geolocation/retry-geocoder';
import { debounceTime, distinctUntilChanged, filter, pairwise, startWith } from 'rxjs/operators';
import { ProjectBase } from '../../contract/project-base.interface';
import { Modules } from 'app/shared/enums/modules.enum';

@UntilDestroy()
@Component({
  selector: 'bh-project-add-edit',
  templateUrl: 'project-add-edit.component.html',
  styleUrls: ['project-add-edit.component.scss']
})
export class ProjectAddEditComponent extends GuardedNavigableInputComponent implements OnInit {
  @ViewChild(MapLocationSelectComponent, { static: true }) mapLocationSelect: MapLocationSelectComponent;
  public readonly fieldLimit = FieldLimit;
  public readonly faMapMarkedAlt: IconDefinition = faMapMarkedAlt;

  public projectForm: UntypedFormGroup;
  public project: ViewProject;
  public latestTransferDate: Date = null;
  public geoLocationLimit = GeoLocationLimit;
  public readonly geoLocationChangeStep = 0.001;
  public enteredAddress: Address;
  public geocoderStatus: RetryGeocoderStatus;
  public organisationFilter: UntypedFormControl = new UntypedFormControl();
  public organisations: Observable<OrganisationInfo[]> = this.projectStore.filteredOrganisations;
  public saveInProgress = false;

  private isManualAddressInput = true;

  constructor(protected authService: KeycloakService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected routerHistory: RouterHistory,
              private formBuilder: UntypedFormBuilder,
              public projectStore: ProjectDataSource,
              private dialogRef: MatDialogRef<ProjectAddEditComponent>,
              public transferHistoryStore: TransferHistoryStore,
              private userConfigurationService: UserConfigurationService,
              private mapLoaderService: MapLoaderService,
              protected cdRef: ChangeDetectorRef,
  ) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit(): void {
    this.buildForm();
    this.projectStore.loadOrganisations();
    if (this.project) {
      this.fillEditForm();
      const id = this.project.projectId;
      this.transferHistoryStore.getLatestTransfer(id);
      this.transferHistoryStore.latestTransferDate.pipe(untilDestroyed(this)).subscribe(
        data => {
          this.latestTransferDate = data;
        }
      );
      this.addressControl.markAsDirty();
      this.cdRef.detectChanges();
    }
    this.setValidators();
    this.addressInputListener();
    this.setSubscription();
    this.filterOrganisationsOnInput();
  }

  private setValidators(): void {
    this.mapLoaderService.isLoaded
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.addressControl.setAsyncValidators(AddressLocationValidator(new RetryGeocoder(new google.maps.Geocoder())));
        this.projectForm.updateValueAndValidity();
      });
  }

  private addressInputListener(): void {
    this.addressControl.valueChanges
      .pipe(
        debounceTime(environment.DELAY_SHORT),
        startWith(<AddressLocation>this.addressControl.value),
        pairwise(),
        filter(() => !this.preventValuePassingIfNotManualEntry()),
        untilDestroyed(this))
      .subscribe(([oldAddress, address]: [AddressLocation, AddressLocation]) => {
        this.compareAndHandleNewAddress(oldAddress, address);
      });
  }

  private preventValuePassingIfNotManualEntry(): boolean {
    const shouldBePrevented = !this.isManualAddressInput;
    this.isManualAddressInput = true;
    return shouldBePrevented;
  }

  private compareAndHandleNewAddress(oldAddress: AddressLocation, newAddress: AddressLocation): void {
    this.handleAddressPart(oldAddress, newAddress);
    this.handleLocationPart();
  }

  private handleAddressPart(oldAddress: AddressLocation, newAddress: AddressLocation): void {
    if (!this.isSameAddresses(oldAddress, newAddress)) {
      this.clearLocation(newAddress);
      this.enteredAddress = newAddress;
    }
  }

  private handleLocationPart(): void {
    const locationFormValue: LatLon = this.addressLocationControl.value;
    if (this.locationExists(locationFormValue)) {
      this.mapLocationSelect.mapClicked({
        latLng: { lat: locationFormValue.lat, lng: locationFormValue.lon }
      });
    }
  }

  private isSameAddresses(firstAddress: Address, secondAddress: Address): boolean {
    return firstAddress.city === secondAddress.city
      && firstAddress.postalCode === secondAddress.postalCode
      && firstAddress.street === secondAddress.street
      && firstAddress.streetNumber === secondAddress.streetNumber;
  }

  private clearLocation({ location }: AddressLocation): void {
    if (this.locationExists(location)) {
      this.addressControl.patchValue({ location: { lat: null, lon: null } });
    }
  }

  private locationExists(location: LatLon): boolean {
    return location && Number.isFinite(location.lat) && Number.isFinite(location.lon);
  }

  private filterOrganisationsOnInput(): void {
    this.organisationFilter
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.projectStore.filterOrganisations(filterTerm));
  }

  public isValid(): boolean {
    return this.projectForm.valid
      && !this.projectForm.pending
      && !(this.geocoderStatus === RetryGeocoderStatus.PENDING);
  }

  private fillEditForm() {
    this.generalDataControl.patchValue({
      projectName: this.project.projectName,
      projectCostCenter: this.project.projectCostCenter,
      projectType: this.project.projectType,
      organisationId: this.project.organisation.organisationId,
      projectStart: new Date(this.project.projectStart),
      projectEnd: this.project.projectEnd ? new Date(this.project.projectEnd) : null,
    }, { emitEvent: false });

    this.addressControl.patchValue({
      street: this.project.projectAddress ? this.project.projectAddress.street : null,
      streetNumber: this.project.projectAddress ? this.project.projectAddress.streetNumber : null,
      postalCode: this.project.projectAddress ? this.project.projectAddress.postalCode : null,
      city: this.project.projectAddress ? this.project.projectAddress.city : null,
      disableEmailNotifications: null,
      location: this.project.location ? this.project.location : null,
    }, { emitEvent: false });
  }

  private setSubscription(): void {

  }

  public updateAddress(address: AddressLocation): void {
    this.isManualAddressInput = false;
    this.addressControl.patchValue(address);
    this.addressControl.markAsDirty();
    this.cdRef.detectChanges();
  }

  public save(): void {
    if (this.isValid()) {
      const projectBase: ProjectBase = this.formValueToProjectBase(this.projectForm.getRawValue());

      if (this.project) {
        this.updateProject({
          projectId: this.project.projectId,
          ...projectBase
        });
      } else {
        const organisationId: string = this.organisationId.value;
        const createGeofence: boolean = this.projectGeofenceCreation.value;
        const isDispositionGeofence = this.authService.hasModule(Modules.AUTOMATED_TRANSFERS);
        this.createProject({
          organisationId,
          createGeofence,
          isDispositionGeofence,
          ...projectBase
        });
      }
    }
  }

  private formValueToProjectBase(formValue: any): ProjectBase {
    const location = this.locationExists(formValue.address.location) ? formValue.address.location : null;
    const { lat, lon } = location ? location : { lat: null, lon: null };

    return {
      projectName: formValue.generalData.projectName || null,
      projectType: formValue.generalData.projectType || null,
      projectCostCenter: formValue.generalData.projectCostCenter || null,
      projectStart: DatesService.ospDateTimeAtStartOfDay(formValue.generalData.projectStart) || null,
      projectEnd: DatesService.ospDateTimeAtEndOfDay(formValue.generalData.projectEnd) || null,
      projectAddress: {
        street: formValue.address.street || null,
        streetNumber: formValue.address.streetNumber || null,
        postalCode: formValue.address.postalCode || null,
        city: formValue.address.city || null
      },
      latitude: lat,
      longitude: lon,
    };
  }

  private createProject(command: CreateProjectCommand) {
    this.saveInProgress = true;
    this.projectStore.addProject(command, (id: string) => {
      this.router.navigate([`sites/projects/list/${id}/general`]);
      this.dialogRef.close(dialogResults.SAVE);
      this.saveInProgress = false;
    });
  }

  private updateProject(command: UpdateProjectCommand) {
    this.saveInProgress = true;
    this.projectStore.updateProject(command, () => {
      this.navigateToCurrentTab.bind(this);
      this.dialogRef.close(dialogResults.SAVE);
      this.saveInProgress = false;
    });
  }

  private navigateToCurrentTab(): void {
    const currentViewTab = this.projectStore.currentViewTab;
    this.router.navigate([`sites/projects/list/${this.project.projectId}/${currentViewTab}`]);
  }

  public getFormattedMinEndDate(): Date {
    if (this.projectEnd.dirty) {
      return this.projectEnd.value == null ? null : new Date(this.latestTransferDate);
    } else {
      return null;
    }
  }

  public projectHasActiveTransfers() {
    return moment(this.latestTransferDate).isBefore(Date.now());
  }

  private buildForm(): void {
    this.projectForm = this.formBuilder.group({
      generalData: this.formBuilder.group({
        projectName: [null, [Validators.required, Validators.maxLength(this.fieldLimit.NAME)]],
        projectCostCenter: [null, [Validators.maxLength(this.fieldLimit.COST_CENTER)]],
        organisationId: [null, [Validators.required]],
        projectType: [null, [Validators.required]],
        projectStart: [new Date()],
        projectEnd: [null],
      }),
      address: this.formBuilder.group({
        street: [null, [Validators.maxLength(this.fieldLimit.STREET)]],
        streetNumber: [null, [Validators.maxLength(this.fieldLimit.STREET_NUMBER)]],
        postalCode: [null, [Validators.maxLength(this.fieldLimit.POSTAL_CODE)]],
        city: [null, [Validators.maxLength(this.fieldLimit.CITY)]],
        projectGeofenceCreation: this.userConfigurationService.userConfiguration.projectAddConfiguration.createGeofence,
        location: this.formBuilder.group({
          lat: [null, [Validators.min(GeoLocationLimit.LAT_MIN), Validators.max(GeoLocationLimit.LAT_MAX)]],
          lon: [null, [Validators.min(GeoLocationLimit.LON_MIN), Validators.max(GeoLocationLimit.LON_MAX)]]
        }),
      }),
      disableEmailNotifications: false,
    });

    this.projectGeofenceCreation.valueChanges
      .subscribe(createGeofence => this.userConfigurationService.saveProjectAddConfiguration(createGeofence));
  }

  updateValidation(): void {
    this.projectStart.updateValueAndValidity();
    this.projectEnd.updateValueAndValidity();
  }

  public get generalDataControl(): AbstractControl {
    return this.projectForm.get('generalData');
  }

  public get addressControl(): AbstractControl {
    return this.projectForm.get('address');
  }

  public get addressLocationControl(): AbstractControl {
    return this.addressControl.get('location');
  }

  get projectName(): AbstractControl {
    return this.generalDataControl.get('projectName');
  }

  get projectCostCenter(): AbstractControl {
    return this.generalDataControl.get('projectCostCenter');
  }

  get organisationId(): AbstractControl {
    return this.generalDataControl.get('organisationId');
  }

  get projectType(): AbstractControl {
    return this.generalDataControl.get('projectType');
  }

  get projectStart(): AbstractControl {
    return this.generalDataControl.get('projectStart');
  }

  get projectEnd(): AbstractControl {
    return this.generalDataControl.get('projectEnd');
  }

  get projectGeofenceCreation(): AbstractControl {
    return this.addressControl.get('projectGeofenceCreation');
  }

  get projectStreet(): AbstractControl {
    return this.addressControl.get('street');
  }

  get projectStreetNumber(): AbstractControl {
    return this.addressControl.get('streetNumber');
  }

  get projectPostalCode(): AbstractControl {
    return this.addressControl.get('postalCode');
  }

  get projectCity(): AbstractControl {
    return this.addressControl.get('city');
  }

  get projectGeofence(): boolean {
    return this.addressControl.get('projectGeofenceCreation').value;
  }

  public get latitudeControl(): AbstractControl {
    return this.addressLocationControl.get('lat');
  }

  public get longitudeControl(): AbstractControl {
    return this.addressLocationControl.get('lon');
  }

  showGeofenceCheckbox(): boolean {
    return !this.project;
  }
}
