import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapLoaderService } from './services/map-loader.service';
import { HttpClientJsonpModule } from '@angular/common/http';
import { MapInfoTransportLocationTypeComponent } from './components/map-info-window/map-info-transport-location-type/map-info-transport-location-type.component';
import { MapMarkersConvertTransportViewPipe } from './pipes/map-markers-convert-transport-view.pipe';
import { MapLocationsComponent } from './components/map-locations/map-locations.component';
import { InfoWindowComponentOutletDirective } from './directives/info-window-component-outlet.directive';
import { InfoWindowVisibilityDirective } from './directives/info-window-visibility.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MapIconBuilderPipe } from './directives/map-icon-builder.pipe';
import { MapCustomOptionsDirective } from './directives/map-custom-options.directive';
import { MapMarkerConvertContactViewPipe } from './pipes/map-marker-convert-contact-view.pipe';
import { IsValidLocationsPipe } from './pipes/is-valid-locations.pipe';
import { MapMarkerConvertProjectViewPipe } from './pipes/map-marker-convert-project-view.pipe';
import { MapMarkerConvertEquipmentViewPipe } from './pipes/map-marker-convert-equipment-view.pipe';
import { MapMarkerConvertEmployeeViewPipe } from './pipes/map-marker-convert-employee-view.pipe';
import { MapMarkerConvertStockViewPipe } from './pipes/map-marker-convert-stock-view.pipe';
import { MapComponent } from './components/map/map.component';
import { MapMovementArrowsComponent } from './components/map-locations/map-movement-arrows/map-movement-arrows.component';
import { MapMovementArrowConvertPipe } from './components/map-locations/map-movement-arrows/map-movement-arrow-convert.pipe';
import { MapMarkersConvertTelematicLocationPipe } from './pipes/map-markers-convert-telematic-location.pipe';
import { MapInfoTelematicsLocationComponent } from './components/map-info-window/map-info-telematics/map-info-telematics-location/map-info-telematics-location.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MainPipeModule } from 'app/shared/pipes/main-pipe.module';
import { MapMarkersConvertTelematicStatusPipe } from './pipes/map-markers-convert-telematic-status.pipe';
import { MapInfoTelematicsEquipmentStatusComponent } from './components/map-info-window/map-info-telematics/map-info-telematics-equipment-status/map-info-telematics-equipment-status';
import { LocationAddressResolver } from './services/location-address.resolver';
import { LocationToLatLngLiteralPipe } from './pipes/location-to-lat-lng-literal.pipe';
import { MapLocationSelectComponent } from './components/map-location-select/map-location-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MapExtendedPropsDirective } from './directives/map-extended-props.directive';
import { MapClusteredComponent } from './components/map-clustered/map-clustered.component';
import { MapClusterComponent } from './components/map-clustered/map-cluster/map-cluster.component';
import { MapClustersConvertTransportItemsPipe } from './pipes/map-clusters-convert-transport-items.pipe';
import { MapGlobalComponent } from './components/map-global/map-global.component';
import { SpeedDialStatesPipe } from './components/map-global/speed-dial-states.pipe';
import { SpeedDialComponent } from './components/speed-dial/speed-dial.component';
import { ToggleButtonComponent } from './components/speed-dial/toggle-button/toggle-button.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MapSettingsComponent } from './components/map-settings/map-settings.component';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { GeofenceSidebarComponent } from './components/geofence-sidebar-component/geofence-sidebar.component';
import { MatCardModule } from '@angular/material/card';
import { HasAnyDeletablePipe } from './components/geofence-sidebar-component/has-any-deletable.pipe';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MapInfoWindowTableViewContactComponent } from './components/map-info-window-cluster-table-view/map-info-window-table-view-contact/map-info-window-table-view-contact';
import { MapInfoWindowTableViewProjectComponent } from './components/map-info-window-cluster-table-view/map-info-window-table-view-project/map-info-window-table-view-project.component';
import { MapMarkerOverlayComponent } from './components/map-marker-overlay/map-marker-overlay.component';
import { MapMarkerLabelComponent } from './components/map-marker-labels/map-marker-label/map-marker-label.component';
import { MapMarkerLabelsComponent } from './components/map-marker-labels/map-marker-labels.component';
import { MapIconResolver } from './services/map-icon-resolver/map-icon-resolver.service';
import { MapIconResolverProject } from './services/map-icon-resolver/map-icon-resolver-entities/map-icon-resolver-project.service';
import { MapIconResolverOrganisation } from './services/map-icon-resolver/map-icon-resolver-entities/map-icon-resolver-organisation.service';
import { MapIconResolverStock } from './services/map-icon-resolver/map-icon-resolver-entities/map-icon-resolver-stock.service';
import { MapIconResolverContact } from './services/map-icon-resolver/map-icon-resolver-entities/map-icon-resolver-contact.service';
import { MapIconResolverTransport } from './services/map-icon-resolver/map-icon-resolver-entities/map-icon-resolver-transport.service';
import { MapJumpGoogleComponent } from './components/map-jump-google/map-jump-google-component/map-jump-google.component';
import { DialogTopLevelModule } from '../dialog-top-level/dialog-top-level.module';
import { MapInfoEquipmentDetailsComponent } from './components/map-info-window/map-info-equipment-details/map-info-equipment-details.component';
import { MapMarkersConvertStockLocationsPipe } from './pipes/map-markers-convert-stock-locations.pipe';
import { MapMarkersConvertContactLocationsPipe } from './pipes/map-markers-convert-contact-locations.pipe';
import { MapMarkersConvertEquipmentLocationsPipe } from './pipes/map-markers-convert-equipment-locations.pipe';
import { MapMarkersConvertOrganisationLocationsPipe } from './pipes/map-markers-convert-organisation-locations.pipe';
import { MapMarkersConvertProjectLocationsPipe } from './pipes/map-markers-convert-project-locations.pipe';
import { MapInfoWindowClusterGlobalListComponent } from './components/map-info-window/map-info-window-cluster-global-list/map-info-window-cluster-global-list.component';
import { ClusterListItemContainerComponent } from './components/map-info-window/map-info-window-cluster-global-list/cluster-list-item-container/cluster-list-item-container.component';
import { SecuredImageModule } from 'app/shared/components/test-place/secured-image/secured-image.module';
import { BhIconModule } from 'app/modules/osp-ui/components/bh-icon/bh-icon.module';
import { OspUiPipesModule } from 'app/modules/osp-ui/pipes/osp-ui-pipes.module';
import { SharedModule } from 'app/shared/shared.module';
import { FormatHoursAmountPipe } from './components/map-info-window/map-info-equipment-details/format-hours-amount.pipe';
import { ActivityStatusPipe } from './components/map-info-window/map-info-equipment-details/activity-status.pipe';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MapClusterGlobalPipe } from './pipes/map-cluster-global.pipe';
import { MapInfoAddressPipe } from './components/map-info-window/map-info-equipment-details/map-info-address.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  imports: [
    CommonModule,
    GoogleMapsModule,
    HttpClientJsonpModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MainPipeModule,
    FlexModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    FontAwesomeModule,
    MatButtonModule,
    MatSelectModule,
    MatSliderModule,
    FormsModule,
    MatDialogModule,
    MatCardModule,
    MatListModule,
    MatCheckboxModule,
    RouterModule,
    ReactiveFormsModule,
    DialogTopLevelModule,
    SecuredImageModule,
    BhIconModule,
    OspUiPipesModule,
    SharedModule,
    ScrollingModule,
  ],
  declarations: [
    MapInfoTransportLocationTypeComponent,
    MapMarkersConvertTransportViewPipe,
    MapLocationsComponent,
    InfoWindowComponentOutletDirective,
    InfoWindowVisibilityDirective,
    MapIconBuilderPipe,
    MapCustomOptionsDirective,
    MapMarkerConvertContactViewPipe,
    IsValidLocationsPipe,
    MapMarkerConvertProjectViewPipe,
    MapMarkerConvertEquipmentViewPipe,
    MapMarkerConvertEmployeeViewPipe,
    MapMarkerConvertStockViewPipe,
    MapComponent,
    MapMovementArrowsComponent,
    MapMovementArrowConvertPipe,
    MapMarkersConvertTelematicLocationPipe,
    MapInfoTelematicsLocationComponent,
    MapMarkersConvertTelematicStatusPipe,
    MapInfoTelematicsEquipmentStatusComponent,
    LocationToLatLngLiteralPipe,
    MapLocationSelectComponent,
    MapExtendedPropsDirective,
    MapClusteredComponent,
    MapClusterComponent,
    MapClustersConvertTransportItemsPipe,
    MapGlobalComponent,
    SpeedDialStatesPipe,
    SpeedDialComponent,
    ToggleButtonComponent,
    MapSettingsComponent,
    GeofenceSidebarComponent,
    HasAnyDeletablePipe,
    MapInfoWindowTableViewContactComponent,
    MapInfoWindowTableViewProjectComponent,
    MapMarkerOverlayComponent,
    MapMarkerLabelComponent,
    MapMarkerLabelsComponent,
    MapJumpGoogleComponent,
    MapInfoEquipmentDetailsComponent,
    FormatHoursAmountPipe,
    ActivityStatusPipe,
    MapMarkersConvertStockLocationsPipe,
    MapMarkersConvertContactLocationsPipe,
    MapMarkersConvertEquipmentLocationsPipe,
    MapMarkersConvertOrganisationLocationsPipe,
    MapMarkersConvertProjectLocationsPipe,
    MapInfoWindowClusterGlobalListComponent,
    ClusterListItemContainerComponent,
    MapClusterGlobalPipe,
    MapInfoAddressPipe,
  ],
  exports: [
    MapMarkersConvertTransportViewPipe,
    MapLocationsComponent,
    MapComponent,
    MapClusteredComponent,
    InfoWindowComponentOutletDirective,
    MapMarkerConvertContactViewPipe,
    MapMarkerConvertProjectViewPipe,
    MapMarkerConvertEquipmentViewPipe,
    MapMarkerConvertEmployeeViewPipe,
    MapMarkerConvertStockViewPipe,
    MapMarkersConvertTelematicLocationPipe,
    MapMarkersConvertTelematicStatusPipe,
    MapLocationSelectComponent,
    MapClustersConvertTransportItemsPipe,
    MapGlobalComponent,
    GeofenceSidebarComponent,
    IsValidLocationsPipe,
    MapJumpGoogleComponent,
    MapInfoEquipmentDetailsComponent,
  ],
  providers: [
    LocationAddressResolver,
    LocationToLatLngLiteralPipe,
    MapIconResolver,
    MapIconResolverProject,
    MapIconResolverOrganisation,
    MapIconResolverStock,
    MapIconResolverContact,
    MapIconResolverTransport,
  ]
})
export class MapModule {
  static forRoot(): ModuleWithProviders<MapModule> {
    return {
      ngModule: MapModule,
      providers: [ MapLoaderService ],
    }
  }
}
