<div fxFlexFill class="list-view" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="list-view-search-filter" [fxFlex]="filterConfig.FORM_HEIGHT + 'px'" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="center start">
      <button *ngIf="showRouteButton()"
              mat-button class="list-view-search-bar__left-icon"
              [matTooltip]='routeButton.tooltip' fxFlex="50px"
              (click)="navigate()">
        <i class="material-icons">{{routeButton.iconName}}</i>
      </button>
      <div class="list-view-search-bar__input-container" fxFlex="1 1 100%" fxLayout="row">

        <!-- SEARCH FORM -->
        <form *ngIf="!hideSearchForm"
              class="list-view-search-bar__input"
              fxLayout="row" fxLayoutAlign="start center"
              [fxFlex]="formFlexOptions.searchForm"
              [formGroup]="searchForm" novalidate
              data-osp-test="bar-search">
          <bh-search-suggestion fxFlex [searchControl]="getForm()" [suggestionField]="suggestionField">
          </bh-search-suggestion>
          <button mat-button
            (click)="search()"
            [matTooltip]="'general.search'|translate"
            fxFlex="40px">
            <mat-icon class="more-icon">search</mat-icon>
          </button>
          <button mat-button [matTooltip]="'general.buttons.resetSearchTerm'|translate" fxFlex="40px" type="reset">
            <mat-icon>clear</mat-icon>
          </button>
      </form>

        <!-- FILTER FORM -->
        <form #filterForm *ngIf="!hideFilterForm"
              class="list-view-filter-bar__input filter-input"
              [ngStyle]="{'height': formHeight.toString() + 'px'}"
              [ngClass]="{
                'list-view-filter-bar__input__expanded': formOverflow !== 'hidden',
                'list-view-filter-bar__input__button-right': showAddButton()
              }"
              fxLayout="row"
              [fxFlex]="formFlexOptions.filterForm"
              [formGroup]="searchForm" novalidate
              data-osp-test="form-filter">
          <div #emptyList
               fxLayout="row" fxLayoutGap="5px"
               class="empty-text-div"
               [ngClass]="{'empty-text-div__scroll': formHeight > filterConfig.MAX_FILTER_INPUT_HEIGHT}"
               (click)="openPanel()"
               matAutocompleteOrigin #origin="matAutocompleteOrigin">
            <fa-icon class="filter-icon" [icon]="faFilter" [matTooltip]="'general.filter'|translate"
                     data-osp-test="icon-filter">
            </fa-icon>
            <mat-form-field floatLabel='never'
                            id="filter-form-field"
                            [ngStyle]="{'overflow': formOverflow, 'height': formHeight.toString() + 'px'}" #formField
                            data-osp-test="input-filter">
              <mat-chip-listbox #chipList class="custom-chip-list" [disabled]="true">
                <div *ngIf="showTimeRangeFilter">
                  <mat-chip-option class="time-range-chip">
                    <label class="type-label filter-label">{{'general.filterTimerange.timeRange' | translate | uppercase}} </label>
                    <label class="value-label filter-label">{{timeRangeDate}}</label>
                  </mat-chip-option>
                  <mat-chip-option *ngIf="showDateTypeControl" class="time-range-chip">
                    <label class="type-label filter-label">{{'general.filterTimerange.filterType' | translate | uppercase}} </label>
                    <label class="value-label filter-label">{{timeRangeType}}</label>
                  </mat-chip-option>
                </div>
                <ng-container *ngFor="let chip of chips; index as chipIndex">
                  <mat-chip-option class="custom-chip"
                            [ngStyle]="{ 'order': chipIndex }" *ngIf="chip.filterType !== FilterTypes.MATCH_ALL_LABELS"
                            data-osp-test="chip-option">
                    <label class="type-label" data-osp-test="chip-label-filter-type"> {{chip.filterType |searchFilterType |uppercase}} </label>
                    <label class="value-label" data-osp-test="chip-label-value">{{ chip.displayFilter | bulkItemTypesTranslator}}</label>
                    <label class="number-label" *ngIf="chip.subChips?.length > 0">{{chip.subChips.length}} subs</label>
                    <mat-icon class="cross-sign" (click)="deleteChip(chip, $event)" data-osp-test="icon-chip-delete">clear</mat-icon>
                  </mat-chip-option>
                </ng-container>

                <div class="search-hint"
                     [ngStyle]="{ 'order': displayShowMore ? chips.length - extraChips - 1 : chips.length }"
                     [ngClass]="{ 'custom-chip-list__item-hidden': chips.length === 0 || isFilterDialogActivated }">
                  <ng-container>
                    <fa-icon class="search-hint__icon" [icon]="faSearch"></fa-icon>
                    <span class="search-hint__text">{{'shared.filterDialog.searchFilterParameters'|translate}}</span>
                  </ng-container>
                  <input class="search-hint__input" matInput readonly [disabled]="true" [matAutocomplete]="auto" [matAutocompleteConnectedTo]="origin">
                </div>

                <label class="empty-label" *ngIf="chips.length === 0 && !timeRangeDate">{{placeholderString}}</label>
              </mat-chip-listbox>
            </mat-form-field>
            <div fxFlexAlign="row" fxFlexLayout="start center" class="show-more" (click)="expandFilterForm()"
              [hidden]="!displayShowMore">
              <label *ngIf="extraChips > 0" class="wieter-label">{{extraChips}}</label>
              <label class="wieter-label">{{'general.buttons.more'|translate}}</label>
              <i class="more-icon material-icons">expand_more</i>
            </div>
          </div>
          <mat-autocomplete class="custom-autocomplete" (opened)="updatePlaceholder(true); filterDialog.open()"
                            (closed)="updatePlaceholder(false)" #auto="matAutocomplete"
                            [panelWidth]="inputWidth | async">
            <bh-filter-dialog #filterDialog [foundCount]="foundCount"
                              [isActivated]="isFilterDialogActivated"
                              data-osp-test="dialog-filter">
            </bh-filter-dialog>
            <mat-option style="display: none;">Option 1</mat-option>
          </mat-autocomplete>
            <div fxFlexAlign="row" fxFlexLayout="start center" class="show-more" (click)="expandFilterForm()"
                  [hidden]="!displayShowMore" data-osp-test="button-expand-chips-list">
              <label *ngIf="extraChips > 0" class="wieter-label">{{extraChips}}</label>
              <label class="wieter-label">{{'general.buttons.more'|translate}}</label>
              <i class="more-icon material-icons">expand_more</i>
            </div>
          <button mat-button class="list-view-filter-bar__right-icon" (click)="resetFilters()"
            [matTooltip]="'general.clearSearch'|translate" fxFlex="50px"
            data-osp-test="button-reset-filter">
            <mat-icon>clear</mat-icon>
          </button>
        </form>

        <button mat-raised-button class="list-view-search-bar__create" color="primary"
          *ngIf="showAddButton()" (click)="openAddMenu()"
          data-osp-test="button-add-asset">
          <fa-icon [icon]="faPLus"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxFlex fxLayout="row" class="content">

    <bh-vertical-tab-select *ngIf="options.length"
                            [optionResolver]="optionResolver"
                            [options]="options">
    </bh-vertical-tab-select>

    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
</div>
<ng-template>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</ng-template>
