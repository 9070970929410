import { PolygonUtils } from './polygon-utils';

export class GeofenceBuilder {
  private static polyLine: google.maps.Polyline;
  private static polygon: google.maps.Polygon;
  private static map: google.maps.Map;
  private static mapClickListener: google.maps.MapsEventListener;
  private static _color = '#d50000';
  private static _strokeColor = '#d50000';
  private static _strokeWeight = 2;
  private static editingPolygonCoords: google.maps.LatLng[];
  private static editingPolygonColor: string;

  public static get pathClosed(): boolean {
    return !!GeofenceBuilder.polygon;
  }

  public static get validShape(): boolean {
    return !!GeofenceBuilder.polygon && !PolygonUtils.hasIntersections(GeofenceBuilder.coords);
  }

  public static get coords(): google.maps.LatLng[] {
    if (GeofenceBuilder.polyLine) {
      return GeofenceBuilder.polyLine.getPath().getArray();
    }
    if (GeofenceBuilder.polygon) {
      return GeofenceBuilder.polygon.getPath().getArray();
    }
    return [];
  }

  public static get color(): string {
    return this._color;
  }

  public static get strokeColor(): string {
    return this._strokeColor;
  }

  public static get strokeWeight(): number {
    return this._strokeWeight;
  }

  public static set color(color: string) {
    GeofenceBuilder._color = color;
    if (GeofenceBuilder.polyLine) {
      GeofenceBuilder.polyLine.setOptions({
        strokeColor: color
      });
    }
    if (GeofenceBuilder.polygon) {
      GeofenceBuilder.polygon.setOptions({
        fillColor: color,
        strokeColor: color
      });
    }
  }

  public static set strokeColor(strokeColor: string) {
    GeofenceBuilder._strokeColor = strokeColor;
    if (GeofenceBuilder.polygon) {
      GeofenceBuilder.polygon.setOptions({
        strokeColor: strokeColor
      });
    }
  }

  public static set strokeWeight(strokeWeight: number) {
    GeofenceBuilder._strokeWeight = strokeWeight;
    if (GeofenceBuilder.polygon) {
      GeofenceBuilder.polygon.setOptions({
        strokeWeight: strokeWeight
      });
    }
  }

  public static start(map: google.maps.Map): void {
    GeofenceBuilder.reset();
    GeofenceBuilder.map = map;
    GeofenceBuilder.polyLine = new google.maps.Polyline({
      strokeColor: GeofenceBuilder.strokeColor,
      strokeOpacity: 0.8,
      strokeWeight: GeofenceBuilder.strokeWeight,
      clickable: true,
      editable: true,
      map: map
    });
    GeofenceBuilder.mapClickListener = google.maps.event.addListener(map, 'click', (event: google.maps.MapMouseEvent) => {
      let path = GeofenceBuilder.polyLine.getPath();
      path.push(event.latLng);
    });
    google.maps.event.addListener(GeofenceBuilder.polyLine, 'rightclick', (event: google.maps.PolyMouseEvent) => {
      if (null !== event.vertex) {
        let path = GeofenceBuilder.polyLine.getPath();
        path.removeAt(event.vertex);
      }
    });
    google.maps.event.addListener(GeofenceBuilder.polyLine, 'click', (event: google.maps.PolyMouseEvent) => {
      if (0 === event.vertex) {
        GeofenceBuilder.closePath();
      }
    });
  }

  public static reset(): void {
    if (GeofenceBuilder.polyLine) {
      GeofenceBuilder.polyLine.setMap(null);
      GeofenceBuilder.polyLine = null;
    }
    if (GeofenceBuilder.polygon) {
      GeofenceBuilder.polygon.setMap(null);
      GeofenceBuilder.polygon = null;
    }
    if (GeofenceBuilder.mapClickListener) {
      GeofenceBuilder.mapClickListener.remove();
    }
  }

  public static closePath(): void {
    if (!GeofenceBuilder.polyLine || GeofenceBuilder.polyLine.getPath().getLength() < 3) {
      return;
    }
    GeofenceBuilder.polygon = new google.maps.Polygon({
      paths: GeofenceBuilder.polyLine.getPath(),
      strokeOpacity: 0.8,
      strokeWeight: GeofenceBuilder.strokeWeight,
      strokeColor: GeofenceBuilder.strokeColor,
      fillColor: GeofenceBuilder.color,
      fillOpacity: 0.35,
      map: GeofenceBuilder.map,
      editable: true
    });
    GeofenceBuilder.polyLine.setMap(null);
    GeofenceBuilder.polyLine = null;
    if (GeofenceBuilder.mapClickListener) {
      GeofenceBuilder.mapClickListener.remove();
    }
    google.maps.event.addListener(GeofenceBuilder.polygon, 'rightclick', (event: google.maps.PolyMouseEvent) => {
      if (null !== event.vertex) {
        let path = GeofenceBuilder.polygon.getPath();
        if (path.getLength() > 3) {
          path.removeAt(event.vertex);
        }
      }
    });
  }

  public static edit(polygon: google.maps.Polygon): void {
    GeofenceBuilder.polygon = polygon;
    GeofenceBuilder.editingPolygonCoords = [...polygon.getPath().getArray()];
    GeofenceBuilder.editingPolygonColor = polygon.get('fillColor');
    GeofenceBuilder.polygon.setEditable(true);

    if (GeofenceBuilder.polyLine) {
      GeofenceBuilder.polyLine.setMap(null);
      GeofenceBuilder.polyLine = null;
    }

    if (GeofenceBuilder.mapClickListener) {
      GeofenceBuilder.mapClickListener.remove();
    }
  }

  public static cancelEdit(): void {
    GeofenceBuilder.polygon.setEditable(false);
    GeofenceBuilder.polygon.setPath(GeofenceBuilder.editingPolygonCoords);
    GeofenceBuilder.polygon.setOptions({
      fillColor: GeofenceBuilder.editingPolygonColor,
      strokeColor: GeofenceBuilder.editingPolygonColor
    });
    GeofenceBuilder.polygon = null;
  }
}
