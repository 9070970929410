<mat-card fxFlexFill>
  <mat-card-content class="details__wrapper" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <div class="details-back-button">
      <fa-icon [icon]="faArrowLeft" (click)="backToEvents()"></fa-icon>
      <span>{{'general.event.s'|translate}} / {{eventGroupInfo.type | equipmentEventTypePipe}}</span>
    </div>

    <div class="details-header">
      <div class="details-header left">
        <div class="details-header__title">{{eventGroupInfo.type | equipmentEventTypePipe}}</div>
        <div class="ws-nw" class="details-header__info" fxLayoutGap="5px">
          <bh-color-badge [value]="eventGroupInfo.status | equipmentEventStatusPipe"
                          [color]="eventGroupInfo.status | statusColorPipe">
          </bh-color-badge>
          <bh-color-badge [value]="eventGroupInfo.severity | equipmentEventSeverityPipe"
                          [color]="eventGroupInfo.severity | statusColorPipe">
          </bh-color-badge>
          <span>{{eventGroupInfo.creationDate | formatTimezoneDateTime: {dateFormat: 'MMM D, yyyy, HH:mm'} }}</span>
          <span>({{duration | timeDifferentPipe}})</span>
        </div>
      </div>

      <div class="details-header right">
        <mat-icon *ngIf="isActiveDamageEvent()"
                  (click)="editDamageEvent()">
          mode_edit
        </mat-icon>

        <mat-form-field *ngIf="isActiveDamageEvent()" class="w-120">
          <mat-label>{{'general.status' |translate}}</mat-label>
          <mat-select [(value)]="selectedStatus"
                      (selectionChange)="changeDamageEventStatus(selectedStatus)">
            <mat-select-trigger *ngIf="selectedStatus">
              <bh-color-badge [value]="selectedStatus | equipmentEventStatusPipe"
                              [color]="selectedStatus | statusColorPipe">
              </bh-color-badge>
            </mat-select-trigger>

            <mat-option *ngFor="let status of statusList"
                        [value]="status"
                        [disabled]="status === selectedStatus">
              <div class="custom-option">
                <bh-color-badge [value]="status | equipmentEventStatusPipe"
                                [color]="status | statusColorPipe">
                </bh-color-badge>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button *ngIf="showCompleteTask()" mat-raised-button type="button" (click)="completeTask()">
          <mat-icon>assignment_turned_in</mat-icon>
          {{'general.buttons.complete'|translate}}
        </button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="details-payload">
      <div class="details-payload__title">{{payloadType | payloadTypePipe}}</div>
      <div class="details-payload__body">
        <mat-table class="payload-table" [dataSource]="payload | async">
          <ng-container *ngFor="let column of payloadDisplayedColumns" matColumnDef="{{column}}">

            <!-- DAMAGE payload type -->

            <ng-container *ngIf="payloadType === PAYLOAD_TYPE.DAMAGE">
              <ng-container *ngIf="column === DAMAGE_PAYLOAD_COLUMNS.damageDate">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{element[column] | formatTimezoneDateTime: {dateFormat: 'MMM D, yyyy, HH:mm'} }}
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column === DAMAGE_PAYLOAD_COLUMNS.damageType">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column] | damageTypeDisplay }}</mat-cell>
              </ng-container>

              <ng-container>
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column]}}</mat-cell>
              </ng-container>
            </ng-container>

            <!-- GEOFENCE payload type -->

            <ng-container *ngIf="payloadType === PAYLOAD_TYPE.GEOFENCE">
              <ng-container *ngIf="column === GEOFENCE_PAYLOAD_COLUMNS.locationType">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column] | locationTypePipe}}</mat-cell>
              </ng-container>

              <ng-container *ngIf="column === GEOFENCE_PAYLOAD_COLUMNS.interactionType">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column] | interactionTypePipe}}</mat-cell>
              </ng-container>

              <ng-container *ngIf="column === GEOFENCE_PAYLOAD_COLUMNS.timestamp">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{element[column] | formatTimezoneDateTime: {dateFormat: 'MMM D, yyyy, HH:mm'} }}
                </mat-cell>
              </ng-container>

              <ng-container>
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column]}}</mat-cell>
              </ng-container>
            </ng-container>

            <!-- EMPLOYEE_ASSIGNMENT payload type -->

            <ng-container *ngIf="payloadType === PAYLOAD_TYPE.EMPLOYEE_ASSIGNMENT">
              <ng-container *ngIf="column === EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS.employee">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column] | fullName}}</mat-cell>
              </ng-container>

              <ng-container *ngIf="column === EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS.employeeRole">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column] | equipmentEmployeeRole}}</mat-cell>
              </ng-container>

              <ng-container *ngIf="column === EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS.handOverDate">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{element[column] | formatTimezoneDateTime: {dateFormat: 'MMM D, yyyy, HH:mm'} }}
                </mat-cell>
              </ng-container>

              <ng-container>
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column]}}</mat-cell>
              </ng-container>
            </ng-container>

            <!-- INCIDENT, MILEAGE, OPERATING_HOURS payload types -->

            <ng-container *ngIf="payloadType === PAYLOAD_TYPE.INCIDENT ||
                                payloadType === PAYLOAD_TYPE.MILEAGE ||
                                payloadType === PAYLOAD_TYPE.OPERATING_HOURS">
              <ng-container *ngIf="column === INCIDENT_PAYLOAD_COLUMNS.logDate">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{element[column] | formatTimezoneDateTime: {dateFormat: 'MMM D, yyyy, HH:mm'} }}
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column === MILEAGE_PAYLOAD_COLUMNS.mileage">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element[column] ? (getMileageNumber(element[column]) | dimensionUnitConverter:'km' | mileage) : '-' }} {{ 'km' | dimensionUnitDisplay }}
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column === OPERATING_HOURS_PAYLOAD_COLUMNS.operatingHours">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element[column] ? element[column] : '-' }} {{ 'general.units.time.hour.pl' | translate }}
                </mat-cell>
              </ng-container>

              <ng-container>
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column]}}</mat-cell>
              </ng-container>
            </ng-container>

            <!-- MACHINE_FAULT payload type -->

            <ng-container *ngIf="payloadType === PAYLOAD_TYPE.MACHINE_FAULT">
              <ng-container *ngIf="column === MACHINE_FAULT_PAYLOAD_COLUMNS.dateTime ||
                                  column === MACHINE_FAULT_PAYLOAD_COLUMNS.clearedDateTime">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{element[column] | formatTimezoneDateTime: {dateFormat: 'MMM D, yyyy, HH:mm'} }}
                </mat-cell>
              </ng-container>

              <ng-container>
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column]}}</mat-cell>
              </ng-container>
            </ng-container>

            <!-- MAINTENANCE payload type -->

            <ng-container *ngIf="payloadType === PAYLOAD_TYPE.MAINTENANCE">
              <ng-container *ngIf="column === MAINTENANCE_PAYLOAD_COLUMNS.dueDate ||
                                  column === MAINTENANCE_PAYLOAD_COLUMNS.completedAtDate">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{element[column] | formatTimezoneDateTime: {dateFormat: 'MMM D, yyyy, HH:mm'} }}
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="column === MAINTENANCE_PAYLOAD_COLUMNS.maintenanceResult">
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column] | maintenanceResult}}</mat-cell>
              </ng-container>

              <ng-container>
                <mat-header-cell *matHeaderCellDef>{{column | columnNamePipe}}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element[column]}}</mat-cell>
              </ng-container>
            </ng-container>

          </ng-container>

          <mat-header-row *matHeaderRowDef="payloadDisplayedColumns"></mat-header-row>
          <mat-row *matRowDef="let payloadRow; columns: payloadDisplayedColumns"></mat-row>
        </mat-table>
      </div>
    </div>

    <div *ngIf="(eventsList | async).length > 0; else noData"
          class="details-events scrollable-table-container" fxFlex fxLayout="column" fxLayoutAlign="start stretch">
      <mat-table  class="events-table scrollable-table"
                  [dataSource]="eventsList | async"
                  matSort
                  (matSortChange)="sortData($event)">
        <ng-container *ngFor="let column of eventsTableDisplayedColumns" matColumnDef="{{column}}">
          <ng-container *ngIf="column === EVENT_COLUMNS.STATUS">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{column | columnNamePipe}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <bh-color-badge [value]="element[column] | equipmentEventStatusPipe"
                              [color]="element[column] | statusColorPipe">
              </bh-color-badge>
            </mat-cell>
          </ng-container>

          <ng-container *ngIf="column === EVENT_COLUMNS.TIMESTAMP">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{column | columnNamePipe}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element[column] | formatTimezoneDateTime: {dateFormat: 'MMM D, yyyy, HH:mm'} }}
            </mat-cell>
          </ng-container>

          <ng-container *ngIf="column === EVENT_COLUMNS.SEVERITY">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{column | columnNamePipe}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <bh-color-badge [value]="element[column] | equipmentEventSeverityPipe"
                              [color]="element[column] | statusColorPipe">
              </bh-color-badge>
            </mat-cell>
          </ng-container>
        </ng-container>

        <mat-header-row *matHeaderRowDef="eventsTableDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let eventRow; columns: eventsTableDisplayedColumns"></mat-row>
      </mat-table>
    </div>

    <div class="details-paginator" fxLayout="row wrap">
      <div class="paginator-wrapper">
        <mat-paginator
          *ngIf="(eventsList | async).length > 0"
          [pageIndex]="eventDetailsDatasource.getPageIndex() | async"
          [pageSize]="eventDetailsDatasource.getPageSize() | async"
          [length]="eventDetailsDatasource.getLength() | async"
          (page)="eventDetailsDatasource.handlePageEvent($event)"
          [pageSizeOptions]="[5, 25, 50, 100]">
        </mat-paginator>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #noData>
  <div class="no-tasks" fxLayout="row" fxLayoutAlign="center center">
    <span>{{'modules.equipment.cost.noDataAvailable'|translate}}</span>
  </div>
</ng-template>
