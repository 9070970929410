<mat-icon *ngIf="canLogEquipmentEvents()"
          class="add-button" [matMenuTriggerFor]="addEvent">
  add_box
</mat-icon>

<mat-menu xPosition="before" #addEvent="matMenu" class="add-event-panel">
  <button mat-menu-item
          *ngIf="canLogEquipmentIncident()"
          (click)="equipmentLogIncident()"
          [disabled]="!isActiveEquipment()"
          [matTooltip]="'modules.equipment.incident.logIncident'|translate">
    <mat-icon fontSet="icon-general2_outline"></mat-icon>
    <span>{{'modules.equipment.incident.logIncident'|translate}}</span>
  </button>
  <button mat-menu-item
          *ngIf="canLogEquipmentOperatingHours()"
          (click)="equipmentRecordOperatingHours()"
          [disabled]="!isActiveEquipment()"
          [matTooltip]="'modules.equipment.base.logOperatingHours'|translate">
    <mat-icon fontSet="icon-workinghours01_outline"></mat-icon>
    <span>{{'modules.equipment.base.logOperatingHours'|translate}}</span>
  </button>
  <button mat-menu-item
          *ngIf="canLogEquipmentMileage()"
          (click)="equipmentRecordMileage()"
          [disabled]="!isActiveEquipment()"
          [matTooltip]="'modules.equipment.base.logMileage'|translate">
    <mat-icon fontSet="icon-odometer_outline"></mat-icon>
    <span>{{'modules.equipment.base.logMileage'|translate}}</span>
  </button>
  <button mat-menu-item
          *ngIf="canLogEquipmentDamage()"
          (click)="equipmentLogDamage()"
          [disabled]="!isActiveEquipment()"
          [matTooltip]="'modules.equipment.damage.logMaliciousDamage'|translate">
    <mat-icon fontSet="icon-violence_outline"></mat-icon>
    <span>{{'modules.equipment.damage.logMaliciousDamage'|translate}}</span>
  </button>
</mat-menu>
