import { LanguageService } from '../../../../shared/services/language.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { ConnectorService } from '../../shared/connector.service';
import { UpdateAempV2ConnectorCommand } from '../../contract/aemp-v2/update-aemp-v2-connector-command';
import { UpdateMailConnectorCommand } from '../../contract/mail/update-mail-connector-command';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthEncoding } from '../../contract/auth-encoding.enum';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthType } from '../../contract/auth-type.enum';
import { ConnectorType } from '../../contract/connector-types.enum';
import { RouterHistory } from '../../../../shared/router-history';
import { ScheduleInformation } from '../../contract/schedule-information';
import { AempV2ConnectionInformation } from '../../contract/aemp-v2/aemp-v2-connection-information';
import { MailConnectionInformation } from '../../contract/mail/mail-connection-information';
import {
  TrackUnitClassicConnectionInformation
} from '../../contract/trackunit-classic/track-unit-classic-connection-information';
import {
  UpdateTrackUnitClassicConnectorCommand
} from '../../contract/trackunit-classic/update-track-unit-classic-connector-command';
import { UpdateGpsOverIpConnectorCommand } from '../../contract/gpsoverip/update-gps-over-ip-connector-command';
import { GpsOverIpConnectionInformation } from '../../contract/gpsoverip/gps-over-ip-connection-information';
import { UpdateRioConnectorCommand } from '../../contract/rio/update-rio-connector-command';
import { RioConnectionInformation } from '../../contract/rio/rio-connection-information';
import { UpdateReidlConnectorCommand } from '../../contract/reidl/update-reidl-connector-command';
import { ReidlConnectionInformation } from '../../contract/reidl/reidl-connection-information';
import { Sinos2ConnectionInformation } from '../../contract/sinos2/sinos2-connection-information';
import { UpdateSinos2ConnectorCommand } from '../../contract/sinos2/update-sinos2-connector-command';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bh-connector-edit',
  templateUrl: 'connector-edit.component.html',
  styleUrls: ['connector-edit.component.scss'],
})
export class ConnectorEditComponent extends GuardedNavigableInputComponent implements OnInit, OnDestroy {

  public type: ConnectorType;
  public connectorId: string;
  public name: string;
  public connectorEditForm: UntypedFormGroup;
  public availableEncodings: string[];
  public availableAuthTypes: string[];
  public currentAuthType: AuthType;
  public oldAuthenticationUrl = '';
  public oldClientId = '';
  public oldClientSecret = '';

  private authTypeSubscription: Subscription | undefined;
  private profileKeySubscription: Subscription | undefined;

  constructor(private connectorService: ConnectorService,
              private formBuilder: UntypedFormBuilder,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected snackBar: MatSnackBar,
              protected routerHistory: RouterHistory,
              private dialogRef: MatDialogRef<ConnectorEditComponent>,
              private languageService: LanguageService) {
    super(authService, router, route, routerHistory);
  }

  public ngOnInit() {
    this.buildForm();
    this.onChanges();
    this.getConnectorInstance();
  }

  public buildForm() {

    let group = {
      'intervalInMinutes': [1, [<any>Validators.required]],
      'maxTimeoutInMinutes': [5, [<any>Validators.required]],
    };

    if (this.isAEMPv2()) {
      this.currentAuthType = AuthType.BASIC_AUTH;
      group['url'] = ['', [<any>Validators.required]];
      group['authType'] = [this.currentAuthType, [<any>Validators.required]];
      group['authEncoding'] = ['', <any>Validators.required];
      group['authenticationUrl'] = [''];
      group['clientId'] = [''];
      group['clientSecret'] = [''];
      group['username'] = ['', <any>Validators.required];
      group['password'] = ['', <any>Validators.required];
      group['profileKey'] = ['LIDAT', [<any>Validators.required]];
      group['apiKey'] = [''];
      this.availableEncodings = Object.keys(AuthEncoding).map(key => AuthEncoding[key]);
      this.availableAuthTypes = Object.keys(AuthType).map(key => AuthType[key]);
    }

    if (this.isMail()) {
      group['server'] = ['', [<any>Validators.required]];
      group['port'] = ['', [<any>Validators.required]];
      group['protocol'] = ['IMAP', [<any>Validators.required]];
      group['encryption'] = ['NONE', [<any>Validators.required]];
      group['user'] = ['', [<any>Validators.required]];
      group['password'] = ['', [<any>Validators.required]];
      group['directory'] = ['', [<any>Validators.required]];
      group['destinationDirectory'] = ['', [<any>Validators.required]];
      group['sender'] = ['', [<any>Validators.required]];
      group['filePattern'] = ['', [<any>Validators.required]];
      group['subjectPattern'] = ['', [<any>Validators.required]];
      group['datePattern'] = ['', [<any>Validators.required]];
      group['profileKey'] = ['LINDE_CONNECT', [<any>Validators.required]];
    }

    if (this.isTrackUnitClassic()) {
      group['url'] = [''];
      group['username'] = [''];
      group['password'] = ['', [<any>Validators.required]];
    }

    if (this.isGpsOverIp()) {
      group['url'] = ['', [<any>Validators.required]];
      group['liveUrl'] = ['', [<any>Validators.required]];
      group['username'] = ['', [<any>Validators.required]];
      group['password'] = ['', [<any>Validators.required]];
    }

    if (this.isRio()) {
      group['assetApiUrl'] = ['', [<any>Validators.required]];
      group['iotEventsApiUrl'] = ['', [<any>Validators.required]];
      group['authenticationUrl'] = ['', [<any>Validators.required]];
      group['clientId'] = ['', [<any>Validators.required]];
      group['clientSecret'] = ['', [<any>Validators.required]];
      group['integrationId'] = ['', [<any>Validators.required]];
    }

    if (this.isReidl()) {
      group['telematicUnitsUrl'] = ['', [<any>Validators.required]];
      group['telematicUnitsToken'] = ['', [<any>Validators.required]];
      group['eanCableUnit'] = ['', [<any>Validators.required]];
      group['eanObdUnit'] = ['', [<any>Validators.required]];
      group['eanDigitalMatterUnit'] = ['', [<any>Validators.required]];
    }

    if (this.isSinos2()) {
      group['url'] = ['https://portal2.sinos.eu/mp-tracking/rest/', [<any>Validators.required]];
      group['manNumber'] = ['', [<any>Validators.required]];
      group['manAccount'] = ['', [<any>Validators.required]];
      group['manPassword'] = ['', [<any>Validators.required]];
    }

    this.connectorEditForm = this.formBuilder.group(group);
  }

  public updateForm(authType: AuthType) {
    this.currentAuthType = authType;
    if (this.connectorEditForm) {
      if (authType === AuthType.BASIC_AUTH) {
        this.makeControlsRequired(['authEncoding', 'username', 'password']);
        this.makeControlsOptional(['authenticationUrl', 'clientId', 'clientSecret', 'apiKey']);
      } else if (authType === AuthType.OAUTH1) {
        this.makeControlsRequired(['clientId', 'clientSecret']);
        this.makeControlsOptional(['authenticationUrl', 'authEncoding', 'username', 'password', 'apiKey']);
      } else if (authType === AuthType.OAUTH2_CLIENT_CREDENTIALS) {
        this.makeControlsRequired(['authenticationUrl', 'clientId', 'clientSecret']);
        this.makeControlsOptional(['authEncoding', 'username', 'password', 'apiKey']);
      } else if (authType === AuthType.OAUTH2_PASSWORD_CREDENTIALS) {
        this.makeControlsRequired(['authenticationUrl', 'username', 'password']);
        this.makeControlsOptional(['authEncoding', 'clientId', 'clientSecret', 'apiKey']);
      } else if (authType === AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS) {
        this.makeControlsRequired(['authenticationUrl', 'clientId', 'clientSecret', 'username', 'password']);
        this.makeControlsOptional(['authEncoding', 'apiKey']);
      } else if (authType === AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS){
        this.makeControlsRequired(['authenticationUrl', 'clientId', 'clientSecret', 'username', 'password', 'apiKey']);
        this.makeControlsOptional(['authEncoding']);
      } else {
        this.makeControlsOptional(
          ['authenticationUrl', 'authEncoding', 'clientId', 'clientSecret', 'username', 'password', 'apiKey']);
      }
    }
  }

  public getConnectorInstance() {
    if (this.isAEMPv2()) {
      this.connectorService.getAEMPv2Connector(this.connectorId).subscribe(connector => {

        this.oldAuthenticationUrl = connector.authenticationUrl;
        this.oldClientId = connector.clientId;
        this.oldClientSecret = connector.clientSecret;
        this.currentAuthType = connector.authType;
        this.connectorEditForm.patchValue({
          intervalInMinutes: connector.intervalInMinutes,
          maxTimeoutInMinutes: connector.maxTimeoutInMinutes,
          url: connector.url,
          authType: this.currentAuthType,
          authenticationUrl: connector.authenticationUrl,
          clientId: connector.clientId,
          clientSecret: connector.clientSecret,
          username: connector.username,
          password: connector.password,
          authEncoding: connector.basicAuthEncoding,
          profileKey: connector.profileKey,
          apiKey: connector.apiKey
        });
        this.name = connector.name;

        this.onChanges();
        this.updateForm(connector.authType);
      });
    }

    if (this.isMail()) {
      this.connectorService.getMailConnector(this.connectorId).subscribe(connector => {
        this.connectorEditForm.patchValue({
          intervalInMinutes: connector.intervalInMinutes,
          maxTimeoutInMinutes: connector.maxTimeoutInMinutes,
          server: connector.server,
          port: connector.port,
          protocol: connector.protocol,
          encryption: connector.encryption,
          user: connector.user,
          password: connector.password,
          directory: connector.directory,
          destinationDirectory: connector.destinationDirectory,
          sender: connector.sender,
          filePattern: connector.filePattern,
          subjectPattern: connector.subjectPattern,
          datePattern: connector.datePattern,
          profileKey: connector.profileKey,
        });
        this.name = connector.name;
      });
    }

    if (this.isTrackUnitClassic()) {
      this.connectorService.getTrackUnitClassicConnector(this.connectorId).subscribe(connector => {
        this.connectorEditForm.patchValue({
          intervalInMinutes: connector.intervalInMinutes,
          maxTimeoutInMinutes: connector.maxTimeoutInMinutes,
          url: connector.url,
          username: connector.username,
          password: connector.password,
        });
        this.name = connector.name;
      });
    }

    if (this.isGpsOverIp()) {
      this.connectorService.getGpsOverIpConnector(this.connectorId).subscribe(connector => {
        this.connectorEditForm.patchValue({
          intervalInMinutes: connector.intervalInMinutes,
          maxTimeoutInMinutes: connector.maxTimeoutInMinutes,
          url: connector.url,
          liveUrl: connector.liveUrl,
          username: connector.username,
          password: connector.password,
        });
        this.name = connector.name;
      })
    }

    if (this.isRio()) {
      this.connectorService.getRioConnector(this.connectorId).subscribe(connector => {
        this.connectorEditForm.patchValue({
          intervalInMinutes: connector.intervalInMinutes,
          maxTimeoutInMinutes: connector.maxTimeoutInMinutes,
          assetApiUrl: connector.assetApiUrl,
          iotEventsApiUrl: connector.iotEventsApiUrl,
          authenticationUrl: connector.authenticationUrl,
          clientId: connector.clientId,
          clientSecret: connector.clientSecret,
          integrationId: connector.integrationId,
        });
        this.name = connector.name;
      })
    }

    if (this.isReidl()) {
      this.connectorService.getReidlConnector(this.connectorId).subscribe(connector => {
        this.connectorEditForm.patchValue({
          intervalInMinutes: connector.intervalInMinutes,
          maxTimeoutInMinutes: connector.maxTimeoutInMinutes,
          telematicUnitsUrl: connector.telematicUnitsUrl,
          telematicUnitsToken: connector.telematicUnitsToken,
          eanCableUnit: connector.eanCableUnit,
          eanObdUnit: connector.eanObdUnit,
          eanDigitalMatterUnit: connector.eanDigitalMatterUnit,
        });
        this.name = connector.name;
      })
    }

    if (this.isSinos2()) {
      this.connectorService.getSinos2Connector(this.connectorId).subscribe(connector => {
        this.connectorEditForm.patchValue({
          intervalInMinutes: connector.intervalInMinutes,
          maxTimeoutInMinutes: connector.maxTimeoutInMinutes,
          url: connector.url,
          manAccount: connector.manAccount,
          manNumber: connector.manNumber,
          manPassword: connector.manPassword,
        });
        this.name = connector.name;
      })
    }
  }

  public save() {
    if (this.isValid()) {
      if (this.isAEMPv2()) {
        this.saveAEMPv2();
      }
      if (this.isMail()) {
        this.saveMail();
      }
      if (this.isTrackUnitClassic()) {
        this.saveTrackUnitClassic();
      }
      if (this.isGpsOverIp()) {
        this.saveGpsOverIp();
      }
      if (this.isRio()) {
        this.saveRio();
      }
      if (this.isReidl()) {
        this.saveReidl();
      }
      if (this.isSinos2()) {
        this.saveSinos2();
      }
    }
  }

  public isValid(): boolean {
    return this.connectorEditForm.valid;
  }

  public saveAEMPv2() {
    if (this.connectorEditForm.valid) {
      let command: UpdateAempV2ConnectorCommand = new UpdateAempV2ConnectorCommand();
      command.connectorId = this.connectorId;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorEditForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorEditForm.value.maxTimeoutInMinutes;

      command.connection = new AempV2ConnectionInformation();
      command.connection.url = this.connectorEditForm.value.url;
      command.connection.authType = this.connectorEditForm.value.authType;
      command.connection.profileKey = this.connectorEditForm.value.profileKey;
      if (this.providesBasicAuthEncoding()) {
        command.connection.basicAuthEncoding = this.connectorEditForm.value.authEncoding;
      }
      if (this.providesAuthUrl()) {
        command.connection.authenticationUrl = this.connectorEditForm.value.authenticationUrl;
      }
      if (this.providesClientId()) {
        command.connection.clientId = this.connectorEditForm.value.clientId;
      }
      if (this.providesClientSecret()) {
        command.connection.clientSecret = this.connectorEditForm.value.clientSecret;
      }
      if (this.providesUsernamePassword()) {
        command.connection.username = this.connectorEditForm.value.username;
        command.connection.password = this.connectorEditForm.value.password;
      }
      if (this.providesAPIKey()) {
        command.connection.apiKey = this.connectorEditForm.value.apiKey;
      }

      this.connectorService.updateAEMPv2Connector(command).subscribe(
        () => {
          this.dialogRef.close();
        },
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {panelClass: 'job-snack'});
        },
      );
    }
  }

  public saveMail() {
    if (this.connectorEditForm.valid) {
      let command: UpdateMailConnectorCommand = new UpdateMailConnectorCommand();
      command.connectorId = this.connectorId;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorEditForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorEditForm.value.maxTimeoutInMinutes;

      command.connection = new MailConnectionInformation();
      command.connection.server = this.connectorEditForm.value.server;
      command.connection.port = this.connectorEditForm.value.port;
      command.connection.protocol = this.connectorEditForm.value.protocol;
      command.connection.encryption = this.connectorEditForm.value.encryption;
      command.connection.user = this.connectorEditForm.value.user;
      command.connection.password = this.connectorEditForm.value.password;
      command.connection.directory = this.connectorEditForm.value.directory;
      command.connection.destinationDirectory = this.connectorEditForm.value.destinationDirectory;
      command.connection.sender = this.connectorEditForm.value.sender;
      command.connection.filePattern = this.connectorEditForm.value.filePattern;
      command.connection.subjectPattern = this.connectorEditForm.value.subjectPattern;
      command.connection.datePattern = this.connectorEditForm.value.datePattern;
      command.connection.profileKey = this.connectorEditForm.value.profileKey;

      this.connectorService.updateMailConnector(command).subscribe(
        () => {
          this.dialogRef.close();
        },
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {panelClass: 'job-snack'});
        },
      );
    }
  }

  public saveTrackUnitClassic() {
    if (this.connectorEditForm.valid) {
      let command: UpdateTrackUnitClassicConnectorCommand = new UpdateTrackUnitClassicConnectorCommand();
      command.connectorId = this.connectorId;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorEditForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorEditForm.value.maxTimeoutInMinutes;

      command.connection = new TrackUnitClassicConnectionInformation();
      command.connection.url = this.connectorEditForm.value.url;
      command.connection.username = this.connectorEditForm.value.username;
      command.connection.password = this.connectorEditForm.value.password;

      this.connectorService.updateTrackUnitClassicConnector(command).subscribe(
        () => {
          this.dialogRef.close();
        },
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {panelClass: 'job-snack'});
        },
      );
    }
  }

  public saveGpsOverIp() {
    if (this.connectorEditForm.valid) {
      let command: UpdateGpsOverIpConnectorCommand = new UpdateGpsOverIpConnectorCommand();
      command.connectorId = this.connectorId;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorEditForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorEditForm.value.maxTimeoutInMinutes;

      command.connection = new GpsOverIpConnectionInformation();
      command.connection.url = this.connectorEditForm.value.url;
      command.connection.liveUrl = this.connectorEditForm.value.liveUrl;
      command.connection.username = this.connectorEditForm.value.username;
      command.connection.password = this.connectorEditForm.value.password;

      this.connectorService.updateGpsOverIpConnector(command).subscribe(
        () => this.dialogRef.close(),
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {panelClass: 'job-snack'});
        },
      );
    }
  }

  public saveRio() {
    if (this.connectorEditForm.valid) {
      let command: UpdateRioConnectorCommand = new UpdateRioConnectorCommand();
      command.connectorId = this.connectorId;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorEditForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorEditForm.value.maxTimeoutInMinutes;

      command.connection = new RioConnectionInformation();
      command.connection.assetApiUrl = this.connectorEditForm.value.assetApiUrl;
      command.connection.iotEventsApiUrl = this.connectorEditForm.value.iotEventsApiUrl;
      command.connection.authenticationUrl = this.connectorEditForm.value.authenticationUrl;
      command.connection.clientId = this.connectorEditForm.value.clientId;
      command.connection.clientSecret = this.connectorEditForm.value.clientSecret;
      command.connection.integrationId = this.connectorEditForm.value.integrationId;

      this.connectorService.updateRioConnector(command).subscribe(
        () => this.dialogRef.close(),
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {panelClass: 'job-snack'});
        },
      );
    }
  }

  public saveReidl() {
    if (this.connectorEditForm.valid) {
      let command: UpdateReidlConnectorCommand = new UpdateReidlConnectorCommand();
      command.connectorId = this.connectorId;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorEditForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorEditForm.value.maxTimeoutInMinutes;

      command.connection = new ReidlConnectionInformation();
      command.connection.telematicUnitsUrl = this.connectorEditForm.value.telematicUnitsUrl;
      command.connection.telematicUnitsToken = this.connectorEditForm.value.telematicUnitsToken;
      command.connection.eanCableUnit = this.connectorEditForm.value.eanCableUnit;
      command.connection.eanObdUnit = this.connectorEditForm.value.eanObdUnit;
      command.connection.eanDigitalMatterUnit = this.connectorEditForm.value.eanDigitalMatterUnit;

      this.connectorService.updateReidlConnector(command).subscribe(
        () => this.dialogRef.close(),
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {panelClass: 'job-snack'});
        },
      );
    }
  }

  public saveSinos2() {
    if (this.connectorEditForm.valid) {
      let command: UpdateSinos2ConnectorCommand = new UpdateSinos2ConnectorCommand();

      command.connectorId = this.connectorId;

      command.schedule = new ScheduleInformation();
      command.schedule.intervalInMinutes = this.connectorEditForm.value.intervalInMinutes;
      command.schedule.maxTimeoutInMinutes = this.connectorEditForm.value.maxTimeoutInMinutes;

      command.connection = new Sinos2ConnectionInformation();
      command.connection.url = this.connectorEditForm.value.url;
      command.connection.manNumber = this.connectorEditForm.value.manNumber;
      command.connection.manAccount = this.connectorEditForm.value.manAccount;
      command.connection.manPassword = this.connectorEditForm.value.manPassword;


      this.connectorService.updateSinos2Connector(command).subscribe(
        () => this.dialogRef.close(),
        (error: HttpErrorResponse) => {
          this.snackBar.open(
            `${this.translate('modules.connector.connectorAddEdit.cannotSaveConnector')}: ${error.error}`,
            undefined, {panelClass: 'job-snack'});
        },
      );
    }
  }

  public isAEMPv2(): boolean {
    return this.type === ConnectorType.AEMP_V2;
  }

  public isMail(): boolean {
    return this.type === ConnectorType.MAIL;
  }

  public isTrackUnitClassic(): boolean {
    return this.type === ConnectorType.TRACKUNIT_CLASSIC;
  }

  public isGpsOverIp(): boolean {
    return this.type === ConnectorType.GPS_OVER_IP;
  }

  public isRio(): boolean {
    return this.type === ConnectorType.RIO;
  }

  public isReidl(): boolean {
    return this.type === ConnectorType.REIDL;
  }

  public isSinos2(): boolean {
    return this.type === ConnectorType.SINOS_PORTAL_2;
  }

  public providesBasicAuthEncoding() {
    return this.currentAuthType === AuthType.BASIC_AUTH;
  }

  public providesAuthUrl(): boolean {
    return [
      AuthType.OAUTH2_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS
    ].includes(this.currentAuthType);
  }

  public providesClientId(): boolean {
    return [
      AuthType.OAUTH1,
      AuthType.OAUTH2_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS
    ].includes(this.currentAuthType);
  }

  public isClientIdMandatory(): boolean {
    return [
      AuthType.OAUTH1,
      AuthType.OAUTH2_CLIENT_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS
    ].includes(this.currentAuthType);
  }

  public providesClientSecret(): boolean {
    return [
      AuthType.OAUTH1,
      AuthType.OAUTH2_CLIENT_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS
    ].includes(this.currentAuthType);
  }

  public providesUsernamePassword(): boolean {
    return [
      AuthType.BASIC_AUTH,
      AuthType.OAUTH2_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS,
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS
    ].includes(this.currentAuthType);
  }

  public providesAPIKey(): boolean {
    return [
      AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS,
    ].includes(this.currentAuthType);
  }

  public onChanges(): void {
    const authTypeFormField = this.connectorEditForm.get('authType');
    if (authTypeFormField && !this.authTypeSubscription) {
      this.authTypeSubscription = authTypeFormField.valueChanges.subscribe(newValue => {
        if (newValue) {
          this.updateForm(newValue);
        }
      });
    }

    const profileKeyFormField = this.connectorEditForm.get('profileKey');
    if (profileKeyFormField && !this.profileKeySubscription) {
      this.profileKeySubscription = profileKeyFormField.valueChanges.subscribe(newProfileKey => {
        if (newProfileKey === 'VOLVO') {
          this.availableAuthTypes = [AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS];
          this.connectorEditForm.get('authType').setValue(AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS);
          this.updateForm(AuthType.OAUTH2_CLIENT_PASSWORD_API_KEY_CREDENTIALS)
        } else if(newProfileKey === 'KUBOTA') {
          this.availableAuthTypes = [AuthType.OAUTH2_PASSWORD_CREDENTIALS];
          this.connectorEditForm.get('authType').setValue(AuthType.OAUTH2_PASSWORD_CREDENTIALS);
          this.updateForm(AuthType.OAUTH2_PASSWORD_CREDENTIALS)
        } else if(newProfileKey === 'CNH_INDUSTRIAL') {
          this.availableAuthTypes = [AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS];
          this.connectorEditForm.get('authType').setValue(AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS);
          this.updateForm(AuthType.OAUTH2_CLIENT_PASSWORD_CREDENTIALS)
        } else {
          this.availableAuthTypes = Object.keys(AuthType).map(key => AuthType[key]);
        }
      });
    }
  }

  private makeControlsRequired(controlNames: string[]): void {
    controlNames.forEach(control => {
      this.connectorEditForm.controls[control].setValidators(Validators.required);
      this.connectorEditForm.controls[control].updateValueAndValidity();
    });
  }

  private makeControlsOptional(controlNames: string[]): void {
    controlNames.forEach(control => {
      this.connectorEditForm.controls[control].setValidators(null);
      this.connectorEditForm.controls[control].updateValueAndValidity();
    });
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  public ngOnDestroy(): void {
    this.authTypeSubscription?.unsubscribe();
    this.profileKeySubscription?.unsubscribe();
  }

}
