import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { faArrowDownUpLock, faArrowDownUpAcrossLine, faLockA } from '@fortawesome/pro-light-svg-icons';
import { AutomatedTransfersStatus } from 'app/shared/contract/automated-transfers-status.interface';
import { AutomatedTransfersStatusList } from 'app/shared/enums/automated-transfers-status-list.enum';
import { LanguageService } from 'app/shared/services/language.service';

@Component({
  selector: 'bh-equipment-automated-transfers-status-change',
  templateUrl: './equipment-automated-transfers-status-change.component.html',
  styleUrls: ['./equipment-automated-transfers-status-change.component.scss']
})
export class EquipmentAutomatedTransfersStatusChangeComponent implements OnInit {
  @Input() currentStatus: AutomatedTransfersStatusList = null;

  public selectedStatus: AutomatedTransfersStatus = null;
  public statusList: AutomatedTransfersStatus[] = [
    {
      value: AutomatedTransfersStatusList.ACTIVE,
      name: this.translate('general.statuses.active'),
      icon: faArrowDownUpAcrossLine,
      disabled: false,
    },
    {
      value: AutomatedTransfersStatusList.INACTIVE,
      name: this.translate('general.statuses.inactive'),
      icon: faArrowDownUpLock,
      disabled: false,
    },
    {
      value: AutomatedTransfersStatusList.BLOCKED,
      name: this.translate('general.statuses.blocked'),
      icon: faLockA,
      disabled: true,
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<EquipmentAutomatedTransfersStatusChangeComponent>,
    private langService: LanguageService,
  ) {}

  public ngOnInit(): void {
    this.setCurrentStatus();
  }

  public changeStatusAvailability(status: AutomatedTransfersStatusList): void {
    switch (status) {
      case AutomatedTransfersStatusList.ACTIVE:
        this.changeDisableStatus(AutomatedTransfersStatusList.ACTIVE, false);
        this.changeDisableStatus(AutomatedTransfersStatusList.INACTIVE, false);
        this.changeDisableStatus(AutomatedTransfersStatusList.BLOCKED, true);
        break;
      case AutomatedTransfersStatusList.INACTIVE:
        this.changeDisableStatus(AutomatedTransfersStatusList.ACTIVE, false);
        this.changeDisableStatus(AutomatedTransfersStatusList.INACTIVE, false);
        this.changeDisableStatus(AutomatedTransfersStatusList.BLOCKED, true);
        break;
      case AutomatedTransfersStatusList.BLOCKED:
        this.changeDisableStatus(AutomatedTransfersStatusList.ACTIVE, true);
        this.changeDisableStatus(AutomatedTransfersStatusList.INACTIVE, false);
        this.changeDisableStatus(AutomatedTransfersStatusList.BLOCKED, false);
        break;
      default:
        break;
    }
  }

  public save(): void {
    this.dialogRef.close(this.selectedStatus.value);
  }

  private setCurrentStatus(): void {
    if (this.currentStatus) {
      this.selectedStatus = this.statusList.find(el => el.value === this.currentStatus);
    } else {
      this.selectedStatus = this.statusList[0];
    }
    this.changeStatusAvailability(this.selectedStatus.value);
  }

  private changeDisableStatus(status: AutomatedTransfersStatusList, disabledStatus: boolean): void {
    this.statusList.find(el => el.value === status).disabled = disabledStatus;
  }

  protected translate(key: string): string {
    return this.langService.getInstant(key);
  }
}
