export enum Authorities {

  // Context User

  SUPERADMIN_VIEW = 'SUPERADMIN_VIEW',
  SUPERADMIN_CREATE = 'SUPERADMIN_CREATE',
  SUPERADMIN_UPDATE = 'SUPERADMIN_UPDATE',
  SUPERADMIN_DELETE = 'SUPERADMIN_DELETE',
  SUPERADMIN_CHANGE_STATUS = 'SUPERADMIN_CHANGE_STATUS',

  PARTNERADMIN_VIEW = 'PARTNERADMIN_VIEW',
  PARTNERADMIN_CREATE = 'PARTNERADMIN_CREATE',
  PARTNERADMIN_UPDATE = 'PARTNERADMIN_UPDATE',
  PARTNERADMIN_DELETE = 'PARTNERADMIN_DELETE',
  PARTNERADMIN_CHANGE_STATUS = 'PARTNERADMIN_CHANGE_STATUS',

  FLEETADMIN_VIEW = 'FLEETADMIN_VIEW',
  FLEETADMIN_CREATE = 'FLEETADMIN_CREATE',
  FLEETADMIN_UPDATE = 'FLEETADMIN_UPDATE',
  FLEETADMIN_DELETE = 'FLEETADMIN_DELETE',
  FLEETADMIN_CHANGE_STATUS = 'FLEETADMIN_CHANGE_STATUS',

  USER_VIEW = 'USER_VIEW',
  USER_CREATE = 'USER_CREATE',
  USER_UPDATE = 'USER_UPDATE',
  USER_DELETE = 'USER_DELETE',
  USER_UPDATE_STATUS = 'USER_UPDATE_STATUS',
  USER_UPDATE_ADFS = 'USER_UPDATE_ADFS',

  // Context Role

  ROLE_VIEW = 'ROLE_VIEW',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_UPDATE = 'ROLE_UPDATE',
  ROLE_DELETE = 'ROLE_DELETE',

  // Context Partner

  PARTNER_VIEW = 'PARTNER_VIEW',
  PARTNER_CREATE = 'PARTNER_CREATE',
  PARTNER_UPDATE = 'PARTNER_UPDATE',
  PARTNER_DELETE = 'PARTNER_DELETE',
  PARTNER_TELEMATIC_UNITS_MANAGE = 'PARTNER_TELEMATIC_UNITS_MANAGE',

  // Context Customer

  CUSTOMER_VIEW = 'CUSTOMER_VIEW',
  CUSTOMER_CREATE = 'CUSTOMER_CREATE',
  CUSTOMER_UPDATE = 'CUSTOMER_UPDATE',
  CUSTOMER_DELETE = 'CUSTOMER_DELETE',

  // Context Sub Customer
  SUB_CUSTOMER_VIEW = 'SUB_CUSTOMER_VIEW',
  SUB_CUSTOMER_MANAGE = 'SUB_CUSTOMER_MANAGE',

  // Context Organisation

  ORGANISATION_VIEW = 'ORGANISATION_VIEW',
  ORGANISATION_CREATE = 'ORGANISATION_CREATE',
  ORGANISATION_UPDATE = 'ORGANISATION_UPDATE',
  ORGANISATION_DELETE = 'ORGANISATION_DELETE',

  // Context Equipment

  EQUIPMENT_VIEW = 'EQUIPMENT_VIEW',
  PROJECT_ASSIGNEE_VIEW = 'PROJECT_ASSIGNEE_VIEW',
  EQUIPMENT_CREATE = 'EQUIPMENT_CREATE',
  EQUIPMENT_UPDATE = 'EQUIPMENT_UPDATE',
  EQUIPMENT_STATUS_UPDATE_ANY = 'EQUIPMENT_STATUS_UPDATE_ANY',
  EQUIPMENT_STATUS_UPDATE_ACTIVE_INACTIVE = 'EQUIPMENT_STATUS_UPDATE_ACTIVE_INACTIVE',
  EQUIPMENT_STATUS_UPDATE_CATEGORY = 'EQUIPMENT_STATUS_UPDATE_CATEGORY',
  EQUIPMENT_TYPE_CHANGE = 'EQUIPMENT_TYPE_CHANGE',
  EQUIPMENT_TECHNICAL_DATA_MANAGE = 'EQUIPMENT_TECHNICAL_DATA_MANAGE',
  EQUIPMENT_DELETE = 'EQUIPMENT_DELETE',
  EQUIPMENT_ADD_LIFECYCLE_DATA = 'EQUIPMENT_ADD_LIFECYCLE_DATA',
  EQUIPMENT_MANAGE_MEASUREMENT_LIFECYCLE = 'EQUIPMENT_MANAGE_MEASUREMENT_LIFECYCLE',
  EQUIPMENT_MANAGE_COSTS = 'EQUIPMENT_MANAGE_COSTS',
  EQUIPMENT_VIEW_COSTS = 'EQUIPMENT_VIEW_COSTS',
  EQUIPMENT_VIEW_CONTRACTS = 'EQUIPMENT_VIEW_CONTRACTS',
  EQUIPMENT_MANAGE_LABELS = 'EQUIPMENT_MANAGE_LABELS',
  EQUIPMENT_MANAGE_DISPOSITION = 'EQUIPMENT_MANAGE_DISPOSITION',
  EQUIPMENT_VIEW_TELEMATICS = 'EQUIPMENT_VIEW_TELEMATICS',
  EQUIPMENT_MANAGE_TELEMATIC_UNIT = 'EQUIPMENT_MANAGE_TELEMATIC_UNIT',
  EQUIPMENT_VIEW_HISTORY = 'EQUIPMENT_VIEW_HISTORY',
  EQUIPMENT_SCANCODE_EDIT = 'EQUIPMENT_SCANCODE_EDIT',
  ASSIGNMENT_REQUESTS_SUPERVISE = 'ASSIGNMENT_REQUESTS_SUPERVISE',
  EQUIPMENT_DELETE_MAINTENANCE_TASK_LIFECYCLE = 'EQUIPMENT_DELETE_MAINTENANCE_TASK_LIFECYCLE',
  EQUIPMENT_STOCK_TRANSFER = 'EQUIPMENT_STOCK_TRANSFER',
  EQUIPMENT_MANAGE_EMPLOYEES = 'EQUIPMENT_MANAGE_EMPLOYEES',
  EQUIPMENT_MANAGE_DAMAGES = 'EQUIPMENT_MANAGE_DAMAGES',
  EQUIPMENT_MANAGE_OPERATING_HOURS = 'EQUIPMENT_MANAGE_OPERATING_HOURS',
  EQUIPMENT_MANAGE_MILEAGE = 'EQUIPMENT_MANAGE_MILEAGE',
  EQUIPMENT_MANAGE_LIFECYCLE_EVENTS = 'EQUIPMENT_MANAGE_LIFECYCLE_EVENTS',
  EQUIPMENT_ADD_ATTACHMENTS = 'EQUIPMENT_ADD_ATTACHMENTS',
  EQUIPMENT_DELETE_ATTACHMENTS = 'EQUIPMENT_DELETE_ATTACHMENTS',
  EQUIPMENT_DISPOSITION_CHANGE_TYPE_OF_USE = 'EQUIPMENT_DISPOSITION_CHANGE_TYPE_OF_USE',
  EQUIPMENT_DISPOSITION_REQUEST_CHANGE_TYPE_OF_USE = 'EQUIPMENT_DISPOSITION_REQUEST_CHANGE_TYPE_OF_USE',
  EQUIPMENT_CALIBRATE_TELEMATIC_UNIT_OPERATING_HOURS = 'EQUIPMENT_CALIBRATE_TELEMATIC_UNIT_OPERATING_HOURS',
  EQUIPMENT_CALIBRATE_TELEMATIC_UNIT_MILEAGE = 'EQUIPMENT_CALIBRATE_TELEMATIC_UNIT_MILEAGE',
  EQUIPMENT_UPDATE_DESCRIPTION = 'EQUIPMENT_UPDATE_DESCRIPTION',
  EQUIPMENT_STATUS_MANAGE = 'EQUIPMENT_STATUS_MANAGE',

  // Context Equipment Type

  EQUIPMENT_TYPE_VIEW = 'EQUIPMENT_TYPE_VIEW',
  EQUIPMENT_TYPE_CREATE = 'EQUIPMENT_TYPE_CREATE',
  EQUIPMENT_TYPE_UPDATE = 'EQUIPMENT_TYPE_UPDATE',
  EQUIPMENT_TYPE_DELETE = 'EQUIPMENT_TYPE_DELETE',

  EQUIPMENT_TYPE_CUSTOMER_VIEW = 'EQUIPMENT_TYPE_CUSTOMER_VIEW',
  EQUIPMENT_TYPE_CUSTOMER_MANAGE = 'EQUIPMENT_TYPE_CUSTOMER_MANAGE',
  EQUIPMENT_TYPE_CUSTOMER_DELETE = 'EQUIPMENT_TYPE_CUSTOMER_DELETE',

  // Context Technical Fields

  TECHNICAL_FIELD_VIEW = 'TECHNICAL_FIELD_VIEW',
  TECHNICAL_FIELD_CREATE = 'TECHNICAL_FIELD_CREATE',
  TECHNICAL_FIELD_UPDATE = 'TECHNICAL_FIELD_UPDATE',
  TECHNICAL_FIELD_DELETE = 'TECHNICAL_FIELD_DELETE',

  // Context Additional Fields

  ADDITIONAL_FIELD_VIEW = 'ADDITIONAL_FIELD_VIEW',
  ADDITIONAL_FIELD_MANAGE = 'ADDITIONAL_FIELD_MANAGE',

  // Context Manufacturer

  MANUFACTURER_VIEW = 'MANUFACTURER_VIEW',
  MANUFACTURER_CREATE = 'MANUFACTURER_CREATE',
  MANUFACTURER_UPDATE = 'MANUFACTURER_UPDATE',
  MANUFACTURER_DELETE = 'MANUFACTURER_DELETE',

  // Context Projects

  PROJECT_VIEW = 'PROJECT_VIEW',
  PROJECT_CREATE = 'PROJECT_CREATE',
  PROJECT_UPDATE = 'PROJECT_UPDATE',
  PROJECT_UPDATE_STATUS = 'PROJECT_UPDATE_STATUS',
  PROJECT_DELETE = 'PROJECT_DELETE',
  PROJECT_MANAGE_LABELS = 'PROJECT_MANAGE_LABELS',
  PROJECT_MANAGE_SCAN_CODE = 'PROJECT_MANAGE_SCAN_CODE',
  PROJECT_ADD_ATTACHMENTS = 'PROJECT_ADD_ATTACHMENTS',
  PROJECT_DELETE_ATTACHMENTS = 'PROJECT_DELETE_ATTACHMENTS',
  PROJECT_ASSIGNEE_MANAGE = 'PROJECT_ASSIGNEE_MANAGE',
  PROJECT_GROUP_MANAGE = 'PROJECT_GROUP_MANAGE',

  // Context Geofences

  GEOFENCE_VIEW = 'GEOFENCE_VIEW',
  GEOFENCE_MANAGE = 'GEOFENCE_MANAGE',

  // Context Jobs

  JOBS_MANAGE = 'JOBS_MANAGE',

  // Context Notifications

  NOTIFICATIONS_MANAGE_ALARMS = 'NOTIFICATIONS_MANAGE_ALARMS',
  NOTIFICATIONS_VIEW_ALARMS = 'NOTIFICATIONS_VIEW_ALARMS',

  // Context Reports

  REPORT_VIEW = 'REPORT_VIEW',
  REPORT_MANAGE = 'REPORT_MANAGE',

  // Context Messages

  MESSAGE_CENTER_USE = 'MESSAGE_CENTER_USE',

  // Context Maintenance

  MAINTENANCE_TASK_VIEW = 'MAINTENANCE_TASK_VIEW',
  MAINTENANCE_TASK_MANAGE = 'MAINTENANCE_TASK_MANAGE',
  MAINTENANCE_TYPE_MANAGE = 'MAINTENANCE_TYPE_MANAGE',

  // Context Bulk Items

  BULK_ITEMS_VIEW = 'BULK_ITEMS_VIEW',
  BULK_ITEMS_MANAGE = 'BULK_ITEMS_MANAGE',
  BULK_ITEMS_SCANCODE_EDIT = 'BULK_ITEMS_SCANCODE_EDIT',
  BULK_ITEMS_STOCK_PROJECT_TRANSFER = 'BULK_ITEMS_STOCK_PROJECT_TRANSFER',
  BULK_ITEMS_ADD_ATTACHMENTS = 'BULK_ITEMS_ADD_ATTACHMENTS',
  BULK_ITEMS_DELETE_ATTACHMENTS = 'BULK_ITEMS_DELETE_ATTACHMENTS',

  // Context Stock

  STOCK_VIEW = 'STOCK_VIEW',
  STOCK_BULK_ITEM_RELOCATE = 'STOCK_BULK_ITEM_RELOCATE',
  STOCK_MANAGE = 'STOCK_MANAGE',
  STOCK_MANAGE_ASSIGNEE = 'STOCK_MANAGE_ASSIGNEE ',
  STOCK_MANAGE_SCAN_CODE = 'STOCK_MANAGE_SCAN_CODE',
  STOCK_ADD_ATTACHMENTS = 'STOCK_ADD_ATTACHMENTS',
  STOCK_DELETE_ATTACHMENTS = 'STOCK_DELETE_ATTACHMENTS',

  // Context Shop
  ONESTOP_PRO_SHOP = 'ONESTOP_PRO_SHOP',

  // Context Staff Management

  EMPLOYEE_VIEW = 'EMPLOYEE_VIEW',
  EMPLOYEE_MANAGE = 'EMPLOYEE_MANAGE',
  EMPLOYEE_MANAGE_LABELS = 'EMPLOYEE_MANAGE_LABELS',
  EMPLOYEE_MANAGE_ATTACHMENTS = 'EMPLOYEE_MANAGE_ATTACHMENTS',
  EMPLOYEE_VIEW_NOTES = 'EMPLOYEE_VIEW_NOTES',
  EMPLOYEE_MANAGE_NOTES = 'EMPLOYEE_MANAGE_NOTES',
  EMPLOYEE_MANAGE_DISPOSITION = 'EMPLOYEE_MANAGE_DISPOSITION',
  EMPLOYEE_MANAGE_QUALIFICATIONS = 'EMPLOYEE_MANAGE_QUALIFICATIONS',
  EMPLOYEE_VIEW_QUALIFICATIONS = 'EMPLOYEE_VIEW_QUALIFICATIONS',
  EMPLOYEE_RENEW_QUALIFICATIONS = 'EMPLOYEE_RENEW_QUALIFICATIONS',

  // Transfer
  ASSET_FROM_STOCK_TRANSFER = 'ASSET_FROM_STOCK_TRANSFER',
  ASSET_FROM_PROJECT_TRANSFER = 'ASSET_FROM_PROJECT_TRANSFER',
  ASSET_WITH_SCAN_TRANSFER = 'ASSET_WITH_SCAN_TRANSFER',
  PROJECT_TRANSFERS_REVERT = 'PROJECT_TRANSFERS_REVERT',
  ASSET_CROSS_ORGANISATION_TO_STOCK_TRANSFER = 'ASSET_CROSS_ORGANISATION_TO_STOCK_TRANSFER',
  ASSET_CROSS_ORGANISATION_TO_PROJECT_TRANSFER = 'ASSET_CROSS_ORGANISATION_TO_PROJECT_TRANSFER',
  AUTOMATED_TRANSFERS_EQUIPMENT_INCLUDE_EXCLUDE = 'AUTOMATED_TRANSFERS_EQUIPMENT_INCLUDE_EXCLUDE',

  // Contact
  CONTACT_MANAGE = 'CONTACT_MANAGE',
  CONTACT_VIEW = 'CONTACT_VIEW',

  // Transportation task
  TRANSPORT_TASK_MANAGE = 'TRANSPORT_TASK_MANAGE',
  TRANSPORT_TASK_DISPOSITION = 'TRANSPORT_TASK_DISPOSITION',
  TRANSPORT_VEHICLE_MANAGE = 'TRANSPORT_VEHICLE_MANAGE',
  TRANSPORT_TASK_EXECUTE = 'TRANSPORT_TASK_EXECUTE',
  TRANSPORT_TASK_VIEW = 'TRANSPORT_TASK_VIEW',

  // Analytics
  GLOBAL_TRANSFER_LOG_VIEW = 'GLOBAL_TRANSFER_LOG_VIEW',
  EQUIPMENT_USAGE_HISTORY_VIEW = 'EQUIPMENT_USAGE_HISTORY_VIEW',

  // Public API connector
  CONNECTOR_VIEW = 'CONNECTOR_VIEW',
  CONNECTOR_CREATE = 'CONNECTOR_CREATE',
  CONNECTOR_DELETE = 'CONNECTOR_DELETE',
}

export function validateCombinations(selectedAuthorities: Authorities[]) {
  const validationErrors = [];

  invalidCombinations()
      .forEach(combination => {
        if (combination.every(val => selectedAuthorities.includes(val))) {
          validationErrors.push(combination);
        }
      });

  return validationErrors;
}

function invalidCombinations() {
  return [
    [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW],
    [Authorities.PROJECT_ASSIGNEE_VIEW, Authorities.EQUIPMENT_CREATE],
    [Authorities.PROJECT_ASSIGNEE_VIEW, Authorities.EQUIPMENT_UPDATE],
    [Authorities.PROJECT_ASSIGNEE_VIEW, Authorities.EQUIPMENT_DELETE],
    [Authorities.PROJECT_ASSIGNEE_VIEW, Authorities.PROJECT_VIEW],
    [Authorities.PROJECT_ASSIGNEE_VIEW, Authorities.PROJECT_CREATE],
    [Authorities.EQUIPMENT_DISPOSITION_CHANGE_TYPE_OF_USE, Authorities.EQUIPMENT_DISPOSITION_REQUEST_CHANGE_TYPE_OF_USE],
    [Authorities.PROJECT_ASSIGNEE_VIEW, Authorities.PROJECT_GROUP_MANAGE],
    [Authorities.EQUIPMENT_UPDATE, Authorities.EQUIPMENT_STATUS_UPDATE_ANY],
    [Authorities.EQUIPMENT_UPDATE, Authorities.EQUIPMENT_STATUS_UPDATE_ACTIVE_INACTIVE],
    [Authorities.EQUIPMENT_UPDATE, Authorities.EQUIPMENT_STATUS_UPDATE_CATEGORY],
    [Authorities.EQUIPMENT_STATUS_UPDATE_ANY, Authorities.EQUIPMENT_STATUS_UPDATE_ACTIVE_INACTIVE],
    [Authorities.EQUIPMENT_STATUS_UPDATE_ANY, Authorities.EQUIPMENT_STATUS_UPDATE_CATEGORY],
    [Authorities.EQUIPMENT_STATUS_UPDATE_ACTIVE_INACTIVE, Authorities.EQUIPMENT_STATUS_UPDATE_CATEGORY]
  ]
}


