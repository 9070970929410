<div class="sidenav">
  <div class="menu" fxLayout="column" fxLayoutAlign="space-between center">
    <div class="menu__up" fxLayout="column" fxLayoutAlign="start center">
      <button mat-icon-button
              (click)="navigateTo(items.DASHBOARD)"
              [matTooltip]="'modules.navigation.sidebar.dashboard'|translate" matTooltipPosition="right"
              [ngClass]="{'sidenav-item--active': isItemActive(items.DASHBOARD)}"
              *ngIf="!hasAnyRole(priviledgedRole.Superadmin, priviledgedRole.Partneradmin)"
              data-osp-test="sidenav-item-dashboard">
        <mat-icon>home</mat-icon>
      </button>
      <button mat-icon-button
              (click)="navigateTo(items.EQUIPMENTS + '/' + getAvailableTab(items.EQUIPMENTS) + '/list')"
              [matTooltip]="'modules.navigation.sidebar.generalData'|translate" matTooltipPosition="right"
              [ngClass]="{'sidenav-item--active': isItemActive(items.EQUIPMENTS)}"
              *ngIf="isAllowedToSeeGeneralTab()"
              data-osp-test="sidenav-item-general-data">
        <mat-icon fontSet="icon-main_equipmentlist01_solid"></mat-icon>
      </button>
      <button mat-icon-button
              (click)="navigateTo(items.PROJECT + '/' + getAvailableTab(items.PROJECT) + '/list')"
              [matTooltip]="'modules.navigation.sidebar.locations'|translate" matTooltipPosition="right"
              [ngClass]="{'sidenav-item--active': isItemActive(items.PROJECT)}"
              *ngIf="isAllowedToSeeProjectTab() && hasModule(modules.DISPOSITION)"
              data-osp-test="sidenav-item-locations">
        <mat-icon fontSet="icon-main_projects02_maps_solid"></mat-icon>
      </button>
      <button mat-icon-button
              (click)="navigateTo(items.DISPOSITION)"
              [matTooltip]="'modules.navigation.sidebar.resourceDisposition'|translate" matTooltipPosition="right"
              [ngClass]="{'sidenav-item--active': isItemActive(items.DISPOSITION)}"
              *ngIf="hasAuthority(authorities.PROJECT_VIEW)
              && !hasAuthority(authorities.PROJECT_ASSIGNEE_VIEW)
              && hasModule(modules.DISPOSITION)"
              data-osp-test="sidenav-item-resource-disposition">
        <mat-icon fontSet="icon-main_disposition_solid"></mat-icon>
      </button>
      <button mat-icon-button
              (click)="navigateTo(items.TRANSPORTATION)"
              [matTooltip]="'modules.navigation.sidebar.transportDisposition'|translate" matTooltipPosition="right"
              [ngClass]="{'sidenav-item--active': isItemActive(items.TRANSPORTATION)}"
              *ngIf="hasAnyAuthority([authorities.TRANSPORT_TASK_DISPOSITION, authorities.TRANSPORT_TASK_MANAGE, authorities.TRANSPORT_TASK_VIEW])
              && hasModule(modules.TRANSPORT_TASK_MANAGEMENT)"
              data-osp-test="sidenav-item-transport-disposition">
              <fa-icon [icon]="faTruck" size="lg"></fa-icon>
      </button>
      <button mat-icon-button
              (click)="navigateTo(items.TRANSFER + '/' + getAvailableTab(items.TRANSFER))"
              [matTooltip]="'modules.navigation.sidebar.transferList'|translate" matTooltipPosition="right"
              [ngClass]="{'sidenav-item--active': isItemActive(items.TRANSFER)}"
              *ngIf="isAllowedToSeeTransferTab()"
              data-osp-test="sidenav-item-transfer-list">
        <fa-icon [icon]="faExchange" size="2x"></fa-icon>
      </button>
      <button mat-icon-button
              (click)="navigateTo(items.RENTAL)"
              [matTooltip]="'modules.navigation.sidebar.rental'|translate" matTooltipPosition="right"
              [ngClass]="{'sidenav-item--active': isItemActive(items.RENTAL)}"
              *ngIf="hasAnyAuthority([authorities.EQUIPMENT_VIEW, authorities.PROJECT_ASSIGNEE_VIEW])
              && hasModule(modules.RENTAL)"
              data-osp-test="sidenav-item-rental">
        <mat-icon>calendar_today</mat-icon>
      </button>
      <button mat-icon-button
              (click)="navigateTo(items.NOTIFICATION)"
              [matTooltip]="'modules.navigation.sidebar.notifications'|translate" matTooltipPosition="right"
              [ngClass]="{'sidenav-item--active': isItemActive(items.NOTIFICATION)}"
              *ngIf="hasAuthority(authorities.NOTIFICATIONS_MANAGE_ALARMS) && hasModule(modules.ALARMS)"
              data-osp-test="sidenav-item-notifications">
        <mat-icon fontSet="icon-main_alarms_solid"></mat-icon>
      </button>

      <button mat-icon-button
              (click)="navigateTo(items.REPORTS_VIEW)"
              [matTooltip]="'modules.navigation.sidebar.reports'|translate" matTooltipPosition="right"
              [ngClass]="{'sidenav-item--active': isItemActive(items.REPORTS_VIEW)}"
              *ngIf="hasAuthority(authorities.REPORT_VIEW) && hasModule(modules.REPORTING)"
              data-osp-test="sidenav-item-reports">
        <mat-icon fontSet="icon-main_reports_solid"></mat-icon>
      </button>

      <button mat-icon-button
              (click)="navigateTo(items.MAINTENANCE_TASKS)"
              [matTooltip]="'modules.navigation.sidebar.maintenance'|translate" matTooltipPosition="right"
              [ngClass]="{'sidenav-item--active': isItemActive(items.MAINTENANCE_TASKS)}"
              *ngIf="hasAnyAuthority([authorities.MAINTENANCE_TASK_MANAGE, authorities.MAINTENANCE_TASK_VIEW]) && hasModule(modules.SERVICE)"
              data-osp-test="sidenav-item-maintenance-inspection-tasks">
        <mat-icon>build</mat-icon>
      </button>

      <button mat-icon-button
              *ngIf="(hasModule(modules.WIP_GLOBAL_TRANSFER_LOG) && hasAuthority(authorities.GLOBAL_TRANSFER_LOG_VIEW)) || (hasModule(modules.EQUIPMENT_USAGE_HISTORY) && hasAuthority(authorities.EQUIPMENT_USAGE_HISTORY_VIEW))"
              [ngClass]="{'sidenav-item--active': isItemActive(items.ANALYTICS)}"
              [matTooltip]="'modules.navigation.sidebar.analytics'|translate" matTooltipPosition="right"
              (click)="navigateTo(items.ANALYTICS + '/' + getAvailableTab(items.ANALYTICS))"
              data-osp-test="sidenav-item-analytics">
        <mat-icon><fa-icon [icon]="faChartMixed"></fa-icon></mat-icon>
      </button>

      <button mat-icon-button
              [matMenuTriggerFor]="menu"
              [matTooltip]="'modules.navigation.sidebar.administration'|translate" matTooltipPosition="right"
              [ngClass]="{'sidenav-item--active': isMenuActive()}"
              data-osp-test="sidenav-item-administration">
        <mat-icon fontSet="icon-main_settings_solid"></mat-icon>
      </button>
    </div>

    <div class="menu__down" fxLayout="column" fxLayoutAlign="start center">
      <button mat-icon-button
              (click)="serviceHealth()"
              [matTooltip]="'modules.navigation.sidebar.healthService'|translate"
              matTooltipPosition="right"
              *ngIf="isInRole(priviledgedRole.Superadmin)">
        <mat-icon>help_outline</mat-icon>
      </button>
      <button mat-icon-button
              (click)="openShopLink()"
              [matTooltip]="'modules.navigation.sidebar.shop'|translate" matTooltipPosition="right"
              *ngIf="hasAnyAuthority([authorities.ONESTOP_PRO_SHOP]) && getShopUrl()">
        <mat-icon>shopping_cart</mat-icon>
      </button>
      <button mat-icon-button (click)="appVersion()"
              [matTooltip]="'modules.navigation.sidebar.info'|translate" matTooltipPosition="right">
        <mat-icon>info_outline</mat-icon>
      </button>
      <button mat-icon-button href="#" (click)="logoutUser($event)"
              [matTooltip]="'modules.navigation.sidebar.signOut'|translate" matTooltipPosition="right">
        <mat-icon>power_settings_new</mat-icon>
      </button>
    </div>
  </div>
</div>

<!--More menus-->
<mat-menu #menu="matMenu" class="sidenav-menu">
  <button mat-menu-item (click)="navigateTo(items.MENU.USERS)"
          *ngIf="hasAnyAuthority([
          authorities.SUPERADMIN_VIEW,
          authorities.PARTNERADMIN_VIEW,
          authorities.FLEETADMIN_VIEW,
          authorities.USER_VIEW])"
          data-osp-test="sidenav-item-user-administration">
    <mat-icon fontSet="icon-main_user_solid"></mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.userAdministration'|translate}}</span>
  </button>
  <button mat-menu-item (click)="navigateTo(items.MENU.ROLES)"
          *ngIf="hasAuthority(authorities.ROLE_VIEW)"
          data-osp-test="sidenav-item-user-roles">
    <mat-icon>group</mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.userRoles'|translate}}</span>
  </button>
  <button mat-menu-item (click)="navigateTo(items.MENU.CUSTOMERS)"
          *ngIf="hasAuthority(authorities.CUSTOMER_VIEW)"
          data-osp-test="sidenav-item-customers">
    <mat-icon fontSet="icon-main_customer_solid"></mat-icon>
    <span>{{'general.customer.pl'|translate}}</span>
  </button>
  <button mat-menu-item (click)="navigateTo(items.MENU.SUB_CUSTOMERS)"
          *ngIf="hasAuthority(authorities.SUB_CUSTOMER_VIEW) && hasModule(modules.SUB_CUSTOMERS)"
          data-osp-test="sidenav-item-customers">
    <mat-icon>diversity_3-outlined</mat-icon>
    <span>{{'general.subCustomer.pl'|translate}}</span>
  </button>
  <button mat-menu-item (click)="navigateTo(items.MENU.PARTNERS)"
          *ngIf="hasAuthority(authorities.PARTNER_VIEW)"
          data-osp-test="sidenav-item-partners">
    <mat-icon>person_outline</mat-icon>
    <span>{{'general.partner.pl'|translate}}</span>
  </button>
  <button mat-menu-item (click)="navigateTo(items.MENU.ORGANIZATIONS)"
          *ngIf="hasAuthority(authorities.ORGANISATION_VIEW)"
          data-osp-test="sidenav-item-organisation-management">
    <mat-icon fontSet="icon-main_organisations_solid"></mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.orgManagement'|translate}}</span>
  </button>
  <button mat-menu-item (click)="navigateTo(items.MENU.TECHNICAL_FIELDS)"
          *ngIf="hasAuthority(authorities.TECHNICAL_FIELD_VIEW)">
    <mat-icon>assignment</mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.technicalFields'|translate}}</span>
  </button>
  <button mat-menu-item (click)="navigateTo(items.MENU.MANUFACTURERS)"
          *ngIf="hasAuthority(authorities.MANUFACTURER_VIEW)">
    <mat-icon>location_city</mat-icon>
    <span>{{'general.manufacturer'|translate}}</span>
  </button>
  <button mat-menu-item (click)="navigateTo(items.MENU.CONNECTORS)"
          *ngIf="hasAuthority(authorities.JOBS_MANAGE)">
    <mat-icon>settings_input_component</mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.interfaces'|translate}}</span>
  </button>
  <button mat-menu-item (click)="navigateTo(items.MENU.REPORTS)"
          *ngIf="hasAuthority(authorities.REPORT_MANAGE)">
    <mat-icon fontSet="icon-main_reports_solid"></mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.reportTypes'|translate}}</span>
  </button>
  <button (click)="navigateTo(items.MENU.MAINTENANCE_RULES)"
          *ngIf="hasAuthority(authorities.MAINTENANCE_TASK_MANAGE) && hasModule(modules.SERVICE)"
          mat-menu-item
          data-osp-test="sidenav-item-maintenance-rules">
    <mat-icon>build</mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.rules'|translate}}</span>
  </button>
  <button (click)="navigateTo(items.MENU.MAINTENANCE_TYPES)"
          *ngIf="hasAuthority(authorities.MAINTENANCE_TYPE_MANAGE) && hasModule(modules.SERVICE)"
          mat-menu-item data-osp-test="sidenav-item-service-types">
    <mat-icon>assignment_turned_in</mat-icon>
    <span>{{'general.serviceType.pl'|translate}}</span>
  </button>
  <button (click)="navigateTo(items.MENU.TELEMATIC_UNITS)"
          *ngIf="hasAuthority(authorities.SUPERADMIN_VIEW)"
          mat-menu-item>
    <mat-icon fontSet="icon-telematics_outline"></mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.telematicsUnits'|translate}}</span>
  </button>
  <button (click)="navigateTo(items.MENU.TELEMATIC_UNITS_LIST)"
          *ngIf="hasAnyAuthority([
            authorities.SUPERADMIN_VIEW,
            authorities.PARTNER_TELEMATIC_UNITS_MANAGE
          ])"
          mat-menu-item>
    <mat-icon fontSet="icon-telematics_outline"></mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.telematicsUnitsAdministration'|translate}}</span>
  </button>
  <button (click)="navigateTo(items.MENU.TELEMATIC_UNITS_PROFILES)"
          *ngIf="hasAuthority(authorities.SUPERADMIN_VIEW)"
          mat-menu-item>
    <mat-icon fontSet="icon-telematics_outline"></mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.telematicUnitProfiles'|translate}}</span>
  </button>
  <button mat-menu-item
          *ngIf="isVisibleFieldManagementMenu()"
          (click)="navigateTo(items.MENU.FIELD_MANAGEMENT)"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="15px"
          data-osp-test="sidenav-item-field-management">
    <mat-icon><fa-icon [icon]="faPenField"></fa-icon></mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.fieldManagement'|translate}}</span>
  </button>
  <button mat-menu-item
          *ngIf="hasAuthority(authorities.CONNECTOR_VIEW) && !isSuperOrPartnerAdmin()"
          (click)="navigateTo(items.MENU.API_KEYS)"
          data-osp-test="sidenav-item-api-keys"
          class="api-keys-menu-item">
    <mat-icon svgIcon="api-keys"></mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.apiKeys'|translate}}</span>
  </button>
  <button mat-menu-item (click)="navigateTo(items.MENU.SETTINGS)" data-osp-test="sidenav-item-user-settings">
    <mat-icon fontSet="icon-main_user_solid"></mat-icon>
    <span>{{'modules.navigation.sidebar.administrationMenu.userSettings'|translate}}</span>
  </button>
</mat-menu>
