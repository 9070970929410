export enum TelematicsUnitType {
  AEMP = 'AEMP',
  SIGFOX_TRACKERTYPL26_1 = 'SIGFOX_TRACKERTYPL26_1',
  SIGFOX_TRACKERTYPL26_2 = 'SIGFOX_TRACKERTYPL26_2',
  SIGFOX_TRACKERTYPL26_3 = 'SIGFOX_TRACKERTYPL26_3',
  BOSCH_MYTOOLS_1 = 'BOSCH_MYTOOLS_1',
  BOSCH_MYTOOLS_2 = 'BOSCH_MYTOOLS_2',
  BOSCH_CLOUD_1 = 'BOSCH_CLOUD_1',
  BOSCH_CLOUD_2 = 'BOSCH_CLOUD_2',
  TRACKUNIT_1 = 'TRACKUNIT_1',
  TRACKUNIT_2 = 'TRACKUNIT_2',
  RUPTELA_1 = 'RUPTELA_1',
  RUPTELA_2 = 'RUPTELA_2',
  UBINAM_1 = 'UBINAM_1',
  UBINAM_2 = 'UBINAM_2',
  TRUSTED_1 = 'TRUSTED_1',
  TRUSTED_2 = 'TRUSTED_2',
  GENERIC_1 = 'GENERIC_1',
  GENERIC_2 = 'GENERIC_2',
  GENERIC_3 = 'GENERIC_3',
  GENERIC_4 = 'GENERIC_4',
  GENERIC_5 = 'GENERIC_5',
  GENERIC_6 = 'GENERIC_6',
  GENERIC_7 = 'GENERIC_7',
  GENERIC_8 = 'GENERIC_8',
  GENERIC_9 = 'GENERIC_9',
  GENERIC_10 = 'GENERIC_10',
  TELTONIKA_CABLE_UNIT = 'TELTONIKA_CABLE_UNIT',
  TELTONIKA_OBD_UNIT = 'TELTONIKA_OBD_UNIT',
  GPS_OVER_IP = 'GPS_OVER_IP',
  ELA_PUCK = 'ELA_PUCK',
  ELA_COIN = 'ELA_COIN',
  RIO = 'RIO',
  CONFIDEX_CLASSIC = 'CONFIDEX_CLASSIC',
  CONFIDEX_ROUGH = 'CONFIDEX_ROUGH',
  CONFIDEX_MICRO = 'CONFIDEX_MICRO',
  DIGITAL_MATTER_OYSTER3_UNIT = 'DIGITAL_MATTER_OYSTER3_UNIT'
}

export function canDetectBluetoothBeacons(type: TelematicsUnitType | string): boolean {
  return type === TelematicsUnitType.TELTONIKA_OBD_UNIT
    || type === TelematicsUnitType.TELTONIKA_CABLE_UNIT
    || type === TelematicsUnitType.DIGITAL_MATTER_OYSTER3_UNIT
}


export function getBeaconTypes(): TelematicsUnitType[] {
  return [
    TelematicsUnitType.ELA_PUCK,
    TelematicsUnitType.ELA_COIN,
    TelematicsUnitType.CONFIDEX_CLASSIC,
    TelematicsUnitType.CONFIDEX_ROUGH,
    TelematicsUnitType.CONFIDEX_MICRO
  ];
}

export function isBeaconType(type: string): boolean {
  if (!type || !TelematicsUnitType[type]) {
    return false;
  }
  return getBeaconTypes().includes(TelematicsUnitType[type]);
}
