import { LanguageService } from '../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentEventSeverity } from '../../contract/equipment-event-severity.enum';

@Pipe({
  name: 'equipmentEventSeverityPipe',
})
export class EquipmentEventSeverityPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(severity: EquipmentEventSeverity): string {
    switch (severity) {
      case EquipmentEventSeverity.INFO:
        return this.translate('shared.pipes.equipmentEventSeverity.info');
      case EquipmentEventSeverity.LOW:
        return this.translate('shared.pipes.equipmentEventSeverity.low');
      case EquipmentEventSeverity.MEDIUM:
        return this.translate('shared.pipes.equipmentEventSeverity.medium');
      case EquipmentEventSeverity.CRITICAL:
        return this.translate('shared.pipes.equipmentEventSeverity.critical');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
