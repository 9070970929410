<div class="equipment-events-wrapper" fxLayout="column">

  <div class="events-header">
    <bh-title [text]="'general.event.pl'|translate"></bh-title>
    <bh-equipment-events-log [isEventsTab]="true"></bh-equipment-events-log>
  </div>

  <form [formGroup]="filterForm" class="events-filters-bar">
    <mat-form-field fxFlex="30%">
      <mat-select multiple
                  [placeholder]="'general.columnNames.status'|translate"
                  formControlName="statusFilters">
        <mat-option *ngFor="let status of statusList | keys" [value]="status">
          {{ status | equipmentEventStatusPipe }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="30%">
      <mat-select multiple
                  [placeholder]="'general.columnNames.severity'|translate"
                  formControlName="severityFilters">
        <mat-option *ngFor="let severity of severityList | keys" [value]="severity">
          {{ severity | equipmentEventSeverityPipe }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field fxFlex="30%">
      <mat-select multiple
                  [placeholder]="'general.columnNames.type'|translate"
                  formControlName="typeFilters">
        <mat-option *ngFor="let type of TYPE_LIST | keys" [value]="type">
          {{ type | equipmentEventTypePipe }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <form [formGroup]="timeRangeForm" class="events-search-and-time-range-bar">
    <mat-form-field fxFlex="30%">
      <mat-label>{{ 'general.search'|translate }}</mat-label>
      <mat-icon matPrefix>search</mat-icon>
      <input matInput [formControl]="searchFilter">
    </mat-form-field>

    <mat-form-field fxFlex="30%">
      <mat-label>{{ 'general.startDate'|translate }}</mat-label>
      <input #fromDateInputRef
             matInput
             formControlName="fromDate"
             bhInputAllowedCharacters="[0-9./]"
             [max]="toDate"
             [matDatepicker]="fromDatePicker">
      <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #fromDatePicker></mat-datepicker>
      <mat-error *ngIf="fromDateControl.errors as errors">
        <span *ngIf="errors.matDatepickerParse">
          {{ 'shared.validation.date.invalidDateFormat'|translate }}.
          {{ 'shared.validation.date.useFormat'|translate: {value: dateFormat} }}
        </span>
        <span *ngIf="errors.matDatepickerMax">
          {{ 'shared.validation.date.cannotBeAfter'|translate: {value: toDate | date: dateFormat.replace('DD', 'dd')} }}
        </span>
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="30%">
      <mat-label>{{ 'general.endDate'|translate }}</mat-label>
      <input #toDateInputRef
             matInput
             formControlName="toDate"
             bhInputAllowedCharacters="[0-9./]"
             [min]="fromDate"
             [matDatepicker]="toDatePicker">
      <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #toDatePicker></mat-datepicker>

      <mat-error *ngIf="toDateControl.errors as errors">
        <span *ngIf="errors.matDatepickerParse">
          {{ 'shared.validation.date.invalidDateFormat'|translate }}.
          {{ 'shared.validation.date.useFormat'|translate: {value: dateFormat} }}
        </span>
        <span *ngIf="errors.matDatepickerMin">
          {{ 'shared.validation.date.cannotBeBefore'|translate: {value: fromDate | date: dateFormat.replace('DD', 'dd')} }}
        </span>
      </mat-error>
    </mat-form-field>
  </form>

  <div class="event-tabs-wrapper">
    <mat-tab-group mat-stretch-tabs
                   animationDuration="0ms"
                   [(selectedIndex)]="selectedTabIndex"
                   (selectedTabChange)="tabChangeEvent($event.index)">
      <mat-tab>
        <ng-template mat-tab-label>
          {{ 'general.activeLog'|translate }}
          <span *ngIf="activeEventCount" class="active-event-badge">{{ activeEventCount }}</span>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>{{ 'general.eventLog'|translate }}</ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>


  <div *ngIf="(eventsList | async).length > 0; else noTasks"
       class="event-table-wrapper scrollable-table-container" fxFlex fxLayoutGap="15px" fxLayoutAlign="start stretch">
    <mat-table [dataSource]="eventsList | async"
               multiTemplateDataRows
               class="scrollable-table"
               matSort
               (matSortChange)="sortData($event)">
      <ng-container *ngFor="let column of displayedColumns" matColumnDef="{{column}}">
        <ng-container *ngIf="column === COLUMNS.STATUS">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="event-header">{{ column | columnNamePipe }}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <bh-color-badge [value]="element[column] | equipmentEventStatusPipe"
                            [color]="element[column] | statusColorPipe">
            </bh-color-badge>
          </mat-cell>
        </ng-container>

        <ng-container *ngIf="column === COLUMNS.SEVERITY">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="event-header">{{ column | columnNamePipe }}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <bh-color-badge [value]="element[column] | equipmentEventSeverityPipe"
                            [color]="element[column] | statusColorPipe">
            </bh-color-badge>
          </mat-cell>
        </ng-container>

        <ng-container *ngIf="column === COLUMNS.TYPE">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="event-header">{{ column | columnNamePipe }}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">{{ element[column] | equipmentEventTypePipe }}</mat-cell>
        </ng-container>

        <ng-container *ngIf="column === COLUMNS.CREATION_DATE">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="event-header">{{ column | columnNamePipe }}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element[column] | formatTimezoneDateTime: {dateFormat: 'MMM D, yyyy, HH:mm'} }}
          </mat-cell>
        </ng-container>

        <ng-container *ngIf="column === COLUMNS.DESCRIPTION">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class="event-header">{{ column | columnNamePipe }}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="element[COLUMNS.TYPE] === TYPE_LIST.MILEAGE" class="text-overflow"
                  [bhOverflowTooltip]="element[column]">
              {{ element[column] ? (getMileageNumber(element[column]) | dimensionUnitConverter:'km' | mileage) : '-' }} {{ 'km' | dimensionUnitDisplay }}
            </span>
            <span *ngIf="element[COLUMNS.TYPE] === TYPE_LIST.OPERATING_HOURS" class="text-overflow"
                  [bhOverflowTooltip]="element[column]">
              {{ element[column] ? element[column] : '-' }} {{ 'general.units.time.hour.pl' | translate }}
            </span>
            <span *ngIf="element[COLUMNS.TYPE] === TYPE_LIST.DAMAGE" class="text-overflow"
                  [bhOverflowTooltip]="element[column]">
              {{ element[column] | damageTypeDisplay }}
            </span>
            <span
              *ngIf="element[COLUMNS.TYPE] !== TYPE_LIST.DAMAGE && element[COLUMNS.TYPE] !== TYPE_LIST.MILEAGE && element[COLUMNS.TYPE] !== TYPE_LIST.OPERATING_HOURS"
              class="text-overflow" [bhOverflowTooltip]="element[column]">
              {{ element[column] }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container *ngIf="column === COLUMNS.OCCURRENCES">
          <mat-header-cell *matHeaderCellDef>
            <span class="event-header">{{ column | columnNamePipe }}</span>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span class="text-overflow" [bhOverflowTooltip]="element[column]">{{ element[column] }}</span>
          </mat-cell>
        </ng-container>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"
               class="active"
               (click)="selectEvent(row)">
      </mat-row>
    </mat-table>
  </div>

  <div class="paginator" fxLayout="row wrap">
    <div *ngIf="(eventsList | async).length > 0 && currentTab === eventTabs.ACTIVE"
         class="paginator-wrapper">
      <mat-paginator
        [pageIndex]="activeLogDatasource.getPageIndex() | async"
        [pageSize]="activeLogDatasource.getPageSize() | async"
        [length]="activeLogDatasource.getLength() | async"
        (page)="activeLogDatasource.handlePageEvent($event)"
        [pageSizeOptions]="[5, 25, 50, 100]">
      </mat-paginator>

      <button class="export-button"
              mat-button
              [matMenuTriggerFor]="export">
        <span class="export-button__wrapper">
          {{ 'general.export'|translate }}
          <mat-icon>expand_more</mat-icon>
        </span>
      </button>
    </div>

    <div *ngIf="(eventsList | async).length > 0 && currentTab === eventTabs.EVENT"
         class="paginator-wrapper">
      <mat-paginator
        [pageIndex]="eventLogDatasource.getPageIndex() | async"
        [pageSize]="eventLogDatasource.getPageSize() | async"
        [length]="eventLogDatasource.getLength() | async"
        (page)="eventLogDatasource.handlePageEvent($event)"
        [pageSizeOptions]="[5, 25, 50, 100]">
      </mat-paginator>

      <button class="export-button"
              mat-button
              [matMenuTriggerFor]="export">
        <span class="export-button__wrapper">
          {{ 'general.export'|translate }}
          <mat-icon>expand_more</mat-icon>
        </span>
      </button>
    </div>
  </div>

</div>

<ng-template #noTasks>
  <div class="no-tasks" fxLayout="row" fxLayoutAlign="center center">
    <span>{{ 'modules.equipment.cost.noDataAvailable'|translate }}</span>
  </div>
</ng-template>

<mat-menu class="export-menu" #export="matMenu">
  <span class="export-menu__title">{{ 'general.format'|translate|uppercase }}</span>
  <button mat-menu-item (click)="exportExcel()">{{ 'general.excelSheet'|translate }}</button>
  <button mat-menu-item (click)="exportCSV()">{{ 'general.csv'|translate }}</button>
</mat-menu>
