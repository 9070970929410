<div class="timeline-event-details">
  <ng-container *ngIf="task?.completedAtMileage > 0 || task?.completedAtOperatingHours > 0">
    <h2 class="timeline-event-details__title">{{'modules.maintenance.task.completion'|translate}}</h2>
    <div class="timeline-event-details__content">
      <div class="timeline-event-details__content__field" *ngIf="task?.completedAtMileage > 0">
        <mat-form-field floatLabel="always">
          <mat-label>{{'general.labels.performedOutAtMileage'|translate}} ({{ 'km' | dimensionUnitDisplay }})</mat-label>
          <input matInput
                readonly
                [value]="task?.completedAtMileage | dimensionUnitConverter: 'km'">
        </mat-form-field>
      </div>

      <div class="timeline-event-details__content__field" *ngIf="task?.completedAtOperatingHours > 0">
        <mat-form-field floatLabel="always">
          <mat-label>{{'general.labels.performedOutAtOperatingHours'|translate}}</mat-label>
          <input matInput
                readonly
                [value]="task?.completedAtOperatingHours">
        </mat-form-field>
      </div>
    </div>
  </ng-container>

  <div *ngIf="shortFieldValues?.length > 0 || longFieldValues?.length > 0">
    <h2 class="timeline-event-details__title">{{'general.labels.inspectionForm'|translate}}</h2>

    <div *ngIf="shortFieldValues?.length > 0" class="timeline-event-details__content">
      <ng-container *ngFor="let field of shortFieldValues">
        <div class="timeline-event-details__content__field"
             *ngIf="(field.maintenanceTypeFieldName | isAllowedDynamicField) || (field.value !== null)">
          <mat-form-field floatLabel="always">
            <mat-label>{{field.maintenanceTypeFieldName | maintenanceTypeFieldTranslation}}</mat-label>
            <input *ngIf="field.maintenanceFieldType === FieldType.DATE"
                   matInput
                   readonly
                   value="{{field.value | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}">
            <input *ngIf="field.maintenanceFieldType !== FieldType.DATE"
                   matInput
                   readonly
                   value="{{field.value}}">
          </mat-form-field>
        </div>
      </ng-container>
    </div>

    <div *ngIf="longFieldValues?.length > 0" class="timeline-event-details__content">
      <ng-container *ngFor="let field of longFieldValues">
        <div class="timeline-event-details__content__area"
             *ngIf="(field.maintenanceTypeFieldName | isAllowedDynamicField) || (field.value !== null)">
          <mat-form-field floatLabel="always">
            <mat-label>{{field.maintenanceTypeFieldName | maintenanceTypeFieldTranslation}}</mat-label>
            <textarea matInput readonly value="{{field.value}}"
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="1"></textarea>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </div>
</div>
