import { TelematicsUnitType } from '../../contract/telematics-unit-type.enum';
import { OptionResolver } from '../../../../shared/components/card-select/option.resolver';
import { Injectable } from '@angular/core';
import { faRouter, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { TelematicsUnitTypeAssignment } from '../../contract/telematics-unit-type-assignment.enum';
import { LanguageService } from '../../../../shared/services/language.service';
import { KeyValuePair } from '../../contract/key-value-pair';
import { TelematicsUnitTypeInfo } from '../../contract/telematics-unit-type-info.enum';

@Injectable()
export class EquipmentTelematicsUnitTypeResolver implements OptionResolver {

  constructor(private languageService: LanguageService) { }

  public resolveName(option: string): string {
    switch (option) {
      case TelematicsUnitTypeAssignment.AEMP:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.iso');
      case TelematicsUnitTypeAssignment.GENERIC_2:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.aemp');
      case TelematicsUnitTypeAssignment.GENERIC_3:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.goldfunk');
      case TelematicsUnitTypeAssignment.GENERIC_4:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.sinos');
      case TelematicsUnitTypeAssignment.GENERIC_5:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.twinline');
      case TelematicsUnitTypeAssignment.SIGFOX_TRACKERTYPL26_1:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.sigfox');
      case TelematicsUnitType.TRACKUNIT_1:
      case TelematicsUnitTypeAssignment.TRACKUNIT_1_RAW:
      case TelematicsUnitTypeAssignment.TRACKUNIT_1_SPOT:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.trackunit');
      case TelematicsUnitTypeAssignment.TELTONIKA_CABLE_UNIT:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.cableUnit');
      case TelematicsUnitTypeAssignment.TELTONIKA_OBD_UNIT:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.obdUnit');
      case TelematicsUnitTypeAssignment.GPS_OVER_IP:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.gps');
      case TelematicsUnitTypeAssignment.RIO:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.rio');
      case TelematicsUnitTypeAssignment.CONFIDEX_CLASSIC:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.confidexClassic');
      case TelematicsUnitTypeAssignment.CONFIDEX_ROUGH:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.confidexTough');
      case TelematicsUnitTypeAssignment.CONFIDEX_MICRO:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.confidexMicro');
      case TelematicsUnitTypeAssignment.DIGITAL_MATTER_OYSTER3_UNIT:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.oyster3');
    }

    return option;
  }

  public resolveSubtitle(option: string): string {
    switch (option) {
      case TelematicsUnitTypeAssignment.TRACKUNIT_1_RAW:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.raw');
      case TelematicsUnitTypeAssignment.TRACKUNIT_1_SPOT:
        return this.languageService.getInstant('modules.equipment.assignTelematicUnit.spot');
      default: return null;
    }
  }

  public resolveIcon(option: string): IconDefinition {
    switch (option) {
      case TelematicsUnitTypeAssignment.AEMP: return faRouter;
      case TelematicsUnitTypeAssignment.GENERIC_2: return faRouter;
      case TelematicsUnitTypeAssignment.GENERIC_3: return faRouter;
      case TelematicsUnitTypeAssignment.GENERIC_4: return faRouter;
      case TelematicsUnitTypeAssignment.GENERIC_5: return faRouter;
      case TelematicsUnitTypeAssignment.GPS_OVER_IP: return faRouter;
      case TelematicsUnitTypeAssignment.RIO: return faRouter;
      default: return null;
    }
  }

  public resolveLogo(option: string): string {
    switch (option) {
      case TelematicsUnitType.SIGFOX_TRACKERTYPL26_1:
      case TelematicsUnitTypeAssignment.SIGFOX_TRACKERTYPL26_1: return './assets/telematic-unit/logos/sigfox.svg';
      case TelematicsUnitType.TRACKUNIT_1:
      case TelematicsUnitTypeAssignment.TRACKUNIT_1_RAW:
      case TelematicsUnitTypeAssignment.TRACKUNIT_1_SPOT:
      case TelematicsUnitTypeAssignment.TRACKUNIT_1_SPOT_RAW: return './assets/telematic-unit/logos/trackunit.svg';
      case TelematicsUnitTypeAssignment.CONFIDEX_CLASSIC:
      case TelematicsUnitTypeAssignment.CONFIDEX_ROUGH: return './assets/telematic-unit/logos/bluetooth.png';
      case TelematicsUnitTypeAssignment.CONFIDEX_MICRO: return './assets/telematic-unit/logos/microBeacon.png';
      case TelematicsUnitTypeAssignment.TELTONIKA_CABLE_UNIT: return './assets/telematic-unit/logos/telematic_retrofit.png';
      case TelematicsUnitTypeAssignment.TELTONIKA_OBD_UNIT: return './assets/telematic-unit/logos/obd_stecker.png';
      case TelematicsUnitTypeAssignment.DIGITAL_MATTER_OYSTER3_UNIT: return './assets/telematic-unit/logos/oyster2.png';
      default: return null;
    }
  }

  public resolveIconImage(option: string): string {
    switch (option) {
      case TelematicsUnitTypeAssignment.TRACKUNIT_1_RAW: return './assets/telematic-unit/img/trackunit_raw.png';
      case TelematicsUnitTypeAssignment.TRACKUNIT_1_SPOT: return './assets/telematic-unit/img/trackunit_spot.png';
      default: return null;
    }
  }

  public getTelematicsUnitInfo(assignment: KeyValuePair): string {
    return `${this.getTelematicsUnitTypeInfo(assignment)}: ${this.getTelematicsUnitId(assignment)}`
  }

  public getTelematicsUnitTypeInfo(assignment: KeyValuePair): string {
    if (TelematicsUnitTypeInfo[assignment.key]) {
      switch (assignment.key) {
        case TelematicsUnitTypeInfo.CONFIDEX_CLASSIC:
          return this.languageService.getInstant('modules.equipment.assignTelematicUnit.confidexClassic');
        case TelematicsUnitTypeInfo.CONFIDEX_ROUGH:
          return this.languageService.getInstant('modules.equipment.assignTelematicUnit.confidexTough');
        case TelematicsUnitTypeInfo.CONFIDEX_MICRO:
          return this.languageService.getInstant('modules.equipment.assignTelematicUnit.confidexMicro');
        default:
          return TelematicsUnitTypeInfo[assignment.key];
      }
    } else {
      return this.languageService.getInstant('general.unknown')
    }
  }

  public getTelematicsUnitId(assignment: KeyValuePair): string {
    if (TelematicsUnitTypeInfo[assignment.key]) {
      switch (assignment.key) {
        case TelematicsUnitTypeInfo.CONFIDEX_CLASSIC:
        case TelematicsUnitTypeInfo.CONFIDEX_ROUGH:
        case TelematicsUnitTypeInfo.CONFIDEX_MICRO:
          return assignment.value.split('##').pop();
        default:
          return assignment.value;
      }
    } else {
      return assignment.value
    }
  }
}
