<div class="geofences-list" fxLayout="column" fxLayoutAlign="start stretch">

  <mat-card class="fences-control-panel">
    <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <mat-spinner *ngIf="isLoading" [strokeWidth]="2" [diameter]="16"></mat-spinner>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center">
        <mat-icon fxFlex="nogrow"
                  aria-label="show-all"
                  (click)="showAllGeofences(!createModeActive)"
                  [matTooltip]="showAllTooltip"
                  fontSet="icon-visible_outline">
        </mat-icon>
        <mat-icon fxFlex="nogrow"
                  aria-label="hide-all"
                  (click)="hideAllGeofences()"
                  [matTooltip]="hideAllTooltip"
                  fontSet="icon-invisible_outline">
        </mat-icon>
        <mat-icon fxFlex="nogrow"
                  aria-label="add"
                  *ngIf="createGeofenceEnabled && !hasAnyActiveMode"
                  (click)="enterCreateMode()"
                  [matTooltip]="tooltipTextAdd"
                  fontSet="icon-add_outline"
                  data-osp-test="icon-title-add">
        </mat-icon>
        <ng-container *ngIf="geofenceListItems|hasAnyDeletable">
          <mat-icon fxFlex="nogrow"
                    aria-label="edit"
                    *ngIf="editGeofenceEnabled && !hasAnyActiveMode"
                    (click)="enterEditMode()"
                    [matTooltip]="tooltipTextEdit">
            mode_edit
          </mat-icon>
          <mat-icon fxFlex="nogrow"
                    aria-label="delete"
                    *ngIf="deleteGeofenceEnabled && !hasAnyActiveMode"
                    (click)="enterDeleteMode()"
                    [matTooltip]="tooltipTextDelete"
                    data-osp-test="icon-title-delete">
            delete_forever
          </mat-icon>
        </ng-container>
        <mat-icon fxFlex="nogrow"
                  aria-label="save"
                  [ngClass]="{'inactive': !createForm.valid || !validGeofence()}"
                  *ngIf="createModeActive || hasSelectedGeofenceForEdit"
                  (click)="save()"
                  [matTooltip]="'general.buttons.save'|translate">
          done
        </mat-icon>
        <mat-icon fxFlex="nogrow"
                  aria-label="cancel"
                  *ngIf="hasAnyActiveMode"
                  (click)="cancel()"
                  [matTooltip]="'general.buttons.cancel'|translate"
                  data-osp-test="icon-title-cancel">
          close
        </mat-icon>
      </div>
    </div>
  </mat-card>

  <!-- list of existing geofences -->
  <mat-card class="geofences-list-overview" *ngIf="!createModeActive && !hasSelectedGeofenceForEdit">
    <mat-list>
      <mat-list-item *ngFor="let geofenceListItem of geofenceListItems">
        <div class="geofences-list-overview__item" fxLayout="row" fxLayoutAlign="space-between center">

          <div fxFlex class="geofences-list-overview__name" (click)="showSingleGeofence(geofenceListItem)">
            {{geofenceListItem.name}}
          </div>

          <div  *ngIf="geofenceListItem.isDispositionGeofence"
                class="geofences-list-overview__icon"
                [matTooltip]="'shared.geofence.transferGeofence'|translate">
            <fa-icon class="auto-geo" [icon]="faArrowRightArrowLeft"></fa-icon>
          </div>

          <div *ngIf="manageAlarmsEnabled && !hasAnyActiveMode && !geofenceListItem.readOnly"
               class="geofences-list-overview__icon">
            <mat-icon class="alarm-inactive"
                      *ngIf="!geofenceListItem.alarming "
                      (click)="setAlarm(geofenceListItem)"
                      [matTooltip]="'shared.geofence.setAlarm'|translate">
              notifications_none
            </mat-icon>
            <mat-icon class="alarm-active"
                      *ngIf="geofenceListItem.alarming"
                      (click)="removeAlarm(geofenceListItem)"
                      [matTooltip]="'shared.geofence.cancelAlarm'|translate">
              notifications_active
            </mat-icon>
          </div>

          <div
            *ngIf="displayAlarmIndicators && !manageAlarmsEnabled && !deleteModeActive && !geofenceListItem.readOnly">
            <mat-icon class="alarm-inactive"
                      *ngIf="geofenceListItem.alarming "
                      [matTooltip]="'shared.geofence.associatedWithAlarm'|translate">
              notifications_none
            </mat-icon>
          </div>

          <div *ngIf="deleteModeActive && !geofenceListItem.readOnly">
            <mat-icon (click)="delete(geofenceListItem)">
              delete_forever
            </mat-icon>
          </div>

          <mat-icon fxFlex="nogrow"
                    aria-label="edit-item"
                    *ngIf="editGeofenceEnabled && editModeActive"
                    (click)="beginGeofenceEdit(geofenceListItem)">
            mode_edit
          </mat-icon>

          <div *ngIf="geofenceListItem.link">
            <mat-icon [routerLink]="[geofenceListItem.link]"
                      [matTooltip]="'general.labels.jumpTo'|translate:{ value: 'general.project.s'|translate }">
              open_in_new
            </mat-icon>
          </div>
        </div>
      </mat-list-item>
      <mat-list-item *ngIf="geofenceListItems.length === 0 && !isLoading">
        <div class="geofences-list-overview__item" fxLayout="row" fxLayoutAlign="center center">
          <div
            class="responsive-text">{{'general.labels.noAnyFound'|translate:{ value: 'general.geofence.pl'|translate } }}</div>
        </div>
      </mat-list-item>
    </mat-list>
  </mat-card>

  <!-- geofence create form -->
  <form [formGroup]="createForm" *ngIf="createGeofenceEnabled || editGeofenceEnabled"
        fxLayout="column" fxLayoutAlign="space-between stretch">
    <mat-card class="fences-create-panel" *ngIf="createModeActive || hasSelectedGeofenceForEdit">
      <mat-list>
        <mat-list-item>
          <mat-form-field class="multi-line-mat-error">
            <mat-label>{{'general.designation'|translate}}</mat-label>
            <input matInput required bhRemoveWhitespaces
                   formControlName="geofenceName"
                   [maxlength]="fieldLimit.NAME">
            <mat-error *ngIf="geofenceName.hasError('geofenceExists')"
                       class="responsive-text">{{'shared.geofence.nameAlreadyExists'|translate}}</mat-error>
          </mat-form-field>
        </mat-list-item>
        <mat-list-item>
          <mat-form-field>
            <mat-label>{{'shared.geofence.color'|translate}}</mat-label>
            <mat-select formControlName="geofenceColor">
              <mat-option *ngFor="let color of colors" [value]="color.hex">{{ color.name }}</mat-option>
            </mat-select>
          </mat-form-field>
        </mat-list-item>

        <div  *ngIf="hasAutomatedTransfersModule"
              [matTooltip]="'shared.geofence.disableOtherGeofenceFlag'|translate"
              [matTooltipDisabled]="!isDispositionGeofenceDisabled">
          <mat-checkbox [disabled]="isDispositionGeofenceDisabled"
                        [checked]="isDispositionGeofenceSelected"
                        (click)="dispositionGeofenceCheckboxEvent()">
            {{'shared.geofence.transferGeofence'|translate}}
          </mat-checkbox>
        </div>

        <mat-list-item *ngIf="coordsOfNewGeofence.length < 3">
          <span class="responsive-text">{{'shared.geofence.clickToCreate'|translate}}</span>
        </mat-list-item>
        <mat-list-item *ngIf="closePathButtonEnabled" fxLayout="row" fxLayoutAlign="center center">
          <button class="create-button" mat-raised-button (click)="closePath()" matButton>
            {{'shared.geofence.completePath'|translate}}
          </button>
        </mat-list-item>
        <mat-list-item *ngIf="invalidGeofence()" fxLayout="row" fxLayoutAlign="center center">
          <mat-error class="responsive-text">{{'shared.geofence.invalidShape'|translate}}</mat-error>
        </mat-list-item>
      </mat-list>
    </mat-card>
  </form>

</div>
