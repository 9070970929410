export enum EquipmentEventPayloadType {
  DAMAGE = 'DamageEquipmentEventPayload',
  EMPLOYEE_ASSIGNMENT = 'EmployeeAssignmentEquipmentEventPayload',
  GEOFENCE = 'GeofenceEquipmentEventPayload',
  INCIDENT = 'IncidentEquipmentEventPayload',
  MACHINE_FAULT = 'MachineFaultEquipmentEventPayload',
  MAINTENANCE = 'MaintenanceEquipmentEventPayload',
  MILEAGE = 'MileageEquipmentEventPayload',
  OPERATING_HOURS = 'OperatingHoursEquipmentEventPayload',
}
