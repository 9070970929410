export enum EquipmentHistoryColumn {
  EQUIPMENT_CREATED = 'equipmentCreated',

  DOCUMENT = 'document',
  EQUIPMENT_BEUTHAUSER_ID = 'equipmentBeutlhauserId',
  EQUIPMENT_CONSTRUCTION_YEAR = 'equipmentConstructionYear',
  EQUIPMENT_CONSTRUCTION_YEAR_MONTH = 'equipmentConstructionYear.month',
  EQUIPMENT_CONSTRUCTION_YEAR_YEAR = 'equipmentConstructionYear.year',
  EQUIPMENT_COST_CENTER = 'equipmentCostCenter',
  EQUIPMENT_CUSTOMER_SERIAL_NUMBER = 'equipmentCustomerSerialNumber',
  EQUIPMENT_DELIVERY_DATE = 'equipmentDeliveryDate',
  EQUIPMENT_GUARANTEE = 'equipmentGuarantee',
  EQUIPMENT_GUARANTEE_OPERATING_HOURS_LIMIT = 'equipmentGuaranteeOperatingHoursLimit',
  EQUIPMENT_INSURANCE_CONTRACT = 'equipmentInsuranceContract',
  EQUIPMENT_INSURANCE_CONTRACT_DOCUMENTS = 'equipmentInsuranceContract.documents',
  EQUIPMENT_LICENSE_PLATE = 'equipmentLicensePlate',
  EQUIPMENT_NAME = 'equipmentName',
  EQUIPMENT_PRODUCT_CLASS = 'equipmentProductClass',
  EQUIPMENT_RENTAL_CONTRACT = 'equipmentRentalContract',
  EQUIPMENT_RENTAL_CONTRACT_PARTNER = 'equipmentRentalContract.contractPartner',
  EQUIPMENT_RENTAL_CONTRACT_DOCUMENTS = 'equipmentRentalContract.documents',
  EQUIPMENT_RENTAL_CONTRACT_TYPE = 'equipmentRentalContract.rentalContractType',
  EQUIPMENT_RENTAL_CONTRACT_MILEAGE_LIMIT_PERIOD = 'equipmentRentalContract.rentalMileageLimitPeriod',
  EQUIPMENT_SALES_CONTRACT = 'equipmentSalesContract',
  EQUIPMENT_SALES_CONTRACT_DOCUMENTS = 'equipmentSalesContract.documents',
  EQUIPMENT_SERIAL_NUMBER = 'equipmentSerialNumber',
  EQUIPMENT_MODEL = 'equipmentModel',
  EQUIPMENT_SERVICE_CONTRACT = 'equipmentServiceContract',
  EQUIPMENT_SERVICE_CONTRACT_DOCUMENTS = 'equipmentServiceContract.documents',
  EQUIPMENT_STATUS_SERVICE = 'equipmentStatusService',
  IMAGE = 'image',
  LABELS = 'labels',
  MANUFACTURER = 'manufacturer',
  ORGANISATION = 'organisation',
  SCANCODE = 'scanCode',
  TELEMATICS_UNIT_ID = 'telematicsUnitId',
  TELEMATICS_UNIT_TYPE = 'telematicsUnitType',
  THUMBNAIL = 'thumbnail',
  SUBEQUIPMENT = 'subEquipment',
  CONTAINER = 'container',
  EQUIPMENT_BGL_CODE = 'equipmentBGLCode',
  REGISTRATION_DATE = 'registrationDate',
  EQUIPMENT_STATUS = 'equipmentStatus',
  EQUIPMENT_STATUS_CATEGORY = 'equipmentStatus.category',
  EQUIPMENT_STATUS_NAME = 'equipmentStatus.name',
  EQUIPMENT_TYPE_CATEGORY1 = 'equipmentType.category1',
  EQUIPMENT_TYPE_CATEGORY2 = 'equipmentType.category2',
  EQUIPMENT_TYPE_TEMPLATEKEY = 'equipmentType.templateKey',
  EQUIPMENT_TYPE_TECHNICAL_FIELDS = 'equipmentType.technicalFields',
  EQUIPMENT_TYPE_CONTAINER = 'equipmentType.container',
  EQUIPMENT_AUTOMATED_TRANSFERS_STATUS = 'automatedTransferInclusionStatus',
}
