import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import moment, { Moment } from 'moment';
import { saveAs } from 'file-saver';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Languages } from 'app/shared/enums/languages.enum';
import { EquipmentsService } from '../equipments.service';
import { LanguageService } from 'app/shared/services/language.service';
import { EventGroupInfo } from '../../contract/event-group-info.interface';
import { TimezoneDatesService } from 'app/shared/timezone/timezone-dates.service';
import { EquipmentEventRequestParams } from '../../contract/equipment-event-request-params';
import { ReportExportFormat } from 'app/modules/reports/contract/report-export-format.enum';
import { EventGroupType } from '../../contract/event-group-type.enum';
import { ZoneId } from 'app/shared/contract/zone-id-param.interface';

@Injectable()
export class EquipmentViewActiveLogDatasource {
  private _activeEventsList: BehaviorSubject<EventGroupInfo[]> = new BehaviorSubject([]);
  public readonly activeEventsList = this._activeEventsList.asObservable();
  private _activeEventCount = new BehaviorSubject(0);
  public readonly activeEventCount = this._activeEventCount.asObservable();
  private _equipmentId = '';
  private _equipmentInternalNumber = '';
  private _statusFilters: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _severityFilters: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _typeFilters: BehaviorSubject<string[]> = new BehaviorSubject([]);
  private _fromDate: BehaviorSubject<Moment> = new BehaviorSubject(null);
  private _toDate: BehaviorSubject<Moment> = new BehaviorSubject(null);

  private _pageIndex = new BehaviorSubject(0);
  private _pageSize = new BehaviorSubject(25);
  private _length = new BehaviorSubject(0);
  private _terms = '';
  private _sort: Sort = null;

  public set equipmentId(id: string) {
    this._equipmentId = id;
  }

  public get equipmentId(): string {
    return this._equipmentId;
  }

  public set equipmentInternalNumber(number: string) {
    this._equipmentInternalNumber = number;
  }

  public set terms(terms: string) {
    this._terms = terms;
  }

  public get terms(): string {
    return this._terms;
  }

  public set sort(sort: Sort) {
    this._sort = sort;
  }

  public set statusFilters(filters: string[]) {
    this._statusFilters.next(filters);
  }

  public get statusFilters(): string[] {
    return this._statusFilters.value;
  }

  public set severityFilters(filters: string[]) {
    this._severityFilters.next(filters);
  }

  public get severityFilters(): string[] {
    return this._severityFilters.value;
  }

  public set typeFilters(filters: string[]) {
    this._typeFilters.next(filters);
  }

  public get typeFilters(): string[] {
    return this._typeFilters.value;
  }

  public set fromDate(param: Moment) {
    this._fromDate.next(param);
  }

  public get fromDate(): Moment {
    return this._fromDate.value;
  }

  public set toDate(param: Moment) {
    this._toDate.next(param);
  }

  public get toDate(): Moment {
    return this._toDate.value;
  }

  constructor(
    private equipmentsService: EquipmentsService,
    private languageService: LanguageService,
    private timezoneDataService: TimezoneDatesService,
  ) { }

  public getEquipmentActiveLogList(): void {
    this.equipmentsService.getEquipmentActiveLog(this.equipmentId, this.getActiveLogRequestParams())
      .subscribe(res => {
        this._activeEventsList.next(res.content);
        this._length.next(res.totalElements);
      });
  }

  private getActiveLogRequestParams(): EquipmentEventRequestParams {
    return {
      severityFilter: this._severityFilters.value,
      statusFilter: this._statusFilters.value,
      typeFilter: this._typeFilters.value,
      fromDate: this.getFromDateParam(),
      toDate: this.getToDateParam(),
      terms: this._terms,
      page: this._pageIndex.value,
      size: this._pageSize.value,
      sort: this.getSortRequest(this._sort),
    }
  }

  public getEquipmentActiveEventCount(): void {
    this.equipmentsService.getEquipmentActiveEventCount(this._equipmentId).subscribe(res => this._activeEventCount.next(res));
  }

  public getPageIndex(): Observable<number> {
    return this._pageIndex.asObservable();
  }

  public getPageSize(): Observable<number> {
    return this._pageSize.asObservable();
  }

  public getLength(): Observable<number> {
    return this._length.asObservable();
  }

  public resetPageIndex(): void {
    this._pageIndex.next(0);
  }

  public handlePageEvent(event: PageEvent): void {
    this._pageIndex.next(event.pageIndex);
    this._pageSize.next(event.pageSize);
    this.getEquipmentActiveLogList();
  }

  public eventsExportExcel(): void {
    let exportFormat = ReportExportFormat.XLSX;
    this.equipmentsService.eventsExportExcel(this._equipmentId, EventGroupType.ACTIVE, this.getEventsExportExcelRequestParams())
      .subscribe(data => this.saveList(data, exportFormat.toString().toLowerCase()));
  }

  private getEventsExportExcelRequestParams(): ZoneId {
    return {
      zoneId: this.timezoneDataService.getSelectedTimezone(),
    }
  }

  public eventsExportCSV(): void {
    let exportFormat = ReportExportFormat.CSV;
    this.equipmentsService.eventsExportCSV(this._equipmentId, EventGroupType.ACTIVE, this.getEventsExportCSVRequestParams())
      .subscribe(data => this.saveList(data, exportFormat.toString().toLowerCase()));
  }

  private getEventsExportCSVRequestParams(): ZoneId {
    return {
      zoneId: this.timezoneDataService.getSelectedTimezone(),
    }
  }

  private saveList(data: Blob, format: string): void {
    saveAs(data, this.createFileName(format));
  }

  private createFileName(format: string): string {
    switch(this.languageService.getUserLanguage()) {
      case Languages.DE:
        return `export-${this._equipmentInternalNumber}-aktive-ereignisse-tabelle-` +
                moment().format('YYYY_MM_DD_HH_mm_ss') + '.' + format;
      case Languages.EN:
        return `export-${this._equipmentInternalNumber}-active-events-table-` +
                moment().format('YYYY_MM_DD_HH_mm_ss') + '.' + format;
      default:
        return `export-${this._equipmentInternalNumber}-active-events-table-` +
                moment().format('YYYY_MM_DD_HH_mm_ss') + '.' + format;
    }
  }

  private getFromDateParam(): string {
    return this._fromDate.value ? this._fromDate.value.startOf('day').toISOString() : null;
  }

  private getToDateParam(): string {
    return this._toDate.value ? this._toDate.value.endOf('day').toISOString() : null;
  }

  private getSortRequest(sort: Sort): string {
    if (sort && sort.active && sort.direction) {
      return `${sort.active},${sort.direction}`;
    }
    return null;
  }
}
