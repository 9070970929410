import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ViewCustomer } from '../contract/view-customer.interface';
import { UpdateCustomerCommand } from '../contract/update-customer-command';
import { CreateCustomerCommand } from '../contract/create-customer-command';
import { DeleteCustomerCommand } from '../contract/delete-customer-command';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { SearchCustomer } from '../contract/search-customer.interface';
import { UpdateCustomerModulesCommand } from '../contract/update-customer-modules-command';
import { UpdateCustomerRolesCommand } from '../contract/update-customer-roles-command';
import { SetCustomerShopIdCommand } from '../contract/set-customer-shopId-command';
import { getHttpParameters } from 'app/shared/utils';
import { SetCustomerAutoIncrementCommand } from '../contract/set-customer-autonumber-schema-command';
import { RemoveCustomerShopIdCommand } from '../contract/remove-customer-shopId-command';
import { UpdateCustomerLanguagesCommand } from '../contract/update-customer-languages.command';
import { Languages } from '../../../shared/enums/languages.enum';
import { MoveCustomerCommand } from '../contract/move-customer-command';
import { CreateSubCustomerCommand } from '../contract/create-sub-customer-command';
import { SubCustomer } from '../contract/sub-customer.interface';

export type CustomerLanguageStats = {
  [key in Languages]?: number
};

@Injectable()
export class CustomersService {

  private url = environment.APP_ORGANISATION_SERVICE_BASE_URL + '/api/v1/customers';
  private userroleUrl = environment.APP_USERROLE_SERVICE_BASE_URL + '/api/v1/customers';

  constructor(private http: HttpClient) { }

  getCustomers(page?: number, size?: number): Observable<PagedResponse<SearchCustomer>> {
    const pagingUrl = this.url + '?page=' + page + '&size=' + size;

    return this.http.get<PagedResponse<SearchCustomer>>(pagingUrl);
  }

  getCustomersSearch(terms: string, page?: number, size?: number): Observable<PagedResponse<SearchCustomer>> {
    let params = {
      'page': page,
      'size': size,
      'terms': terms
    };
    let httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<SearchCustomer>>(this.url + '/search/all', {params: httpParams});
  }

  getAllCustomers(): Observable<SubCustomer[]> {
    const getAllUrl = this.url + '/all';
    return this.http.get<SubCustomer[]>(getAllUrl);
  }

  getCustomer(customerId: string): Observable<ViewCustomer> {
    return this.http.get<ViewCustomer>( this.url + '/' + customerId);
  }

  getCustomerName(customerId: string): Observable<string> {
    const requestOptions: Object = { responseType: 'text' };

    return this.http.get<string>( this.url + '/' + customerId + '/name', requestOptions);
  }

  getCustomerAutoIncrementSchema(customerId: string): Observable<string> {
    return this.http.get( this.url + '/' + customerId + '/project-number-auto-increment-schema', {responseType: 'text'});
  }

  updateCustomerAutoIncrementSchema(command: SetCustomerAutoIncrementCommand): Observable<string> {
    return this.http.post(
      this.url + '/set-customer-project-autoincrement-schema', JSON.stringify(command), {responseType: 'text'}
    );
  }

  getCustomerShopId(customerId: string): Observable<string> {
    return this.http.get( this.url + '/' + customerId + '/shopId', {responseType: 'text'});
  }

  getShopUrl(customerId: string): Observable<string> {
    return this.http.get( this.url + '/' + customerId + '/shopUrl', { responseType: 'text' });
  }

  addCustomer(command: CreateCustomerCommand) {
    return this.http.post(this.url + '/create', JSON.stringify(command), {responseType: 'text'});
  }

  addSubCustomer(command: CreateSubCustomerCommand) {
    return this.http.post(this.url + '/sub-customers/create', JSON.stringify(command), {responseType: 'text'});
  }

  updateCustomer(command: UpdateCustomerCommand) {
    return this.http.post(this.url + '/update', JSON.stringify(command), {responseType: 'text'});
  }

  deleteCustomer(command: DeleteCustomerCommand) {
    return this.http.post(this.url + '/delete', JSON.stringify(command), {responseType: 'text'});
  }

  moveCustomer(command: MoveCustomerCommand) {
    return this.http.post(this.url + '/move', JSON.stringify(command), {responseType: 'text'});
  }

  hasSubCustomers(customerId: string): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/' + customerId + '/' + 'has-sub-customers');
  }

  updateModules(command: UpdateCustomerModulesCommand) {
    return this.http.post(this.url + '/update-modules', JSON.stringify(command), {responseType: 'text'});
  }

  updateRoles(command: UpdateCustomerRolesCommand) {
    return this.http.post(this.url + '/update-roles', JSON.stringify(command), {responseType: 'text'});
  }

  updateShopId(command: SetCustomerShopIdCommand): Observable<string> {
    return this.http.post(this.url + '/setShopId', JSON.stringify(command), {responseType: 'text'})
  }

  deleteShopId(command: RemoveCustomerShopIdCommand): Observable<string> {
    return this.http.post(this.url + '/removeShopId', JSON.stringify(command), {responseType: 'text'})
  }

  customerNumberInUse(customerNumber: string, customerId: string, partnerId: string): Observable<boolean> {
    let params = {
      'customerNumber': customerNumber,
      'customerId': customerId,
      'partnerId': partnerId
    };

    let httpParams = getHttpParameters(params);

    return this.http.get<boolean>(this.url + '/customer-number-in-use', {params: httpParams});
  }

  customerNameInUse(customerName: string, customerId: string, partnerId: string): Observable<boolean> {
    let params = {
      'customerName': customerName,
      'customerId': customerId,
      'partnerId': partnerId
    };

    let httpParams = getHttpParameters(params);

    return this.http.get<boolean>(this.url + '/customer-name-in-use', {params: httpParams});
  }


  public updateCustomerLanguages(command: UpdateCustomerLanguagesCommand): Observable<string> {
    return this.http.post(this.url + '/set-languages', JSON.stringify(command), {responseType: 'text'});
  }

  public getCustomerLanguageStats(customerId: string): Observable<CustomerLanguageStats> {
    return this.http.get(`${this.userroleUrl}/${customerId}/languages-usage`);
  }

  public isAllowedAsDefaultLanguage(customerId: string, language: Languages): Observable<boolean> {
    const params = getHttpParameters({ language })
    return this.http.get<boolean>(`${this.url}/${customerId}/allowed-to-set-default-language`, { params });
  }
}
