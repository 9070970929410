<div class="container">
  <div>{{'modules.equipment.equipmentView.automatedTransfersStatus'|translate}}</div>
  <mat-form-field>
    <mat-select [(value)]="selectedStatus"
                (selectionChange)="changeStatusAvailability(selectedStatus.value)">
      <mat-select-trigger *ngIf="selectedStatus">
        <fa-icon [icon]="selectedStatus.icon"></fa-icon>
        <span>{{selectedStatus.name}}</span>
      </mat-select-trigger>

      <mat-option *ngFor="let status of statusList"
                  [value]="status"
                  [disabled]="status.disabled">
        <div class="custom-option">
          <fa-icon [icon]="status.icon"></fa-icon>
          <span>{{status.name}}</span>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="footer">
    <button mat-raised-button color="accent" mat-dialog-close="abort">{{'general.buttons.cancel'|translate}}</button>
    <button mat-raised-button
            bhTimeoutSaveButton
            color="primary"
            (throttledClick)="save()">
      {{'general.buttons.save'|translate}}
    </button>
  </div>
</div>
