import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EquipmentMaintenanceTask } from '../../../../../../contract/equipment-maintenance-task';
import {
  byTypeFieldName,
  MaintenanceFieldValue,
} from '../../../../../../../../shared/contract/task-completion/maintenance-field-value';
import { EquipmentMaintenanceTaskService } from '../../../../../../shared/services/equipment-maintenance-task.service';
import { FieldType } from '../../../../../../../../shared/enums/field-type';
import { LanguageService } from '../../../../../../../../shared/services/language.service';
import { LifeCycleTask } from '../../../../../../contract/life-cycle-task';
import { MaintenanceDynamicField } from '../../../../../../../../shared/enums/maintenance-dynamic-field.enum';

@Component({
  selector: 'bh-task-event',
  templateUrl: './task-event.component.html'
})

@UntilDestroy()
export class TaskEventComponent implements OnInit {

  @Input() maintenanceTaskLifeCycle: LifeCycleTask;

  public task: EquipmentMaintenanceTask;
  public shortFieldValues: MaintenanceFieldValue[];
  public longFieldValues: MaintenanceFieldValue[];
  public FieldType = FieldType;

  constructor(protected equipmentMaintenanceTaskService: EquipmentMaintenanceTaskService,
              protected languageService: LanguageService) {
  }

  public ngOnInit(): void {
    this.getMaintenanceTask();
  }

  public getMaintenanceTask(): void {
    this.equipmentMaintenanceTaskService.getById(this.maintenanceTaskLifeCycle.maintenanceTaskId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: task => this.setEquipmentMaintenanceTask(task),
        error: error => console.log('error get invoice details: ', error)
      });
  }

  public getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  private setEquipmentMaintenanceTask(task: EquipmentMaintenanceTask): void {
    if (task) {
      this.task = task;
      this.shortFieldValues = this.task.maintenanceTaskCompletionInfo.maintenanceFieldValues
        .filter(value => value.maintenanceTypeFieldName !== MaintenanceDynamicField.DESCRIPTION)
        .sort(byTypeFieldName());
      this.longFieldValues = this.task.maintenanceTaskCompletionInfo.maintenanceFieldValues
        .filter(value => value.maintenanceTypeFieldName === MaintenanceDynamicField.DESCRIPTION);
    }
  }
}
