<div class="list-view-main__right" fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
  <div class="list-view-main__right-header" fxFlex="60px" fxLayout="row" fxLayoutAlign="space-between center">

    <div class="full-screen" fxFlex="0 0 70px" fxLayout="row" fxLayoutAlign="center center"
         [matTooltip]="(projectStore.fullScreen ? 'general.labels.detailView.reduce' : 'general.labels.detailView.enlarge')|translate"
         (click)="projectStore.toggleFullScreen()">
      <mat-icon *ngIf="!projectStore.fullScreen" fontSet="icon-fullscreen_lauch_outline"></mat-icon>
      <mat-icon *ngIf="projectStore.fullScreen" fontSet="icon-fullscreen_exit_outline"></mat-icon>
    </div>

    <div class="name" fxFlex="10 1 100%">
      <pre class="project-number"># {{ project?.projectNumber?.trim() }}</pre>
      <span class="separator">&nbsp;-&nbsp;</span>
      <span class="project-name" data-osp-test="label-project-name">{{ project?.projectName }}</span>
      <mat-icon class="project-icon" [matTooltip]="'general.project.isFinished'|translate"
                *ngIf="projectCheckerService.isProjectFinished(project)">
        done_all
      </mat-icon>

    </div>
    <div *ngIf="project?.projectStructureType===ProjectStructureType.PROJECT_GROUP" class="group-label">
      {{'modules.disposition.projectView.mainProject'|translate}}
    </div>

    <div fxFlex="0 1 90px" class="edit"
         *ngIf="!projectCheckerService.isProjectFinished(project) && editButtonVisibleForProjectGroups(project)">
      <button mat-raised-button (click)="edit()"
              *ngIf="hasAuthority(authorities.PROJECT_UPDATE)">
        <span>{{'general.buttons.edit'|translate}}</span>
      </button>
    </div>

    <div fxFlex="0 1 40px" class="more" [matMenuTriggerFor]="projectMore"
         *ngIf="showMoreMenu()">
      <mat-icon>more_vert</mat-icon>
    </div>

    <mat-menu #projectMore="matMenu">
      <div [matTooltip]="finishTooltip"
           [matTooltipDisabled]="!finishDisabled">
        <button mat-menu-item
                *ngIf="projectCheckerService.allowFinishProject(project)"
                [disabled]="finishDisabled"
                (click)="finishProject()">
          <mat-icon [ngClass]="{'disabled': finishDisabled}">done_all</mat-icon>
          <span>{{'modules.disposition.projectView.completeProject'|translate}}</span>
        </button>
      </div>
      <div>
        <button *ngIf="projectCheckerService.isProjectFinished(project)"
                mat-menu-item
                (click)="reopenProject(project.projectId)">
          <mat-icon>lock_open</mat-icon>
          <span>{{'modules.disposition.projectView.reopenProject'|translate}}</span>
        </button>
      </div>
      <div *ngIf="hasAuthority(authorities.PROJECT_GROUP_MANAGE)
            && hasModule(modules.PROJECT_GROUP_MANAGEMENT)
            && isNotSubProject(project)
            && isActiveProject(project)">
        <button mat-menu-item
                (click)="project.projectStructureType === ProjectStructureType.STANDALONE_PROJECT ?
                createProjectGroup(project?.projectId, project.projectName) :
                createSubProject(project?.projectId, project.projectName)">
          <mat-icon>
            subdirectory_arrow_right
          </mat-icon>
          <span>{{'modules.disposition.projectView.createSubProject'|translate}}</span>
        </button>
      </div>
      <div *ngIf="hasAuthority(authorities.PROJECT_GROUP_MANAGE)
            && hasModule(modules.PROJECT_GROUP_MANAGEMENT)
            && !isNotSubProject(project)">
        <button mat-menu-item
                (click)="renameSubProject(project?.projectId, project.projectName)">
          <mat-icon>
            edit
          </mat-icon>
          <span>{{'modules.disposition.projectView.renameSubProject'|translate}}</span>
        </button>
      </div>
      <div *ngIf="isStandaloneProject(project?.projectStructureType); else deleteSubProjectTmp"
           [matTooltip]="getDeletableTooltip()"
           [matTooltipDisabled]="!deleteDisabled">
        <button mat-menu-item
                *ngIf="hasAuthority(authorities.PROJECT_DELETE)"
                [disabled]="deleteDisabled"
                (click)="projectDelete(project?.projectId)">
          <mat-icon [ngClass]="{'disabled': deleteDisabled}">delete_forever</mat-icon>
          <span>{{'modules.disposition.projectView.deleteProject'|translate}}</span>
        </button>
      </div>
      <ng-template #deleteSubProjectTmp>
        <div *ngIf="project?.projectStructureType !== ProjectStructureType.PROJECT_GROUP"
             [matTooltip]="getDeletableSubProjectTooltip()"
             [matTooltipDisabled]="!deleteSubProjectDisabled()">
          <button mat-menu-item
                  *ngIf="hasAuthority(authorities.PROJECT_GROUP_MANAGE)"
                  [disabled]="deleteSubProjectDisabled()"
                  (click)="subProjectDelete(project?.projectId, project?.projectName)">
            <mat-icon [ngClass]="{'disabled': deleteSubProjectDisabled()}">delete_forever</mat-icon>
            <span>{{'modules.disposition.projectView.deleteProject'|translate}}</span>
          </button>
        </div>
        <div *ngIf="project?.projectStructureType === ProjectStructureType.PROJECT_GROUP"
             [matTooltip]="'modules.disposition.projectView.deletableProjectGroupTooltip'|translate"
             [matTooltipDisabled]="!deleteProjectGroupDisabled(project)">
          <button mat-menu-item
                  *ngIf="hasAuthority(authorities.PROJECT_GROUP_MANAGE)"
                  [disabled]="deleteProjectGroupDisabled(project)"
                  (click)="projectGroupDelete(project?.projectId, project?.projectName)">
            <mat-icon [ngClass]="{'disabled': deleteProjectGroupDisabled(project)}">delete_forever</mat-icon>
            <span>{{'modules.disposition.projectView.deleteProjectGroup'|translate}}</span>
          </button>
        </div>
      </ng-template>
    </mat-menu>
  </div>

  <div class="list-view-main__right-body" fxFlex fxLayout="row" fxLayoutAlign="start stretch">
    <div #submenu class="submenu" fxFlex="70px" fxLayout="column" fxLayoutAlign="start stretch">
      <div>
        <p (click)="setTab(subMenuRouterLinks.GENERAL)"
           [routerLink]="[subMenuRouterLinks.GENERAL]"
           routerLinkActive="submenu--active">
          <mat-icon fontSet="icon-general_outline"></mat-icon>
          <span>{{'general.generalData'|translate}}</span>
        </p>
        <ng-container *ngIf="project?.projectStructureType !== ProjectStructureType.PROJECT_GROUP">
          <p *ngIf="hasModule(modules.LIVE_DATA) && hasAuthority(authorities.GEOFENCE_VIEW)"
             (click)="setTab(subMenuRouterLinks.GEOFENCES)"
             [routerLink]="[subMenuRouterLinks.GEOFENCES]"
             routerLinkActive="submenu--active">
            <mat-icon fontSet="icon-geofences_outline"></mat-icon>
            <span>{{'modules.disposition.projectView.geofences'|translate}}</span>
          </p>

          <p (click)="setTab(subMenuRouterLinks.COMMENTS)"
             [routerLink]="[subMenuRouterLinks.COMMENTS]"
             routerLinkActive="submenu--active">
            <mat-icon fontSet="icon-comments_outline"></mat-icon>
            <span>{{'general.comment.s'|translate}}</span>
          </p>

          <p (click)="setTab(subMenuRouterLinks.ATTACHMENTS)"
             [routerLink]="[subMenuRouterLinks.ATTACHMENTS]"
             routerLinkActive="submenu--active">
            <mat-icon fontSet="icon-attachment_outline"></mat-icon>
            <span>{{'general.attachment.pl'|translate}}</span>
          </p>

          <p (click)="setTab(subMenuRouterLinks.ASSIGNMENTS)"
             [routerLink]="[subMenuRouterLinks.ASSIGNMENTS]"
             routerLinkActive="submenu--active">
            <fa-icon [icon]="faPalletAlt"></fa-icon>
            <span>{{'modules.disposition.base.inventory.s'|translate}}</span>
          </p>

          <p (click)="setTab(subMenuRouterLinks.TRANSFER_HISTORY)"
             [routerLink]="[subMenuRouterLinks.TRANSFER_HISTORY]"
             routerLinkActive="submenu--active">
            <fa-icon [icon]="faExchange"></fa-icon>
            <span>{{'modules.disposition.projectView.transfers'|translate}}</span>
          </p>
          <p *ngIf="allowChangeTypeOfUse()"
             (click)="setTab(subMenuRouterLinks.CHANGE_TYPE_OF_USE)"
             [routerLink]="[subMenuRouterLinks.CHANGE_TYPE_OF_USE]"
             routerLinkActive="submenu--active">
            <fa-icon [icon]="faRetweetAlt"></fa-icon>
            <span>{{'modules.disposition.projectView.typeOfUse'|translate}}</span>
          </p>
        </ng-container>
      </div>
      <p #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="tabsMoreMenu"
         [ngClass]="{'submenu--active': isTabMoreMenuActive()}">
        <mat-icon>more_horiz</mat-icon>
        <span>{{'general.buttons.more'|translate}}</span>
      </p>
    </div>

    <mat-menu class="equipment-custom-mat-menu" #tabsMoreMenu="matMenu">
      <ng-container *ngFor="let tab of hiddenTabs">
        <button mat-menu-item [routerLink]="[tab.url]" (click)="setTab(tab.url)">
          <mat-icon *ngIf="tab.matIconFontSet || tab.matIconContent" [fontSet]="tab.matIconFontSet">
            {{ tab.matIconContent }}
          </mat-icon>
          <fa-icon *ngIf="tab.faIcon" [icon]="tab.faIcon"></fa-icon>
          <span>{{ tab.name }}</span>
        </button>
      </ng-container>
    </mat-menu>

    <div class="content" fxFlex>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
