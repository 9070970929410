<div  class="color-badge"
      [ngClass]="color"
      [style.filter]="brighter?'brightness(0.9)':'brightness(1)'">
  <span *ngIf="showIcon">
    <fa-icon *ngIf="color === 'green'" [icon]="faCircleCheck"></fa-icon>
    <fa-icon *ngIf="color === 'orange'" [icon]="faTriangleExclamation"></fa-icon>
    <fa-icon *ngIf="color === 'red'" [icon]="faCircleExclamation"></fa-icon>
  </span>
  <span>{{value}}</span>
</div>
