<mat-card class="entity-creation">

  <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">

    <div class="entity-creation-title" fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-icon class="title-icon" (click)="clickTitleIcon()">account_circle</mat-icon>
      <span>{{'modules.userrole.userSettings.userSettings'|translate}}</span>
    </div>

    <div class="entity-creation-actions" fxFlex="100">
    </div>

    <div class="entity-creation-form" fxFlex="100">
      <h3>{{'modules.userrole.userSettings.changePassword'|translate}}</h3>
      <p>
        {{'modules.userrole.userSettings.clickToChangePassword'|translate}}:
      </p>
      <p>
        <button mat-raised-button (click)="resetPassword()" *ngIf="!resetEmailSent && !waiting">
          {{'modules.userrole.userSettings.changePassword'|translate}}
        </button>
      </p>
      <p *ngIf="waiting">
        <mat-spinner [strokeWidth]="3" [diameter]="20"></mat-spinner>
      </p>
      <p *ngIf="resetEmailSent" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center">
        <mat-icon class="ok">done_all</mat-icon>
        <span>{{'modules.userrole.userSettings.checkMail'|translate}}</span>
      </p>
    </div>

    <div class="entity-creation-form" fxFlex="100" fxLayout="row" fxLayoutGap="20">
      <div fxLayout="column">
        <h3>{{'modules.userrole.userSettings.userLanguage'|translate}}</h3>
        <bh-language-switcher></bh-language-switcher>
      </div>
      <div fxLayout="column">
        <h3>{{'modules.userrole.userSettings.timezone'|translate}}</h3>
        <bh-timezone-switcher></bh-timezone-switcher>
      </div>
    </div>

    <div class="entity-creation-form" fxFlex="100">
      <h3>{{'modules.userrole.userSettings.userDimensionUnit'|translate}}</h3>
      <bh-dimension-unit-switcher></bh-dimension-unit-switcher>
    </div>

    <div *ngIf="environment.EQUIPMENT_SEARCH_V2_TOGGLE_AVAILABLE" class="entity-creation-form" fxFlex="100" fxLayout="column" fxLayoutGap="20">
      <h3>{{'modules.userrole.userSettings.equipmentSearchBackendVersion'|translate}}</h3>
      <mat-slide-toggle color="primary" [formControl]="useEquipmentSearchV2Control" fxFlex>
        {{'modules.userrole.userSettings.equipmentSearchEnableV2'|translate}}
      </mat-slide-toggle>
    </div>

    <div *ngIf="environment.EQUIPMENT_EVENTS_TOGGLE_AVAILABLE" class="entity-creation-form" fxFlex="100" fxLayout="column" fxLayoutGap="20">
      <h3>{{'modules.userrole.userSettings.equipmentEvents'|translate}}</h3>
      <mat-slide-toggle color="primary" [formControl]="equipmentEventsControl" fxFlex>
        {{'modules.userrole.userSettings.equipmentEvents'|translate}}
      </mat-slide-toggle>
    </div>

  </mat-card-content>

</mat-card>
