export enum ColumnDefinitionKeys {
  GENERAL_THUMBNAIL = 'THUMBNAIL',

  GENERAL_STATUS = 'STATUS',
  GENERAL_ORGANISATION = 'ORGANISATION',
  GENERAL_OPEN_DETAILS = 'OPEN_DETAILS',
  GENERAL_LABELS = 'LABELS',
  GENERAL_IDENTIFIER = 'IDENTIFIER',
  GENERAL_NAME = 'NAME',
  GENERAL_EMAIL = 'EMAIL',
  GENERAL_ADDRESS = 'ADDRESS',

  EQUIPMENT_INFO = 'EQUIPMENT_INFO',
  EQUIPMENT_IMAGE = 'EQUIPMENT_IMAGE',
  EQUIPMENT_CUSTOMER_SERIAL_NUMBER = 'EQUIPMENT_CUSTOMER_SERIAL_NUMBER',
  EQUIPMENT_SERIAL_NUMBER = 'EQUIPMENT_SERIAL_NUMBER',
  EQUIPMENT_LABELS = 'EQUIPMENT_LABELS',
  EQUIPMENT_MODEL = 'EQUIPMENT_MODEL',
  EQUIPMENT_TYPE_CATEGORY1 = 'EQUIPMENT_TYPE_CATEGORY1',
  EQUIPMENT_TYPE_CATEGORY2 = 'EQUIPMENT_TYPE_CATEGORY2',
  EQUIPMENT_TYPE_CATEGORY2_FOR_TRANSFER = 'EQUIPMENT_TYPE_CATEGORY2_FOR_TRANSFER',
  CURRENT_ADDRESS = 'CURRENT_ADDRESS',
  EQUIPMENT_NAME = 'EQUIPMENT_NAME',
  EQUIPMENT_BEUTLHAUSER_ID = 'EQUIPMENT_BEUTLHAUSER_ID',
  EQUIPMENT_COST_CENTER = 'EQUIPMENT_COST_CENTER',
  EQUIPMENT_CONSTRUCTION_YEAR = 'EQUIPMENT_CONSTRUCTION_YEAR',
  EQUIPMENT_CURRENT_LOCATION_NAME = 'EQUIPMENT_CURRENT_LOCATION_NAME',
  EQUIPMENT_CURRENT_LOCATION_NUMBER = 'EQUIPMENT_CURRENT_LOCATION_NUMBER',
  EQUIPMENT_CURRENT_LOCATION_COST_CENTER = 'EQUIPMENT_CURRENT_LOCATION_COST_CENTER',
  CURRENT_OPERATING_HOURS = 'CURRENT_OPERATING_HOURS',
  EQUIPMENT_SCAN_CODE = 'EQUIPMENT_SCAN_CODE',
  EQUIPMENT_BGL_CODE = 'EQUIPMENT_BGL_CODE',
  EQUIPMENT_LICENSE_PLATE = 'EQUIPMENT_LICENSE_PLATE',
  EQUIPMENT_REGISTRATION_DATE = 'EQUIPMENT_REGISTRATION_DATE',
  ASSIGNED_OWNER = 'ASSIGNED_OWNER',
  ASSIGNED_PERSON_IN_CHARGE = 'ASSIGNED_PERSON_IN_CHARGE',
  ASSIGNED_MAINTENANCE_ASSIGNEE = 'ASSIGNED_MAINTENANCE_ASSIGNEE',
  ASSIGNED_DRIVER = 'ASSIGNED_DRIVER',
  ASSIGNED_POSSESSOR = 'ASSIGNED_POSSESSOR',
  CURRENT_LOCATION_NUMBER = 'CURRENT_LOCATION_NUMBER',
  CURRENT_LOCATION_NAME = 'CURRENT_LOCATION_NAME',
  CURRENT_LOCATION_COST_CENTER = 'CURRENT_LOCATION_COST_CENTER',
  SUB_EQUIPMENT = 'SUB_EQUIPMENT',    // Do not change value. Related to a global css class .cdk-column-SUB_EQUIPMENT
  EQUIPMENT_FAVOURITE = 'EQUIPMENT_FAVOURITE',
  EQUIPMENT_ATTENTION_STATUS = 'EQUIPMENT_ATTENTION_STATUS',
  EQUIPMENT_CORE_DATA = 'EQUIPMENT_CORE_DATA',

  BULK_ITEM_ICON = 'BULK_ITEM_ICON',
  BULK_ITEM_NUMBER = 'BULK_ITEM_NUMBER',
  BULK_ITEM_NAME = 'BULK_ITEM_NAME',
  BULK_ITEM_TYPE = 'BULK_ITEM_TYPE',
  BULK_ITEM_CATEGORY = 'BULK_ITEM_CATEGORY',
  BULK_ITEM_BGL_CODE = 'BULK_ITEM_BGL_CODE',
  BULK_ITEM_BAL_CODE = 'BULK_ITEM_BAL_CODE',
  BULK_ITEM_SCAN_CODE = 'BULK_ITEM_SCAN_CODE',
  BULK_ITEM_COST_CENTER = 'BULK_ITEM_COST_CENTER',
  BULK_ITEM_COST_TYPE = 'BULK_ITEM_COST_TYPE',
  BULK_ITEM_UNIT = 'BULK_ITEM_UNIT',
  BULK_ITEM_WEIGHT = 'BULK_ITEM_WEIGHT',
  MANUFACTURER_ID = 'MANUFACTURER_ID',
  MANUFACTURER_NAME = 'MANUFACTURER_NAME',
  SUPPLIER_ID = 'SUPPLIER_ID',
  SUPPLIER_NAME = 'SUPPLIER_NAME',

  TRANSFER_BUIK_ITEM_TYPE = 'TRANSFER_BUIK_ITEM_TYPE',
  TRANSFER_BULK_ITEM_COST_CENTER = 'TRANSFER_BULK_ITEM_COST_CENTER',
  TRANSFER_LABELS = 'TRANSFER_LABELS',
  STOCK_NAME = 'STOCK_NAME',
  STOCK_COST_CENTER = 'STOCK_COST_CENTER',
  STOCK_ADDRESS = 'STOCK_ADDRESS',
  STOCK_ORGANISATION = 'STOCK_ORGANISATION',
  AMOUNT_AVAILABLE = 'AMOUNT_AVAILABLE',
  EQUIPMENT_AMOUNT_AVAILABLE = 'EQUIPMENT_AMOUNT_AVAILABLE',
  INFO_TRANSFER_CART = 'INFO_TRANSFER_CART',
  CURRENT_PROJECT = 'CURRENT_PROJECT',

  EMPLOYEE_ICON = 'EMPLOYEE_ICON',
  EMPLOYEE_STAFF_NUMBER = 'EMPLOYEE_STAFF_NUMBER',
  EMPLOYEE_FIRST_NAME = 'EMPLOYEE_FIRST_NAME',
  EMPLOYEE_LAST_NAME = 'EMPLOYEE_LAST_NAME',
  EMPLOYEE_EMAIL = 'EMPLOYEE_EMAIL',
  EMPLOYEE_OFFICE_PHONE_NUMBER = 'EMPLOYEE_OFFICE_PHONE_NUMBER',
  EMPLOYEE_OFFICE_MOBILE_NUMBER = 'EMPLOYEE_OFFICE_MOBILE_NUMBER',

  CONTACT_IDENTIFIER = 'CONTACT_IDENTIFIER',
  CONTACT_TYPE = 'CONTACT_TYPE',
  CONTACT_PHONE_NUMBER = 'CONTACT_PHONE_NUMBER',
  CONTACT_PERSON = 'CONTACT_PERSON',
  CONTACT_ICON = 'CONTACT_ICON',

  TRANSPORT_IDENTIFIER = 'TRANSPORT_IDENTIFIER',
  TRANSPORT_DUE_DATE = 'TRANSPORT_DUE_DATE',
  TRANSPORT_STATE = 'TRANSPORT_STATE',
  TRANSPORT_TITLE = 'TRANSPORT_TITLE',
  TRANSPORT_DESCRIPTION = 'TRANSPORT_DESCRIPTION',
  TRANSPORT_PRIORITY = 'TRANSPORT_PRIORITY',
  TRANSPORT_START_ITEM = 'TRANSPORT_START_ITEM',
  TRANSPORT_TARGET_ITEM = 'TRANSPORT_TARGET_ITEM',
  TRANSPORT_EQUIPMENT_DISPLAY_NAME = 'TRANSPORT_EQUIPMENT_DISPLAY_NAME',
  TRANSPORT_ESTIMATED_DURATION = 'TRANSPORT_ESTIMATED_DURATION',
  TRANSPORT_RELATED_PROJECT = 'TRANSPORT_RELATED_PROJECT',
  TRANSPORT_START_DATE = 'TRANSPORT_START_DATE',
  TRANSPORT_END_DATE = 'TRANSPORT_END_DATE',
  TRANSPORT_EXECUTION_START_DATE = 'TRANSPORT_EXECUTION_START_DATE',
  TRANSPORT_EXECUTION_END_DATE = 'TRANSPORT_EXECUTION_END_DATE',
  TRANSPORT_TYPE = 'TRANSPORT_TYPE',

  MAINTENANCE_IMAGE = 'MAINTENANCE_IMAGE',
  MAINTENANCE_EQUIPMENT_MANUFACTURER_NAME = 'MAINTENANCE_EQUIPMENT_MANUFACTURER_NAME',
  MAINTENANCE_CURRENT_PROJECT_NAME = 'MAINTENANCE_CURRENT_PROJECT_NAME',
  MAINTENANCE_TASK_NAME = 'MAINTENANCE_TASK_NAME',
  MAINTENANCE_CURRENT_LOCATION_NAME = 'MAINTENANCE_CURRENT_LOCATION_NAME',
  MAINTENANCE_CURRENT_LOCATION_NUMBER = 'MAINTENANCE_CURRENT_LOCATION_NUMBER',
  MAINTENANCE_CURRENT_LOCATION_COST_CENTER = 'MAINTENANCE_CURRENT_LOCATION_COST_CENTER',
  MAINTENANCE_TELEMATIC = 'MAINTENANCE_TELEMATIC',
  MAINTENANCE_TASK_TYPE = 'MAINTENANCE_TASK_TYPE',
  MAINTENANCE_EQUIPMENT = 'MAINTENANCE_EQUIPMENT',
  MAINTENANCE_DUE_DATE = 'MAINTENANCE_DUE_DATE',
  MAINTENANCE_TASK_REMAINING = 'MAINTENANCE_TASK_REMAINING',
  MAINTENANCE_TASK_ACTION = 'MAINTENANCE_TASK_ACTION',
  MAINTENANCE_CURRENT_ADDRESS = 'MAINTENANCE_CURRENT_ADDRESS',
  MAINTENANCE_TELEMATIC_LOCATION = 'MAINTENANCE_TELEMATIC_LOCATION',
  MAINTENANCE_TYPE_NAME = 'MAINTENANCE_TYPE_NAME',
  MAINTENANCE_COMPLETED_BY = 'MAINTENANCE_COMPLETED_BY',
  MAINTENANCE_COMPLETED_AT_MILEAGE = 'MAINTENANCE_COMPLETED_AT_MILEAGE',
  MAINTENANCE_COMPLETED_AT_OPERATING_HOURS = 'MAINTENANCE_COMPLETED_AT_OPERATING_HOURS',
  MAINTENANCE_COMPLETED_AT_DATE = 'MAINTENANCE_COMPLETED_AT_DATE',
  MAINTENANCE_RESULT = 'MAINTENANCE_RESULT',

  ANALYTICS_ACCEPTANCE_DATE = 'ANALYTICS_ACCEPTANCE_DATE',
  ANALYTICS_ACCEPTANCE_USER = 'ANALYTICS_ACCEPTANCE_USER',
  ANALYTICS_AMOUNT = 'ANALYTICS_AMOUNT',
  ANALYTICS_CHANGE_DATE = 'ANALYTICS_CHANGE_DATE',
  ANALYTICS_CHARGE_TYPE = 'ANALYTICS_CHARGE_TYPE',
  ANALYTICS_CREATION_DATE = 'ANALYTICS_CREATION_DATE',
  ANALYTICS_CREATOR_USER = 'ANALYTICS_CREATOR_USER',
  ANALYTICS_DELIVERY_NOTE_NUMBER = 'ANALYTICS_DELIVERY_NOTE_NUMBER',
  ANALYTICS_EQUIPMENT_BDL_CODE = 'ANALYTICS_EQUIPMENT_BDL_CODE',
  ANALYTICS_EQUIPMENT_CHANGE_DATE = 'ANALYTICS_EQUIPMENT_CHANGE_DATE',
  ANALYTICS_EQUIPMENT_CHARGE_TYPE = 'ANALYTICS_EQUIPMENT_CHARGE_TYPE',
  ANALYTICS_EQUIPMENT_COST_CENTER = 'ANALYTICS_EQUIPMENT_COST_CENTER',
  ANALYTICS_EQUIPMENT_CREATION_DATE = 'ANALYTICS_EQUIPMENT_CREATION_DATE',
  ANALYTICS_EQUIPMENT_DAYS = 'ANALYTICS_EQUIPMENT_DAYS',
  ANALYTICS_EQUIPMENT_DRIVER = 'ANALYTICS_EQUIPMENT_DRIVER',
  ANALYTICS_EQUIPMENT_END_DATE = 'ANALYTICS_EQUIPMENT_END_DATE',
  ANALYTICS_EQUIPMENT_GUID_ID = 'ANALYTICS_EQUIPMENT_GUID_ID',
  ANALYTICS_EQUIPMENT_INTERNAL_NUMBER = 'ANALYTICS_EQUIPMENT_INTERNAL_NUMBER',
  ANALYTICS_EQUIPMENT_LABELS = 'ANALYTICS_EQUIPMENT_LABELS',
  ANALYTICS_EQUIPMENT_LICENSE_PLATE = 'ANALYTICS_EQUIPMENT_LICENSE_PLATE',
  ANALYTICS_EQUIPMENT_LOCATION_ADDRESS = 'ANALYTICS_EQUIPMENT_LOCATION_ADDRESS',
  ANALYTICS_EQUIPMENT_LOCATION_COST_CENTER = 'ANALYTICS_EQUIPMENT_LOCATION_COST_CENTER',
  ANALYTICS_EQUIPMENT_LOCATION_ID = 'ANALYTICS_EQUIPMENT_LOCATION_ID',
  ANALYTICS_EQUIPMENT_LOCATION_NAME = 'ANALYTICS_EQUIPMENT_LOCATION_NAME',
  ANALYTICS_EQUIPMENT_LOCATION_INTERNAL_NUMBER = 'ANALYTICS_EQUIPMENT_LOCATION_INTERNAL_NUMBER',
  ANALYTICS_EQUIPMENT_MANUFACTURER_NAME = 'ANALYTICS_EQUIPMENT_MANUFACTURER_NAME',
  ANALYTICS_EQUIPMENT_MODEL = 'ANALYTICS_EQUIPMENT_MODEL',
  ANALYTICS_EQUIPMENT_MAINTENANCE_ASSIGNEE = 'ANALYTICS_EQUIPMENT_MAINTENANCE_ASSIGNEE',
  ANALYTICS_EQUIPMENT_NAME = 'ANALYTICS_EQUIPMENT_NAME',
  ANALYTICS_EQUIPMENT_ORGANISATION = 'ANALYTICS_EQUIPMENT_ORGANISATION',
  ANALYTICS_EQUIPMENT_OWNER = 'ANALYTICS_EQUIPMENT_OWNER',
  ANALYTICS_EQUIPMENT_POSSESSOR = 'ANALYTICS_EQUIPMENT_POSSESSOR',
  ANALYTICS_EQUIPMENT_PERSON_IN_CHARGE = 'ANALYTICS_EQUIPMENT_PERSON_IN_CHARGE',
  ANALYTICS_EQUIPMENT_SCAN_CODE = 'ANALYTICS_EQUIPMENT_SCAN_CODE',
  ANALYTICS_EQUIPMENT_START_DATE = 'ANALYTICS_EQUIPMENT_START_DATE',
  ANALYTICS_EQUIPMENT_STATUS = 'ANALYTICS_EQUIPMENT_STATUS',
  ANALYTICS_EQUIPMENT_TRANSFER_COMMENT = 'ANALYTICS_EQUIPMENT_TRANSFER_COMMENT',
  ANALYTICS_EQUIPMENT_TRANSFER_EXTERNAL_PERSON_NAME = 'ANALYTICS_EQUIPMENT_TRANSFER_EXTERNAL_PERSON_NAME',
  ANALYTICS_EQUIPMENT_TRANSFER_INTERNAL_EMPLOYEE = 'ANALYTICS_EQUIPMENT_TRANSFER_INTERNAL_EMPLOYEE',
  ANALYTICS_EQUIPMENT_TYPE_CATEGORY1 = 'ANALYTICS_EQUIPMENT_TYPE_CATEGORY1',
  ANALYTICS_EQUIPMENT_TYPE_CATEGORY2 = 'ANALYTICS_EQUIPMENT_TYPE_CATEGORY2',
  ANALYTICS_EQUIPMENT_WORKING_DAYS = 'ANALYTICS_EQUIPMENT_WORKING_DAYS',
  ANALYTICS_SOURCE_ADDRESS = 'ANALYTICS_SOURCE_ADDRESS',
  ANALYTICS_SOURCE_COST_CENTER = 'ANALYTICS_SOURCE_COST_CENTER',
  ANALYTICS_SOURCE_GUID_ID = 'ANALYTICS_SOURCE_GUID_ID',
  ANALYTICS_SOURCE_EXTERNAL_ID = 'ANALYTICS_SOURCE_EXTERNAL_ID',
  ANALYTICS_SOURCE_INT_NR = 'ANALYTICS_SOURCE_INT_NR',
  ANALYTICS_SOURCE_NAME = 'ANALYTICS_SOURCE_NAME',
  ANALYTICS_TARGET_ADDRESS = 'ANALYTICS_TARGET_ADDRESS',
  ANALYTICS_TARGET_COST_CENTER = 'ANALYTICS_TARGET_COST_CENTER',
  ANALYTICS_TARGET_EXTERNAL_ID = 'ANALYTICS_TARGET_EXTERNAL_ID',
  ANALYTICS_TARGET_GUID_ID = 'ANALYTICS_TARGET_GUID_ID',
  ANALYTICS_TARGET_INT_NR = 'ANALYTICS_TARGET_INT_NR',
  ANALYTICS_TARGET_NAME = 'ANALYTICS_TARGET_NAME',
  ANALYTICS_TRANSFER_COMMENT = 'ANALYTICS_TRANSFER_COMMENT',
  ANALYTICS_TRANSFER_DATE = 'ANALYTICS_TRANSFER_DATE',
  ANALYTICS_TRANSFER_EXTERNAL_PERSON_NAME = 'ANALYTICS_TRANSFER_EXTERNAL_PERSON_NAME',
  ANALYTICS_TRANSFER_INTERNAL_PERSON_NAME = 'ANALYTICS_TRANSFER_INTERNAL_PERSON_NAME',
  ANALYTICS_TRANSFER_ID = 'ANALYTICS_TRANSFER_ID',
  ANALYTICS_TRANSFER_ITEM_COST_CENTER = 'ANALYTICS_TRANSFER_ITEM_COST_CENTER',
  ANALYTICS_TRANSFER_ITEM_EXTERNAL_ID = 'ANALYTICS_TRANSFER_ITEM_EXTERNAL_ID',
  ANALYTICS_TRANSFER_ITEM_GUID_ID = 'ANALYTICS_TRANSFER_ITEM_GUID_ID',
  ANALYTICS_TRANSFER_ITEM_INT_NR = 'ANALYTICS_TRANSFER_ITEM_INT_NR',
  ANALYTICS_TRANSFER_ITEM_NAME = 'ANALYTICS_TRANSFER_ITEM_NAME',
  ANALYTICS_TRANSFER_ITEM_TYPE = 'ANALYTICS_TRANSFER_ITEM_TYPE',
  ANALYTICS_TRANSFER_ITEM_ORGANISATION_EXTERNAL_ID = 'ANALYTICS_TRANSFER_ITEM_ORGANISATION_EXTERNAL_ID',
  ANALYTICS_TRANSFER_ITEM_ORGANISATION_GUID_ID = 'ANALYTICS_TRANSFER_ITEM_ORGANISATION_GUID_ID',
  ANALYTICS_TRANSFER_ITEM_ORGANISATION_NR = 'ANALYTICS_TRANSFER_ITEM_ORGANISATION_NR',
  ANALYTICS_TRANSFER_ITEM_ORGANISATION_NAME = 'ANALYTICS_TRANSFER_ITEM_ORGANISATION_NAME',
  ANALYTICS_TRANSFER_ORGANISATION_EXTERNAL_ID = 'ANALYTICS_TRANSFER_ORGANISATION_EXTERNAL_ID',
  ANALYTICS_TRANSFER_ORGANISATION_GUID_ID = 'ANALYTICS_TRANSFER_ORGANISATION_GUID_ID',
  ANALYTICS_TRANSFER_ORGANISATION_NR = 'ANALYTICS_TRANSFER_ORGANISATION_NR',
  ANALYTICS_TRANSFER_TYPE_NAME = 'ANALYTICS_TRANSFER_TYPE_NAME',
  ANALYTICS_TRANSFER_ITEM_UNIT = 'ANALYTICS_TRANSFER_ITEM_UNIT',
}
