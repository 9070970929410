import { EquipmentProjectAssignments } from '../contract/equipment-project-assignments.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PagedResponse } from '../../../shared/contract/page-response.interface';
import { DocumentLink } from '../../../shared/contract/document-link.interface';
import { SetProjectDescriptionCommand } from '../../equipment/contract/set-project-description-command';
import { CreateGeofenceCommand } from '../contract/create-geofence-command';
import { CreateProjectCommand } from '../contract/create-project-command';
import { CreateProjectCommentCommand } from '../contract/create-project-comment-command';
import { DeleteGeofenceCommand } from '../contract/delete-geofence-command';
import { DeleteProjectCommand } from '../contract/delete-project-command';
import { DeleteProjectCommentCommand } from '../contract/delete-project-comment-command';
import { DeleteProjectDocumentCommand } from '../contract/delete-project-document-command';
import { SearchProjectLocation } from '../contract/search-project-location.interface';
import { SearchProjectOrProjectGroup } from '../contract/search-project.interface';
import { SetResetAlarmingGeofenceCommand } from '../contract/set-reset-alarming-geofence-command';
import { UpdateProjectCommand } from '../contract/update-project-command';
import { UpdateProjectCommentCommand } from '../contract/update-project-comment-command';
import { UploadProjectDocumentCommand } from '../contract/upload-project-document-command';
import { ViewGeofence } from '../contract/view-geofence.interface';
import { ViewProjectComment } from '../contract/view-project-comment.interface';
import { ViewProject } from '../contract/view-project.interface';
import { DispositionProject } from './disposition-project';
import { CustomerProjectLabel } from '../contract/customer-project-label.interface';
import { AddProjectLabelCommand } from '../contract/add-project-label-command';
import { DeleteProjectLabelCommand } from '../contract/delete-project-label-command';
import { AssigneeCount } from '../contract/assignee-count';
import { getHttpParameters } from '../../../shared/utils';
import { UpdateProjectDocumentDescriptionCommand } from '../contract/update-project-document-description-command';
import { PairProjectScanCodeCommand } from '../contract/pair-project-scan-code-command';
import { UnpairProjectScanCodeCommand } from '../contract/unpair-project-scan-code-command';
import { CustomEncoder } from '../../../shared/custom-encoder';
import { SearchProjectIncludingAssignments } from '../contract/search-project-including-assignments.interface';
import { EmployeeProjectAssignments } from '../contract/employee-project-assignments.interface';
import { InsertProjectTypeOfUseTimelineEntryCommand } from '../contract/insert-project-type-of-use-timeline-entry-command';
import { ViewOrganisation } from '../../organisation/contract/view-organisation.interface';
import { CreateProjectGroupCommand } from '../contract/create-project-group-command';
import { Modules } from '../../../shared/enums/modules.enum';
import { KeycloakService } from '../../../core/keycloak';
import { ProjectStructureType } from './enums/project-structure-type';
import { CreateSubProjectCommand } from '../contract/create-sub-project-command';
import { RenameSubProjectCommand } from '../contract/rename-sub-project';
import { DeleteProjectGroupCommand } from '../contract/delete-project-group-command';
import { IViewProjectFilter } from '../contract/view-project-filter.interface';
import { ProjectFilterCollectionView } from './filter-view/project-filter-collection-view.interface';
import { SearchProjectParameter } from './interfaces/SearchProjectParameter.interface';
import { GetProjectLocationSearchParameter } from './interfaces/GetProjectLocationSearchParameter.interface';
import { GetProjectLocationParameter } from './interfaces/GetProjectLocationParameter.interface';
import { UpdateProjectDocumentNameCommand } from '../contract/update-project-document-name-command';
import { ChangeGeofenceStatusCommand } from '../contract/change-geofence-status-command';
import { GenericUpdateGeofenceCommand } from 'app/shared/modules/map/components/geofence-sidebar-component/generic-update-geofence-command';
import { DTZ } from 'app/shared/timezone/date-timezone';
import { RequestErrorHandlerProjectService } from 'app/core/interceptors/request-error-handlers/request-error-handler-project.service';

@Injectable()
export class ProjectsService {

  private url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1';
  private projectUrl = this.url + '/projects';
  private projectV2Url = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v2/projects';
  private projectGroupUrl = environment.APP_DISPOSITION_SERVICE_BASE_URL + '/api/v1/project-group';
  private geofenceUrl = this.url + '/geofences';
  private dispositionUrl = this.url + '/projects/disposition';
  private labelUrl = this.url + '/projects/labels';
  private filterableLabelsUrl = this.url + '/projects/filterable-labels';
  private filterableAssigneesUrl = this.url + '/projects/filterable-assignees';
  private equipmentUrl = this.url + '/equipments';
  private employeeUrl = this.url + '/employees';

  constructor(private http: HttpClient, private authService: KeycloakService) {
  }

  public getDispositionProjects(): Observable<DispositionProject[]> {
    return this.http.get<DispositionProject[]>(this.dispositionUrl);
  }

  public pairScanCode(command: PairProjectScanCodeCommand): Observable<string> {
    return this.http.post(`${this.projectUrl}/pair-scan-code`, JSON.stringify(command), {responseType: 'text'});
  }

  public unpairScanCode(command: UnpairProjectScanCodeCommand): Observable<string> {
    return this.http.post(`${this.projectUrl}/unpair-scan-code`, JSON.stringify(command), {responseType: 'text'});
  }

  public getDispositionProject(projectId: string): Observable<DispositionProject> {
    return this.http.get<DispositionProject>(`${this.dispositionUrl}/${projectId}`);
  }

  public hasAssignmentOrTransferRequestWorkflow(): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/has-assignment-or-transfer-request-workflow');
  }

  public hasAssignmentRequestWorkflow(): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/has-assignment-request-workflow');
  }

  public hasTransferRequestWorkflow(): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/has-transfer-request-workflow');
  }

  public hasTypeOfUseWorkflow(): Observable<boolean> {
    return this.http.get<boolean>(this.url + '/has-type-of-use-workflow');
  }

  public hasTransferHistory(projectId: string): Observable<boolean> {
    return this.http.get<boolean>(this.url + `/transfer-history/projects/${projectId}/has-transfer-history`);
  }

  public getOrganisationsByCustomerIdForTransfer(customerId: string): Observable<ViewOrganisation[]> {
    return this.http.get<ViewOrganisation[]>(this.url + '/organisations/by-customer/' + customerId);
  }

  public searchProjects(params: SearchProjectParameter): Observable<PagedResponse<SearchProjectOrProjectGroup>> {
    let url: string = this.getUrlDependingOnUserHasProjectGroupModule();
    return this.http.get<PagedResponse<SearchProjectOrProjectGroup>>(
      url + '/search/all', {params: getHttpParameters(params)}
    );
  }

  public searchProjectsForTransfer(params: Object): Observable<PagedResponse<SearchProjectOrProjectGroup>> {
    let httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<SearchProjectOrProjectGroup>>(`${this.projectUrl}/search/transfer`, {params: httpParams});
  }

  public searchProjectsIncludingAssignments(params: Object): Observable<PagedResponse<SearchProjectIncludingAssignments>> {
    let httpParams = getHttpParameters(params);

    return this.http.get<PagedResponse<SearchProjectIncludingAssignments>>(
      `${this.projectUrl}/search/all-including-assignments`,
      {params: httpParams}
    ).pipe(
      map(res => ({
        ...res,
        content: res.content.map(item => this.convertStoredDatesSearchProjectIncludingAssignments(item))
      })));
  }

  public getProjectLocations(parameter: GetProjectLocationParameter): Observable<SearchProjectLocation[]> {
    return this.http.get<SearchProjectLocation[]>(`${this.projectUrl}/geo`, {params: getHttpParameters(parameter)});
  }

  public getProjectLocationsSearch(parameter: GetProjectLocationSearchParameter): Observable<SearchProjectLocation[]> {
    return this.http.get<SearchProjectLocation[]>(`${this.projectUrl}/search/geo`, {params: getHttpParameters(parameter)});
  }

  public getProject(projectId: string): Observable<ViewProject> {
    let url: string = this.getUrlDependingOnUserHasProjectGroupModule();
    return this.http.get<ViewProject>(url + '/' +  projectId);
  }

  public addProject(command: CreateProjectCommand): Observable<string> {
    return this.http.post(`${this.projectV2Url}/create`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateProject(command: UpdateProjectCommand): Observable<string> {
    return this.http.post(`${this.projectV2Url}/update`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteProject(command: DeleteProjectCommand): Observable<string> {
    return this.http.post(`${this.projectUrl}/delete`, JSON.stringify(command), {responseType: 'text'});
  }

  public reopenProject(projectId: string): Observable<string> {
    let command = {projectId};

    return this.http.post(`${this.projectUrl}/reopen`, JSON.stringify(command), {
      responseType: 'text',
      context: RequestErrorHandlerProjectService.getHttpContext(),
    });
  }

  public deleteSubProject(command: DeleteProjectCommand): Observable<string> {
    return this.http.post(`${this.projectV2Url}/delete`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteProjectGroup(command: DeleteProjectGroupCommand): Observable<string> {
    return this.http.post(`${this.url}/project-group/demote-to-project`, JSON.stringify(command), {responseType: 'text'});
  }

  public createProjectGroup(command: CreateProjectGroupCommand): Observable<string> {
    return this.http.post(`${this.projectGroupUrl}/create-from-project`, JSON.stringify(command), {responseType: 'text'});
  }

  public createSubProject(command: CreateSubProjectCommand): Observable<string> {
    return this.http.post(`${this.projectV2Url}/create-within-project-group`, JSON.stringify(command), {responseType: 'text'});
  }

  public renameSubProject(command: RenameSubProjectCommand): Observable<string> {
    return this.http.post(`${this.projectV2Url}/rename-sub-project`, JSON.stringify(command), {responseType: 'text'});
  }

  public finishProject(command: DeleteProjectCommand): Observable<string> {
    return this.http.post(`${this.projectV2Url}/finish`, JSON.stringify(command), {responseType: 'text'});
  }

  public getAllGeofences(): Observable<ViewGeofence[]> {
    return this.http.get<ViewGeofence[]>(`${this.projectUrl}/geofences`);
  }

  public getGeofences(projectId: string): Observable<ViewGeofence[]> {
    return this.http.get<ViewGeofence[]>(`${this.projectUrl}/${projectId}/geofences`);
  }

  public createGeofence(command: CreateGeofenceCommand): Observable<string> {
    return this.http.post(`${this.geofenceUrl}/create`, JSON.stringify(command), {
      responseType: 'text',
      context: RequestErrorHandlerProjectService.getHttpContext(),
    });
  }

  public updateGeofence(command: GenericUpdateGeofenceCommand): Observable<string> {
    return this.http.post(`${this.geofenceUrl}/update`, JSON.stringify(command), {
      responseType: 'text',
      context: RequestErrorHandlerProjectService.getHttpContext(),
    });
  }

  public deleteGeofence(command: DeleteGeofenceCommand): Observable<string> {
    return this.http.post(`${this.geofenceUrl}/delete`, JSON.stringify(command), {responseType: 'text'});
  }

  public changeGeofenceStatus(command: ChangeGeofenceStatusCommand): Observable<string> {
    return this.http.post(`${this.geofenceUrl}/change-status`, JSON.stringify(command), {responseType: 'text'});
  }

  public setAlarmGeofence(command: SetResetAlarmingGeofenceCommand): Observable<string> {
    return this.http.post(`${this.geofenceUrl}/set-alarming`, JSON.stringify(command), {responseType: 'text'});
  }

  public resetAlarmGeofence(command: SetResetAlarmingGeofenceCommand): Observable<string> {
    return this.http.post(`${this.geofenceUrl}/reset-alarming`, JSON.stringify(command), {responseType: 'text'});
  }

  // Documents APIs

  public getDocuments(projectId: string): Observable<DocumentLink[]> {
    return this.http.get<DocumentLink[]>(`${this.projectUrl}/${projectId}/documents`);
  }

  public addDocument(command: UploadProjectDocumentCommand): Observable<string> {
    return this.http.post(`${this.projectUrl}/add-document`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteDocument(command: DeleteProjectDocumentCommand): Observable<string> {
    return this.http.post(`${this.projectUrl}/delete-document`, JSON.stringify(command), {responseType: 'text'});
  }

  public setDescription(command: SetProjectDescriptionCommand): Observable<string> {
    return this.http.post(`${this.projectUrl}/set-description`, JSON.stringify(command), {responseType: 'text'});
  }

  // Comments APIs

  public getComments(projectId: string): Observable<ViewProjectComment[]> {
    return this.http.get<ViewProjectComment[]>(`${this.projectUrl}/${projectId}/comments`);
  }

  public addComment(command: CreateProjectCommentCommand): Observable<string> {
    return this.http.post(`${this.projectUrl}/add-comment`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateComment(command: UpdateProjectCommentCommand): Observable<string> {
    return this.http.post(`${this.projectUrl}/edit-comment`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteComment(command: DeleteProjectCommentCommand): Observable<string> {
    return this.http.post(`${this.projectUrl}/delete-comment`, JSON.stringify(command), {responseType: 'text'});
  }

  public hasAssignmentCollisions(projectId: string, startDate: string, endDate: string): Observable<boolean> {
    let params = new HttpParams({encoder: new CustomEncoder()})
    params = params.set('startDate', startDate);
    if (endDate) {
      params = params.set('endDate', endDate);
    }

    return this.http.get<boolean>(`${this.projectUrl}/${projectId}/assignment-conflicts`, {params: params});
  }

  public getProjectUrl(): string {
    return this.projectUrl;
  }

  public getCustomerLabels(params?: Object): Observable<CustomerProjectLabel[]> {
    let httpParams = getHttpParameters(params);

    return this.http.get<CustomerProjectLabel[]>(this.labelUrl, {params: httpParams});
  }

  public getFilterableCustomerLabels(filter: IViewProjectFilter): Observable<CustomerProjectLabel[]> {
    let httpParams = getHttpParameters(filter);

    return this.http.get<CustomerProjectLabel[]>(this.filterableLabelsUrl, {params: httpParams});
  }

  public addLabel(command: AddProjectLabelCommand): Observable<string> {
    return this.http.put(`${this.projectUrl}/add-label`, JSON.stringify(command), {responseType: 'text'});
  }

  public deleteLabel(command: DeleteProjectLabelCommand): Observable<string> {
    return this.http.put(`${this.projectUrl}/delete-label`, JSON.stringify(command), {responseType: 'text'});
  }

  public getLabels(projectId: string, projectStructureType: ProjectStructureType): Observable<string[]> {
    if (projectStructureType !== ProjectStructureType.PROJECT_GROUP) {
      return this.http.get<string[]>(`${this.projectUrl}/${projectId}/labels`);
    } else {
      return of([]);
    }
  }

  public getFilterableProjectAssignees(filter: IViewProjectFilter): Observable<AssigneeCount[]> {
    return this.http.get<AssigneeCount[]>(this.filterableAssigneesUrl, {params: getHttpParameters(filter)});
  }

  public updateDocumentName(command: UpdateProjectDocumentNameCommand): Observable<string> {
    return this.http.post(`${this.projectUrl}/update-document-name`, JSON.stringify(command), {responseType: 'text'});
  }

  public updateDocumentDescription(command: UpdateProjectDocumentDescriptionCommand): Observable<string> {
    return this.http.post(`${this.projectUrl}/update-document-description`, JSON.stringify(command), {responseType: 'text'});
  }

  public getProjectFilters(params: Object): Observable<ProjectFilterCollectionView> {
    const httpParams = getHttpParameters(params);
    return this.http.get<ProjectFilterCollectionView>(`${this.projectUrl}/filter`, {params: httpParams});
  }

  // Equipments APIs
  public getAllAssignmentsForEquipmentsByProjectIds(projectIds: string[]): Observable<EquipmentProjectAssignments> {
    const params = getHttpParameters({ projectIds });
    return this.http.get<EquipmentProjectAssignments>(`${this.equipmentUrl}/assignments`, { params });
  }

  // Employees APIs
  public getAssignmentsByEmployeeIds(employeeIds: string[]): Observable<EmployeeProjectAssignments> {
    const params = getHttpParameters({ employeeIds });
    return this.http.get<EmployeeProjectAssignments>(`${this.employeeUrl}/assignments`, { params });
  }

  public addAssetChargePeriod(command: InsertProjectTypeOfUseTimelineEntryCommand) {
    return this.http.post(`${this.projectUrl}/type-of-use`, JSON.stringify(command), {responseType: 'text'});
  }

  private getUrlDependingOnUserHasProjectGroupModule(): string {
    return this.authService.hasModule(Modules.PROJECT_GROUP_MANAGEMENT) ? this.projectV2Url : this.projectUrl;
  }

  private convertStoredDatesSearchProjectIncludingAssignments(value: SearchProjectIncludingAssignments): SearchProjectIncludingAssignments {
    return {
      ...value,
      assignedEquipment:
        value.assignedEquipment.map(item => this.convertStoredDates(item, ['startDate', 'endDate'])),
      assignedEmployees:
        value.assignedEmployees.map(item => this.convertStoredDates(item, ['assignmentStartDate', 'assignmentEndDate'])),
    }
  }

  private convertStoredDates<T extends object>(value: T, keys: (keyof T)[]): T {
    if (!(keys?.length > 0)) {
      return value;
    }
    const convertedDateTuples = keys.map(key => ({
      [key]: DTZ.DateTimezone({ store: <string|Date>value[key] }).displayDate
    }));

    return { ...value, ...(Object.assign({}, ...convertedDateTuples)) };
  }
}
