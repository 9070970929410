import { Component, Input } from '@angular/core';
import { faCircleCheck, faTriangleExclamation, faCircleExclamation } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'bh-color-badge',
  templateUrl: './color-badge.component.html',
  styleUrls: ['./color-badge.component.scss']
})
export class ColorBadgeComponent {
  @Input() value = '';
  @Input() color = '';
  @Input() showIcon = false;
  @Input() brighter = false;

  public faCircleCheck = faCircleCheck;
  public faTriangleExclamation = faTriangleExclamation;
  public faCircleExclamation = faCircleExclamation;
}
