<form [formGroup]="connectorAddForm" novalidate fxLayout="column" fxLayoutAlign="start stretch">

  <h2 *ngIf="isAEMPv2()">{{'modules.connector.base.newAempV2'|translate}}</h2>
  <h2 *ngIf="isMail()">{{'modules.connector.base.newMail'|translate}}</h2>
  <h2 *ngIf="isTrackUnitClassic()">{{'modules.connector.base.newTrackUnitClassic'|translate}}</h2>
  <h2 *ngIf="isGpsOverIp()">{{'modules.connector.base.newGpsOverIp'|translate}}</h2>
  <h2 *ngIf="isRio()">{{'modules.connector.base.newRio'|translate}}</h2>
  <h2 *ngIf="isReidl()">{{'modules.connector.base.newReidl'|translate}}</h2>
  <h2 *ngIf="isSinos2()">{{'modules.connector.base.newSinos2'|translate}}</h2>

  <!-- Basic -->
  <mat-form-field class="mat-block">
    <mat-label>{{'general.name.s'|translate}}</mat-label>
    <input matInput formControlName="name" required
           [matTooltip]="'modules.connector.connectorAddEdit.nameTooltip'|translate">
  </mat-form-field>
  <ng-container *ngIf="isAEMPv2()">
    <mat-form-field>
      <mat-label>{{'modules.connector.connectorAddEdit.profile'|translate}}</mat-label>
      <mat-select formControlName="profileKey">
        <mat-option value="LIDAT">{{'modules.connector.connectorAddEdit.profileKey.LIDAT'|translate}}</mat-option>
        <mat-option value="CAT">{{'modules.connector.connectorAddEdit.profileKey.CAT'|translate}}</mat-option>
        <mat-option value="KOMTRAX">{{'modules.connector.connectorAddEdit.profileKey.KOMTRAX'|translate}}</mat-option>
        <mat-option
          value="LIDAT_EXTENDED">{{'modules.connector.connectorAddEdit.profileKey.LIDAT_EXTENDED'|translate}}</mat-option>
        <mat-option value="WITOS">{{'modules.connector.connectorAddEdit.profileKey.WITOS'|translate}}</mat-option>
        <mat-option
          value="ATLAS_COPCO">{{'modules.connector.connectorAddEdit.profileKey.ATLAS_COPCO'|translate}}</mat-option>
        <mat-option value="HITACHI">{{'modules.connector.connectorAddEdit.profileKey.HITACHI'|translate}}</mat-option>
        <mat-option value="KOBELCO">{{'modules.connector.connectorAddEdit.profileKey.KOBELCO'|translate}}</mat-option>
        <mat-option value="BOMAG">{{'modules.connector.connectorAddEdit.profileKey.BOMAG'|translate}}</mat-option>
        <mat-option
          value="CAT_OAUTH2">{{'modules.connector.connectorAddEdit.profileKey.CAT_OAUTH2'|translate}}</mat-option>
        <mat-option value="TRACK_UNIT_WACKER_NEUSON">
          {{'modules.connector.connectorAddEdit.profileKey.TRACK_UNIT_WACKER_NEUSON'|translate}}
        </mat-option>
        <mat-option value="JCB_LIVELINK">
          {{'modules.connector.connectorAddEdit.profileKey.JCB_LIVELINK'|translate}}
        </mat-option>
        <mat-option value="TAKEUCHI">
          {{'modules.connector.connectorAddEdit.profileKey.TAKEUCHI'|translate}}
        </mat-option>
        <mat-option value="VOLVO">
          {{'modules.connector.connectorAddEdit.profileKey.VOLVO'|translate}}
        </mat-option>
        <mat-option value="KUBOTA">
          {{'modules.connector.connectorAddEdit.profileKey.KUBOTA'|translate}}
        </mat-option>
        <mat-option value="CNH_INDUSTRIAL">
          {{'modules.connector.connectorAddEdit.profileKey.CNH_INDUSTRIAL'|translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <mat-form-field class="mat-block">
    <mat-label>{{'modules.connector.connectorAddEdit.updateInterval'|translate}}</mat-label>
    <input matInput type="number" min="1"
           formControlName="intervalInMinutes" required
           [matTooltip]="'modules.connector.connectorAddEdit.updateIntervalTooltip'|translate">
  </mat-form-field>
  <mat-form-field class="mat-block">
    <mat-label>{{'modules.connector.connectorAddEdit.timeout'|translate}}</mat-label>
    <input matInput type="number" min="1"
           formControlName="maxTimeoutInMinutes" required
           [matTooltip]="'modules.connector.connectorAddEdit.timeoutTooltip'|translate">
  </mat-form-field>

  <!-- AEMP -->
  <section *ngIf="isAEMPv2()">
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.url'|translate|uppercase}}</mat-label>
      <input matInput
             formControlName="url"
             required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.authenticationMode'|translate}}</mat-label>
      <mat-select bhSelectOverlayPanelClass
                  formControlName="authType" required>
        <mat-option *ngFor="let authType of availableAuthTypes" [value]="authType">
          {{ authType }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mat-block" *ngIf="providesAuthUrl()">
      <mat-label>{{'modules.connector.connectorAddEdit.authenticationUrl'|translate}}</mat-label>
      <input matInput
             formControlName="authenticationUrl" required>
    </mat-form-field>
    <mat-form-field class="mat-block" *ngIf="providesClientId()">
      <mat-label>{{'modules.connector.connectorAddEdit.clientId'|translate}}</mat-label>
      <input matInput
             formControlName="clientId"
             [required]="isClientIdMandatory()">
    </mat-form-field>
    <bh-show-hide fxLayout="row" fxLayoutAlign="start center" *ngIf="providesClientSecret()">
      <mat-form-field class="mat-block">
        <mat-label>{{'modules.connector.connectorAddEdit.clientSecret'|translate}}</mat-label>
        <input matInput
               formControlName="clientSecret"
               #showhideinput type="password" required>
      </mat-form-field>
    </bh-show-hide>
    <mat-form-field class="mat-block" *ngIf="providesBasicAuthEncoding()">
      <mat-label>{{'modules.connector.connectorAddEdit.authEncoding'|translate}}</mat-label>
      <input matInput
             formControlName="authEncoding" [matAutocomplete]="encodingAuto" required>
    </mat-form-field>
    <mat-form-field class="mat-block" *ngIf="providesAPIKey()">
      <mat-label>{{'modules.connector.connectorAddEdit.apiKey'|translate}}</mat-label>
      <input matInput
             formControlName="apiKey" required>
    </mat-form-field>
    <mat-autocomplete #encodingAuto="matAutocomplete">
      <mat-option *ngFor="let encoding of availableEncodings" [value]="encoding">
        {{ encoding }}
      </mat-option>
    </mat-autocomplete>
    <mat-form-field class="mat-block" *ngIf="providesUsernamePassword()">
      <mat-label>{{'modules.connector.connectorAddEdit.userName'|translate}}</mat-label>
      <input matInput
             formControlName="username"
             required>
    </mat-form-field>
    <bh-show-hide fxLayout="row" fxLayoutAlign="start center" *ngIf="providesUsernamePassword()">
      <mat-form-field class="mat-block">
        <mat-label>{{'general.password'|translate}}</mat-label>
        <input matInput
               #showhideinput formControlName="password" type="password" required>
      </mat-form-field>
    </bh-show-hide>
  </section>

  <!-- Mail -->
  <section *ngIf="isMail()">
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.server'|translate}}</mat-label>
      <input matInput formControlName="server"
             required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.port'|translate}}</mat-label>
      <input matInput type="number" min="1"
             formControlName="port" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'modules.connector.connectorAddEdit.protocol'|translate}}</mat-label>
      <mat-select formControlName="protocol"
                  required>
        <mat-option value="IMAP">{{'modules.connector.connectorAddEdit.protocolIMAP'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'modules.connector.connectorAddEdit.encryption'|translate}}</mat-label>
      <mat-select formControlName="encryption"
                  required>
        <mat-option value="NONE">{{'general.none'|translate}}</mat-option>
        <mat-option
          value="SSL_TLS">{{'modules.connector.connectorAddEdit.encryptionType.SSL_TLS'|translate}}</mat-option>
        <mat-option
          value="STARTSSL">{{'modules.connector.connectorAddEdit.encryptionType.STARTSSL'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.userName'|translate}}</mat-label>
      <input matInput formControlName="user"
             required>
    </mat-form-field>
    <bh-show-hide fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field class="mat-block">
        <mat-label>{{'general.password'|translate}}</mat-label>
        <input matInput #showhideinput formControlName="password"
               type="password" required>
      </mat-form-field>
    </bh-show-hide>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.directory'|translate}}</mat-label>
      <input matInput
             formControlName="directory" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.destinationDirectory'|translate}}</mat-label>
      <input matInput
             formControlName="destinationDirectory" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.sender'|translate}}</mat-label>
      <input matInput formControlName="sender"
             required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.filePattern'|translate}}</mat-label>
      <input matInput
             formControlName="filePattern" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.subjectPattern'|translate}}</mat-label>
      <input matInput
             formControlName="subjectPattern" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.datePattern'|translate}}</mat-label>
      <input matInput
             formControlName="datePattern" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'modules.connector.connectorAddEdit.profile'|translate}}</mat-label>
      <mat-select formControlName="profileKey">
        <mat-option value="LINDE_CONNECT">{{'modules.connector.connectorAddEdit.lindeConnect'|translate}}</mat-option>
      </mat-select>
    </mat-form-field>
  </section>

  <!-- TrackUnit Classic -->
  <section *ngIf="isTrackUnitClassic()">
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.url'|translate|uppercase}}</mat-label>
      <input matInput
             formControlName="url">
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.userName'|translate}}</mat-label>
      <input matInput
             formControlName="username">
    </mat-form-field>
    <bh-show-hide fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field class="mat-block">
        <mat-label>{{'general.password'|translate}}</mat-label>
        <input matInput #showhideinput formControlName="password"
               type="password" required>
      </mat-form-field>
    </bh-show-hide>
  </section>

  <!-- GPSoverIP -->
  <section *ngIf="isGpsOverIp()">
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.url'|translate|uppercase}}</mat-label>
      <input matInput
             formControlName="url" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.liveUrl'|translate|uppercase}}</mat-label>
      <input matInput
             formControlName="liveUrl" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.userName'|translate}}</mat-label>
      <input matInput
             formControlName="username" required>
    </mat-form-field>
    <bh-show-hide fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field class="mat-block">
        <mat-label>{{'general.password'|translate}}</mat-label>
        <input matInput #showhideinput formControlName="password"
               type="password" required>
      </mat-form-field>
    </bh-show-hide>
  </section>

  <!-- RIO -->
  <section *ngIf="isRio()">
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.assetApiUrl'|translate}}</mat-label>
      <input matInput
             formControlName="assetApiUrl" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.iotEventsApiUrl'|translate}}</mat-label>
      <input matInput
             formControlName="iotEventsApiUrl" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.authenticationUrl'|translate}}</mat-label>
      <input matInput
             formControlName="authenticationUrl" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.clientId'|translate}}</mat-label>
      <input matInput
             formControlName="clientId" required>
    </mat-form-field>
    <bh-show-hide fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field class="mat-block">
        <mat-label>{{'modules.connector.connectorAddEdit.clientSecret'|translate}}</mat-label>
        <input matInput
               formControlName="clientSecret" #showhideinput type="password" required>
      </mat-form-field>
    </bh-show-hide>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.integrationId'|translate}}</mat-label>
      <input matInput
             formControlName="integrationId" required>
    </mat-form-field>
  </section>

  <!-- REIDL -->
  <section *ngIf="isReidl()">
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.telematicUnitsUrl'|translate}}</mat-label>
      <input matInput
             formControlName="telematicUnitsUrl" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.telematicUnitsToken'|translate}}</mat-label>
      <input matInput
             formControlName="telematicUnitsToken" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.eanCableUnit'|translate}}</mat-label>
      <input matInput
             formControlName="eanCableUnit" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.eanOBDUnit'|translate}}</mat-label>
      <input matInput
             formControlName="eanObdUnit" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.eanDigitalMatterUnit'|translate}}</mat-label>
      <input matInput
             formControlName="eanDigitalMatterUnit" required>
    </mat-form-field>
  </section>

  <!-- SINOS PORTAL 2 -->
  <section *ngIf="isSinos2()">
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.url'|translate}}</mat-label>
      <input matInput
             formControlName="url" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.manNumber'|translate}}</mat-label>
      <input matInput
             formControlName="manNumber" required>
    </mat-form-field>
    <mat-form-field class="mat-block">
      <mat-label>{{'modules.connector.connectorAddEdit.manAccount'|translate}}</mat-label>
      <input matInput
             formControlName="manAccount" required>
    </mat-form-field>
    <bh-show-hide fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field class="mat-block">
        <mat-label>{{'modules.connector.connectorAddEdit.manPassword'|translate}}</mat-label>
        <input matInput
               #showhideinput type="password" formControlName="manPassword" required>
      </mat-form-field>
    </bh-show-hide>
  </section>

  <button class="saveButton"
          mat-raised-button
          bhTimeoutSaveButton
          (throttledClick)="save()"
          [disabled]="!isValid()">
    <mat-icon>save</mat-icon>
    {{'general.buttons.save'|translate}}
  </button>

</form>
