import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { InteractionType } from '../enums/interaction-type.enum';

@Pipe({
  name: 'interactionTypePipe'
})
export class InteractionTypePipe implements PipeTransform {
  constructor(private languageService: LanguageService) { }

  public transform(value: string): string {
    switch (value) {
      case InteractionType.ENTERED:
        return this.translate('shared.pipes.interactionType.entered');
      case InteractionType.LEFT:
        return this.translate('shared.pipes.interactionType.left');
    }
    return value;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
