<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" fxFlexFill>
  <fa-icon *ngIf="isConsumed; else displayLocation"
           class="default-icon"
           [icon]="trashIcon">
  </fa-icon>
  <ng-template #displayLocation>
    <mat-icon *ngIf="isProject"
              class="default-icon"
              fontSet="icon-main_projects02_maps_solid"
              [matTooltip]="'general.project.s'|translate"
              [matTooltipDisabled]="!isAvailableForCurrentUser">
    </mat-icon>
    <fa-icon *ngIf="!isProject"
             class="default-icon"
             [icon]="stockIcon"
             [matTooltip]="'general.stock.s'|translate"
             [matTooltipDisabled]="!isAvailableForCurrentUser">
    </fa-icon>

    <span *ngIf="columnDefName !== columnDef.CURRENT_LOCATION_NUMBER"
          class="text-overflow"
          [bhOverflowTooltip]="value">
    {{value ? value : '-'}}
  </span>
    <span *ngIf="columnDefName === columnDef.CURRENT_LOCATION_NUMBER"
          class="text-overflow"
          [class.project-number]="isProject"
          [bhOverflowTooltip]="value">
    {{value ? '# ' + value : '-'}}
  </span>

    <mat-icon *ngIf="isAllowedToSeeLocation && value"
              class="link-icon"
              [matTooltip]="linkTooltip"
              (click)="navigateToLocation()">
      open_in_new
    </mat-icon>
  </ng-template>
</div>
