import { ViewTelematicUnit } from './view-telematic-unit.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CurrentEquipmentStatus } from '../contract/current-equipment-status.interface';
import { EquipmentTelematics } from '../contract/equipment-telematics';
import { TelematicsLocationRecord } from '../contract/telematics-location-record.interface';
import { EquipmentFault } from '../contract/equipment-fault.interface';
import { getHttpParameters } from '../../../shared/utils';
import { PagedResponse } from 'app/shared/contract/page-response.interface';
import { TelematicsUnitType } from '../contract/telematics-unit-type.enum';
import { ViewTeltonikaUnit } from './view-teltonika-unit.interface';
import { ViewCustomerTelematicUnit } from './view-customer-telematic-unit.interface';
import { EquipmentBeacon } from '../contract/equipment-beacon.interface';
import { TelematicsUnitAssignmentInfo } from '../contract/telematics-unit-assignment-info.interface';
import { ViewDigitalMatterUnit } from './view-digital-matter.unit';
import { BatteryStatus } from '../../../shared/enums/battery-status.enum';
import { DigitalMatterProfile } from '../../../shared/assign-digital-matter-unit/digital-matter-profile';
import { map } from 'rxjs/operators';
import {
  DigitalMatterProfileDeployment
} from '../../../shared/assign-digital-matter-unit/digital-matter-profile-deployment';
import {
  UpdateDigitalMatterProfileCommand
} from '../../telematic-unit-profiles/shared/commands/update-digital-matter-profile-command';

@Injectable()
export class TelematicsService {

  private url = environment.APP_TELEMATICS_SERVICE_BASE_URL + '/api/v1/telematics/equipment/';
  private calibrationUrl = environment.APP_TELEMATICS_SERVICE_BASE_URL + '/api/v1/calibration';
  private urlTelematicsUnits = environment.APP_TELEMATICS_SERVICE_BASE_URL + '/api/v1/assigned-telematics-units';
  private urlCustomerTelematicsUnits = environment.APP_TELEMATICS_SERVICE_BASE_URL + '/api/v1/customer-telematics-units';
  private urlTeltonika = environment.APP_TELEMATICS_SERVICE_BASE_URL + '/api/v1/teltonika';
  private urlDigitalMatter = environment.APP_TELEMATICS_SERVICE_BASE_URL + '/api/v1/digital-matter';
  private urlTelematics = environment.APP_TELEMATICS_SERVICE_BASE_URL + '/api/v1/telematics';

  constructor(private http: HttpClient) {
  }

  public getEquipmentStatus(equipmentId: string): Observable<CurrentEquipmentStatus> {
    return this.http.get<CurrentEquipmentStatus>(`${this.url}${equipmentId}/current-status`);
  }

  public getEquipmentFaults(equipmentId: string, params?: Object): Observable<EquipmentFault[]> {
    let httpParams = getHttpParameters(params);
    return this.http.get<EquipmentFault[]>(`${this.url}${equipmentId}/current-faults`, {params: httpParams});
  }

  public getEquipmentBeacons(equipmentId: string): Observable<EquipmentBeacon[]> {
    return this.http.get<EquipmentBeacon[]>(`${this.url}${equipmentId}/current-beacons`);
  }

  public getEquipmentDataSeries(equipmentId: string, params?: Object): Observable<EquipmentTelematics> {
    let httpParams = getHttpParameters(params);
    return this.http.get<EquipmentTelematics>(`${this.url}${equipmentId}`, {params: httpParams});
  }

  public getLocationsByEquipment(equipmentId: string, params: Object): Observable<TelematicsLocationRecord[]> {
    let httpParams = getHttpParameters(params);
    return this.http.get<TelematicsLocationRecord[]>(`${this.url}${equipmentId}/locations-by-timeframe`, {params: httpParams});
  }

  public getTelematicUnits(params: Object): Observable<PagedResponse<ViewTelematicUnit>> {
    let httpParams = getHttpParameters(params);
    return this.http.get<PagedResponse<ViewTelematicUnit>>(this.urlTelematicsUnits, {params: httpParams});
  }

  public calibrateOperatingHours(equipmentId: string, calibratedOperatingHours: string) {
    return this.http.post(
      `${this.calibrationUrl}/operating-hours`,
      JSON.stringify({equipmentId, calibratedOperatingHours}), {responseType: 'text'});
  }

  public calibrateMileage(equipmentId: string, calibratedMileage: number) {
    return this.http.post(`${this.calibrationUrl}/mileage`, JSON.stringify({
      equipmentId,
      calibratedMileage
    }), {responseType: 'text'});
  }


  public getTelematicsUnitNumbers(telematicsUnitType: TelematicsUnitType): Observable<ViewCustomerTelematicUnit[]> {
    const params: HttpParams = getHttpParameters({telematicsUnitType: telematicsUnitType});
    return this.http.get<ViewCustomerTelematicUnit[]>(`${this.urlCustomerTelematicsUnits}`, {params: params});
  }

  public getTeltonikaData(imei: string): Observable<ViewTeltonikaUnit> {
    const params: HttpParams = getHttpParameters({imei: imei});
    return this.http.get<ViewTeltonikaUnit>(`${this.urlTeltonika}/live`, {params: params});
  }

  public getDigitalMatterData(imei: string): Observable<ViewDigitalMatterUnit> {
    const params: HttpParams = getHttpParameters({imei: imei});
    return this.http.get<ViewDigitalMatterUnit>(`${this.urlDigitalMatter}/live`, {params: params});
  }

  public isBeaconInUse(beaconInstanceId: string, telematicsUnitType: TelematicsUnitType): Observable<TelematicsUnitAssignmentInfo> {
    const params = getHttpParameters({id: beaconInstanceId, type: telematicsUnitType});
    return this.http.get<TelematicsUnitAssignmentInfo>(`${this.urlTelematics}/beacon-in-use`, {params});
  }

  public getDigitalMatterBatteryStatus(telematicsUnitId: string): Observable<BatteryStatus> {
    const params = getHttpParameters({imei: telematicsUnitId});
    return this.http.get<BatteryStatus>(`${this.urlDigitalMatter}/battery-status`, {params});
  }

  public getAllDigitalMatterProfiles(): Observable<DigitalMatterProfile[]> {
    return this.http.get<DigitalMatterProfile[]>(`${this.urlDigitalMatter}/profiles`)
      .pipe(map(profiles => {
        profiles.forEach(profile => profile.translations = new Map(Object.entries(profile.translations)));
        return profiles;
      }));
  }

  public changeDigitalMatterProfile(telematicsUnitId: string, profileId: string) {
    const body = {
      profileId: profileId,
      telematicsUnitId: telematicsUnitId
    };
    return this.http.post(`${this.urlDigitalMatter}/profiles/change-profile`, JSON.stringify(body), { responseType: 'text' });
  }

  public updateDigitalMatterProfile(updatedProfileCommand: UpdateDigitalMatterProfileCommand): Observable<string> {
    return this.http.post(`${this.urlDigitalMatter}/profiles/update`, JSON.stringify(updatedProfileCommand), { responseType: 'text' });
  }

  public getCurrentDigitalMatterProfile(telematicsUnitId: string): Observable<DigitalMatterProfileDeployment> {
    return this.http.get<DigitalMatterProfileDeployment>(`${this.urlDigitalMatter}/profile-deployments/` + telematicsUnitId);
  }

  public beaconHasData(beaconId: string) {
    return this.http.get<boolean>(`${this.urlTelematics}/beacon-has-data?id=` + beaconId);
  }
}
