import { Injectable } from '@angular/core';
import Keycloak from 'keycloak-js';


import { environment } from '../../environments/environment';
import { User } from '../shared/contract/user';
import { Authorities } from '../shared/enums/authorities.enum';
import { BehaviorSubject, interval } from 'rxjs';
import * as Sentry from '@sentry/angular-ivy';
import { PrivilegedRole } from '../shared/enums/privileged-roles.enum';
import { Modules } from '../shared/enums/modules.enum';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { RealmType } from '../shared/contract/realm-type';

@Injectable()
export class KeycloakService {

  static auth: any = {};
  static user: User;
  static isOnline: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(navigator.onLine);
  static idpLoginPath = '/external-login';
  static idpLoginParam = 'idp';
  static organisationsFull: string[];

  static setOnline(): void {
    this.isOnline.next(true);
  }

  static setOffline(): void {
    this.isOnline.next(false);
  }

  static checkAndResolveIdpAlias(): string {
    if (window.location.pathname === this.idpLoginPath && window.location.search) {
      return new URLSearchParams(location.search).get(this.idpLoginParam);
    }
    return null;
  }

  static isWackerNeusonDomain(): boolean {
    return window.location.hostname === environment.APP_WACKER_NEUSON_DOMAIN;
  }

  static isTriathlonDomain(): boolean {
    return window.location.hostname === environment.APP_TRIATHLON_DOMAIN;
  }

  static getClientId(): string {
    if (KeycloakService.isWackerNeusonDomain()) {
      return environment.APP_WACKER_NEUSON_CLIENT_ID;
    } else if (KeycloakService.isTriathlonDomain()) {
      return environment.APP_TRIATHLON_CLIENT_ID;
    }
    return environment.APP_CLIENT_ID;
  }

  static getKeycloakBaseUrl(): string {
    if (KeycloakService.isWackerNeusonDomain()) {
      return environment.APP_WACKER_NEUSON_KEYCLOAK_BASE_URL;
    } else if (KeycloakService.isTriathlonDomain()) {
      return environment.APP_TRIATHLON_KEYCLOAK_BASE_URL;
    }
    return environment.APP_KEYCLOAK_BASE_URL;
  }

  static getRealm(): string {
    if (KeycloakService.isWackerNeusonDomain()) {
      return environment.APP_WACKER_NEUSON_REALM;
    } else if (KeycloakService.isTriathlonDomain()) {
      return  environment.APP_TRIATHLON_REALM;
    }
    return environment.APP_REALM;
  }

  static getRealmType(): RealmType {
    if (KeycloakService.isWackerNeusonDomain()) {
      return RealmType.WACKER_NEUSON;
    } else if (KeycloakService.isTriathlonDomain()) {
      return RealmType.TRIATHLON;
    }
    return RealmType.DEFAULT;
  }

  static init(updateCheckMs: number, refreshTokenMinutes: number): Promise<any> {

    window.addEventListener('online',  () => {KeycloakService.setOnline()});
    window.addEventListener('offline', () => {KeycloakService.setOffline()});

    let keycloakAuth: any = new Keycloak({
      url: KeycloakService.getKeycloakBaseUrl() + '/auth',
      realm: KeycloakService.getRealm(),
      clientId: KeycloakService.getClientId(),
      scope: 'openid profile beutlhauser'

    });
    KeycloakService.auth.loggedIn = false;

    return new Promise((resolve, reject) => {
      if (!KeycloakService.isOnline.value) {
        const token = localStorage.getItem('token');
        const tokenParsed = JSON.parse(localStorage.getItem('tokenParsed'));
        if (token && tokenParsed) {
          keycloakAuth.token = token;
          keycloakAuth.tokenParsed = tokenParsed;
          KeycloakService.handleAuthenticated(resolve, reject, keycloakAuth, updateCheckMs, refreshTokenMinutes);

          this.isOnline.asObservable()
            .pipe(debounceTime(5000), distinctUntilChanged())
            .subscribe(isOnline => {
              if (isOnline) {
                KeycloakService.reconnect();
              }
            });
        }
      } else {
        KeycloakService.initAdapter(resolve, reject, keycloakAuth, updateCheckMs, refreshTokenMinutes);
      }
    });
  }

  static initAdapter(resolve: Function, reject: Function, keycloakAuth: any, updateCheckMs: number, refreshTokenMinutes: number) {
    // due to changes in SameCookie policy in Chrome, LoginIframe needs to be deactivated for local development
    // see https://www.keycloak.org/docs/8.0/securing_apps/index.html#session-status-iframe
    const checkLoginIframe = this.isSSL(KeycloakService.getKeycloakBaseUrl());

    keycloakAuth.init({
      checkLoginIframe: checkLoginIframe
    })
      .then(authenticated => {
        if (!authenticated) {
          let idp = this.checkAndResolveIdpAlias();
          !!idp ? keycloakAuth.login({redirectUri: window.location.origin, idpHint: idp}) : keycloakAuth.login();
        } else {
          KeycloakService.handleAuthenticated(resolve, reject, keycloakAuth, updateCheckMs, refreshTokenMinutes);
        }
      }).catch((error) => {
      reject(error);
    });
  }

  static isSSL(url: string): boolean {
    return url.startsWith('https://');
  }

  static handleAuthenticated(resolve: Function,
     reject: Function,
     keycloakAuth: any,
     updateCheckMs: number,
     refreshTokenMinutes: number): void {
    KeycloakService.auth.loggedIn = true;
    KeycloakService.auth.authz = keycloakAuth;
    if (KeycloakService.isOnline.value) {
      KeycloakService.auth.logoutUrl = KeycloakService.auth.authz.createLogoutUrl();
    }
    localStorage.setItem('token', KeycloakService.auth.authz.token);
    localStorage.setItem('tokenParsed', JSON.stringify(KeycloakService.auth.authz.tokenParsed));
    this.user = new User(KeycloakService.auth.authz.tokenParsed);

    Sentry.setUser({
      email: this.user.email,
      customerId: this.user.customerId,
      roles: this.user.roles?.join(',')
    });

    if (KeycloakService.isOnline.value) {
      KeycloakService.auth.authz.loadUserProfile().then((profile) => {
        localStorage.setItem('profile', JSON.stringify(profile));

        interval(updateCheckMs).pipe(map((x) => KeycloakService.updateToken(refreshTokenMinutes).then(() => {
          localStorage.setItem('token', KeycloakService.auth.authz.token);
          this.user.updateUser(KeycloakService.auth.authz.tokenParsed);
        }))).subscribe();
        resolve(this.user);
      }).catch(() => {
        reject('Failed to load user profile');
      });
    } else {
      interval(updateCheckMs).pipe(map((x) => KeycloakService.updateToken(refreshTokenMinutes).then(() => {
        localStorage.setItem('token', KeycloakService.auth.authz.token);
        this.user.updateUser(KeycloakService.auth.authz.tokenParsed);
      }))).subscribe();
      resolve(this.user);
    }
  }

  static updateToken(refreshTokenMinutes: number): Promise<string> {
    return new Promise<string>((resolve) => {
      if (KeycloakService.auth.authz.token && KeycloakService.isOnline.value) {
        KeycloakService.auth.authz.updateToken(refreshTokenMinutes * 60).then(() => {
          resolve(<string>KeycloakService.auth.authz.token);
        }).catch(function () {
          window.location.reload();
        });
      }
    });
  }

  static refreshTokenForced(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      KeycloakService.updateToken(999999).then(() => {
        localStorage.setItem('token', KeycloakService.auth.authz.token);
        localStorage.setItem('tokenParsed', JSON.stringify(KeycloakService.auth.authz.tokenParsed));
        KeycloakService.user = new User(KeycloakService.auth.authz.tokenParsed);
        KeycloakService.organisationsFull = null;
        resolve(null);
      }).catch(() => reject());
    });
  }

  static logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('profile');
    Sentry.setUser(null);
    KeycloakService.auth.authz.logout({ redirectUri: window.location.origin });
  }

  static reconnect(): void {
    KeycloakService.init(10000, 1).then(() => console.log('Reconnected to authentication provider'));
  }

  static getUser(): User {
    return KeycloakService.user;
  }

  private static requireFetchOrganisationsFullList(): boolean {
    const organisations = KeycloakService.user.organisations;
    return organisations != null && organisations.length === 1 && organisations.includes('*');
  }

  private static fetchAssignedOrganisations(token: string): string[] {
    try {
      let request = new XMLHttpRequest();
      request.open('GET', `${environment.APP_USERROLE_SERVICE_BASE_URL}/api/v1/users/user-assigned-organisations`, false);
      request.setRequestHeader('Accept', 'application/json');
      request.setRequestHeader('Authorization', `Bearer ${token}`);
      request.setRequestHeader('X-Realm', KeycloakService.getRealm());
      request.send(null);

      if (request.status === 200) {
        return JSON.parse(request.response);
      }
    } catch (err) {
      console.error(err);
    }

    return [];
  }

  getAuthorities() {
    return KeycloakService.user.authorities;
  }

  getRoles() {
    return KeycloakService.user.roles;
  }

  isInRole(role: PrivilegedRole): boolean {
    return KeycloakService.user.roles.includes(role);
  }

  isInRoles(rolesToCheck: PrivilegedRole[]): boolean {
    let found = false;
    rolesToCheck.forEach(role => {
      if (this.isInRole(role)) {
        found = true;
      }
    });
    return found;
  }

  isTokenExpired() {
    return !KeycloakService.isOnline.value || KeycloakService.auth.authz.isTokenExpired();
  }

  hasAuthority(name: Authorities): boolean {
    return KeycloakService.user.authorities.includes(name);
  }

  hasAnyAuthority(authoritiesToCheck: Authorities[]): boolean {
    return authoritiesToCheck.some(authority => this.hasAuthority(authority));
  }

  isInAuthority(name: string): boolean {
    let authoritiesToCheck = name.replace(/ /g, '').split(',');

    for (let i = 0; i < authoritiesToCheck.length; i++) {
      if (this.hasAuthority(Authorities[authoritiesToCheck[i]])) {
        return true;
      }
    }
    return false;
  }

  hasModule(module: Modules): boolean {
    if (this.isInRoles([PrivilegedRole.Superadmin, PrivilegedRole.Partneradmin])) {
      return true;
    }

    return KeycloakService.user.modules.includes(module);
  }

  isSuperOrPartnerAdmin(): boolean {
    return this.isInRoles([PrivilegedRole.Superadmin, PrivilegedRole.Partneradmin]);
  }

  getToken() {
    return KeycloakService.auth.authz.token;
  }

  getRefreshToken() {
    return KeycloakService.auth.authz.refreshToken;
  }

  getTokenForHeader(withPrefix: string) {
    return withPrefix + ' ' + KeycloakService.auth.authz.token;
  }

  getBearerToken() {
    return this.getTokenForHeader('Bearer');
  }

  getUserCustomerId() {
    return KeycloakService.user.customerId;
  }

  getUserUserId() {
    return KeycloakService.user.userId;
  }

  getUserPartnerId() {
    return KeycloakService.user.partnerId;
  }

  getUserFullName() {
    return KeycloakService.user.getFullName();
  }

  public getUserEmail(): string {
    return KeycloakService.user.email;
  }

  public getUserFirstName(): string {
    return KeycloakService.user.firstName;
  }

  public getUserLastName(): string {
    return KeycloakService.user.lastName;
  }

  public getUserOrganisations(): string[] {
    if (!KeycloakService.organisationsFull) {
      if (KeycloakService.requireFetchOrganisationsFullList()) {
        KeycloakService.organisationsFull = KeycloakService.fetchAssignedOrganisations(this.getToken());
      } else {
        KeycloakService.organisationsFull = KeycloakService.user.organisations;
      }
    }

    return KeycloakService.organisationsFull;
  }

  public isUserAssignedToOrganisation(organisationId: string): boolean {
    const organisations = this.getUserOrganisations();
    return organisations != null && organisations.includes(organisationId);
  }
}
