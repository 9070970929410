export enum EquipmentFilterType {
  ORGANISATION = 'equipmentOrganisations',
  ASSIGNED_EMPLOYEE = 'assignedEmployee',
  LABEL = 'customerLabels',
  CLASS = 'equipmentTypes',
  STATUS = 'status',                      // NEW EQ-STATUSES
  EQUIPMENT_STATUS = 'equipmentStatus',   // OLD EQ-STATUSES
  TRANSPORT_VEHICLE_ONLY = 'showOnlyTransportVehicles',
  FAVOURITE_ONLY = 'showOnlyFavouriteEquipments',
  WITHOUT_SUBEQUIPMENTS = 'showSubEquipments',
  TRANSPORT_TYPES = 'transportTypes',
  MATCH_ALL_LABELS = 'matchAllLabels',
  ATTENTION_STATUS = 'attentionStatus',
  COMPLETENESS_STATUS = 'completenessStatus',

  TRANSPORT_OPTIONS = 'equipmentFilterTransportOptions'
}
