import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { LanguageService } from 'app/shared/services/language.service';
import { EventDetailsDatasource } from 'app/modules/equipment/shared/services/event-details.datasource';
import { EventDetailsPayload, EventRow } from 'app/modules/equipment/contract/equipment-event-details.interface';
import { EquipmentEventPayloadType } from 'app/modules/equipment/contract/equipment-event-payload-type.enum';
import { EventGroupInfo } from 'app/modules/equipment/contract/event-group-info.interface';
import {
  DAMAGE_PAYLOAD_COLUMNS,
  EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS,
  GEOFENCE_PAYLOAD_COLUMNS,
  INCIDENT_PAYLOAD_COLUMNS,
  MACHINE_FAULT_PAYLOAD_COLUMNS,
  MAINTENANCE_PAYLOAD_COLUMNS,
  MILEAGE_PAYLOAD_COLUMNS,
  OPERATING_HOURS_PAYLOAD_COLUMNS,
} from 'app/modules/equipment/contract/event-details-payload-columns-constants';
import { EVENT_DETAILS_EVENT_COLUMNS } from 'app/modules/equipment/contract/event-details-event-columns-constants';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { EquipmentEventStatus } from 'app/modules/equipment/contract/equipment-event-status.enum';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { EquipmentEventType } from 'app/modules/equipment/contract/equipment-event-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  MaintenanceTaskCompleteNavigationExtras
} from '../../../../../../maintenance/tasks/shared/contract/maintenance-task-complete-extra-state.interface';
import { EquipmentEventTabs } from 'app/modules/equipment/contract/equipment-event-tabs.enum';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';

@UntilDestroy()
@Component({
  selector: 'bh-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventDetailsComponent implements OnInit {

  public eventGroupInfo: EventGroupInfo = null;
  public payload: Observable<EventDetailsPayload[]>;
  public eventsList: Observable<EventRow[]>;
  public payloadType: EquipmentEventPayloadType = null;
  public duration = 0;
  public payloadDisplayedColumns: string[];
  public eventsTableDisplayedColumns = Object.values(EVENT_DETAILS_EVENT_COLUMNS);

  public selectedStatus = null;
  public statusList = [EquipmentEventStatus.OPEN, EquipmentEventStatus.CLOSED, EquipmentEventStatus.FIXED];

  public PAYLOAD_TYPE = EquipmentEventPayloadType;
  public DAMAGE_PAYLOAD_COLUMNS = DAMAGE_PAYLOAD_COLUMNS;
  public GEOFENCE_PAYLOAD_COLUMNS = GEOFENCE_PAYLOAD_COLUMNS;
  public EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS = EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS;
  public INCIDENT_PAYLOAD_COLUMNS = INCIDENT_PAYLOAD_COLUMNS;
  public MACHINE_FAULT_PAYLOAD_COLUMNS = MACHINE_FAULT_PAYLOAD_COLUMNS;
  public MAINTENANCE_PAYLOAD_COLUMNS = MAINTENANCE_PAYLOAD_COLUMNS;
  public readonly faArrowLeft: IconDefinition = faArrowLeft;
  public EVENT_COLUMNS = EVENT_DETAILS_EVENT_COLUMNS;

  constructor(
    private equipmentsDatasource: EquipmentsDataSource,
    private eventDetailsDatasource: EventDetailsDatasource,
    private router: Router,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) { }

  public ngOnInit(): void {
    this.setInitParams();
    this.eventGroupInfoSubscription();
    this.payloadTypeSubscription();
    this.eventDetailsDatasource.updateDamageEventStatusChangeTrigger(false);
  }

  public editDamageEvent(): void {
    this.router.navigate(['equipments', this.eventDetailsDatasource.equipmentId, 'edit-damage', this.eventDetailsDatasource.eventGroupId]);
  }

  public changeDamageEventStatus(status: EquipmentEventStatus): void {
    if (status !== this.eventGroupInfo.status) {
      this.openConfirmDialog(status);
    }
  }

  public completeTask(): void {
    const navigationExtras: MaintenanceTaskCompleteNavigationExtras = {
      state: {
        navigationAfterComplete: `assets/equipment/list/${this.eventDetailsDatasource.equipmentId}/events`,
      }
    };
    this.router.navigate(['maintenance/tasks/complete', this.eventGroupInfo.eventGroupId], navigationExtras);
  }

  public showCompleteTask(): boolean {
    return this.eventGroupInfo.status === EquipmentEventStatus.OPEN
        && (this.eventGroupInfo.type === EquipmentEventType.MAINTENANCE
        || this.eventGroupInfo.type === EquipmentEventType.INSPECTION);
  }

  public isActiveDamageEvent(): boolean {
    return this.eventGroupInfo.status === EquipmentEventStatus.OPEN
      && this.eventGroupInfo.type === EquipmentEventType.DAMAGE;
  }

  public getMileageNumber(value: string): number {
    return parseFloat(value);
  }

  private openConfirmDialog(status: EquipmentEventStatus): void {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.equipment.confirmation.message.changeDamageEventStatus');
    dialogRef.afterClosed().subscribe(res => {
      if (res === dialogResults.YES) {
        this.eventDetailsDatasource.selectedDamageEventStatus = status;
        this.eventDetailsDatasource.updateDamageEventStatusChangeTrigger(true);
        this.eventDetailsDatasource.updateEquipmentDamageEventStatus().subscribe(() => this.backToEvents());
      } else {
        this.selectedStatus = this.eventGroupInfo.status;
      }
      this.cdr.detectChanges();
    });
  }

  public backToEvents(): void {
    this.eventDetailsDatasource.resetPageIndex();
    this.equipmentsDatasource.navigateBackTab = this.getNavigateBackTab();
    this.router.navigate(['assets/equipment/list', this.eventDetailsDatasource.equipmentId, 'events']);
  }

  public sortData(sort: Sort): void {
    this.eventDetailsDatasource.sort = sort;
    this.eventDetailsDatasource.getEventDetails();
  }

  private setInitParams(): void {
    this.payload = this.eventDetailsDatasource.eventGroupPayload;
    this.eventsList = this.eventDetailsDatasource.eventsList;

    this.setPayloadTable();
  }

  private payloadTypeSubscription(): void {
    this.eventDetailsDatasource.payloadType
      .pipe(untilDestroyed(this))
      .subscribe(type => {
        this.payloadType = type;
        this.setPayloadTable();
      })
  }

  private eventGroupInfoSubscription(): void {
    this.eventDetailsDatasource.eventGroupInfo
      .pipe(untilDestroyed(this))
      .subscribe(info => {
        this.eventGroupInfo = info;
        this.selectedStatus = info.status;
        this.calculateDuration(info.creationDate);
      })
  }

  private setPayloadTable(): void {
    switch (this.payloadType) {
      case EquipmentEventPayloadType.DAMAGE:
        this.payloadDisplayedColumns = Object.values(DAMAGE_PAYLOAD_COLUMNS);
        break;
      case EquipmentEventPayloadType.EMPLOYEE_ASSIGNMENT:
        this.payloadDisplayedColumns = Object.values(EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS);
        break;
      case EquipmentEventPayloadType.INCIDENT:
        this.payloadDisplayedColumns = Object.values(INCIDENT_PAYLOAD_COLUMNS);
        break;
      case EquipmentEventPayloadType.MACHINE_FAULT:
        this.payloadDisplayedColumns = Object.values(MACHINE_FAULT_PAYLOAD_COLUMNS);
        break;
      case EquipmentEventPayloadType.MAINTENANCE:
        this.payloadDisplayedColumns = Object.values(MAINTENANCE_PAYLOAD_COLUMNS);
        break;
      case EquipmentEventPayloadType.MILEAGE:
        this.payloadDisplayedColumns = Object.values(MILEAGE_PAYLOAD_COLUMNS);
        break;
      case EquipmentEventPayloadType.OPERATING_HOURS:
        this.payloadDisplayedColumns = Object.values(OPERATING_HOURS_PAYLOAD_COLUMNS);
        break;
      case EquipmentEventPayloadType.GEOFENCE:
        this.payloadDisplayedColumns = Object.values(GEOFENCE_PAYLOAD_COLUMNS);
        break;
    }
  }

  private calculateDuration(creationDate: Date): void {
    this.duration = moment(moment.now()).diff(creationDate, 'hours');
  }

  private getNavigateBackTab(): EquipmentEventTabs {
    return this.eventGroupInfo.status === EquipmentEventStatus.OPEN ? EquipmentEventTabs.ACTIVE : EquipmentEventTabs.EVENT;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  protected readonly MILEAGE_PAYLOAD_COLUMNS = MILEAGE_PAYLOAD_COLUMNS;
  protected readonly OPERATING_HOURS_PAYLOAD_COLUMNS = OPERATING_HOURS_PAYLOAD_COLUMNS;
}
