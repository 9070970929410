import {MobileWebAppPopupMode} from 'app/shared/enums/mobile-web-app-popup-mode.enum';
import {Languages} from '../app/shared/enums/languages.enum';

export const environment = {
  production: false,
  enableServiceWorker: true,
  firebase: {
    apiKey: 'AIzaSyDnLC0R5ayR8BEMXvmy25HB33gi-9aH6vw',
    authDomain: 'onestop-pro-dev.firebaseapp.com',
    databaseURL: 'https://onestop-pro-dev.firebaseio.com',
    projectId: 'onestop-pro-dev',
    storageBucket: 'onestop-pro-dev.appspot.com',
    messagingSenderId: '273697160234',
    appId: '1:273697160234:web:3e4556cf7dad35d2'
  },

  APP_DOMAIN: 'brz.development.onestop-pro.de',
  APP_REALM: 'beutlhauser',
  APP_CLIENT_ID: 'frontend',
  APP_KEYCLOAK_BASE_URL: 'https://brz-sso.development.onestop-pro.de',

  APP_WACKER_NEUSON_CLIENT_ID: 'frontend',
  APP_WACKER_NEUSON_DOMAIN: 'brz-wackerneuson.development.onestop-pro.de',
  APP_WACKER_NEUSON_REALM: 'wackerneuson',
  APP_WACKER_NEUSON_KEYCLOAK_BASE_URL: 'https://brz-wackerneuson-sso.development.onestop-pro.de',

  APP_TRIATHLON_CLIENT_ID: 'frontend',
  APP_TRIATHLON_DOMAIN: 'brz-triathlon.development.onestop-pro.de',
  APP_TRIATHLON_REALM: 'triathlon',
  APP_TRIATHLON_KEYCLOAK_BASE_URL: 'https://brz-triathlon-sso.development.onestop-pro.de',

  APP_EQUIPMENT_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/equipment-api',
  APP_TELEMATICS_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/telematics-api',
  APP_DISPOSITION_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/disposition-api',
  APP_JOB_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/job-api',
  APP_USERROLE_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/userrole-api',
  APP_ORGANISATION_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/organisation-api',
  APP_NOTIFICATION_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/notifications-api',
  APP_COMMUNICATION_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/communication-api',
  APP_REPORT_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/report-api',
  APP_EQUIPMENT_AUDIT_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/equipment-audit-api',
  APP_PUSH_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/push-api',
  APP_MAINTENANCE_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/maintenance-api',
  APP_CORE_DATA_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/core-data-api',
  APP_TRANSPORT_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/transport-api',
  APP_FILE_SERVICE_BASE_URL: 'https://brz.development.onestop-pro.de/file-api',
  APP_FILE_DOWNLOAD_SERVICE_BASE_URL: 'https://brz-files.development.onestop-pro.de',
  logErrorsInSentry: false,
  SENTRY: 'https://584b4051e3274e279fd430f51edd14b4@o1221674.ingest.sentry.io/6417546',
  GOOGLE_MAPS_API_KEY: 'AIzaSyBDpjf-ieJfoxWYogePJr_yAvp4DQ3kbaA',
  DS_SCANNER_LICENCE_KEY: '052-60373747-97182704',
  MATOMO_URL: 'https://analyze.onestop-pro.de/',
  MATOMO_SITE_ID: 4,
  DELAY_SHORTEST: 500,
  DELAY_SHORT: 1000,
  DELAY_LONG: 2000,
  DELAY_LONGEST: 4000,
  MOBILE_WEB_APP_POPUP_MODE: MobileWebAppPopupMode.SHOW_POPUP_NOT_CLOSABLE,

  TWO_HUNDRED_FIFTY_SIX_THOUSAND: 256000,
  ONE_MILLION: 1000000,
  TEN_THOUSAND: 10000,

  TEN_MILLION: 10000000,
  TEN_SEXTILLION: 10000000000000000000000,

  FALLBACK_LANGUAGE: Languages.DEV,

  FOTA_API_TOKEN: '3026|oIdxHkfR93MbCBkKIV5o5MkjImSNpo279CRd39Av',

  LOCALIZE_API_KEY: 'KRIcGPZWpp3fvBIF1mcmD5gYMiZPJIua1ABScofj',
  LOCALIZE_API_BASE_URL: 'https://localization.infrastructure.onestoppro.de/osp-infrastructure-localization-api/web',

  EQUIPMENT_SEARCH_V2_TOGGLE_AVAILABLE: false,
  EQUIPMENT_EVENTS_TOGGLE_AVAILABLE: false,
};
