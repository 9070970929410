import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from '../../../../../../core/keycloak';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { DisplayService } from '../../../../../../shared/display.service';
import { BaseEquipmentViewComponent } from '../base/base-equipment-view.component';
import { EquipmentCheckerService } from '../../../../shared/services/equipment-checker.service';
import { Authorities } from '../../../../../../shared/enums/authorities.enum';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faRedo, faTruck, faUnlink, faArrowDownUpLock, faArrowDownUpAcrossLine, faLockA, faWavePulse } from '@fortawesome/pro-light-svg-icons';
import { RouterHistory } from '../../../../../../shared/router-history';
import { LanguageService } from 'app/shared/services/language.service';
import { Modules } from '../../../../../../shared/enums/modules.enum';
import {
  EquipmentTelematicsUnitTypeResolver
} from '../../../../shared/services/equipment-telematics-unit-type.resolver';
import { EquipmentSubMenuLink } from 'app/shared/enums/equipment-sub-menu-link.enum';
import { SubMenuTab } from 'app/modules/disposition/shared/interfaces/sub-menu-tab.interface';
import { SubmenuTabsResizeHandler } from 'app/shared/submenu-tabs-resize-handler';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { EquipmentStatusCategory } from '../../../../contract/equipment-status-category.enum';
import {
  MatMenuItemButtonConfig
} from '../../../../../../shared/components/button/mat-menu-item-button/mat-menu-item-button-config';
import {
  RequestErrorHandlerEquipmentService
} from 'app/core/interceptors/request-error-handlers/request-error-handler-equipment.service';
import { isBeaconType, TelematicsUnitType } from '../../../../contract/telematics-unit-type.enum';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from '../../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../../assets/matomo/matomo-actions.enum';
import { AutomatedTransfersStatusList } from 'app/shared/enums/automated-transfers-status-list.enum';
import { UserConfigurationService } from '../../../../../../shared/services/user-configuration.service';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view',
  templateUrl: './equipment-view.component.html',
  styleUrls: ['./equipment-view.component.scss']
})
export class EquipmentViewComponent extends BaseEquipmentViewComponent implements OnInit, AfterViewInit {
  public readonly faRedo: IconDefinition = faRedo;
  public readonly faUnlink: IconDefinition = faUnlink;
  public readonly faTruck: IconDefinition = faTruck;
  public readonly faWavePulse: IconDefinition = faWavePulse;
  public readonly subMenuRouterLinks = EquipmentSubMenuLink;

  private submenuHandler: SubmenuTabsResizeHandler;
  private isResizeObserverInitialized = false;

  isUpdateEquipmentDisabled = () => this.equipment?.status?.category === EquipmentStatusCategory.NOT_AVAILABLE;

  updateNotAvailableEquipmentTooltip = () => this.translate('modules.equipment.base.updateNotAvailableEquipment')

  public burgerMenuButtonConfigs: MatMenuItemButtonConfig[] = [
    {
      text: this.translate('general.buttons.delete'),
      matIconContent: 'delete_forever',
      show: () => this.hasAuthority(this.authorities.EQUIPMENT_DELETE),
      disabled: () => this.deleteDisabled(this.equipment),
      tooltipDisabled: () => this.resolveDeleteTooltip(this.equipment),
      click: () => this.equipmentDelete(this.equipment.equipmentId)
    },
    {
      text: this.translate('general.buttons.edit'),
      matIconContent: 'edit',
      show: () => this.hasAuthority(this.authorities.EQUIPMENT_UPDATE),
      disabled:  () => this.isUpdateEquipmentDisabled(),
      tooltipDisabled: this.updateNotAvailableEquipmentTooltip,
      click: () => this.edit()
    },
    {
      text: this.translate('modules.equipment.equipmentView.changeEquipmentType'),
      matIconContent: 'developer_board',
      show: () => this.hasAuthority(this.authorities.EQUIPMENT_TYPE_CHANGE),
      disabled: () => this.isUpdateEquipmentDisabled(),
      tooltipDisabled: this.updateNotAvailableEquipmentTooltip,
      click: () => this.changeEquipmenttype(this.equipment?.equipmentId)
    },
    {
      text: this.translate('modules.equipment.equipmentView.transportVehicleSettings'),
      faIcon: faTruck,
      show: () => this.hasAuthority(this.authorities.TRANSPORT_VEHICLE_MANAGE) && this.hasModule(this.modules.TRANSPORT_TASK_MANAGEMENT),
      disabled: () => this.isUpdateEquipmentDisabled(),
      tooltipDisabled: this.updateNotAvailableEquipmentTooltip,
      click: () => this.transportVehicleSettings(this.equipment?.equipmentId)
    },
    {
      text: this.translate('modules.equipment.equipmentView.assignTelematicUnit'),
      matIconFontSet:  'icon-telematics_outline',
      show: () => this.canAssignTelematicUnit(),
      disabled: () => this.isUpdateEquipmentDisabled(),
      tooltipDisabled: this.updateNotAvailableEquipmentTooltip,
      click: () => this.assignTelematicUnit()
    },
    {
      text: this.translate('modules.equipment.equipmentView.unassignTelematicUnit'),
      faIcon: faUnlink,
      show: () => this.canUnassignTelematicUnit(),
      disabled: () => this.isUpdateEquipmentDisabled(),
      tooltipDisabled: this.updateNotAvailableEquipmentTooltip,
      click: () => this.unassignTelematicUnit()
    },
    {
      text: this.translate('modules.equipment.equipmentView.calibrateTelematicUnit'),
      matIconFontSet:  'icon-workinghours01_outline',
      show: () => this.canCalibrateTelematicUnit(),
      disabled: () => this.isUpdateEquipmentDisabled(),
      tooltipDisabled: this.updateNotAvailableEquipmentTooltip,
      click: () => this.calibrateTelematicUnit()
    },
    {
      text: this.translate('modules.equipment.equipmentView.automatedTransfersStatus'),
      faIcon: this.getAutomatedTransfersStatusIcon(),
      show: () => this.isAutomatedTransfersStatusAvailable(),
      disabled: () => false,
      tooltipDisabled: this.updateNotAvailableEquipmentTooltip,
      click: () => this.changeAutomatedTransfersStatus(),
    },
  ]

  @ViewChild('submenu', { static: true }) submenu: ElementRef;
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;

  public hiddenTabs: SubMenuTab[] = [];
  public subMenuTabs: SubMenuTab[] = [
    {
      name: this.translate('general.generalData'),
      url: EquipmentSubMenuLink.GENERAL,
      matIconFontSet: 'icon-general_outline',
    },
    {
      name: this.translate('modules.equipment.equipmentView.timeline'),
      url: EquipmentSubMenuLink.TIMELINE,
      matIconFontSet: 'icon-timeline_outline',
    },
    {
      name: this.translate('modules.equipment.base.costs'),
      url: EquipmentSubMenuLink.COST,
      matIconFontSet: 'icon-costs_outline',
    },
    {
      name: `${this.translate('general.inspection.pl')} ${this.translate('general.maintenance.pl')}`,
      url: EquipmentSubMenuLink.TASKS,
      matIconFontSet: 'icon-service_outline',
    },
    {
      name: this.translate('general.telematics'),
      url: EquipmentSubMenuLink.TELEMATIC,
      matIconFontSet: 'icon-telematics_outline',
    },
    {
      name: this.translate('general.disposition.s'),
      url: EquipmentSubMenuLink.DISPOSITION,
      matIconFontSet: 'icon-disposition_outline',
    },
    {
      name: this.translate('general.usage'),
      url: EquipmentSubMenuLink.USAGE,
      matIconFontSet: 'icon-disposition_outline',
    },
    {
      name: this.translate('general.subEquipm.pl'),
      url: EquipmentSubMenuLink.SUB_EQUIPMENTS,
      matIconContent: 'subdirectory_arrow_right',
    },
    {
      name: this.translate('general.contract.s'),
      url: EquipmentSubMenuLink.CONTRACTS,
      matIconFontSet: 'icon-contract2_outline',
    },
    {
      name: this.translate('general.map.s'),
      url: EquipmentSubMenuLink.CARD,
      matIconFontSet: 'icon-map_outline',
    },
    {
      name: this.translate('general.event.pl'),
      url: EquipmentSubMenuLink.EVENTS,
      faIcon: faWavePulse,
    },

  ];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected authService: KeycloakService,
    protected dialog: MatDialog,
    protected routerHistory: RouterHistory,
    public equipmentStore: EquipmentsDataSource,
    public displayService: DisplayService,
    public equipmentCheckerService: EquipmentCheckerService,
    public userConfigurationService: UserConfigurationService,
    protected langService: LanguageService,
    protected telematicsUnitResolver: EquipmentTelematicsUnitTypeResolver,
    protected errorHandlerEquipmentService: RequestErrorHandlerEquipmentService,
    protected matomoTracker: MatomoTracker
  ) {
    super(router, route, authService, dialog, routerHistory, equipmentStore, displayService, langService, matomoTracker);
  }

  public ngOnInit(): void {
    this.initListenerNotFoundEquipment();
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.displayService.isTableAndDetailsChanges,
      this.displayService.isDetailsFullscreenChanges
    ])
    .pipe(untilDestroyed(this))
    .subscribe(([isTableAndDetailsChanges, isDetailsFullscreenChanges]) => {
      setTimeout(() => {
        if ((isTableAndDetailsChanges || isDetailsFullscreenChanges) && !this.isResizeObserverInitialized) {
          this.initSubMenuResizeObserver();
        }
      });
    });
  }

  private initSubMenuResizeObserver() {
    this.submenuHandler = new SubmenuTabsResizeHandler(this.submenu, this.subMenuTabs);
    const resizeObserver = new ResizeObserver(() => {
      this.hiddenTabs = this.submenuHandler.onSubMenuResize();
      if (!this.hiddenTabs.length) {
        this.trigger.closeMenu();
      }
    });
    resizeObserver.observe(this.submenu.nativeElement);
    this.isResizeObserverInitialized = true;
  }

  private initListenerNotFoundEquipment(): void {
    this.errorHandlerEquipmentService.selectEventSender(
      sender => sender
        .pipe(untilDestroyed(this))
        .subscribe(error => this.equipmentStore.handleNotFoundEquipment(error))
    );
  }

  public isThereSomeButtonInShowMoreMenu(): boolean {
    return this.burgerMenuButtonConfigs
      .map((buttonConfig) => buttonConfig.show())
      .some((value) => value);
  }

  public showMoreMenu(): boolean {
    return this.isThereSomeButtonInShowMoreMenu();
  }

  public isTabMoreMenuActive(): boolean {
    const tabsMoreMenuPaths = this.hiddenTabs.map(tab => tab.url);

    const currentTabPath = this.route.snapshot.firstChild && this.route.snapshot.firstChild.url[0].path;
    return tabsMoreMenuPaths.includes(currentTabPath);
  }

  public createTelematicsTooltip(): string {
    if (this.equipmentCheckerService.isViewEquipmentLinkedToTelematicsUnit(this.equipment)) {
      return `${this.translate('modules.equipment.equipmentView.linkedTelematicsUnit')}: ${
        this.equipmentCheckerService.hasEquipmentCurrentTelematicsData(this.equipment)
          ? this.translate('modules.equipment.equipmentView.dataReceived')
          : this.translate('general.noData')
      } ${
        this.equipment.assignedTelematicsUnits
          .map(assignment => `\n ${this.telematicsUnitResolver.getTelematicsUnitInfo(assignment)}`)
          .join()
      }`
    }
    return '';
  }

  public canSeeUsage(): boolean {
    return this.hasAnyAuthority([Authorities.PROJECT_ASSIGNEE_VIEW, Authorities.EQUIPMENT_VIEW])
  }

  public canSeeContracts(): boolean {
    return this.hasAuthority(Authorities.EQUIPMENT_VIEW_CONTRACTS) && this.hasModule(Modules.COSTS);
  }

  public canSeeTelematics(): boolean {
    return this.hasAuthority(Authorities.EQUIPMENT_VIEW_TELEMATICS) && this.hasModule(Modules.LIVE_DATA);
  }

  public canAssignTelematicUnit(): boolean {
    return !this.currentTelematicUnit && this.canChangeTelematicAssignment();
  }

  public canUnassignTelematicUnit(): boolean {
    return !!this.currentTelematicUnit && this.canChangeTelematicAssignment();
  }

  public canCalibrateTelematicUnit(): boolean {
    return !!this.currentTelematicUnit
      && this.currentTelematicUnit.key !== TelematicsUnitType.DIGITAL_MATTER_OYSTER3_UNIT
      && !isBeaconType(this.currentTelematicUnit.key)
      && this.hasModule(Modules.LIVE_DATA)
      && this.authService.hasAnyAuthority([
        Authorities.EQUIPMENT_CALIBRATE_TELEMATIC_UNIT_MILEAGE,
        Authorities.EQUIPMENT_CALIBRATE_TELEMATIC_UNIT_OPERATING_HOURS
      ]);
  }

  public isAutomatedTransfersStatusAvailable(): boolean {
    return  this.authService.hasModule(Modules.AUTOMATED_TRANSFERS) &&
            this.authService.hasAuthority(Authorities.AUTOMATED_TRANSFERS_EQUIPMENT_INCLUDE_EXCLUDE) &&
            this.canUnassignTelematicUnit();
  }

  public getAutomatedTransfersStatusIcon(): IconDefinition {
    switch (this.equipment?.automatedTransferInclusionStatus || null) {
      case AutomatedTransfersStatusList.INACTIVE:
        return faArrowDownUpLock;
      case AutomatedTransfersStatusList.BLOCKED:
        return faLockA;
        case AutomatedTransfersStatusList.ACTIVE:
        default:
          return faArrowDownUpAcrossLine;
    }
  }

  private canChangeTelematicAssignment(): boolean {
    return this.hasModule(Modules.LIVE_DATA) && this.hasAuthority(Authorities.EQUIPMENT_MANAGE_TELEMATIC_UNIT);
  }

  trackMatomoEvent_EquipmentInfo_EquipmentActions() {
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_INFO, matomoActions.EQUIPMENT_ACTIONS);
  }

  public isPostgresEnabled(): boolean {
    return this.userConfigurationService.getEquipmentSearchV2Configuration()?.useSearchV2;
  }

  public isEquipmentEventsEnable(): boolean {
    return this.userConfigurationService.getEquipmentEventsConfiguration()?.eventsAvailable;
  }

  public showAddDamageButton(): boolean {
    return  this.authService.hasAuthority(Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA) ||
            this.authService.hasAuthority(Authorities.EQUIPMENT_UPDATE) ||
            this.authService.hasAuthority(Authorities.EQUIPMENT_MANAGE_DAMAGES);
  }

  public addDamage(): void {
    this.router.navigate([`/equipments/${this.equipment.equipmentId}/add-damage`]);
  }
}
