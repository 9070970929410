export const DAMAGE_PAYLOAD_COLUMNS = {
  damageDate: 'damageDate',
  damageType: 'damageType',
  damageLocation: 'damageLocation',
  documentation: 'documentation',
  invoiceNumber: 'invoiceNumber',
  mileage: 'mileage',
  operatingHours: 'operatingHours',
  submittedBy: 'submittedBy',
}

export const EMPLOYEE_ASSIGNMENT_PAYLOAD_COLUMNS = {
  handOverDate: 'handOverDate',
  employee: 'employee',
  employeeRole: 'employeeRole',
  submittedBy: 'submittedBy',
}

export const GEOFENCE_PAYLOAD_COLUMNS = {
  geofenceName: 'geofenceName',
  locationName: 'locationName',
  locationType: 'locationType',
  interactionType: 'interactionType',
  timestamp: 'timestamp',
}

export const INCIDENT_PAYLOAD_COLUMNS = {
  logDate: 'logDate',
  mileage: 'mileage',
  operatingHours: 'operatingHours',
  subject: 'subject',
  submittedBy: 'submittedBy',
  description: 'description',
}

export const MACHINE_FAULT_PAYLOAD_COLUMNS = {
  dateTime: 'dateTime',
  clearedDateTime: 'clearedDateTime',
  severity: 'severity',
  source: 'source',
  description: 'description',
};

export const MAINTENANCE_PAYLOAD_COLUMNS = {
  dueDate: 'dueDate',
  completedAtDate: 'completedAtDate',
  completedAtMileage: 'completedAtMileage',
  completedAtOperatingHours: 'completedAtOperatingHours',
  maintenanceResult: 'maintenanceResult',
  taskName: 'taskName',
}

export const MILEAGE_PAYLOAD_COLUMNS = {
  logDate: 'logDate',
  mileage: 'mileage',
  submittedBy: 'submittedBy',
  comment: 'comment',
}

export const OPERATING_HOURS_PAYLOAD_COLUMNS = {
  logDate: 'logDate',
  operatingHours: 'operatingHours',
  submittedBy: 'submittedBy',
  comment: 'comment',
}
