import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomersService } from '../../shared/customers.service';
import { UpdateCustomerCommand } from '../../contract/update-customer-command';
import { CustomerTypeEnum } from '../../contract/customer-type.enum';
import { ViewCustomer } from '../../contract/view-customer.interface';
import { GuardedNavigableInputComponent } from '../../../../shared/navigation-guards/guarded-navigable-input.component';
import { KeycloakService } from '../../../../core/keycloak';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterHistory } from '../../../../shared/router-history';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { CustomerNumberInUseValidator } from '../../../../shared/custom-validators/customer-number-in-use.validator';
import { asyncValidatorFactory } from '../../../../shared/custom-validators/async-validator.factory';
import { CountryEnum } from '../../../../shared/country.enum';
import { CountryResolver } from '../../../../shared/country.resolver';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PrivilegedRole } from '../../../../shared/enums/privileged-roles.enum';
import { TrimValidator } from 'app/shared/custom-validators/trim.validator';
import { CustomerNameInUseValidator } from '../../../../shared/custom-validators/customer-name-in-use.validator';

@UntilDestroy()
@Component({
  selector: 'bh-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.scss']
})
export class CustomerEditComponent extends GuardedNavigableInputComponent implements OnInit {

  public readonly fieldLimit = FieldLimit;
  public countryEnum = CountryEnum;
  customerEditForm: UntypedFormGroup;
  customerType = [];
  private customer: ViewCustomer;
  public editSubCustomer = false;

  constructor(private formBuilder: UntypedFormBuilder,
              protected router: Router,
              private customerService: CustomersService,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected routerHistory: RouterHistory,
              private countryResolver: CountryResolver) {
    super(authService, router, route, routerHistory);
  }

  ngOnInit() {
    this.buildForm();
    this.getCustomer();
    this.initAsyncValidators();
    this.customerEditFormChangeHandler();

    this.route.queryParams.subscribe(params => {
      this.editSubCustomer = params['editSubCustomer'] || false;
    });
  }

  public navigateBack(): void {
    let backUrl = '/customers/list';
    if (this.editSubCustomer) {
      backUrl = '/customers/sub-customers/list';
    }

    this.goBack(backUrl);
  }

  public getCountryName(option: CountryEnum): string {
    return this.countryResolver.resolveName(option);
  }

  buildForm() {
    this.customerEditForm = this.formBuilder.group({
      customerId: ['', [<any>Validators.required]],
      customerName: ['', [<any>Validators.required]],
      customerNumber: ['', [TrimValidator.hasWhitespaces]],
      customerAddress: this.formBuilder.group({
        country: ['', <any>Validators.required],
        street: '',
        streetNumber: '',
        postalCode: '',
        city: '',
      }),
      customerTypeList: this.formBuilder.array([]),
      hasAcknowledgeAssignmentWorkflow: false,
      hasTransferRequestWorkflow: false,
      hasTypeOfUseWorkflow: false,
      hasStockTransferRequestWorkflow: false,
      activateTransferRequestAutoAcceptance: [{value: false, disabled: true}],
      daysForTransferRequestAutoAcceptance: [{value: null, disabled: true},
        [Validators.min(1), Validators.max(365), Validators.pattern('^[0-9]*$')],
      ]
    });
  }

  get customerCountryKeys() {
    return Object.keys(this.countryEnum);
  }

  get customerName(): AbstractControl {
    return this.customerEditForm.get('customerName');
  }

  get customerNumber(): AbstractControl {
    return this.customerEditForm.get('customerNumber');
  }

  get customerTypeList() {
    return this.customerEditForm.get('customerTypeList') as UntypedFormArray;
  }

  get customerCountry(): AbstractControl {
    return this.customerEditForm.get('customerAddress').get('country');
  }

  get customerStreet(): AbstractControl {
    return this.customerEditForm.get('customerAddress').get('street');
  }

  get customerStreetNumber(): AbstractControl {
    return this.customerEditForm.get('customerAddress').get('streetNumber');
  }

  get customerPostalCode(): AbstractControl {
    return this.customerEditForm.get('customerAddress').get('postalCode');
  }

  get customerCity(): AbstractControl {
    return this.customerEditForm.get('customerAddress').get('city');
  }

  get activateTransferRequestAutoAcceptance(): AbstractControl {
    return this.customerEditForm.get('activateTransferRequestAutoAcceptance');
  }

  get daysForTransferRequestAutoAcceptance(): AbstractControl {
    return this.customerEditForm.get('daysForTransferRequestAutoAcceptance');
  }

  get hasStockTransferRequestWorkflow(): AbstractControl {
    return this.customerEditForm.get('hasStockTransferRequestWorkflow');
  }

  get hasTransferRequestWorkflow(): AbstractControl {
    return this.customerEditForm.get('hasTransferRequestWorkflow');
  }

  get isFleetAdminRole(): boolean {
    return this.authService.getRoles().includes(PrivilegedRole.Flottenadmin.toString())
  }

  save(): void {
    if (this.isValid()) {
      let customer = this.customerEditForm.getRawValue();
      let cmd = new UpdateCustomerCommand();
      cmd.customerId = customer.customerId;
      cmd.customerName = customer.customerName;
      cmd.customerNumber = customer.customerNumber;
      cmd.customerAddress = customer.customerAddress;
      cmd.customerTypeList = customer.customerTypeList;
      cmd.hasAcknowledgeAssignmentWorkflow = customer.hasAcknowledgeAssignmentWorkflow;
      cmd.hasTransferRequestWorkflow = customer.hasTransferRequestWorkflow;
      cmd.hasTypeOfUseWorkflow = customer.hasTypeOfUseWorkflow;
      cmd.hasStockTransferRequestWorkflow = customer.hasStockTransferRequestWorkflow;
      cmd.activateTransferRequestAutoAcceptance = customer.activateTransferRequestAutoAcceptance;
      cmd.daysForTransferRequestAutoAcceptance = customer.daysForTransferRequestAutoAcceptance;
      this.customerService.updateCustomer(cmd).subscribe(
        () => {
          if (this.editSubCustomer) {
            this.navigateBack();
          } else {
            this.navigateAfterSubmit();
          }
          },
        (error: HttpErrorResponse) => {
            console.log('edit customer error: ', error);
          }
      );
    }
  }

  public isValid(): boolean {
    return this.customerEditForm.valid;
  }

  getCustomer(): void {
    this.route.params.subscribe(params => {
      const customerId = params['id'];
      this.customerService.getCustomer(customerId).subscribe(
          data => {
            this.customer = data;
            this.setFormValues();
            this.getCustomerType();
          },
          error => {
            console.log(error);
          }
      );
    });
  }

  getCustomerType(): void {
    let customerTypeFromEnum = Object.keys(CustomerTypeEnum);
    customerTypeFromEnum.forEach(ct => {
      if (this.customer.customerTypeList) {
        let existingCustomerType = this.customer.customerTypeList.find(customerType => Object.is(ct, customerType));

        if (existingCustomerType) {
          this.customerType.push({
            name: ct,
            isSelected: true
          });

          this.customerTypeList.push(new UntypedFormControl(ct));
        } else {
          this.customerType.push({
            name: ct,
            isSelected: false
          })
        }
      } else {
        this.customerType.push({
          name: ct,
          isSelected: false
        })
      }
    });
  }

  selectCostumerType(ct): void {
    ct.isSelected = !ct.isSelected;

    if (ct.isSelected) {
      ct.isSelected = true;
      this.customerTypeList.push(new UntypedFormControl(ct.name));
    } else {
      let index: number = this.customerTypeList.value.findIndex(x => Object.is(ct.name, x));

      this.customerTypeList.removeAt(index);
    }
  }

  private setFormValues(): void {
    this.customerEditForm.patchValue({
      customerId: this.customer.customerId,
      customerName: this.customer.customerName,
      customerNumber: this.customer.customerNumber,
      hasAcknowledgeAssignmentWorkflow: this.customer.acknowledgeAssignmentWorkflow,
      hasTransferRequestWorkflow: this.customer.acknowledgeTransferRequestWorkflow ?
       this.customer.acknowledgeTransferRequestWorkflow : false,
      hasTypeOfUseWorkflow: this.customer.typeOfUseWorkflow ? this.customer.typeOfUseWorkflow : false,
      hasStockTransferRequestWorkflow: this.customer.acknowledgeStockTransferRequestWorkflow ?
       this.customer.acknowledgeStockTransferRequestWorkflow : false,
      customerAddress: {
        country: this.customer.customerAddress.country,
        street: this.customer.customerAddress.street,
        streetNumber: this.customer.customerAddress.streetNumber,
        postalCode: this.customer.customerAddress.postalCode,
        city: this.customer.customerAddress.city
      },
      activateTransferRequestAutoAcceptance: this.customer.activateTransferRequestAutoAcceptance,
      daysForTransferRequestAutoAcceptance: this.customer.daysForTransferRequestAutoAcceptance,
    });
    if (Boolean(this.customer.acknowledgeTransferRequestWorkflow) || Boolean(this.customer.acknowledgeStockTransferRequestWorkflow)) {
      this.activateTransferRequestAutoAcceptance.enable();
      if (Boolean(this.customer.activateTransferRequestAutoAcceptance)) {
        this.daysForTransferRequestAutoAcceptance.enable();
      } else {
        this.daysForTransferRequestAutoAcceptance.disable();
      }
    } else {
      this.activateTransferRequestAutoAcceptance.disable();
      this.daysForTransferRequestAutoAcceptance.disable();
    }
  }

  private initAsyncValidators() {
    this.customerName.setAsyncValidators(asyncValidatorFactory((value) => {
      let customerId: string = this.customer?.customerId;
      let partnerId: string = this.customer?.partnerId;
      return CustomerNameInUseValidator.isValid(value, customerId, partnerId, this.customerService);
    }));

    this.customerNumber.setAsyncValidators(asyncValidatorFactory((value) => {
      let customerId: string = this.customer?.customerId;
      let partnerId: string = this.customer?.partnerId;
      return CustomerNumberInUseValidator.isValid(value, customerId, partnerId, this.customerService);
    }));
  }

  private customerEditFormChangeHandler(): void {
    this.hasTransferRequestWorkflow.valueChanges.pipe(debounceTime(100), untilDestroyed(this)).subscribe({
      next: value => {
        this.setFieldsStatuses();
      },
    });
    this.hasStockTransferRequestWorkflow.valueChanges.pipe(debounceTime(100), untilDestroyed(this)).subscribe({
      next: value => {
        this.setFieldsStatuses();
      },
    });
    this.activateTransferRequestAutoAcceptance.valueChanges.pipe(debounceTime(100), untilDestroyed(this)).subscribe({
      next: value => {
        this.setFieldsStatuses();
      },
    });
  }
  private setFieldsStatuses(): void {
    if (Boolean(this.hasStockTransferRequestWorkflow.value) || Boolean(this.hasTransferRequestWorkflow.value)) {
      this.activateTransferRequestAutoAcceptance.enable();
      if (Boolean(this.activateTransferRequestAutoAcceptance.value)) {
        this.daysForTransferRequestAutoAcceptance.enable();
      } else {
        this.daysForTransferRequestAutoAcceptance.patchValue(null, {emitEvent: false});
        this.daysForTransferRequestAutoAcceptance.disable();
      }
    } else {
      this.activateTransferRequestAutoAcceptance.patchValue(false, {emitEvent: false});
      this.daysForTransferRequestAutoAcceptance.patchValue(null, {emitEvent: false});
      this.activateTransferRequestAutoAcceptance.disable();
      this.daysForTransferRequestAutoAcceptance.disable();
    }
  }
}
