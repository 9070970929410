import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';

@Component({
  selector: 'bh-core-data',
  templateUrl: './core-data.component.html',
  styleUrls: ['./core-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreDataComponent implements OnInit {
  @Input() percentage = 0;

  public chartData: ChartData;
  public chartOptions: ChartOptions<'doughnut'> = {
    cutout: '85%',
    responsive: true,
    hover: {mode: null},
    elements: {
      line: {}
    },
    plugins: {
      legend: {display: false},
      tooltip: {enabled: false},
    },
  };
  private chartColors = ['#97c01c', '#E4F0BE'];

  public ngOnInit(): void {
    this.chartData = {
      datasets: [{
        data: [this.percentage, (100 - this.percentage)],
        backgroundColor: this.chartColors,
        borderColor: this.chartColors,
        borderWidth: 1,
      }],
    }
  }
}
