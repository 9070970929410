import { PrivilegedRole } from '../../../../shared/enums/privileged-roles.enum';
import { KeycloakService } from '../../../../core/keycloak';
import { EquipmentsDataSource } from '../../shared/equipments.datasource';
import { TelematicsUnitType } from '../../contract/telematics-unit-type.enum';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FieldLimit } from '../../../../shared/enums/fieldLimit.enum';
import { EquipmentTelematicsUnitTypeResolver } from '../../shared/services/equipment-telematics-unit-type.resolver';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ViewEquipment } from '../../contract/view-equipment.interface';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { TelematicsUnitTypeAssignment } from '../../contract/telematics-unit-type-assignment.enum';
import { AssignTelematicsUnitCommand } from '../../contract/assign-telematics-unit-command';
import { faRouter } from '@fortawesome/pro-duotone-svg-icons';
import { ViewCustomerTelematicUnit } from '../../shared/view-customer-telematic-unit.interface';
import { ViewTeltonikaUnit } from '../../shared/view-teltonika-unit.interface';
import * as moment from 'moment';
import { MatStepper } from '@angular/material/stepper';
import { debounceTime, distinctUntilChanged, finalize, first, mergeMap, switchMap, timeout } from 'rxjs/operators';
import { LanguageService } from '../../../../shared/services/language.service';
import { get, isNull, isUndefined } from 'lodash';
import { TeltonikaDatasource } from '../../shared/teltonika.datasource';
import { EMPTY, interval, Observable, of } from 'rxjs';
import { ViewEquipmentTelematicsData } from '../../contract/view-equipment-telematics-data.interface';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { OperatingHoursPipe } from '../../../../shared/pipes/operating-hours.pipe';
import { MileagePipe } from '../../../../shared/pipes/mileage.pipe';
import { VoltagePipe } from '../../../../shared/pipes/voltage.pipe';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { TelematicsUnitAssignmentInfo } from '../../contract/telematics-unit-assignment-info.interface';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { WarningDialogComponent } from 'app/shared/components/warning-dialog/warning-dialog.component';
import { ReassignTelematicsUnitCommand } from '../../contract/reassign-telematics-unit-command';
import { Modules } from '../../../../shared/enums/modules.enum';
import { DimensionUnitConverterPipe } from '../../../../shared/pipes/dimension-unit-converter.pipe';
import { ViewDigitalMatterUnit } from '../../shared/view-digital-matter.unit';
import { DigitalMatterDatasource } from '../../shared/digital-matter.datasource';
import { LatLonLocation } from '../../../../shared/contract/lat-lon-location.interface';
import { TelematicsLoadingState } from '../../shared/telematics-loading-state.enum';
import { DigitalMatterProfile } from '../../../../shared/assign-digital-matter-unit/digital-matter-profile';
import { DigitalMatterMobileSignalPipe } from '../../../../shared/pipes/digital-matter-mobile-signal.pipe';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from '../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../assets/matomo/matomo-actions.enum';
import { TelematicsService } from '../../shared/telematics.service';
import { DomSanitizer } from '@angular/platform-browser';


interface TelematicUnitFormGroup {
  telematicsUnitType: FormControl<TelematicsUnitTypeAssignment | null>,
  generalData: FormGroup<GeneralDataFormGroup>;
  profileData: FormGroup<ProfileDataFormGroup>;
  equipmentData: FormGroup<EquipmentDataFormGroup>,
  calibrateData: FormGroup<CalibrateDataFormGroup>,
}

interface GeneralDataFormGroup {
  telematicsUnitId: FormControl<string | null>,
  telematicsIdentificationType: FormControl<string>,
}

interface ProfileDataFormGroup {
  profileId: FormControl<string>
}


interface EquipmentDataFormGroup {
  batteryVoltage: FormControl<string>,
  mobileSignal: FormControl<string>,
  lastGPSFix: FormControl<string>,
  engineOperatingHours: FormControl<string>,
  digitalInputOne: FormControl<string>,
  digitalInputTwo: FormControl<string>,
  powerVoltage: FormControl<string>,
  gpsSignal: FormControl<string>,
  lastUnitData: FormControl<string>,
  currentStateMileage: FormControl<string>,
  satellites: FormControl<string>,
}

interface CalibrateDataFormGroup {
  operatingHours: FormControl<number>,
  mileAge: FormControl<number>,
}

@UntilDestroy()
@Component({
  selector: 'bh-equipment-telematics-unit-assign',
  templateUrl: './equipment-telematics-unit-assign.component.html',
  styleUrls: ['./equipment-telematics-unit-assign.component.scss'],
})
export class EquipmentTelematicsUnitAssignComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('stepper') private myStepper: MatStepper;

  @ViewChild('confidexMicroActivation') confidexMicroActivationIframe: ElementRef;

  public faRouter = faRouter;
  public equipment: ViewEquipment;
  public telematicsUnitForm: FormGroup<TelematicUnitFormGroup>;
  public telematicsLoadingState: TelematicsLoadingState = TelematicsLoadingState.READY;

  private teltonikaData: ViewTeltonikaUnit;
  private digitalMatterData: ViewDigitalMatterUnit;
  public locationsForMap: LatLonLocation[];
  public digitalMatterProfiles: DigitalMatterProfile[];
  public selectedLanguage: string;

  public teltonikaIds: Observable<ViewCustomerTelematicUnit[]> = this.equipmentStore.filteredTeltonikaIds;

  public teltonikaLoadingDate: Date;
  public digitalMatterLoadingDate: Date;

  public teltonikaTimeout;
  public digitalMatterTimeout;

  public teltonikaIdsFilter: FormControl<string> = new FormControl();

  public telematicsUnitOptions = this.getTelematicsUnitOptions();
  public readonly fieldLimit = FieldLimit;
  public calibrating = false;
  public buttonThrottle = 1000;

  public confidexMicroBeaconHasData = false;

  private readonly pollingDelay = 1000;
  private readonly pollingMaxAttempts = 10;

  public confidexMicroEmbeddedYoutubeUrl;

  private teltonikaTypesSet = new Set<TelematicsUnitTypeAssignment>([
    TelematicsUnitTypeAssignment.TELTONIKA_OBD_UNIT,
    TelematicsUnitTypeAssignment.TELTONIKA_CABLE_UNIT]);

  private digitalMatterTypesSet = new Set<TelematicsUnitTypeAssignment>([
    TelematicsUnitTypeAssignment.DIGITAL_MATTER_OYSTER3_UNIT]);

  private confidexTypesSet = new Set<TelematicsUnitTypeAssignment>([
    TelematicsUnitTypeAssignment.CONFIDEX_CLASSIC,
    TelematicsUnitTypeAssignment.CONFIDEX_ROUGH,
    TelematicsUnitTypeAssignment.CONFIDEX_MICRO]);

  constructor(public telematicsUnitResolver: EquipmentTelematicsUnitTypeResolver,
              private equipmentStore: EquipmentsDataSource,
              private dialogRef: MatDialogRef<EquipmentTelematicsUnitAssignComponent>,
              private authService: KeycloakService,
              protected languageService: LanguageService,
              private teltonikaDatasource: TeltonikaDatasource,
              private digitalMatterDatasource: DigitalMatterDatasource,
              private operatingHoursPipe: OperatingHoursPipe,
              private mileagePipe: MileagePipe,
              private digitalMatterMobileSignalPipe: DigitalMatterMobileSignalPipe,
              private voltagePipe: VoltagePipe,
              private dimensionUnitConverterPipe: DimensionUnitConverterPipe,
              private dialog: MatDialog,
              private matomoTracker: MatomoTracker,
              private telematicsService: TelematicsService,
              private cdref: ChangeDetectorRef,
              private domSanitizer: DomSanitizer
  ) {
    this.selectedLanguage = languageService.getCurrentLanguage();
    this.initializeEmbeddedConfidexMicroYoutubeUrl();
  }

  public get telematicsUnitTypeControl(): FormControl<TelematicsUnitTypeAssignment> {
    return this.telematicsUnitForm.controls.telematicsUnitType;
  }

  public get generalDataControl(): FormGroup<GeneralDataFormGroup> {
    return this.telematicsUnitForm.controls.generalData;
  }

  public get telematicsUnitIdControl(): FormControl<string> {
    return this.generalDataControl.controls.telematicsUnitId;
  }

  public get telematicsIdentificationTypeControl(): FormControl<string> {
    return this.generalDataControl.controls.telematicsIdentificationType;
  }

  public get equipmentDataControl(): FormGroup<EquipmentDataFormGroup> {
    return this.telematicsUnitForm.controls.equipmentData;
  }

  public get calibrateDataControl(): FormGroup<CalibrateDataFormGroup> {
    return this.telematicsUnitForm.controls.calibrateData;
  }

  public get profileDataControl(): FormGroup<ProfileDataFormGroup> {
    return this.telematicsUnitForm.controls.profileData;
  }

  public get selectedProfileIdControl(): FormControl<string> {
    return this.profileDataControl.controls.profileId;
  }

  public ngOnInit(): void {
    this.matomoTracker.trackEvent(matomoCategories.TELEMATIC, matomoActions.ASSIGN_TELEMATIC_UNIT);
    this.buildForm();
    this.telematicsUnitTypeListener();
    this.filterTeltonikaIdsOnInput();
    this.subscribeAllDigitalMatterProfiles();
    this.getAllDigitalMatterProfiles();
    this.telematicsDataListener();
  }

  public ngOnDestroy(): void {
    this.clearTeltonikaTimeout();
    this.clearDigitalMatterTimeout();
  }

  public saveDigitalMatter(): void {
    if (this.telematicsUnitForm.invalid) {
      return;
    }
    const telematicsUnitId = this.showRioSteps()
      ? this.telematicsIdentificationTypeControl.value + '##' + this.telematicsUnitIdControl.value
      : this.telematicsUnitIdControl.value;

    const profileId = this.showDigitalMatterSteps() ? this.selectedProfileIdControl.value : null;
    this.assignDigitalMatterUnit(telematicsUnitId, profileId);
  }

  public save(): void {
    if (this.telematicsUnitForm.invalid) {
      return;
    }

    const telematicsUnitId = this.showRioSteps()
      ? this.telematicsIdentificationTypeControl.value + '##' + this.telematicsUnitIdControl.value
      : this.telematicsUnitIdControl.value;

    if (!this.isBeacon()) {
      this.assignTelematicsUnit(telematicsUnitId);
    } else {
      this.equipmentStore.isBeaconInUse(telematicsUnitId, TelematicsUnitType[this.telematicsUnitTypeControl.value])
        .pipe(
          switchMap(assignmentInfo => this.checkAssignment(assignmentInfo)),
          untilDestroyed(this))
        .subscribe(assignmentInfo =>
          this.assignTelematicsUnit(telematicsUnitId, assignmentInfo?.assigned));
    }
  }

  private checkAssignment(assignmentInfo: TelematicsUnitAssignmentInfo): Observable<TelematicsUnitAssignmentInfo> {
    if (!Boolean(assignmentInfo) || !Boolean(assignmentInfo?.assigned)) {
      return of(assignmentInfo);
    }

    if (Boolean(assignmentInfo?.equipmentInfo)) {
      return this.openReassignConfirmationDialog(assignmentInfo);
    }
    return this.openReassignWarningDialog();
  }

  private openReassignConfirmationDialog(assignmentInfo: TelematicsUnitAssignmentInfo): Observable<TelematicsUnitAssignmentInfo> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.confirmMessage = this.languageService.getInstant(
      'modules.equipment.assignTelematicUnit.reassignConfirmationMessage',
      { value: this.getEquipmentFullName(assignmentInfo) });
    return dialogRef.afterClosed()
      .pipe(
        switchMap(dialogResult => dialogResult === dialogResults.YES ? of(assignmentInfo) : EMPTY),
        untilDestroyed(this));
  }

  private getEquipmentFullName(assignmentInfo: TelematicsUnitAssignmentInfo): string {
    return [
      assignmentInfo?.equipmentInfo?.equipmentName || assignmentInfo?.equipmentInfo?.equipmentModel,
      assignmentInfo?.equipmentInfo?.equipmentCustomerSerialNumber
    ]
      .filter(Boolean)
      .join('/');
  }

  private openReassignWarningDialog(): Observable<never> {
    const dialogRef = this.dialog.open(WarningDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.message = this.languageService.getInstant('modules.equipment.assignTelematicUnit.reassignmentRefused');
    return dialogRef.afterClosed()
      .pipe(switchMap(() => EMPTY), untilDestroyed(this));
  }

  private assignDigitalMatterUnit(telematicsUnitId: string, profileId: string = null, reassignment: boolean = false) {
    const cmd: AssignTelematicsUnitCommand = {
      equipmentId: this.equipment.equipmentId,
      telematicsUnitType: this.telematicsUnitTypeMapper(this.telematicsUnitTypeControl.value),
      telematicsUnitId: telematicsUnitId,
      calibratedMileage: this.dimensionUnitConverterPipe.toSystemDimensionUnit(this.calibrateDataControl.controls.mileAge.value, 'km'),
      calibratedOperatingHours: this.calibrateDataControl.controls.operatingHours.value,
      profileId: profileId,
    };

    (!reassignment
      ? this.equipmentStore.assignTelematicsUnit(cmd)
      : this.equipmentStore.reassignTelematicsUnit(this.toReassignTelematicsUnitCommand(cmd)))
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.dialogRef.close(result);
      });
  }

  public assignTelematicsUnit(telematicsUnitId: string, reassignment: boolean = false): void {

    const cmd: AssignTelematicsUnitCommand = {
      equipmentId: this.equipment.equipmentId,
      telematicsUnitType: this.telematicsUnitTypeMapper(this.telematicsUnitTypeControl.value),
      telematicsUnitId: telematicsUnitId,
      calibratedMileage: this.dimensionUnitConverterPipe.toSystemDimensionUnit(this.calibrateDataControl.controls.mileAge.value, 'km'),
      calibratedOperatingHours: this.calibrateDataControl.controls.operatingHours.value,
      profileId: null
    };
    (!reassignment
      ? this.equipmentStore.assignTelematicsUnit(cmd)
      : this.equipmentStore.reassignTelematicsUnit(this.toReassignTelematicsUnitCommand(cmd)))
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        const { currentOperatingHours, currentMileage } = this.equipment;

        if (this.showTeltonikaOrDigitalMatterSteps()
          && (currentOperatingHours !== Number(cmd.calibratedOperatingHours)
            || this.formatNumber(currentMileage) !== Number(cmd.calibratedMileage).toString())) {
          this.waitTelematicsChanges(result);
        } else {
          this.dialogRef.close(result);
        }
      });
  }

  private formatNumber(inputNumber: number): string {
    const roundedNumber: string = inputNumber.toFixed(2);
    return roundedNumber.endsWith('.00') ? roundedNumber : roundedNumber.replace(/\.?0*$/, '');
  }

  private toReassignTelematicsUnitCommand(cmd: AssignTelematicsUnitCommand): ReassignTelematicsUnitCommand {
    return {
      equipmentId: cmd.equipmentId,
      telematicsUnitId: cmd.telematicsUnitId,
      telematicsUnitType: cmd.telematicsUnitType,
      profileId: cmd.profileId
    };
  }

  private waitTelematicsChanges(result: any) {
    this.calibrating = true;
    const { equipmentId, currentOperatingHours, currentMileage } = this.equipment;

    const fetchEquipmentTelematicsData = () => {
      return this.equipmentStore.getEquipmentTelematicsData(equipmentId);
    };

    const isTelematicsDataChanged = (data: ViewEquipmentTelematicsData) => {
      return data.currentOperatingHours !== currentOperatingHours
        || data.currentMileage !== currentMileage;
    };
    of(this.equipment)
      .pipe(
        untilDestroyed(this),
        mergeMap(() =>
          interval(this.pollingDelay)
            .pipe(mergeMap(fetchEquipmentTelematicsData))
            .pipe(first(isTelematicsDataChanged))
            .pipe(timeout(this.pollingDelay * this.pollingMaxAttempts))
            .pipe(finalize(() => {
              this.equipmentStore.updateCurrentEquipment();
              this.dialogRef.close(result);
            })),
        )).subscribe();
  }

  public getTelematicUnitLogo(option: string): string {
    return this.telematicsUnitResolver.resolveLogo(option);
  }

  public getTelematicUnitName(option: string): string {
    return this.telematicsUnitResolver.resolveName(option);
  }

  private filterTeltonikaIdsOnInput(): void {
    this.teltonikaIdsFilter
      .valueChanges
      .pipe(debounceTime(150), distinctUntilChanged())
      .subscribe(filterTerm => this.equipmentStore.filterTeltonikaIds(filterTerm));
  }

  // private getTelematicsUnitOptions(): string[] { TODO Activate when Digital Matter Units are active for customers
  // See ticket https://onestop-pro.atlassian.net/browse/BEUT-25803
  //   return [
  //     TelematicsUnitTypeAssignment.TRACKUNIT_1_SPOT,
  //     TelematicsUnitTypeAssignment.TRACKUNIT_1_RAW,
  //     TelematicsUnitTypeAssignment.TELTONIKA_OBD_UNIT,
  //     TelematicsUnitTypeAssignment.TELTONIKA_CABLE_UNIT,
  //     TelematicsUnitTypeAssignment.CONFIDEX_ROUGH,
  //     TelematicsUnitTypeAssignment.CONFIDEX_MICRO,
  //     TelematicsUnitTypeAssignment.DIGITAL_MATTER_OYSTER3_UNIT,
  //     ...(this.authService.isInRole(PrivilegedRole.Flottenadmin)
  //       ? [TelematicsUnitTypeAssignment.SIGFOX_TRACKERTYPL26_1,
  //         TelematicsUnitTypeAssignment.AEMP,
  //         TelematicsUnitTypeAssignment.GENERIC_2,
  //         TelematicsUnitTypeAssignment.GENERIC_3,
  //         TelematicsUnitTypeAssignment.GENERIC_4,
  //         TelematicsUnitTypeAssignment.GENERIC_5,
  //         TelematicsUnitTypeAssignment.GPS_OVER_IP,
  //         TelematicsUnitTypeAssignment.RIO,
  //       ]
  //       : []),
  //   ];
  // }

  private getTelematicsUnitOptions(): string[] {
    if (this.authService.hasModule(Modules.WIP_DIGITAL_MATTER_TRACKER)) {
      return [
        TelematicsUnitTypeAssignment.TRACKUNIT_1_SPOT,
        TelematicsUnitTypeAssignment.TRACKUNIT_1_RAW,
        TelematicsUnitTypeAssignment.TELTONIKA_OBD_UNIT,
        TelematicsUnitTypeAssignment.TELTONIKA_CABLE_UNIT,
        TelematicsUnitTypeAssignment.CONFIDEX_ROUGH,
        TelematicsUnitTypeAssignment.CONFIDEX_MICRO,
        TelematicsUnitTypeAssignment.DIGITAL_MATTER_OYSTER3_UNIT,
        ...(this.authService.isInRole(PrivilegedRole.Flottenadmin)
          ? [TelematicsUnitTypeAssignment.SIGFOX_TRACKERTYPL26_1,
            TelematicsUnitTypeAssignment.AEMP,
            TelematicsUnitTypeAssignment.GENERIC_2,
            TelematicsUnitTypeAssignment.GENERIC_3,
            TelematicsUnitTypeAssignment.GENERIC_4,
            TelematicsUnitTypeAssignment.GENERIC_5,
            TelematicsUnitTypeAssignment.GPS_OVER_IP,
            TelematicsUnitTypeAssignment.RIO,
          ]
          : []),
      ];
    } else {
      return [
        TelematicsUnitTypeAssignment.TRACKUNIT_1_SPOT,
        TelematicsUnitTypeAssignment.TRACKUNIT_1_RAW,
        TelematicsUnitTypeAssignment.TELTONIKA_OBD_UNIT,
        TelematicsUnitTypeAssignment.TELTONIKA_CABLE_UNIT,
        TelematicsUnitTypeAssignment.CONFIDEX_ROUGH,
        TelematicsUnitTypeAssignment.CONFIDEX_MICRO,
        ...(this.authService.isInRole(PrivilegedRole.Flottenadmin)
          ? [TelematicsUnitTypeAssignment.SIGFOX_TRACKERTYPL26_1,
            TelematicsUnitTypeAssignment.AEMP,
            TelematicsUnitTypeAssignment.GENERIC_2,
            TelematicsUnitTypeAssignment.GENERIC_3,
            TelematicsUnitTypeAssignment.GENERIC_4,
            TelematicsUnitTypeAssignment.GENERIC_5,
            TelematicsUnitTypeAssignment.GPS_OVER_IP,
            TelematicsUnitTypeAssignment.RIO,
          ]
          : []),
      ];
    }
  }

  private getAllDigitalMatterProfiles() {
    this.digitalMatterDatasource.fetchAllDigitalMatterProfiles();
  }

  private subscribeAllDigitalMatterProfiles() {
    this.digitalMatterDatasource.digitalMatterProfiles.pipe(untilDestroyed(this))
      .subscribe({
        next: profileList => {
          this.digitalMatterProfiles = profileList;
          this.setDefaultProfileId();
          this.ensureSelectedLanguageExistsInAllProfiles(profileList);
        },
        error: () => this.digitalMatterProfiles = null
      });
  }

  private ensureSelectedLanguageExistsInAllProfiles(profileList: DigitalMatterProfile[]): void {
    const profileWithoutTranslation =
      profileList?.find(profile => !profile.translations.has(this.selectedLanguage));
    if (profileWithoutTranslation) {
      this.selectedLanguage = 'en-US';
    }
  }

  private buildForm(): void {
    this.telematicsUnitForm = new FormGroup<TelematicUnitFormGroup>({
      telematicsUnitType: new FormControl(null, Validators.required),
      generalData: new FormGroup({
        telematicsUnitId: new FormControl(null, Validators.required),
        telematicsIdentificationType: new FormControl(null),
      }),
      profileData: new FormGroup({
        profileId: new FormControl(null)
      }),
      equipmentData: new FormGroup({
        batteryVoltage: new FormControl({ value: '', disabled: true }),
        mobileSignal: new FormControl({ value: '', disabled: true }),
        lastGPSFix: new FormControl({ value: '', disabled: true }),
        engineOperatingHours: new FormControl({ value: '', disabled: true }),
        digitalInputOne: new FormControl({ value: '', disabled: true }),
        digitalInputTwo: new FormControl({ value: '', disabled: true }),
        powerVoltage: new FormControl({ value: '', disabled: true }),
        gpsSignal: new FormControl({ value: '', disabled: true }),
        lastUnitData: new FormControl({ value: '', disabled: true }),
        currentStateMileage: new FormControl({ value: '', disabled: true }),
        satellites: new FormControl({ value: '', disabled: true }),
      }),
      calibrateData: new FormGroup({
        operatingHours: new FormControl(
          this.equipment.currentOperatingHours || 0,
          [Validators.required, Validators.min(0)]),
        mileAge: new FormControl(
          this.mileageToUserDimensionUnit(this.equipment.currentMileage || 0),
          [Validators.required, Validators.min(0)]),
      })
    });
  }

  private telematicsUnitTypeMapper(assignmentType: TelematicsUnitTypeAssignment): TelematicsUnitType {
    return [
      TelematicsUnitTypeAssignment.TRACKUNIT_1_RAW,
      TelematicsUnitTypeAssignment.TRACKUNIT_1_SPOT,
    ].some(type => type === assignmentType)
      ? TelematicsUnitType.TRACKUNIT_1
      : TelematicsUnitType[assignmentType];
  }

  private telematicsUnitTypeListener(): void {
    this.telematicsUnitTypeControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((type: TelematicsUnitTypeAssignment) => {
        this.resetFormAfterUnitTypeChange();
        this.changeValidationIdentificationType(type);
        this.changeValidatorUnitId(type);
        this.setControlStatus(this.calibrateDataControl, this.isTeltonikaOrDigitalMatterUnitType(type));
      });
  }

  private resetFormAfterUnitTypeChange(): void {
    this.telematicsUnitIdControl.reset();
  }

  private changeValidationIdentificationType(type: TelematicsUnitTypeAssignment): void {
    const validators: ValidatorFn[] = [];
    if (type === TelematicsUnitTypeAssignment.RIO) {
      validators.push(Validators.required);
    }
    this.telematicsIdentificationTypeControl.clearValidators();
    this.telematicsIdentificationTypeControl.setValidators(validators);
    this.telematicsIdentificationTypeControl.updateValueAndValidity();
  }

  private changeValidatorUnitId(type: TelematicsUnitTypeAssignment): void {
    const validators: ValidatorFn[] = [Validators.required];
    if (this.isConfidexBeaconType(type)) {
      validators.push(Validators.minLength(FieldLimit.SHORTER_IDENTIFIER));
      validators.push(Validators.maxLength(FieldLimit.SHORTER_IDENTIFIER));
      validators.push(Validators.pattern('^[A-F0-9]+$'));
    }
    this.telematicsUnitIdControl.clearValidators();
    this.telematicsUnitIdControl.setValidators(validators);
    this.telematicsUnitIdControl.updateValueAndValidity();
  }

  private setControlStatus(control: AbstractControl, enabled: boolean): void {
    if (enabled) {
      control.enable();
    } else {
      control.disable();
    }
  }

  private isTeltonikaOrDigitalMatterUnitType(type: TelematicsUnitTypeAssignment): boolean {
    return this.isTeltonikaUnitType(type)
      || this.isDigitalMatterUnitType(type);
  }

  private isTeltonikaUnitType(type: TelematicsUnitTypeAssignment): boolean {
    return this.teltonikaTypesSet.has(type);
  }

  private isConfidexMicroBeaconType(type: TelematicsUnitTypeAssignment): boolean {
    return TelematicsUnitTypeAssignment.CONFIDEX_MICRO === type;
  }

  private isDigitalMatterUnitType(type: TelematicsUnitTypeAssignment): boolean {
    return this.digitalMatterTypesSet.has(type);
  }


  private isConfidexBeaconType(type: TelematicsUnitTypeAssignment): boolean {
    return this.confidexTypesSet.has(type);
  }

  public isConfidexBeacon(): boolean {
    return this.isConfidexBeaconType(this.telematicsUnitTypeControl.value);
  }

  public showTeltonikaOrDigitalMatterSteps() {
    return this.isTeltonikaOrDigitalMatterUnitType(this.telematicsUnitTypeControl.value);
  }

  public showDigitalMatterSteps() {
    return this.isDigitalMatterUnitType(this.telematicsUnitTypeControl.value);
  }

  public showTeltonikaSteps() {
    return this.isTeltonikaUnitType(this.telematicsUnitTypeControl.value);
  }

  public showRioSteps() {
    return this.telematicsUnitTypeControl
      && this.telematicsUnitTypeControl.value === TelematicsUnitTypeAssignment.RIO;
  }

  public showConfidexMicroExplanationSteps() {
    return this.isConfidexMicroBeaconType(this.telematicsUnitTypeControl.value) && !this.confidexMicroBeaconHasData;
  }

  public beaconHasData() {
    if (!this.isConfidexMicroBeaconType(this.telematicsUnitTypeControl.value)) {
      return;
    }
    const beaconId = this.telematicsUnitIdControl?.value;
    this.telematicsService.beaconHasData(beaconId).subscribe(result => {
      this.confidexMicroBeaconHasData = result
    });
  }

  public getTelematicsIdentificationTypes(): string[] {
    return ['VIN']
  }

  public getTelematicsIdentifierLabel(): string {
    if (this.showRioSteps()) {
      if (this.telematicsIdentificationTypeControl.value === 'VIN') {
        return 'VIN'
      }
    }

    return this.languageService.getInstant('modules.equipment.base.serialNumber')
  }

  public getTelematicsUnitNumbers() {
    const unitType = this.telematicsUnitTypeMapper(this.telematicsUnitTypeControl.value);
    this.equipmentStore.loadTeltonikaIds(unitType);
  }

  public trackMatomoEvent_Telematic_SelectedUnitType() {
    const unitType = this.telematicsUnitTypeMapper(this.telematicsUnitTypeControl.value);
    this.matomoTracker.trackEvent(matomoCategories.TELEMATIC, matomoActions.SELECTED_TYPE + unitType);
  }

  public onStepChange(event: StepperSelectionEvent) {
    if (this.isFirstStep(event.previouslySelectedIndex)) {
      this.getTelematicsUnitNumbers();
    }
    if (this.isThirdStep(event.previouslySelectedIndex)) {
      clearInterval(this.teltonikaTimeout);
    }
  }

  private isThirdStep(index: number) {
    return index === 2;
  }

  private isFirstStep(index: number) {
    return index === 0;
  }

  private telematicsDataListener() {
    this.teltonikaDataListener();
    this.digitalMatterDataListener();
  }

  private teltonikaDataListener(): void {
    this.teltonikaDatasource.teltonikaDataLoading
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        this.telematicsLoadingState = state;
        if (state === TelematicsLoadingState.ERROR) {
          this.clearTeltonikaTimeout()
        }
      });
    this.teltonikaDatasource.teltonikaData.pipe(untilDestroyed(this)).subscribe(data => {
      this.teltonikaData = data;
      this.locationsForMap = this.teltonikaData ? [this.teltonikaData] : undefined;
      this.teltonikaLoadingDate = new Date();
      this.fillTeltonikaForm();
    });
  }

  private digitalMatterDataListener(): void {
    this.digitalMatterDatasource.digitalMatterDataLoading
      .pipe(untilDestroyed(this))
      .subscribe(state => {
        this.telematicsLoadingState = state;
        if (state === TelematicsLoadingState.ERROR) {
          this.clearDigitalMatterTimeout();
        }
      });
    this.digitalMatterDatasource.digitalMatterData.pipe(untilDestroyed(this)).subscribe(data => {
      this.digitalMatterData = data;
      this.locationsForMap = this.digitalMatterData ? [this.digitalMatterData] : undefined;
      this.digitalMatterLoadingDate = new Date();
      this.fillDigitalMatterForm();
    });
  }

  public setDefaultProfileId() {
    if (this.selectedProfileIdControl.value || !this.digitalMatterProfiles || this.digitalMatterProfiles.length === 0) {
      return;
    }
    let profile = this.digitalMatterProfiles.find(profileArray =>
      profileArray.translations?.get('en-US')?.displayName === 'Standard');
    profile = profile ?? this.digitalMatterProfiles[0];
    this.selectedProfileIdControl.setValue(profile?.profileId);
  }

  public fetchClearData() {
    if (this.showTeltonikaSteps()) {
      this.fetchClearTeltonikaData();
    } else {
      this.fetchClearDigitalMatterData();
    }
  }

  public fetchClearTeltonikaData() {
    this.teltonikaData = undefined;
    this.locationsForMap = undefined;
    this.teltonikaLoadingDate = undefined;
    this.telematicsLoadingState = undefined;

    this.cleanTeltonikaForm();
    this.updateTeltonikaData();
  }

  public fetchClearDigitalMatterData() {
    this.digitalMatterData = undefined;
    this.locationsForMap = undefined;
    this.digitalMatterLoadingDate = undefined;
    this.telematicsLoadingState = undefined;

    this.cleanDigitalMatterForm();
    this.updateDigitalMatterData();
  }

  private updateTeltonikaData() {
    this.clearTeltonikaTimeout();
    this.triggerTeltonikaLiveDataUpdate();
    this.teltonikaTimeout = setInterval(() => this.triggerTeltonikaLiveDataUpdate(), 10000)
    this.myStepper.selected.completed = true;
  }

  private updateDigitalMatterData() {
    this.clearDigitalMatterTimeout();
    this.triggerDigitalMatterLiveDataUpdate();
    this.digitalMatterTimeout = setInterval(() => this.triggerDigitalMatterLiveDataUpdate(), 10000)
    this.myStepper.selected.completed = true;
  }

  private triggerTeltonikaLiveDataUpdate() {
    if (!this.isTeltonikaUnitType(this.telematicsUnitTypeControl.value)) {
      return;
    }
    this.teltonikaDatasource.fetchTeltonikaLiveData(this.telematicsUnitIdControl.value);
  }

  private triggerDigitalMatterLiveDataUpdate() {
    if (!this.isDigitalMatterUnitType(this.telematicsUnitTypeControl.value) || !this.telematicsUnitIdControl.value) {
      return;
    }
    this.digitalMatterDatasource.fetchDigitalMatterLiveData(this.telematicsUnitIdControl.value);
  }

  private cleanTeltonikaForm() {
    this.equipmentDataControl.patchValue({
      batteryVoltage: '',
      mobileSignal: '',
      lastGPSFix: '',
      engineOperatingHours: '',
      digitalInputOne: '',
      digitalInputTwo: '',
      powerVoltage: '',
      gpsSignal: '',
      lastUnitData: '',
      currentStateMileage: '',
      satellites: ''
    })
  }

  private cleanDigitalMatterForm() {
    this.equipmentDataControl.patchValue({
      lastGPSFix: '',
      lastUnitData: '',
      mobileSignal: ''
    })
  }

  private fillTeltonikaForm() {
    this.equipmentDataControl.patchValue({
      batteryVoltage: this.getTeltonikaValue('batteryVoltage',
        val => this.voltagePipe.transform(val)),
      mobileSignal: this.getTeltonikaValue('gsmSignal'),
      lastGPSFix: this.getTeltonikaValue('lastGpsDate',
        val => moment(val).format('DD.MM.YYYY HH:mm:ss')),
      engineOperatingHours: this.getTeltonikaValue('cumulativeOperatingHours',
        val => this.operatingHoursPipe.transform(val)),
      digitalInputOne: this.getTeltonikaValue('digitalInput1',
        val => val === 1 ? this.getSwitchOnString() : this.getSwitchOffString()),
      digitalInputTwo: this.getTeltonikaValue('digitalInput2',
        val => val === 1 ? this.getSwitchOnString() : this.getSwitchOffString()),
      powerVoltage: this.getTeltonikaValue('powerSupplyVoltage',
        val => this.voltagePipe.transform(val)),
      gpsSignal: this.getTeltonikaValue('gnssStatus'),
      lastUnitData: this.getTeltonikaValue('lastUnitDate',
        val => moment(val).format('DD.MM.YYYY HH:mm:ss')),
      currentStateMileage: this.getTeltonikaValue('distanceOdometerInKm',
        val => this.mileagePipe.transform(this.mileageToUserDimensionUnit(val))),
      satellites: this.getTeltonikaValue('satellites'),
    });
  }

  private fillDigitalMatterForm() {
    this.equipmentDataControl.patchValue({
      lastGPSFix: this.getDigitalMatterValue('lastGpsDate',
        val => moment(val).format('DD.MM.YYYY HH:mm:ss')),
      lastUnitData: this.getDigitalMatterValue('lastUnitDate',
        val => moment(val).format('DD.MM.YYYY HH:mm:ss')),
      mobileSignal: this.getDigitalMatterValue('gsmSignal',
        val => this.digitalMatterMobileSignalPipe.transform(val)),
    });
  }

  private getTeltonikaValue(key: string, mapper: (val: any) => any = (val) => val): any {
    let value = get(this.teltonikaData, key);
    if (!isUndefined(value) && !isNull(value)) {
      return mapper(get(this.teltonikaData, key));
    }

    return this.getDataNotAvailableString();
  }

  private getDigitalMatterValue(key: string, mapper: (val: any) => any = (val) => val): any {
    let value = get(this.digitalMatterData, key);
    if (!isUndefined(value) && !isNull(value)) {
      return mapper(get(this.digitalMatterData, key));
    }

    return this.getDataNotAvailableString();
  }

  private getSwitchOnString(): string {
    return this.languageService.getInstant('general.switch.on');
  }

  private getSwitchOffString(): string {
    return this.languageService.getInstant('general.switch.off');
  }

  private getDataNotAvailableString(): string {
    return this.languageService.getInstant('modules.equipment.assignTelematicUnit.dataNotAvailable');
  }

  public isTelematicReady(): boolean {
    return this.telematicsLoadingState === TelematicsLoadingState.READY
  }

  public isTelematicLoading(): boolean {
    return this.telematicsLoadingState === TelematicsLoadingState.LOADING
  }

  public isTelematicError(): boolean {
    return this.telematicsLoadingState === TelematicsLoadingState.ERROR
  }

  public getRefreshButtonTooltip(): string {
    if (this.isTelematicReady()) {
      return this.languageService.getInstant('modules.equipment.assignTelematicUnit.lastUpdate', {
        date: moment(this.teltonikaLoadingDate).format('DD.MM.YYYY HH:mm:ss')
      });
    } else if (this.isTelematicError()) {
      return this.languageService.getInstant('modules.equipment.assignTelematicUnit.errorUpdate', {
        date: moment(this.teltonikaLoadingDate).format('DD.MM.YYYY HH:mm:ss')
      });
    }
  }

  private isBeacon(): boolean {
    return this.telematicsUnitTypeControl
      && (this.telematicsUnitTypeControl.value === TelematicsUnitTypeAssignment.CONFIDEX_CLASSIC
        || this.telematicsUnitTypeControl.value === TelematicsUnitTypeAssignment.CONFIDEX_ROUGH
        || this.telematicsUnitTypeControl.value === TelematicsUnitTypeAssignment.CONFIDEX_MICRO);
  }

  private mileageToUserDimensionUnit(mileage): number {
    return this.dimensionUnitConverterPipe.toUserDimensionUnit(mileage || 0, 'km');
  }


  private clearTeltonikaTimeout(): void {
    if (this.teltonikaTimeout) {
      clearTimeout(this.teltonikaTimeout);
    }
  }

  private clearDigitalMatterTimeout(): void {
    if (this.digitalMatterTimeout) {
      clearTimeout(this.digitalMatterTimeout);
    }
  }

  trackMatomoEvent_Telematic_ProfileSelection() {
    const profileDisplayName = this.digitalMatterProfiles.find(profile =>
      profile.profileId === this.profileDataControl.value.profileId).translations.get('en-US').displayName
    this.matomoTracker.trackEvent(matomoCategories.TELEMATIC, matomoActions.PROFILE_SELECTED_TYPE + profileDisplayName);
  }

  trackMatomoEvent_Telematic_NextStep(generalData: string) {
    this.matomoTracker.trackEvent(matomoCategories.TELEMATIC, matomoActions.NEXT_STEP + generalData);
  }

  trackMatomoEvent_Telematic_StepBack(generalData: string) {
    this.matomoTracker.trackEvent(matomoCategories.TELEMATIC, matomoActions.BACK_STEP + generalData);
  }

  onStepBackToUnitTypeSelection() {
    this.telematicsUnitIdControl.reset();
    this.resetConfidexMicroBeaconHasData();
    this.trackMatomoEvent_Telematic_StepBack('unit-type-selection');
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  resetConfidexMicroBeaconHasData(): void {
    this.confidexMicroBeaconHasData = false;
  }

  private initializeEmbeddedConfidexMicroYoutubeUrl(): any {
    this.confidexMicroEmbeddedYoutubeUrl =
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.getConfidexActivationEmbeddedUrlWithEnabledApiControl());
  }

  private getConfidexActivationEmbeddedUrlWithEnabledApiControl(): string {
    // needed to be able to use the command to pause the video
    return this.languageService.getInstant('modules.equipment.assignTelematicUnit.confidexMicroExplanation.youtubeEmbeddedLink') +
      '&enablejsapi=1';
  }

  pauseConfidexActivationVideo(): void {
    this.confidexMicroActivationIframe?.nativeElement?.contentWindow?.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
  }
}
