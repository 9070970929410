import { GeofenceLocationType } from '../../../shared/enums/geofence-location-type.enum';

export class CreateGeofenceCommand {
  locationId: string;
  locationType: GeofenceLocationType;
  geofenceName: string;
  geofenceColor: string;
  coordinates: { lat: number, lon: number }[];
  isDispositionGeofence: boolean;
}
