import { Injectable } from '@angular/core';
import { LocationTypePipe } from 'app/modules/maintenance/shared/pipes/location-type.pipe';
import { EquipmentLocationsFilterViewConverter } from 'app/shared/contract/filter/filter-converter/equipment-locations-filter-view-converter.class';
import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { GroupedEquipmentLocationFilterView } from 'app/shared/contract/filter/filter-view/grouped-equipment-location-filter-view.interface';

@Injectable()
export class EquipmentLocationsFilterViewConverterFactoryService {

  constructor(protected locationTypePipe: LocationTypePipe) {
  }

  public createEquipmentLocationsFilterViewConverter(originalFilters: GroupedEquipmentLocationFilterView[], selectedValues: FilterParams) {
    return new EquipmentLocationsFilterViewConverter(originalFilters, selectedValues, this.locationTypePipe);
  }
}
