import { Injectable } from '@angular/core';
import { LanguageService } from 'app/shared/services/language.service';
import { AutomatedTransfersStatusList } from 'app/shared/enums/automated-transfers-status-list.enum';

@Injectable()
export class AutomatedTransfersStatusResolver {

  constructor(private languageService: LanguageService) { }

  public resolveName(status: string): string {
    switch (status) {
      case AutomatedTransfersStatusList.ACTIVE:
        return this.languageService.getInstant('general.statuses.active');
      case AutomatedTransfersStatusList.INACTIVE:
        return this.languageService.getInstant('general.statuses.inactive');
      case AutomatedTransfersStatusList.BLOCKED:
        return this.languageService.getInstant('general.statuses.blocked');
      default:
        return null;
    }
  }
}
