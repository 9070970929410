<mat-expansion-panel #expasionPanel
                     [hideToggle]="hideToggleButton && !expasionPanel.expanded"
                     (afterExpand)="getEventDocuments()"
                     (afterCollapse)="afterPanelCollapse()"
                     (closed)="clearEventDocuments()"
                     [disabled]="hideToggleButton && !expasionPanel.expanded">
  <mat-expansion-panel-header collapsedHeight="140px"
                              [ngClass]="{ 'no-details': (hideToggleButton && !expasionPanel.expanded) }">

    <mat-panel-title class="title">
      <div class="title-content title-content__main"
           [ngClass]="{ 'title-content__without-details': hideToggleButton && !expasionPanel.expanded }">
        <div class="title-block" fxFlex="0 0 66%" fxLayoutAlign="start center" fxLayoutGap="5px">
          <div class="title-block__header"
            [fxFlex]="lifeCycle.lifeCycleType === lifeCycleType.COMPLETED_MAINTENANCE_TASK ? '0 0 50%' : '0 0 100%'"
            fxLayoutAlign="start center">
            <mat-icon *ngIf="useCustomFontIcon(lifeCycle)" fontSet="{{eventIcon}}"></mat-icon>
            <fa-icon *ngIf="!useCustomFontIcon(lifeCycle)" [icon]="eventIcon"></fa-icon>
            <span>{{lifeCycle | lifeCycleTitle}}</span>
          </div>
          <bh-maintenance-result-badge *ngIf="lifeCycle.lifeCycleType === lifeCycleType.COMPLETED_MAINTENANCE_TASK"
            fxLayoutAlign="start center"
            [result]="lifeCycle.maintenanceResult">
          </bh-maintenance-result-badge>
        </div>

        <div class="title-block title-block__last"
             fxFlex="0 0 34%;" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="title-block__log-date">
            {{lifeCycle.logDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy'} }}
          </div>
          <div class="title-block__controls" fxLayout="row" fxLayoutAlign="center center">
            <span [matTooltip]="'modules.equipment.equipmentDetailTimeline.noChangesForInactive'|translate"
                  [matTooltipDisabled]="!editDisabled">
            <button mat-icon-button
                    *ngIf="editAllowed"
                    [disabled]="editDisabled"
                    (click)="$event.stopPropagation(); editLifecycle(lifeCycle)">
              <mat-icon>edit</mat-icon>
            </button>
            </span>

            <span [matTooltip]="'modules.equipment.equipmentDetailTimeline.noChangesForInactive'|translate"
                  [matTooltipDisabled]="!deleteDisabled">
            <button mat-icon-button
                    *ngIf="deleteAllowed"
                    [disabled]="deleteDisabled"
                    (click)="$event.stopPropagation(); deleteLifecycle(lifeCycle)">
              <mat-icon>delete</mat-icon>
            </button>
            </span>
          </div>
        </div>
      </div>

    </mat-panel-title>
    <mat-panel-description class="title">
      <div class="title-content title-content__secondary"
           [ngClass]="{ 'title-content__without-details': hideToggleButton && !expasionPanel.expanded }">
        <div class="title-block" fxFlex="0 0 33%;">
          <div class="title-block__submit-info">
            <ng-container
              *ngIf="(lifeCycle.submitterInfo ? (lifeCycle.submitterInfo | submitterInfo) : lifeCycle.submittedBy) as submitter">
              <span data-osp-test="event-submitter-name">
                {{'general.recorded'|translate|lowercase}}
                {{'general.by'|translate}}:
                {{submitter}}
              </span>
            </ng-container>

            <br/>
            {{'general.on'|translate}}:
            {{lifeCycle.submitDate | formatTimezoneDateTime: {dateFormat: 'dddd, D. MMM yyyy HH:mm:ss'} }}
          </div>
        </div>

        <div class="title-block" fxFlex="0 0 33%;">
          <div class="title-block__event-header" *ngFor="let headers of eventHeaders">
            <span class="title-block__event-header__first">{{headers[0]}}</span>
            {{headers[1]}}
          </div>
        </div>

        <div class="title-block title-block__last" fxFlex="0 0 33%;">
          <div *ngIf="eventDescription" #descriptionElem
            class="title-block__event-description"
            bhMultiline
            [rows]="4"
            [disableMultiline]="expasionPanel.expanded">
            <span class="title-block__event-description__first">{{eventDescription[0]}}</span>
            {{eventDescription[1]}}
        </div>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent *ngIf="hasDetails">
    <div class="event-body">
      <div class="event-details">
        <div class="event-details__field"
             fxFlex="0 0 33%;"
             *ngFor="let details of filterEventDetails(eventDetails); trackBy: eventDetailsTrackBy">
          <mat-form-field floatLabel="always">
            <mat-label>{{details[0]}}</mat-label>
            <input matInput
                   readonly
                   value="{{details[1]}}">
          </mat-form-field>
        </div>
      </div>

      <div class="event-life-cycle" [ngSwitch]="lifeCycle.lifeCycleType">
        <bh-cost-event *ngSwitchCase="lifeCycleType.EQUIPMENT_COST_EVENT"
                       [equipmentId]="equipmentId"
                       [costLifeCycle]="lifeCycle">
        </bh-cost-event>
        <bh-task-event *ngSwitchCase="lifeCycleType.COMPLETED_MAINTENANCE_TASK"
                       [maintenanceTaskLifeCycle]="lifeCycle">
        </bh-task-event>
        <ng-container *ngSwitchCase="lifeCycleType.EQUIPMENT_DAMAGE_EVENT">
          <bh-damage-event *ngIf="lifeCycle?.equipmentCostId"
                           [equipmentId]="equipmentId"
                           [damageLifeCycle]="lifeCycle">
          </bh-damage-event>
        </ng-container>
      </div>

      <div class="attachments" *ngIf="hasAttachments">
        <bh-contract-attachments
          [documents]="lcDocuments.asObservable() | async">
        </bh-contract-attachments>
        <div class="attachments__spinner" *ngIf="attachmentsLoading">
          <mat-progress-spinner color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </div>
      </div>
    </div>
  </ng-template>
</mat-expansion-panel>
