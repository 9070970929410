import { environment } from 'environments/environment';
import { LanguageService } from 'app/shared/services/language.service';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StockStore } from '../../shared/stock.store';
import { ViewStock } from '../../../contract/view-stock';
import { RoleAuthorityGuardsComponent } from '../../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../../core/keycloak';
import { Authorities } from '../../../../../shared/enums/authorities.enum';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faBoxesAlt, faExchange } from '@fortawesome/pro-light-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { StockAddEditComponent } from '../../stock-add-edit/stock-add-edit.component';
import { delay } from 'rxjs/operators';
import { DeleteStockCommand } from '../../../contract/delete-stock-command';
import { CheckboxConfirmDialogComponent } from '../../../../../shared/components/checkbox-confirm-dialog/checkbox-confirm-dialog.component';
import { dialogResults } from '../../../../../shared/enums/dialogResults.enum';
import { StockType } from '../../../../../shared/enums/stock-type';
import { faGlobe } from '@fortawesome/pro-solid-svg-icons';
import { SubMenuTab } from 'app/modules/disposition/shared/interfaces/sub-menu-tab.interface';
import { StockSubMenuLink } from 'app/shared/enums/stock-sub-menu-link.enum';
import { SubmenuTabsResizeHandler } from 'app/shared/submenu-tabs-resize-handler';
import { MatMenuTrigger } from '@angular/material/menu';

@UntilDestroy()
@Component({
  selector: 'bh-stock-view',
  templateUrl: './stock-view.component.html',
  styleUrls: ['./stock-view.component.scss']
})
export class StockViewComponent extends RoleAuthorityGuardsComponent implements OnInit, OnDestroy, AfterViewInit {

  public readonly faGlobe: IconDefinition = faGlobe;
  public readonly faExchange: IconDefinition = faExchange;

  public authorities = Authorities;
  public faBoxesAlt: IconDefinition = faBoxesAlt;
  public stock: ViewStock;
  public deleteDisabled = false;
  public isGeofenceAvailableType = false;
  public readonly subMenuRouterLinks = StockSubMenuLink;

  private submenuHandler: SubmenuTabsResizeHandler;

  @ViewChild('submenu', { static: true }) submenu: ElementRef;
  @ViewChild('menuTrigger') trigger: MatMenuTrigger;

  public hiddenTabs: SubMenuTab[] = [];
  public subMenuTabs: SubMenuTab[] = [
    {
      name: this.translate('general.generalData'),
      url: StockSubMenuLink.GENERAL,
      matIconFontSet: 'icon-general_outline',
    },
    {
      name: this.translate('modules.disposition.projectView.geofences'),
      url: StockSubMenuLink.GEOFENCES,
      matIconFontSet: 'icon-geofences_outline',
    },
    {
      name: this.translate('general.attachment.pl'),
      url: StockSubMenuLink.ATTACHMENTS,
      matIconFontSet: 'icon-attachment_outline',
    },
    {
      name: this.translate('modules.disposition.base.inventory.s'),
      url: StockSubMenuLink.INVENTORY,
      faIcon: faBoxesAlt,
    },
    {
      name: this.translate('modules.disposition.projectView.transfers'),
      url: StockSubMenuLink.TRANSFER,
      faIcon: faExchange,
    }
  ];

  constructor(public stockStore: StockStore,
              private dialog: MatDialog,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              private languageService: LanguageService,
              private router: Router,
  ) {
    super(authService);
  }

  public ngOnInit(): void {
    this.routeParamsSubscription();
    this.selectedStockSubscription();
    this.selectedStockDeletableSubscription();
  }

  ngAfterViewInit(): void {
    this.submenuHandler = new SubmenuTabsResizeHandler(this.submenu, this.subMenuTabs);
    const resizeObserver = new ResizeObserver(() => {
      this.hiddenTabs = this.submenuHandler.onSubMenuResize();
      if (!this.hiddenTabs.length) {
        this.trigger.closeMenu();
      }
    });
    resizeObserver.observe(this.submenu.nativeElement);
  }

  public isTabMoreMenuActive(): boolean {
    const tabsMoreMenuPaths = this.hiddenTabs.map(tab => tab.url);
    const currentTabPath = this.route.snapshot.firstChild && this.route.snapshot.firstChild.url[0].path;
    return tabsMoreMenuPaths.includes(currentTabPath);
  }

  public delete(): void {
    const dialogRef = this.dialog.open(CheckboxConfirmDialogComponent);
    dialogRef.componentInstance.confirmMessage = this.translate('modules.disposition.confirmation.message.stockRemove');
    dialogRef.componentInstance.secondaryConfirmMessage = this.translate('modules.disposition.confirmation.message.stockRemoveSecondary');
    dialogRef.componentInstance.buttonMessage = this.translate('general.buttons.delete');

    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        this.stockStore.delete(new DeleteStockCommand(this.stock.stockId));
      }
    });
  }

  public edit(): void {
    const dialogRef = this.dialog.open(StockAddEditComponent, {restoreFocus: false});
    dialogRef.componentInstance.stock = this.stock;
    dialogRef.afterClosed().pipe(delay(environment.DELAY_SHORT)).subscribe(() => {
      this.stockStore.selectStock(this.stock.stockId);
      this.stockStore.update();
    });
  }

  public canDelete(): boolean {
    return this.hasAuthority(this.authorities.STOCK_MANAGE)
      && !this.deleteDisabled
      && this.stock.stockType !== StockType.MAIN;
  }

  public noSpaces(name: string): boolean {
    return name && !name.includes(' ');
  }

  public setTab(tab: string): void {
    this.stockStore.changeTab(tab);
  }

  public showMoreMenu(): boolean {
    return this.hasAnyAuthority([
      this.authorities.STOCK_VIEW,
      this.authorities.STOCK_MANAGE
    ]);
  }

  public ngOnDestroy(): void {
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }

  private routeParamsSubscription(): void {
    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {
      this.stockStore.setRoutedStockId(params['id']);
      this.stockStore.selectStock(params['id']);
    });
  }

  private selectedStockSubscription(): void {
    const stockId = this.route.snapshot.params['id'];
    this.stockStore.selectStock(stockId);
    this.stockStore.selectedStock.pipe(untilDestroyed(this)).subscribe(stock => {
      this.stock = stock;
      const urlSegments: string[] = this.router.url.split('/');
      this.isGeofenceAvailableType = this.isGeofenceAvailableTypeCheck(stock.stockType);

      this.stockStore.changeTab(urlSegments[urlSegments.length - 1]);

      if (this.isSwitchedOnGeofenceTabToUpsupportedGeofenceStockType()) {
        this.stockStore.changeTab('general');
        if (this.stock.stockId) {
          this.navigateToGeneralTab();
        }
      }
    });
  }

  private isGeofenceAvailableTypeCheck(type: StockType): boolean {
    return [StockType.POSITION, StockType.STOCK, StockType.MAIN].includes(type);
  }

  private isSwitchedOnGeofenceTabToUpsupportedGeofenceStockType(): boolean {
    return !this.isGeofenceAvailableType && this.stockStore.currentDetailTab === 'geofences';
  }

  private selectedStockDeletableSubscription(): void {
    this.stockStore.selectedStockDeletable
      .pipe(untilDestroyed(this))
      .subscribe(deletable => this.deleteDisabled = !deletable);
  }

  private navigateToGeneralTab(): void {
    this.router.navigate([`sites/stocks/list/${this.stock.stockId}/general`]);
  }
}
