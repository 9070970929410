import {LanguageService} from 'app/shared/services/language.service';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OnlineStatusService} from '../../shared/services/online-status.service';
import {Router} from '@angular/router';
import {KeycloakService} from '../keycloak';
import {EMPTY, from, Observable, of, throwError as observableThrowError} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import {HttpStatusCodes} from '../../shared/enums/http-status-codes.enum';
import {ErrorSnackbarComponent} from '../../shared/components/error-snackbar/error-snackbar.component';
import {UrlErrorHandler} from './url-error-handler';
import {MultiTenancy} from '../multi-tenancy';
import {DatesService} from '../../shared/services/dates.service';
import {InterceptorResult} from './http-interceptor-result.type';
import {RequestErrorHandlerEquipmentService} from './request-error-handlers/request-error-handler-equipment.service';
import { RequestErrorHandlerProjectService } from './request-error-handlers/request-error-handler-project.service';
import { RequestErrorHandlerStockService } from './request-error-handlers/request-error-handler-stock.service';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

  private storage: Storage;
  private urlErrorHandler: UrlErrorHandler;

  constructor(
    private snackBar: MatSnackBar,
    private onlineStatusService: OnlineStatusService,
    private router: Router,
    private keycloakService: KeycloakService,
    private languageService: LanguageService,
    private errorHandlerEquipmentService: RequestErrorHandlerEquipmentService,
    private errorHandlerProjectService: RequestErrorHandlerProjectService,
    private errorHandlerStockService: RequestErrorHandlerStockService,
  ) {
    this.storage = localStorage || window.localStorage;
    this.urlErrorHandler = new UrlErrorHandler();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): InterceptorResult {

    if (!this.onlineStatusService.onlineStatus
        && !this.urlErrorHandler.isExpectedOfflineOrAllowedErrorUrl(false, req.url)) {
      this.router.navigate(['/']);
      return EMPTY;
    }

    // Rewrite domain name for tenants applications
    req = req.clone({url: MultiTenancy.prepareUrl(req.url)});

    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }

    if (!req.headers.has('X-Realm')) {
      req = req.clone({
        headers: req.headers.set('X-Realm', KeycloakService.getRealm())
      });
    }

    if (!req.headers.has('Accept-Language')
      && this.languageService && this.languageService.getCurrentLocale()) {
      req = req.clone({
        headers: req.headers.set('Accept-Language', this.languageService.getCurrentLocale()),
      });
    }

    if (!req.headers.has('Time-Zone-ID') && DatesService.getLocalTimeZone()) {
      req = req.clone({
        headers: req.headers.set('Time-Zone-ID', DatesService.getLocalTimeZone()),
      });
    }

    let tokenPromise;
    if (this.keycloakService.isTokenExpired()) {
      tokenPromise = Promise.resolve(() => this.getTokenFromLocalStorage());
    } else {
      tokenPromise = KeycloakService.updateToken(0);
    }

    if (!req.headers.has('Authorization')) {
      tokenPromise.then(token => {
        req = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + token)
        })
      });
    }

    return from(tokenPromise).pipe(mergeMap(() => next.handle(req)
    .pipe(catchError((error: any) => this.handleError(error, req)))));
  }

  private getTokenFromLocalStorage(): string {
    return this.storage.getItem('token');
  }

  private handleError = (error: any, req: HttpRequest<any>) => {
    return of(error)
      .pipe(
        mergeMap(() => this.handleEquipmentError(error, req)),
        mergeMap(() => this.handleProjectError(error, req)),
        mergeMap(() => this.handleStockError(error, req)),
        mergeMap(() => this.handleErrorInternally(error, req))
      );
  };

  private handleEquipmentError(error: any, req: HttpRequest<any>): Observable<never | HttpErrorResponse> {
    return this.errorHandlerEquipmentService.handleError(error, req);
  }

  private handleProjectError(error: any, req: HttpRequest<any>): Observable<never | HttpErrorResponse> {
    return this.errorHandlerProjectService.handleError(error, req);
  }

  private handleStockError(error: any, req: HttpRequest<any>): Observable<never | HttpErrorResponse> {
    return this.errorHandlerStockService.handleError(error, req);
  }

  private handleErrorInternally(error: any, req: HttpRequest<any>): Observable<never> {
    let message = this.translate('shared.requestInterceptorService.techError');
    let statusCode: HttpStatusCodes = HttpStatusCodes.UNKNOWN_ERROR;

    if (this.isHttpErrorResponse(error)) {
      statusCode = error.status;
      switch (error.status) {
        case HttpStatusCodes.BAD_REQUEST:
          message = this.translate('shared.requestInterceptorService.techErrorInvalidRequest');
          break;
        case HttpStatusCodes.UNAUTHORIZED:
        case HttpStatusCodes.FORBIDDEN:
          message = this.translate('shared.requestInterceptorService.techErrorInvalidNoAuthorization');
          break;
      }
    }

    if (!this.urlErrorHandler.isExpectedOfflineOrAllowedErrorUrl(
        this.onlineStatusService.onlineStatus, req.url, error.status)) {

      let sentryId = this.translate('shared.requestInterceptorService.sentryNotAvailable');
      if (environment.SENTRY.length > 0) {
        sentryId = Sentry.captureMessage(JSON.stringify(error), (scope: Sentry.Scope) => {
          return scope
            .setLevel('error')
            .setTag('http_method', req.method)
            .setTag('error_code', error.status)
            .setExtra('payload', JSON.stringify(req.body))
            .setExtra('http_method', req.method)
            .setExtra('error_code', error.status)
            .setExtra('response', JSON.stringify(error.error));
        });
      }

      if (!this.urlErrorHandler.isThumbnailUrl(req.url)) {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          data: {
            message: message + '\n' + `${statusCode.valueOf()} - ID: ${sentryId}`
          }
        });
      }

      return observableThrowError(error);
    } else if (this.urlErrorHandler.shouldThrowObservable(req.url, error.status)) {
      return observableThrowError(error);
    } else {
      return EMPTY;
    }
  };

  private isHttpErrorResponse(error: any): error is HttpErrorResponse {
    return error instanceof HttpErrorResponse;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
