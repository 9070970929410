<mat-card class="entity-creation">

  <mat-card-content fxLayout="column" fxLayoutAlign="start stretch">

    <div class="entity-creation-title" fxFlex="100" fxLayoutGap="5px" fxLayoutAlign="start center">
      <mat-icon>person_add</mat-icon>
      <span>{{'modules.organisation.customerAddEdit.createNewCustomer'|translate}}</span>
    </div>

    <div class="entity-creation-actions" fxFlex="100">
      <button class="create-button" mat-raised-button bhTimeoutSaveButton (throttledClick)="save()"
        [disabled]="!isValid()">
        {{'general.buttons.add'|translate}} |
        <mat-icon>add_circle</mat-icon>
      </button>
      <button mat-raised-button (click)="navigateBack()">{{'general.buttons.cancel'|translate}}</button>
      <button mat-raised-button (click)="resetForm()">
        {{'general.buttons.resetData'|translate}}
      </button>
    </div>

    <div class="entity-creation-form" fxFlex="100">
      <form [formGroup]="customerAddForm" novalidate>
        <div fxLayoutGap="15px" fxLayout="row" fxLayoutAlign="space-between start">
          <div class="entity-creation-form-left" fxFlex="70">
            <div class="entity-creation-form-title">{{'modules.organisation.base.customerBaseData'|translate|uppercase}}
            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFill>
              <mat-form-field fxFlex="33">
                <mat-select placeholder="{{'general.partner.s'|translate|uppercase}}" formControlName="partnerId"
                  class="organisation-select">
                  <mat-option *ngFor="let partner of partners" [value]="partner.partnerId">
                    {{partner.partnerName}}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="partnerId.hasError('required') && !partnerId.pristine">
                  {{'shared.validation.required'|translate:{ value: 'general.partner.s'|translate } }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFill>

              <mat-form-field fxFlex="50">
                <mat-label>{{'general.name.s'|translate|uppercase}}</mat-label>
                <input matInput bhRemoveWhitespaces [maxlength]="fieldLimit.NAME" [formControl]="customerName">

                <mat-error *ngIf="customerName.hasError('taken')">
                  {{'shared.validation.alreadyAssigned'|translate:{ value: ('general.name.s'|translate) } }}
                </mat-error>
                <mat-error *ngIf="customerName.hasError('required')">
                  {{'shared.validation.required'|translate:{ value: 'general.name.s'|translate } }}
                </mat-error>
              </mat-form-field>

              <mat-form-field fxFlex="50">
                <mat-label>{{'general.units.number'|translate|uppercase}}</mat-label>
                <input matInput bhRemoveWhitespaces [formControl]="customerNumber">

                <mat-error *ngIf="customerNumber.hasError('taken')">
                  {{'shared.validation.alreadyAssigned'|translate:{ value: ('general.units.number'|translate) } }}
                </mat-error>
                <mat-error *ngIf="customerNumber.hasError('hasWhitespaces')" data-osp-test="error-trim-spaces">
                  {{'shared.validation.string.trimSpaces'|translate|translate}}
                </mat-error>
              </mat-form-field>

            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFill>
              <ng-container formGroupName="customerAddress">
                <mat-form-field fxFlex="20">
                  <mat-label>{{'general.address.street'|translate|uppercase}}</mat-label>
                  <input bhRemoveWhitespaces matInput [formControl]="customerStreet" [maxlength]="fieldLimit.STREET">
                </mat-form-field>

                <mat-form-field fxFlex="20">
                  <mat-label>{{'general.address.houseNumber'|translate|uppercase}}</mat-label>
                  <input bhRemoveWhitespaces matInput [formControl]="customerStreetNumber"
                    [maxlength]="fieldLimit.STREET_NUMBER">
                </mat-form-field>

                <mat-form-field fxFlex="20">
                  <mat-label>{{'general.address.postCode'|translate|uppercase}}</mat-label>
                  <input bhRemoveWhitespaces matInput [formControl]="customerPostalCode" [maxlength]="fieldLimit.POSTAL_CODE">
                </mat-form-field>

                <mat-form-field fxFlex="20">
                  <mat-label>{{'general.address.city'|translate|uppercase}}</mat-label>
                  <input bhRemoveWhitespaces matInput [formControl]="customerCity" [maxlength]="fieldLimit.CITY">
                </mat-form-field>

                <mat-form-field fxFlex="20">
                  <mat-label>{{'general.address.country'|translate|uppercase}}</mat-label>
                  <mat-select formControlName="country" [maxlength]="fieldLimit.COUNTRY">
                    <mat-option *ngFor="let key of countryKeys" [value]="key">
                      {{ getCountryName(countryEnum[key]) }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="customerCountry.hasError('required')">
                    {{'shared.validation.required'|translate:{ value: 'general.address.country'|translate } }}
                  </mat-error>
                </mat-form-field>
              </ng-container>
              <mat-form-field fxFlex="20">
                <mat-label>{{'modules.organisation.customerAddEdit.confirmationDispositionConflicts'|translate}}</mat-label>
                <mat-select formControlName="hasAcknowledgeAssignmentWorkflow">
                  <mat-option [value]="true">{{'general.buttons.yes'|translate}}</mat-option>
                  <mat-option [value]="false">{{'general.buttons.no'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field fxFlex="20">
                <mat-label>{{'modules.organisation.customerAddEdit.typeOfUseActive'|translate}}</mat-label>
                <mat-select formControlName="hasTypeOfUseWorkflow">
                  <mat-option [value]="true">{{'general.buttons.yes'|translate}}</mat-option>
                  <mat-option [value]="false">{{'general.buttons.no'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start start" fxFill>
              <mat-form-field fxFlex="20">
                <mat-label>{{'modules.organisation.customerAddEdit.confirmationTransferRequests'|translate}}</mat-label>
                <mat-select formControlName="hasTransferRequestWorkflow">
                  <mat-option [value]="true">{{'general.buttons.yes'|translate}}</mat-option>
                  <mat-option [value]="false">{{'general.buttons.no'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field fxFlex="20">
                <mat-label>{{'modules.organisation.customerAddEdit.confirmationStockTransferRequests'|translate}}</mat-label>
                <mat-select formControlName="hasStockTransferRequestWorkflow">
                  <mat-option [value]="true">{{'general.buttons.yes'|translate}}</mat-option>
                  <mat-option [value]="false">{{'general.buttons.no'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>
              <ng-container *ngIf="isFleetAdminRole">
                <mat-form-field fxFlex="20">
                  <mat-label>{{'modules.organisation.customerAddEdit.automaticTransferRequestAcceptance'|translate}}</mat-label>
                  <mat-select formControlName="activateTransferRequestAutoAcceptance">
                    <mat-option [value]="true">{{'general.buttons.yes'|translate}}</mat-option>
                    <mat-option [value]="false">{{'general.buttons.no'|translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="25">
                  <mat-label>{{'modules.organisation.customerAddEdit.dayForAutomaticTransferRequestAcceptance'|translate}}</mat-label>
                  <input matInput bhRemoveWhitespaces [formControl]="daysForTransferRequestAutoAcceptance">

                  <mat-error *ngIf="daysForTransferRequestAutoAcceptance.hasError('min') ||
                 daysForTransferRequestAutoAcceptance.hasError('max')">
                    {{'shared.validation.range'|translate:{ minValue: '1', maxValue: '365' } }}
                  </mat-error>
                  <mat-error *ngIf="daysForTransferRequestAutoAcceptance.hasError('pattern')">
                    {{ 'shared.validation.number.integer'|translate }}
                  </mat-error>
                </mat-form-field>
              </ng-container>
            </div>

          </div>

          <div class="entity-creation-form-right" fxFlex="30">

            <div class="entity-creation-form-title">{{'modules.organisation.base.customerType.s'|translate|uppercase}}
            </div>

            <mat-list>
              <mat-list-item class="entity-creation-form-right__list-item"
                [ngClass]="{'entity-creation-form-right__list-item--selected': ct.isSelected}"
                *ngFor="let ct of customerType;" (click)="selectCostumerType(ct)">
                <mat-icon matListItemIcon>check_circle</mat-icon>
                <p matListItemTitle>{{ct.name | customerTypeDisplay}} </p>
              </mat-list-item>
            </mat-list>

          </div>
        </div>
      </form>
    </div>

  </mat-card-content>

</mat-card>
