<mat-card fxFlexFill class="entity-creation">
  <mat-card-content fxFlexFill fxLayout="column" fxLayoutAlign="start stretch">
    <div class="entity-creation-title" fxLayoutAlign="start center" fxLayoutGap="5px">
      <mat-icon>developer_board</mat-icon>
      <span *ngIf="!isEditMode">{{'modules.equipment.incident.logIncident'|translate}}</span>
      <span *ngIf="isEditMode">{{'modules.equipment.incident.editIncident'|translate}}</span>
    </div>

    <div class="entity-creation-actions">
      <button class="create-button"
              mat-raised-button
              bhTimeoutSaveButton
              (throttledClick)="save()"
              [disabled]="!isValid()">
        <span *ngIf="!isEditMode">{{'general.buttons.add'|translate}}</span>
        <span *ngIf="isEditMode">{{'general.buttons.save'|translate}}</span>
        <mat-icon *ngIf="!isEditMode">add_circle</mat-icon>
        <mat-icon *ngIf="isEditMode">save</mat-icon>
      </button>
      <button mat-raised-button (click)="navigateBack()">
        <span *ngIf="!isEditMode">{{'general.buttons.cancel'|translate}}</span>
        <span *ngIf="isEditMode">{{'general.buttons.toOverview'|translate}}</span>
      </button>
      <button mat-raised-button (click)="resetForm()" *ngIf="!isEditMode">
        {{'general.buttons.resetData'|translate}}
      </button>
    </div>

    <div class="entity-creation-form" fxFlex>
      <form [formGroup]="incidentAddForm" novalidate fxLayout="row" fxLayoutAlign="space-between stretch">
        <div fxFlexFill fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="15px">
          <div class="entity-creation-form-left border-right" fxFlex="25">
            <div class="card-group-title">{{'modules.equipment.incident.incidentData'|translate}}</div>
            <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="space-between start" fxFill>

              <mat-form-field>
                <mat-label>{{'general.date'|translate}}</mat-label>
                <input matInput required
                       formControlName="incidentDate" autocomplete="off"
                       [matDatepicker]="incidentDatePicker">
                <mat-datepicker-toggle matSuffix [for]="incidentDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #incidentDatePicker></mat-datepicker>
                <mat-error *ngIf="incidentDate.hasError('required')">
                  {{'shared.validation.required'|translate: {value:'modules.equipment.incident.incidentDate'|translate} }}
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'general.operatingHour.pl'|translate}}</mat-label>
                <input type="number" matInput
                       formControlName="currentOperatingHours"
                       bhMaxLength="7">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'general.mileage'|translate}} ({{ 'km' | dimensionUnitDisplay }})</mat-label>
                <input type="number" matInput
                       formControlName="currentMileage"
                       bhMaxLength="7">
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'general.subject'|translate}}</mat-label>
                <input matInput required
                       bhRemoveWhitespaces
                       formControlName="subject"
                       [maxlength]="fieldLimit.MEDIUM_TEXT">
                <mat-error *ngIf="subject.hasError('required')">
                  {{'shared.validation.required'|translate: {value:'general.subject'|translate} }}
                </mat-error>
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{'general.description'|translate}}</mat-label>
                <textarea matInput #documentTextarea
                          formControlName="description"
                          [maxlength]="fieldLimit.MEDIUM_DESCRIPTION">
                </textarea>
              </mat-form-field>
            </div>
          </div>

          <div class="entity-creation-form-left" fxFlex>
            <div class="card-group-title">{{'general.document.pl'|translate}}</div>
            <div fxLayoutGap="15px" fxLayout="column" fxLayoutAlign="space-between start">
              <bh-file-upload
                [itemId]="equipmentIdParam"
                uploaderType="EQUIPMENT_ADD_EDIT"
                (onFileUploaded)="onFileUploaded($event)">
              </bh-file-upload>

              <div *ngFor="let document of documents" fxLayout="row" fxLayoutAlign="space-between start">
                <p *ngIf="document">{{'general.document.s'|translate}}: {{document.fileName}}</p>
                <button mat-icon-button (click)="removeDocument(document)">
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
