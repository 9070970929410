import { LanguageService } from 'app/shared/services/language.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CreateGeofenceCommand } from '../../../../contract/create-geofence-command';
import { ProjectDataSource } from '../../../../shared/project.datasource';
import { ViewProject } from '../../../../contract/view-project.interface';
import { DeleteGeofenceCommand } from '../../../../contract/delete-geofence-command';
import { ProjectCheckerService } from '../../../../shared/services/project-checker.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  RoleAuthorityGuardsComponent,
} from '../../../../../../shared/navigation-guards/role-authority-guards.component';
import { KeycloakService } from '../../../../../../core/keycloak';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SetResetAlarmingGeofenceCommand } from '../../../../contract/set-reset-alarming-geofence-command';
import { Observable } from 'rxjs';
import { exhaustMap, filter } from 'rxjs/operators';
import { ZoomLevel } from '../../../../../../shared/enums/zoom-level.enum';
import { ViewGeofence } from '../../../../contract/view-geofence.interface';
import {
  GeofenceSidebarComponent,
} from '../../../../../../shared/modules/map/components/geofence-sidebar-component/geofence-sidebar.component';
import {
  GenericCreateGeofenceCommand,
} from '../../../../../../shared/modules/map/components/geofence-sidebar-component/generic-create-geofence-command';
import { ProjectStructureType } from 'app/modules/disposition/shared/enums/project-structure-type';
import { MatDialog } from '@angular/material/dialog';
import {
  ConfirmationDeleteDataDialogComponent,
  DeleteDataResult,
} from '../../../../../../shared/components/confirmation-dialog/confirmation-delete-data-dialog/confirmation-delete-data-dialog.component';
import { ChangeGeofenceStatusCommand } from '../../../../contract/change-geofence-status-command';
import { GeofenceStatus } from '../../../../contract/geofence-status.enum';
import {
  GeofenceListItem
} from '../../../../../../shared/modules/map/components/geofence-sidebar-component/geofence-list-item';
import {
  GenericUpdateGeofenceCommand
} from 'app/shared/modules/map/components/geofence-sidebar-component/generic-update-geofence-command';
import { GeofenceLocationType } from '../../../../../../shared/enums/geofence-location-type.enum';
import { Modules } from 'app/shared/enums/modules.enum';
import { convertViewGeofenceToGenericGeofence } from 'app/modules/disposition/shared/geofence.converter';
import { WarningDialogComponent } from 'app/shared/components/warning-dialog/warning-dialog.component';

@UntilDestroy()
@Component({
  selector: 'bh-project-view-geofences',
  templateUrl: './project-view-geofences.component.html',
  styleUrls: ['./project-view-geofences.component.scss']
})
export class ProjectViewGeofencesComponent extends RoleAuthorityGuardsComponent implements OnInit, OnDestroy {

  @ViewChild('geofenceSidebar', { static: true })
  private geofenceSidebar: GeofenceSidebarComponent;
  public map: google.maps.Map;
  public mapCenterGermany = {lat: 50.98609893339354, lng: 10.290756225585938};
  public projectCheckerService: ProjectCheckerService = new ProjectCheckerService();
  public createGeofenceEnabled = false;
  public editGeofenceEnabled = false;
  public deleteGeofenceEnabled = false;
  public manageAlarmsEnabled = false;
  public hasAutomatedTransfersModule = false;

  constructor(protected authService: KeycloakService,
              public projectStore: ProjectDataSource,
              private snackBar: MatSnackBar,
              private languageService: LanguageService,
              private dialog: MatDialog) {
    super(authService);
  }

  ngOnInit(): void {
    this.dispositionGeofenceBadRequestErrorListener();
    this.projectStore.currentViewTab = 'geofences';
    this.geofenceSidebar.setLoading(true);
    this.hasAutomatedTransfersModule = this.authService.hasModule(Modules.AUTOMATED_TRANSFERS);
  }

  ngOnDestroy(): void {
  }

  public mapReady(event) {
    this.map = event.data.map;
    this.map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    this.centerMap();

    this.subscribeToCurrentProject();
    this.subscribeToGeofences();
  }

  private centerMap() {
    if (this.projectHasAddress) {
      this.map.setCenter(new google.maps.LatLng(this.project.location.lat, this.project.location.lon));
      this.map.setZoom(ZoomLevel.CITY_BLOCK);
    } else {
      this.map.setCenter(this.mapCenterGermany);
      this.map.setZoom(ZoomLevel.COUNTRY);
    }
  }

  private subscribeToCurrentProject(): void {
    this.projectStore.currentProject
      .pipe(
        untilDestroyed(this),
        filter((project: ViewProject) => {
          return !!project && project.projectStructureType !== ProjectStructureType.PROJECT_GROUP
        })
      )
      .subscribe(() => {
        this.geofenceSidebar.setLoading(true);
        this.centerMap();
        this.updateEnabledFunctions();
        this.projectStore.getGeofences();
      });
  }

  private subscribeToGeofences(): void {
    this.projectStore.geofences
    .pipe(untilDestroyed(this))
    .subscribe((res: ViewGeofence[]) => {
      this.geofenceSidebar.updateGeofences(res.map(el => convertViewGeofenceToGenericGeofence(el)), true);
      this.geofenceSidebar.setLoading(false);
    });
  }

  private updateEnabledFunctions(): void {
    const projectFinished = this.projectCheckerService.isProjectFinished(this.project);

    this.createGeofenceEnabled = this.editGeofenceEnabled = this.deleteGeofenceEnabled =
      !projectFinished
        && this.hasModule(this.modules.LIVE_DATA)
        && this.hasAuthority(this.authorities.GEOFENCE_MANAGE);

    this.manageAlarmsEnabled = !projectFinished;
  }

  public get project(): ViewProject {
    return this.projectStore.project();
  }

  public get projectHasAddress(): boolean {
    return !!this.project?.location;
  }

  public onCreate(genericCreateCommand: GenericCreateGeofenceCommand): void {
    let cmd: CreateGeofenceCommand = new CreateGeofenceCommand();
    cmd.locationId = this.project.projectId;
    cmd.locationType = GeofenceLocationType.PROJECT;
    cmd.geofenceName = genericCreateCommand.geofenceName;
    cmd.geofenceColor = genericCreateCommand.geofenceColor;
    cmd.coordinates = genericCreateCommand.coordinates;
    cmd.isDispositionGeofence = genericCreateCommand.isDispositionGeofence;

    this.geofenceSidebar.setLoading(true);
    this.projectStore.createGeofence(cmd);
  }

  public onEdit(genericUpdateCommand: GenericUpdateGeofenceCommand): void {
    this.geofenceSidebar.setLoading(true);
    this.projectStore.updateGeofence(genericUpdateCommand);
  }

  public onDelete(geofence: GeofenceListItem): void {
    const config = {
      autoFocus: false,
      width: '800px',
      data: {
        confirmTitle: this.translate('shared.geofence.removeProjectGeofence'),
        confirmMessage: this.translate('shared.geofence.removeProjectGeofenceMessage', {value: geofence.name}),
        cancelButtonLabel: this.translate('general.buttons.no'),
        keepDataButtonLabel: this.translate('general.buttons.yesKeepData'),
        deleteDataButtonLabel: this.translate('general.buttons.yesDeleteData'),
      },
    };

    this.dialog.open(ConfirmationDeleteDataDialogComponent, config).afterClosed()
    .pipe(untilDestroyed(this))
    .subscribe((result: DeleteDataResult) => {
      if (DeleteDataResult.DELETE_DATA === result) {
        let cmd: DeleteGeofenceCommand = new DeleteGeofenceCommand();
        cmd.geofenceId = geofence.id;
        this.projectStore.deleteGeofence(cmd);

      } else if (DeleteDataResult.KEEP_DATA === result) {
        let cmd: ChangeGeofenceStatusCommand = new ChangeGeofenceStatusCommand();
        cmd.geofenceId = geofence.id;
        cmd.geofenceStatus = GeofenceStatus.INACTIVE;
        this.projectStore.changeGeofenceStatus(cmd);
      }
    });
  }

  public onSetAlarm(geofenceId: string): void {
    const cmd: SetResetAlarmingGeofenceCommand = new SetResetAlarmingGeofenceCommand();
    cmd.projectId = this.project.projectId;
    cmd.geofenceId = geofenceId;

    this.geofenceSidebar.setLoading(true);
    this.projectStore.setAlarmGeofence(cmd).subscribe(() => {
        this.snackbarInfo(this.translate('modules.disposition.projectViewGeofence.geoAlarmSet'));
        this.geofenceSidebar.setLoading(false);
    });
  }

  public onRemoveAlarm(geofenceId: string): void {
    const cmd: SetResetAlarmingGeofenceCommand = new SetResetAlarmingGeofenceCommand();
    cmd.projectId = this.project.projectId;
    cmd.geofenceId = geofenceId;

    this.geofenceSidebar.setLoading(true);
    this.projectStore.resetAlarmGeofence(cmd).subscribe(() => {
      this.snackbarInfo(this.translate('modules.disposition.projectViewGeofence.geoAlarmReset'));
      this.geofenceSidebar.setLoading(false);
    });
  }

  private dispositionGeofenceBadRequestErrorListener(): void {
    this.projectStore.dispositionGeofenceBadRequestError
      .pipe(
        untilDestroyed(this),
        exhaustMap(() => this.openDispositionGeofenceBadRequestDialog()),
      ).subscribe(() => this.geofenceSidebar.setLoading(false));
  }

  private openDispositionGeofenceBadRequestDialog(): Observable<any> {
    const dialogRef = this.dialog.open(WarningDialogComponent, { autoFocus: false });
    dialogRef.componentInstance.message =
      this.languageService.getInstant('modules.disposition.confirmation.message.dispositionGeofenceAlreadyExist');
    return dialogRef.afterClosed();
  }

  private snackbarInfo(message: string) {
    this.snackBar.open(message, null, {duration: 3000});
  }

  private translate(key: string, interpolateParams?: any): string {
    return this.languageService.getInstant(key, interpolateParams);
  }

}
