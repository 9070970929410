export enum EquipmentEventType {
  DAMAGE = 'DAMAGE',
  GEOFENCE = 'GEOFENCE',
  INCIDENT = 'INCIDENT',
  INSPECTION = 'INSPECTION',
  MILEAGE = 'MILEAGE',
  MAINTENANCE = 'MAINTENANCE',
  MACHINE_FAULT = 'MACHINE_FAULT',
  OPERATING_HOURS = 'OPERATING_HOURS',
  RESPONSIBLE = 'RESPONSIBLE',
}
