export enum Modules {
  BASE = 'BASE',
  COMMUNICATION = 'COMMUNICATION',
  ALARMS = 'ALARMS',
  SERVICE = 'SERVICE',
  COSTS = 'COSTS',
  DISPOSITION = 'DISPOSITION',
  REPORTING = 'REPORTING',
  LIVE_DATA = 'LIVE_DATA',
  RENTAL = 'RENTAL',
  BULK_ITEM_MANAGEMENT = 'BULK_ITEM_MANAGEMENT',
  STAFF_MANAGEMENT = 'STAFF_MANAGEMENT',
  CONTACT_MANAGEMENT = 'CONTACT_MANAGEMENT',
  HISTORIC_TRANSFERS = 'HISTORIC_TRANSFERS',
  TRANSPORT_TASK_MANAGEMENT = 'TRANSPORT_TASK_MANAGEMENT',
  PROJECT_GROUP_MANAGEMENT = 'PROJECT_GROUP_MANAGEMENT',
  WIP_GLOBAL_TRANSFER_LOG = 'WIP_GLOBAL_TRANSFER_LOG',
  WIP_DIGITAL_MATTER_TRACKER = 'WIP_DIGITAL_MATTER_TRACKER',
  EQUIPMENT_USAGE_HISTORY = 'EQUIPMENT_USAGE_HISTORY',
  AUTOMATED_TRANSFERS = 'AUTOMATED_TRANSFERS',
  SUB_CUSTOMERS = 'SUB_CUSTOMERS',
  EQUIPMENT_EVENT_MANAGEMENT = 'EQUIPMENT_EVENT_MANAGEMENT',
}
