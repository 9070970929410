import { KeycloakService } from 'app/core/keycloak';
import { EquipmentStateFilterView } from '../filter-view/equipment-state-filter-view.interface';
import { EquipmentsService } from 'app/modules/equipment/shared/equipments.service';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { OrganisationsService } from 'app/modules/organisation/shared/organisations.service';
import { EquipmentFilterRequestParams } from 'app/shared/contract/filter/equipment/equipment-filter-request-params.interface';
import { EquipmentStatusCategoryPipe } from 'app/shared/pipes/equipment-status-category.pipe';
import { EquipmentStatusNamePipe } from 'app/shared/pipes/equipment-status-name.pipe';
import { FilterSingleOptionDisplayNamePipe } from 'app/shared/pipes/filter-single-option-display-name.pipe';
import { BehaviorSubject, map, Observable, of, zip } from 'rxjs';
import { BaseFilterViewConverter } from '../filter-converter/base-filter-view-converter.class';
import { CommonFilterViewConverter } from '../filter-converter/common-filter-view-converter.class';
import { EquipmentStatusViewConverter } from '../filter-converter/equipment-status-view-converter.class';
import { EquipmentTypeFilterViewConverter } from '../filter-converter/equipment-type-filter-view-converter.class';
import { OrganisationFilterViewConverter } from '../filter-converter/organisation-filter-view-converter.class';
import { FilterParams } from '../filter-params.class';
import { FilterType } from '../filter-type';
import { FilterTypes } from '../filter-types.enum';
import { FilterUtils } from '../filter-utils.class';
import { AssignedEmployeeFilterView } from '../filter-view/assigned-employee-filter-view.interface';
import { CommonFilterView } from '../filter-view/common-filter-view.interface';
import { EquipmentFilterCollectionView } from '../filter-view/equipment-filter-collection/equipment-filter-collection-view.interface';
import { EquipmentFilterType } from '../filter-view/equipment-filter-collection/equipment-filter-type.interface';
import { SingleFilterOption } from '../filter-view/equipment-filter-collection/single-filter-option.enum';
import { EquipmentTransportFilterOptions } from '../filter-view/equipment-transport-filter-options.interface';
import { EquipmentTypeFilterView } from '../filter-view/equipment-type-filter-view.interface';
import { OrganisationFilterView } from '../filter-view/organisation-filter-view.interface';
import { FiltersBaseService } from '../filters-base-service.class';
import { UpdateFilterCommand } from '../update-filter-command.interface';
import { ResponsiblePersonFilterViewConverter } from '../filter-converter/responsible-person-filter-view-converter.class';
import { SearchFilterMediatorReceiverEquipmentStatusesChanged } from 'app/shared/services/mediator-notifier/search-filter-mediator/search-filter-mediator-receiver.interface';
import { SearchFilterMediatorService } from 'app/shared/services/mediator-notifier/search-filter-mediator/search-filter-mediator.service';
import { EquipmentStatusNewFilterReadinessChecker } from '../readiness-checker/core/checker/equipment-filter-readiness-checker.class';
import { TranslatableStringPipe } from 'app/modules/osp-ui/pipes/translatable-string/translatable-string.pipe';

type EquipmentFilterItemView = (
  CommonFilterView
  | OrganisationFilterView
  | AssignedEmployeeFilterView
  | EquipmentTypeFilterView
  | EquipmentTransportFilterOptions
  | EquipmentStateFilterView
);

type UpdateFilterByTypeCommand = {
  [key in EquipmentFilterType]?: EquipmentFilterItemView[];
}


export abstract class EquipmentFiltersBaseService
  extends FiltersBaseService<EquipmentFilterRequestParams>
  implements SearchFilterMediatorReceiverEquipmentStatusesChanged {
  private organisationMap = new Map<string, ViewOrganisation>();
  private isInitFiltersUpdate = false;
  private isPostInitFiltersUpdate = false;
  private isNoFiltersApplied = true;

  private readonly _isReady = new BehaviorSubject<boolean>(false);
  public readonly isReady = this._isReady.asObservable();
  private filterChecker = new EquipmentStatusNewFilterReadinessChecker(this.params, this.equipmentsService);

  protected abstract getFilters(): Observable<EquipmentFilterCollectionView>;

  public get isSearchFiltersApplied(): boolean {
    return this.isPostInitFiltersUpdate && !this.isNoFiltersApplied;
  }

  constructor(
    params: FilterParams,
    protected equipmentsService: EquipmentsService,
    protected organisationsService: OrganisationsService,
    protected authService: KeycloakService,
    protected singleOptionDisplayNameResolver: FilterSingleOptionDisplayNamePipe,
    protected equipmentStatusCategoryPipe: EquipmentStatusCategoryPipe,
    protected equipmentStatusNamePipe: EquipmentStatusNamePipe,
    protected translatableStringPipe: TranslatableStringPipe,
    private filterMediator: SearchFilterMediatorService,
  ) {
    super(params);
    this.filterMediator.addReceiver(this);
    this.initIsReadyListener();
  }

  public getFilterParams(): EquipmentFilterRequestParams {
    this.filterChecker.isReadyCheck();
    return super.getFilterParams();
  }

  public updateFilterParams(commands: UpdateFilterCommand[], updateFilters = true): void {
    this.filterChecker.isReadyCheck();
    super.updateFilterParams(commands, updateFilters);
  }

  public updateFilters(): void {
    if (!this._isReady.value) {
      this.filterChecker.isReadyCheck();
    } else {
      this.filterUpdateCheck();
      zip(
        this.getFilters(),
        this.getOrganisationMap())
      .subscribe(([filters, organisationMap]) => {
        this.organisationMap = organisationMap;
        FilterUtils.pushMissingParentOrganisationToFilters(filters[EquipmentFilterType.ORGANISATION], this.organisationMap);
        const convertedFilters = this.convertToFilterTypes(filters);
        this.mergeToResultFilters(convertedFilters);
        this.onFiltersUpdated.emit();
      });
    }
  }

  public equipmentStatusesChanged(): void {
    this.filterChecker.equipmentStatusesChanged();
  }

  public resetFilters(command?: UpdateFilterCommand[], updateFilters = true): void {
    this.params.reset();
    this.filterChecker.overrideDefaultFilter(command);
    this.updateFilterParams(command || [], updateFilters);
  }

  private initIsReadyListener(): void {
    this.filterChecker.isReady
      .subscribe(({ isReady, commands }) => {
        if (isReady) {
          this.updateFilterParams(commands ?? [], false);
          this._isReady.next(isReady);
          this.updateFilters();
        } else {
          this._isReady.next(isReady);
        }
      });
  }

  private filterUpdateCheck(): void {
    if (this.isInitFiltersUpdate) {
      this.isPostInitFiltersUpdate = true;
    } else {
      this.isInitFiltersUpdate = true;
    }
    this.isNoFiltersApplied = !this.params.hasAnyParam();
  }

  protected getEquipmentOrganisations(): Observable<OrganisationFilterView[]> {
    return this.equipmentsService.getEquipmentOrganisations(this.params.getFilterParams());
  }

  protected getResponsiblePeople(): Observable<AssignedEmployeeFilterView[]> {
    return this.equipmentsService.getResponsiblePeople(this.params.getFilterParams());
  }

  protected getCustomerLabels(): Observable<CommonFilterView[]> {
    return this.equipmentsService.getCustomerLabels(this.params.getFilterParams());
  }

  protected getEquipmentTypes(): Observable<EquipmentTypeFilterView[]> {
    return this.equipmentsService.getGroupedEquipmentTypeCounts(this.params.getFilterParams());
  }

  protected getNewEquipmentStatuses(): Observable<EquipmentStateFilterView[]> {
    return this.equipmentsService.getEquipmentNewStatuses(this.params.getFilterParams());
  }

  protected getTransportVehicleOptions(): Observable<EquipmentTransportFilterOptions> {
    return this.equipmentsService.getTransportFilterOptions(this.params.getFilterParams());
  }

  protected getFavouriteEquipmentOptions(): Observable<CommonFilterView[]> {
    return this.equipmentsService.getFavouriteFilterOptions(this.params.getFilterParams())
      .pipe(
        map(result => {
          return [{
            name: SingleFilterOption.FAVOURITE_ONLY,
            count: result.count
          }];
        })
      );
  }

  protected getEquipmentAttentionStatuses(): Observable<CommonFilterView[]> {
    return this.equipmentsService.getEquipmentAttentionStatuses(this.params.getFilterParams());
  }

  protected getEquipmentCompletenessStatuses(): Observable<CommonFilterView[]> {
    return this.equipmentsService.getEquipmentCompletenessStatuses(this.params.getFilterParams());
  }

  protected splitEquipmentTransportFilterOptions({ transportTypes, transportVehicleCount: count }: EquipmentTransportFilterOptions): {
    [EquipmentFilterType.TRANSPORT_TYPES]: CommonFilterView[],
    [EquipmentFilterType.TRANSPORT_VEHICLE_ONLY]: CommonFilterView[],
  } {
    return {
      [EquipmentFilterType.TRANSPORT_TYPES]: transportTypes,
      [EquipmentFilterType.TRANSPORT_VEHICLE_ONLY]: [{ name: SingleFilterOption.VEHICLE_ONLY, count }],
    };
  }

  protected getSubequipmentOption(): Observable<CommonFilterView[]> {
    return of(<CommonFilterView[]>[{ name: SingleFilterOption.WITHOUT_SUBEQUIPMENTS, count: null }]);
  }

  protected getMatchAllLabelsOption(): Observable<CommonFilterView[]> {
    return of(<CommonFilterView[]>[{ name: SingleFilterOption.ALL_LABELS, count: null }]);
  }

  private getOrganisationMap(): Observable<Map<string, ViewOrganisation>> {
    return this.organisationsService
      .getOrganisationsByCustomerId(this.getUserCustomerId(), true)
      .pipe(
        map(FilterUtils.toFlatList),
        map(FilterUtils.convertToOrganisationMap));
  }

  private getUserCustomerId(): string {
    return this.authService.getUserCustomerId();
  }

  private convertToFilterTypes(transportFilters: EquipmentFilterCollectionView): FilterType[] {
    return Object.keys(transportFilters)
      .map((key: EquipmentFilterType) => this.createConverter(key, transportFilters[key] as EquipmentFilterItemView[]))
      .map(filterConverter => filterConverter && filterConverter.getFilters())
      .filter(Boolean);
  }

  private createConverter(type: EquipmentFilterType, filters: EquipmentFilterItemView[]): BaseFilterViewConverter<any> {
    switch (type) {
      case EquipmentFilterType.ORGANISATION:
        return new OrganisationFilterViewConverter(filters as OrganisationFilterView[], this.params, this.organisationMap);
      case EquipmentFilterType.ASSIGNED_EMPLOYEE:
        return new ResponsiblePersonFilterViewConverter(filters as AssignedEmployeeFilterView[], this.params);
      case EquipmentFilterType.LABEL:
        return new CommonFilterViewConverter(filters as CommonFilterView[], this.params, FilterTypes.LABEL);
      case EquipmentFilterType.CLASS:
        return new EquipmentTypeFilterViewConverter(
          filters as EquipmentTypeFilterView[],
          this.params,
          this.translatableStringPipe);
      case EquipmentFilterType.STATUS:
        return new EquipmentStatusViewConverter(
          filters as EquipmentStateFilterView[],
          this.params,
          this.equipmentStatusCategoryPipe,
          this.equipmentStatusNamePipe);
      case EquipmentFilterType.TRANSPORT_TYPES:
        return new CommonFilterViewConverter(filters as CommonFilterView[], this.params, FilterTypes.TRANSPORT_TYPE);
      case EquipmentFilterType.TRANSPORT_VEHICLE_ONLY:
        return new CommonFilterViewConverter(
          filters as CommonFilterView[],
          this.params,
          FilterTypes.TRANSPORT_VEHICLE,
          this.singleOptionDisplayNameResolver
        );
      case EquipmentFilterType.FAVOURITE_ONLY:
        return new CommonFilterViewConverter(
          filters as CommonFilterView[],
          this.params,
          FilterTypes.FAVOURITE_EQUIPMENT,
          this.singleOptionDisplayNameResolver
        );
      case EquipmentFilterType.WITHOUT_SUBEQUIPMENTS:
        return new CommonFilterViewConverter(
          filters as CommonFilterView[],
          this.params,
          FilterTypes.SUBEQUIPMENT,
          this.singleOptionDisplayNameResolver
        );
      case EquipmentFilterType.MATCH_ALL_LABELS:
        return new CommonFilterViewConverter(
          filters as CommonFilterView[],
          this.params,
          FilterTypes.MATCH_ALL_LABELS,
          this.singleOptionDisplayNameResolver
        )
      case EquipmentFilterType.ATTENTION_STATUS:
        return new CommonFilterViewConverter(
          filters as CommonFilterView[],
          this.params,
          FilterTypes.ATTENTION_STATUS
        );
      case EquipmentFilterType.COMPLETENESS_STATUS:
        return new CommonFilterViewConverter(
          filters as CommonFilterView[],
          this.params,
          FilterTypes.COMPLETENESS_STATUS,
        );
    }
  }

  protected updateFilterByType(type: EquipmentFilterType, filters: EquipmentFilterItemView[]): void {
    this.updateFilterByTypes({ [type]: filters });
  }

  protected updateFilterByTypes(command: UpdateFilterByTypeCommand): void {
    Object.keys(command).forEach((type: EquipmentFilterType) => {
      const converter = this.createConverter(type, command[type]);
      this.pushToResultFilters(converter.getFilters());
    });
    this.onFiltersUpdated.emit();
  }
}
