export enum FilterTypes {
  CLASS = 'CLASS',
  EQUIPMENT_CLASS = 'EQUIPMENT_CLASS',
  EQUIPMENT_TYPE = 'EQUIPMENT_TYPE',
  TYPE = 'TYPE',
  STATUS = 'STATUS',
  DUE_DATE = 'DUE_DATE',
  LABEL = 'LABEL',
  SUBEQUIPMENT = 'SUBEQUIPMENT',
  ORGANISATION = 'ORGANISATION',
  EQUIPMENT_ORGANISATION = 'EQUIPMENT_ORGANISATION',
  LOCATION = 'LOCATION',
  RESPONSIBLE_PERSON = 'RESPONSIBLE_PERSON',
  PRIORITY = 'PRIORITY',
  TRANSPORT_TYPE = 'TRANSPORT_TYPE',
  TRANSPORT_STATE = 'TRANSPORT_STATE',
  CONTACT_STATE = 'CONTACT_STATE',
  EMPLOYEE_STATE = 'EMPLOYEE_STATE',
  EQUIPMENT_STATE = 'EQUIPMENT_STATE',
  PROJECT_STATE = 'PROJECT_STATE',
  TRANSPORT_RELATED_PROJECT = 'TRANSPORT_RELATED_PROJECT',
  TRANSPORT_ASSIGNED_VEHICLE = 'TRANSPORT_ASSIGNED_VEHICLE',
  TRANSPORT_VEHICLE = 'TRANSPORT_VEHICLE',
  FAVOURITE_EQUIPMENT = 'FAVOURITE_EQUIPMENT',
  MAINTENANCE_TYPE = 'MAINTENANCE_TYPE',
  ARTICLE_GROUPS = 'ARTICLE_GROUPS',
  EQUIPMENT_LOCATIONS = 'EQUIPMENT_LOCATIONS',
  PROJECT = 'PROJECT',
  STOCK = 'STOCK',
  MAINTENANCE_CATEGORY = 'MAINTENANCE_CATEGORY',
  MAINTENANCE_RESULT = 'MAINTENANCE_RESULT',
  CATEGORY = 'CATEGORY',
  ONLY_LATEST_COMPLETED_FOR_RULE = 'ONLY_LATEST_COMPLETED_FOR_RULE',
  MATCH_ALL_LABELS = 'MATCH_ALL_LABELS',
  CHARGE_TYPE = 'CHARGE_TYPE',
  SOURCE_LOCATION = 'SOURCE_LOCATION',
  SOURCE_LOCATION_ORGANISATION = 'SOURCE_LOCATION_ORGANISATION',
  TARGET_LOCATION = 'TARGET_LOCATION',
  TARGET_LOCATION_ORGANISATION = 'TARGET_LOCATION_ORGANISATION',
  TRANSFER_TYPE = 'TRANSFER_TYPE',
  ATTENTION_STATUS = 'ATTENTION_STATUS',
  COMPLETENESS_STATUS = 'COMPLETENESS_STATUS',
}

export enum FilterTypesDisplayNames {
  ORGANISATION_CONTACT = 'ORGANISATION_CONTACT',
  ORGANISATION_EQUIPMENT = 'ORGANISATION_EQUIPMENT',
  ORGANISATION_RELATED_PROJECT = 'ORGANISATION_RELATED_PROJECT',
  ORGANISATION_STOCK = 'ORGANISATION_STOCK',
  ORGANISATION_VEHICLE = 'ORGANISATION_VEHICLE',
  ORGANISATION_EMPLOYEE = 'ORGANISATION_EMPLOYEE',
  EQUIPMENT_STATUS_VEHICLE = 'EQUIPMENT_STATUS_VEHICLE',
}
