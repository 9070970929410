<fa-icon *ngIf="status === AutomatedTransfersStatusList.ACTIVE"
          [icon]="faArrowDownUpAcrossLine"
          [matTooltipDisabled]="tooltipDisabled"
          [matTooltip]="'general.statuses.active'|translate">
</fa-icon>
<fa-icon *ngIf="status === AutomatedTransfersStatusList.INACTIVE"
          [icon]="faArrowDownUpLock"
          [matTooltipDisabled]="tooltipDisabled"
          [matTooltip]="'general.statuses.inactive'|translate">
</fa-icon>
<fa-icon *ngIf="status === AutomatedTransfersStatusList.BLOCKED"
          [icon]="faLockA"
          [matTooltipDisabled]="tooltipDisabled"
          [matTooltip]="'general.statuses.blocked'|translate">
</fa-icon>
