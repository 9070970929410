import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MaintenanceRule } from 'app/modules/maintenance/rules/shared/contract/maintenance-rule';
import { months } from 'app/modules/maintenance/rules/shared/months';
import { intervalThresholdCombinationValidator } from 'app/modules/maintenance/rules/shared/validators/interval-threshold-combination.validator';
import { thresholdValidator } from 'app/modules/maintenance/rules/shared/validators/threshold.validator';
import { dayMonthValidator } from 'app/shared/custom-validators/day-month.validator';
import { LanguageService } from 'app/shared/services/language.service';
import { Utils } from 'app/shared/utils';
import moment from 'moment';
import { IntervalPanelFormType } from './interval-panel-form-type.enum';
import { IntervalPanelFixedDatePatchValue, IntervalPanelPatchValue, IntervalPanelPeriodPatchValue, PatchValueCommon } from './interval-panel-patch-value.interface';
import { IntervalsDurationEnum } from '../../../modules/maintenance/rules/shared/intervals-duration.enum';
import { DimensionUnitConverterPipe } from '../../pipes/dimension-unit-converter.pipe';

interface ParsedPeriodInterval {
  interval: number;
  intervalDuration: IntervalsDurationEnum;
}

type BuildFormMap = {
  [key in IntervalPanelFormType]: () => UntypedFormGroup;
}

type PatchValueMap = {
  [key in IntervalPanelFormType]: (rule: MaintenanceRule) => PatchValueCommon;
}

@Injectable()
export class IntervalPanelFormGroupFactoryService {
  private warningThresholdValidator = thresholdValidator('mediumWarningThreshold', 'highWarningThreshold');
  private subWarningThresholdValidator = thresholdValidator('interval', 'mediumWarningThreshold');
  private readonly buildFormMap: BuildFormMap = {
    [IntervalPanelFormType.FIXED_DATE]: () => this.buildFixedDateIntervalForm(),
    [IntervalPanelFormType.PERIOD_INTERVAL]: () => this.buildPeriodIntervalForm(),
    [IntervalPanelFormType.OPERATING_HOURS]: () => this.buildOperatingHoursIntervalForm(),
    [IntervalPanelFormType.MILEAGE]: () => this.buildMileageIntervalForm(),
  };

  private readonly patchValueMap: PatchValueMap = {
    [IntervalPanelFormType.FIXED_DATE]: (rule: MaintenanceRule) => this.getFixedDateIntervalPatchValue(rule),
    [IntervalPanelFormType.PERIOD_INTERVAL]: (rule: MaintenanceRule) => this.getPeriodIntervalPatchValue(rule),
    [IntervalPanelFormType.OPERATING_HOURS]: (rule: MaintenanceRule) => this.getOperatingHoursIntervalPatchValue(rule),
    [IntervalPanelFormType.MILEAGE]: (rule: MaintenanceRule) => this.getMileageIntervalPatchValue(rule),
  }

  constructor(
    private fb: UntypedFormBuilder,
    private languageService: LanguageService,
    private dimensionUnitConverterPipe: DimensionUnitConverterPipe
  ) { }

  public buildForm(type: IntervalPanelFormType): UntypedFormGroup {
    return (this.buildFormMap[type] && this.buildFormMap[type]()) || null;
  }

  public getPatchValue(type: IntervalPanelFormType, rule: MaintenanceRule): PatchValueCommon {
    return (this.patchValueMap[type] && this.patchValueMap[type](rule)) || null;
  }

  public buildAndPatchForm(type: IntervalPanelFormType, rule: MaintenanceRule): UntypedFormGroup {
    const form = this.buildForm(type);
    if (form) {
      form.patchValue(this.getPatchValue(type, rule));
      return form;
    }
    return null;
  }

  private buildFixedDateIntervalForm(): UntypedFormGroup {
    return this.fb.group({
      day: [null, [Validators.required, Validators.min(1)]],
      month: [null, Validators.required],
      mediumWarningThreshold: [30, [Validators.required, Validators.min(1)]],
      highWarningThreshold: [7, [Validators.required, Validators.min(0)]],
      activated: false
    }, { validators: [this.warningThresholdValidator, dayMonthValidator] });
  }

  private buildPeriodIntervalForm(): UntypedFormGroup {
    return this.fb.group({
      interval: [null, [Validators.required, Validators.min(1)]],
      intervalDuration: [null, Validators.required],
      mediumWarningThreshold: [30, [Validators.required]],
      highWarningThreshold: [7, [Validators.required]],
      activated: false
    },
    {
      validators: [
        intervalThresholdCombinationValidator(
          'interval',
          'intervalDuration',
          'mediumWarningThreshold',
          'highWarningThreshold'
        )
      ]
    });
  }

  private buildOperatingHoursIntervalForm(): UntypedFormGroup {
    return this.fb.group({
      interval: [null, [Validators.required, Validators.min(1)]],
      mediumWarningThreshold: [100, [Validators.required, Validators.min(1)]],
      highWarningThreshold: [25, [Validators.required, Validators.min(1)]],
      activated: false
    }, { validators: [this.warningThresholdValidator, this.subWarningThresholdValidator] });
  }

  private buildMileageIntervalForm(): UntypedFormGroup {
    return this.fb.group({
      interval: [null, [Validators.required, Validators.min(1)]],
      mediumWarningThreshold: [250, [Validators.required, Validators.min(1)]],
      highWarningThreshold: [50, [Validators.required, Validators.min(1)]],
      activated: false
    }, { validators: [this.warningThresholdValidator, this.subWarningThresholdValidator] })
  }

  private getFixedDateIntervalPatchValue(rule: MaintenanceRule): IntervalPanelFixedDatePatchValue {
    return Boolean(rule?.fixedDateInterval)
      ? {
        day: null,
        month: null,
        mediumWarningThreshold: null,
        highWarningThreshold: null,
        activated: null,
        ...{
          ...rule.fixedDateInterval,
          month: months(this.languageService.getCurrentLocale()).find(m => Number(m.value) === rule.fixedDateInterval.month),
          activated: true,
        }
      }
      : null;
  }

  private getPeriodIntervalPatchValue(rule: MaintenanceRule): IntervalPanelPeriodPatchValue {
    return Boolean(rule?.periodInterval)
      ? {
        interval: null,
        intervalDuration: null,
        mediumWarningThreshold: null,
        highWarningThreshold: null,
        activated: null,
        ...{
          ...rule.periodInterval,
          ...this.parsePeriodInterval(rule.periodInterval.requiredIncrease),
          activated: true,
        }
      }
      : null;
  }

  private getOperatingHoursIntervalPatchValue(rule: MaintenanceRule): IntervalPanelPatchValue {
    return Boolean(rule?.operatingHoursInterval)
      ? {
        interval: null,
        mediumWarningThreshold: null,
        highWarningThreshold: null,
        activated: null,
        ...{
          ...rule.operatingHoursInterval,
          interval: rule.operatingHoursInterval.requiredIncrease,
          activated: true,
        }
      }
      : null;
  }

  private getMileageIntervalPatchValue(rule: MaintenanceRule): IntervalPanelPatchValue {
    return Boolean(rule?.mileageInterval)
      ? {
        interval: null,
        mediumWarningThreshold: null,
        highWarningThreshold: null,
        activated: null,
        ...{
          ...rule.mileageInterval,
          interval: this.dimensionUnitConverterPipe.toUserDimensionUnit(rule.mileageInterval.requiredIncrease, 'km'),
          mediumWarningThreshold: this.dimensionUnitConverterPipe.toUserDimensionUnit(rule.mileageInterval.mediumWarningThreshold, 'km'),
          highWarningThreshold: this.dimensionUnitConverterPipe.toUserDimensionUnit(rule.mileageInterval.highWarningThreshold, 'km'),
          activated: true,
        }
      }
      : null;
  }

  private parsePeriodInterval(intervalDuration: string): ParsedPeriodInterval {
    if (Utils.isISODuration(intervalDuration)) {
      const periodInterval = intervalDuration.replace(/[^a-zA-Z]+/g, '').split('');
      switch (periodInterval[1]) {
        case 'D':
          return {
            interval: moment.duration(intervalDuration).asDays(),
            intervalDuration: IntervalsDurationEnum.DAYS
          };
        case 'M':
          return {
            interval: moment.duration(intervalDuration).asMonths(),
            intervalDuration: IntervalsDurationEnum.MONTHS
          };
        case 'Y':
          return {
            interval: moment.duration(intervalDuration).asYears(),
            intervalDuration: IntervalsDurationEnum.YEARS
          };
      }
    }

    return { interval: null, intervalDuration: null }
  }
}
