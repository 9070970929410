<div fxLayout="column" fxLayoutGap="5px" [ngClass]="'display-mode-' + displayMode">
  <div>
    <mat-checkbox class="panel-title"
      [formControl]="panelToggle"
      [bhMatCheckboxReadonly]="readonly"
      color="primary">
      {{ checkboxLabel }}
    </mat-checkbox>
    <ng-container *ngIf="panelToggle.value && showNextDueDate">
      <ng-container *ngIf="!invalidDueDate() && getCurrentDueDate() !== null; else noDate">
        <span class="due-date-calculated">{{'shared.maintenance.rules.dueDateCalculated'|translate}} {{getCurrentDueDate()|date:'dd.MM.yyyy'}}</span>
      </ng-container>
      <ng-template #noDate>
        <span class="due-date-calculated">{{'shared.maintenance.rules.dueDateCalculated'|translate}} --.--.----</span>
      </ng-template>
    </ng-container>
  </div>
  <div class="interval-panel"
    [ngClass]="{
      'extended': panelToggle.value,
      'collapsed': !panelToggle.value,
      'disable-animation': disableAnimation
    }">
    <div class="interval-panel-content">
      <form [formGroup]="intervalGroup">
        <mat-label>{{ inputLabel }}</mat-label>

        <ng-container [ngSwitch]="inputType">
          <!-- DATE INPUT TYPE -->
          <section *ngSwitchCase="inputTypes.DATE" fxLayoutGap="15px">
            <mat-form-field fxFlex="50">
              <input matInput
                [readonly]="readonly"
                autocomplete="off"
                formControlName="day"
                type="number"
                min="1"
                [attr.aria-label]="inputLabel"
                [errorStateMatcher]="crossFieldDayMonthErrorMatcher"
                data-osp-test="input-due-date-day">
              <mat-error *ngIf="intervalGroup.hasError('leapYear')">
                {{'general.units.time.leapYear'|translate}}
              </mat-error>
              <mat-error *ngIf="intervalGroup.hasError('invalidDate')">
                {{'shared.validation.date.invalidAmountOfDays'|translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <mat-select formControlName="month"
                [bhMatSelectReadonly]="readonly"
                [compareWith]="compareFn"
                data-osp-test="select-due-date-month">
                <mat-option *ngFor="let month of months" [value]="month">{{month.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </section>

          <!-- PERIOD INPUT TYPE -->
          <section *ngSwitchCase="inputTypes.PERIOD" fxLayoutGap="15px">
            <mat-form-field fxFlex="50">
              <input matInput
                [readonly]="readonly"
                bhChangeableInputAllowedPattern="^[1-9][0-9]*$"
                autocomplete="off"
                formControlName="interval"
                (blur)="normalizePeriodValues()"
                data-osp-test="input-interval-value">
              <mat-error *ngIf="intervalGroup.get('interval').hasError('numberBigger')" >
                {{'shared.validation.number.invalidNumber'|translate}}
              </mat-error>
            </mat-form-field>
            <mat-form-field fxFlex="50">
              <mat-select
                formControlName="intervalDuration"
                [bhMatSelectReadonly]="readonly"
                (selectionChange)="normalizePeriodValues()"
                data-osp-test="select-interval-duration">
                <mat-option *ngFor="let interval of intervalDurations" [value]="interval">
                  {{interval | intervalDurationTranslation}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </section>

          <!-- NUMBER INPUT TYPE -->
          <ng-container *ngSwitchDefault>
            <mat-form-field>
              <input matInput
                [readonly]="readonly"
                autocomplete="off"
                formControlName="interval"
                type="number"
                [attr.aria-label]="inputLabel"
                data-osp-test="input-interval-number">
              <span matTextSuffix>{{ inputSuffix }}</span>
            </mat-form-field>
          </ng-container>
        </ng-container>

        <mat-label>
          <div class="color-preview yellow"></div>
            {{
              (displayMode === displayModes.COMPACT
                ? 'shared.maintenance.rules.warningYellowShort'
                : 'shared.maintenance.rules.warningYellow') | translate
            }}
        </mat-label>
        <mat-form-field>
          <input matInput
            [readonly]="readonly"
            bhChangeableInputAllowedPattern = "^[0-9]$|^[1-9][0-9]*$"
            autocomplete="off"
            formControlName="mediumWarningThreshold"
            type="number"
            min="0"
            [attr.aria-label]="warningSuffix"
            [errorStateMatcher]="crossFieldThresholdErrorMatcher"
            [attr.data-osp-test]="'input-medium-warning-threshold-'+inputType">
          <span matTextSuffix>{{ warningSuffix }}</span>
            <mat-error *ngIf="mediumWarningThreshold.getError('invalidMedium'); else invalidCombinationMedium">
              {{'shared.validation.number.invalidNumber'|translate}}
            </mat-error>
          <ng-template #invalidCombinationMedium>
            <mat-error *ngIf="intervalGroup.getError('invalidCombination')">
              {{'shared.validation.number.invalidNumber'|translate}}
            </mat-error>
          </ng-template>
        </mat-form-field>
        <mat-label>
          <div class="color-preview red"></div>
          {{
            (displayMode === displayModes.COMPACT
              ? 'shared.maintenance.rules.warningRedShort'
              : 'shared.maintenance.rules.warningRed') | translate
          }}
        </mat-label>
        <mat-form-field>
          <input matInput
            [readonly]="readonly"
            bhChangeableInputAllowedPattern = "^[0-9]$|^[1-9][0-9]*$"
            autocomplete="off"
            formControlName="highWarningThreshold"
            type="number"
            min="0"
            [attr.aria-label]="warningSuffix"
            [errorStateMatcher]="crossFieldThresholdErrorMatcher"
            [attr.data-osp-test]="'input-high-warning-threshold-'+inputType">
          <span matTextSuffix>{{ warningSuffix }}</span>
          <mat-error *ngIf="highWarningThreshold.getError('invalidUpper'); else invalidCombinationHigh">
            {{'shared.validation.number.invalidNumber'|translate}}
          </mat-error>
          <ng-template #invalidCombinationHigh>
            <mat-error *ngIf="intervalGroup.getError('invalidCombination')">
              {{'shared.validation.number.invalidNumber'|translate}}
            </mat-error>
          </ng-template>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>
