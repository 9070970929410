import { LanguageService } from '../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentEventStatus } from '../../contract/equipment-event-status.enum';

@Pipe({
  name: 'equipmentEventStatusPipe',
})
export class EquipmentEventStatusPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(status: EquipmentEventStatus): string {
    switch (status) {
      case EquipmentEventStatus.OPEN:
        return this.translate('shared.pipes.equipmentEventStatus.open');
      case EquipmentEventStatus.CLOSED:
        return this.translate('shared.pipes.equipmentEventStatus.closed');
      case EquipmentEventStatus.FIXED:
        return this.translate('shared.pipes.equipmentEventStatus.fixed');
      case EquipmentEventStatus.UNDEFINED:
        return this.translate('shared.pipes.equipmentEventStatus.undefined');
      case EquipmentEventStatus.UNKNOWN:
        return this.translate('shared.pipes.equipmentEventStatus.unknown');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
