import { AutofocusDirective } from './directives/autofocus.directive';
import { ExpandingTreeComponent } from './components/expanding-tree/component/expanding-tree.component';
import { ExpandingTreeModule } from './components/expanding-tree/expanding-tree.module';
import { OrganisationTypeDisplayPipe } from './pipes/organisation-type-display.pipe';
import { SchedulerLocaleLoaderService } from './scheduler/scheduler-locale-loader.service';
import { SearchFilterTypePipe } from 'app/shared/pipes/search-filter-type.pipe';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { QuillModule } from 'ngx-quill';
import { CustomMaterialModule } from '../core/custom-material/custom-material.module';
import { FileUploadComponent } from './components/file-upload-component/landscape/file-upload.component';
import { AssignmentCommentComponent } from './components/assignment-comment/assignment-comment.component';
import {
  DatePickerInputComponent
} from './components/date-range-select-component/date-picker-input-component/date-picker-input.component';
import { DateRangeSelectComponent } from './components/date-range-select-component/date-range-select.component';
import { DescriptionComponent } from './components/description-component/description.component';
import { MobileDescriptionComponent } from './components/description-component/mobile/mobile-description.component';
import { ErrorSnackbarComponent } from './components/error-snackbar/error-snackbar.component';
import { RoleConflictSnackbarComponent } from './components/role-conflict-snackbar/role-conflict-snackbar.component';
import { MobileSubHeaderComponent } from './components/mobile-sub-header/mobile-sub-header.component';
import { InputMaxLengthDirective } from './directives/input-max-length.directive';
import { InputMaxValueDirective } from './directives/input-max-value.directive';
import { InputMinValueDirective } from './directives/input-min-value.directive';
import { OnlineStatusService } from './services/online-status.service';
import { PushService } from './services/push-service';
import { DSScannerAdapter } from './components/scanner/dsscanner/DSScanner.adapter';
import { ScannerAdapter } from './components/scanner/scanner.adapter';
import { TaskListComponent } from './components/task-list/task-list.component';
import { UserConfigurationService } from './services/user-configuration.service';
import { SuccessSnackbarComponent } from './components/success-snackbar/success-snackbar.component';
import { TitleComponent } from './components/title/title.component';
import { SubtitleComponent } from './components/subtitle/subtitle.component';
import { MobileSwipeToOptionsComponent } from './components/mobile-swipe-to-options/mobile-swipe-to-options.component';
import {
  MobileSwipeOptionComponent
} from './components/mobile-swipe-to-options/mobile-swipe-option/mobile-swipe-option.component';
import { EditListService } from './services/edit-list.service';
import { TaskRemainingComponent } from './components/task-list/task-remaining/task-remaining.component';
import { MobileFileUploadComponent } from './components/file-upload-component/mobile/mobile-file-upload.component';
import { RemainingValueTypePipe } from './pipes/remaining-value-type.pipe';
import { MaintenanceCategoryPipe } from './pipes/maintenance-category.pipe';
import { MobileWizardComponent } from './components/mobile-wizard-component/mobile-wizard.component';
import {
  MobileWizardTabComponent
} from './components/mobile-wizard-component/mobile-wizard-tab-component/mobile-wizard-tab.component';
import { FileTypeNamePipe } from './pipes/file-type-name.pipe';
import { WindowEventService } from './services/window-event-service';
import {
  MaintenanceRuleViewDialogComponent
} from '../modules/maintenance/rules/maintenance-rule-view-dialog/maintenance-rule-view-dialog.component';
import {
  MaintenanceRuleAddEditComponent
} from '../modules/maintenance/rules/maintenance-rule-add-edit/maintenance-rule-add-edit.component';
import { IntervalPanelComponent } from '../modules/maintenance/rules/interval-panel/interval-panel.component';
import {
  IntervalCalculationBaseDialogComponent
} from '../modules/maintenance/rules/maintenance-rule-add-edit/interval-calculation-base-dialog/interval-calculation-base-dialog.component';
import { MaintenanceRuleService } from '../modules/maintenance/rules/shared/service/maintenance-rule-service';
import { MaintenanceTypeService } from '../modules/maintenance/types/shared/service/maintenance-type.service';
import { MobileWizardSettingsService } from './services/mobile-wizard-settings.service';
import {
  MobileWizardSettingsComponent
} from './components/mobile-wizard-component/mobile-wizard-settings-component/mobile-wizard-settings-component';
import { LabelChipsComponent } from './components/label-chips-component/label-chips.component';
import { FileComponent } from './components/file/file.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CheckboxConfirmDialogComponent } from './components/checkbox-confirm-dialog/checkbox-confirm-dialog.component';
import { InlineTextEditComponent } from './components/inline-text-edit/inline-text-edit.component';
import {
  AttachmentListCategoryComponent
} from './components/attachment-list-category/attachment-list-category.component';
import { ShorteningFilenameComponent } from './components/shortening-filename/shortening-filename.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {
  PendingAssignmentAcknowledgementsComponent
} from './components/pending-assignment-acknowledgements/pending-assignment-acknowledgements.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StepDialogComponent } from './components/step-dialog/step-dialog.component';
import { CardSelectComponent } from './components/card-select/card-select.component';
import { VerticalTabSelectComponent } from './components/vertical-tab-select/vertical-tab-select.component';
import { HorizontalTabSelectComponent } from './components/horizontal-tab-select/horizontal-tab-select.component';
import { BulkItemStocksComponent } from './components/bulk-item-stocks/bulk-item-stocks.component';
import { NgChartsModule } from 'ng2-charts';
import { AssetListComponent } from './components/asset-list-component/landscape/asset-list.component';
import { RouterModule } from '@angular/router';
import {
  ChooseAddProcessDialogComponent
} from './components/choose-add-process-dialog/choose-add-process-dialog.component';
import { DisplayService } from './display.service';
import { AssetListTypeResolver } from './components/asset-list-component/asset-list-type-resolver';
import { AddressService } from './services/address.service';
import { TabService } from './tab.service';
import { OfflineDataStore } from './services/offline-data.store';
import { OfflineTransferAmountsService } from './services/offline-transfer-amounts.service';
import {
  AttachmentDocuwareComponent
} from './components/attachment-docuware-component/landscape/attachment-docuware.component';
import {
  MobileAttachmentDocuwareComponent
} from './components/attachment-docuware-component/mobile/mobile-attachment-docuware.component';
import { MobileNavigationListComponent } from './components/mobile-navigation-list/mobile-navigation-list.component';
import { ScanCodeStore } from './services/scan-code.store';
import { ScanCodeService } from './services/scan-code.service';
import { TranslateModule } from '@ngx-translate/core';
import { SearchFilterComponent } from './components/search-filter-component/landscape/search-filter.component';
import { FilterDialogComponent } from './components/search-filter-component/filter-dialog/filter-dialog.component';
import { FilterDisplayPipe } from './pipes/filter.pipe';
import { GroupDisplayPipe } from './pipes/group.pipe';
import { StockTypeResolver } from './pipes/stock-type.resolver';
import { NgPipesModule } from 'ngx-pipes';
import { MainPipeModule } from './pipes/main-pipe.module';
import { TextEditComponent } from './components/text-edit-component/text-edit.component';
import { TeamComponent } from './components/team-component/team.component';
import { LanguageService } from './services/language.service';
import { DompurifyPipe } from './pipes/dompurify.pipe';
import { NavigationMapperService } from './services/navigation-mapper.service';
import { FormatDispositionProjectDateRangePipe } from './pipes/format-date/format-disposition-project-date-range.pipe';
import { FormatAssignmentDateRangePipe } from './pipes/format-date/format-assignment-date-range.pipe';
import { ExplanationCardComponent } from './components/explanation-card/explanation-card.component';
import { TimefenceComponent } from './components/timefence/timefence.component';
import { TimefenceSelectedDayPipe } from './pipes/timefence-selected-day.pipe';
import { DayNamePipe } from './pipes/day-name.pipe';
import {
  ConfirmationDialogDeleteUserRoleComponent
} from './components/confirmation-dialog/confirmation-dialog-delete-user-role/confirmation-dialog-delete-user-role.component';
import { FormatProjectStartDatePipe } from './pipes/format-date/format-project-start-date.pipe';
import { FormatProjectEndDatePipe } from './pipes/format-date/format-project-end-date.pipe';
import {
  FormatAssignmentEmployeeProjectDateRangePipe
} from './pipes/format-date/format-assignment-employee-project-date-range.pipe';
import { TimeoutSavebuttonDirective } from './directives/timeout-savebutton.directive';
import { SecurityQueryDialogComponent } from './components/security-query-dialog/security-query-dialog.component';
import { SecurityQueryProcessResolver } from './components/security-query-dialog/security-query-process-resolver';
import { FirstColumnDirective } from './directives/first-column.directive';
import {
  AttachmentTopfactComponent
} from './components/attachment-topfact-component/landscape/attachment-topfact.component';
import {
  MobileAttachmentTopfactComponent
} from './components/attachment-topfact-component/mobile/mobile-attachment-topfact.component';
import { DatepickerAutoModeSwitchDirective } from './directives/datepicker-auto-mode-switch.directive';
import { DragAndDropService } from './services/drag-and-drop.service';
import { DraggableItemTypeDirective } from './directives/draggable-item-type.directive';
import { OperatingHoursPipe } from './pipes/operating-hours.pipe';
import { PreventExpandAfterDrag } from './directives/prevent-expand-after-drag.directive';
import { StepHeaderOverlayDirective } from './directives/step-header-overlay.directive';
import { SelectedSearchFilterValuesPipe } from './pipes/selected-search-filter-values.pipe';
import {
  DispositionAssignConfirmationMessageComponent
} from './components/disposition-assign-confirmation-message/disposition-assign-confirmation-message.component';
import { CurrentItemPaginatorDirective } from './directives/current-item-paginator/current-item-paginator.directive';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker/date-time-picker.component';
import {
  LastTransferDateReasonPipe
} from '../modules/disposition/bulk-items/bulk-item-transfer/landscape/transfer-cart-confirmation/last-transfer-date-reason-pipe';
import {
  DateTimeRangeSelectComponent
} from './components/dateTime-range-select-component/date-time-range-select/date-time-range-select.component';
import { ContactTypeResolverPipe } from './pipes/contact-type-resolver.pipe';
import { MapSvgMarkerFactory } from './services/map-svg-marker-factory.service';
import { CountryResolver } from './country.resolver';
import { SortOrderComponent } from './components/sort-order/sort-order.component';
import { SchedulerDragHighlighterDirective } from './directives/scheduler-drag-highlighter.directive';
import {
  TransportationTimelineSchedulerService
} from '../modules/transportation/shared/services/transportation-timeline-scheduler.service';
import { InputAllowedCharactersDirective } from './directives/input-allowed-characters.directive';
import { InputConfirmDialogComponent } from './components/input-confirm-dialog/input-confirm-dialog.component';
import { TimeDurationComponent } from './components/time-duration/time-duration.component';
import { InputAllowedPatternDirective } from './directives/input-allowed-pattern.directive';
import { WarningDialogComponent } from './components/warning-dialog/warning-dialog.component';
import { PageSwitcherComponent } from './components/page-switcher/page-switcher.component';
import { MileagePipe } from './pipes/mileage.pipe';
import {
  AttachmentListImageCategoryComponent
} from './components/attachment-list-image-category/attachment-list-image-category.component';
import { PreventFocusSwitchDirective } from './directives/prevent-focus-switch.directive';
import { NativeAppLinksDialogComponent } from './components/native-app-links-dialog/native-app-links-dialog.component';
import {
  ConfigurableDialogFieldsComponent
} from './components/configurable-dialog-fields/configurable-dialog-fields.component';
import { RemoveWhitespacesDirective } from './directives/remove-whitespaces.directive';
import { MaxCharactersLimitPipe } from './pipes/max-characters-limit.pipe';
import {
  TableConfigurationDialogComponent
} from './components/table-configuration-dialog/table-configuration-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CardSelectAlarmTypesComponent } from './components/card-select-alarm-types/card-select-alarm-types.component';
import {
  EquipmentTransportTypesIconComponent
} from './components/icon/equipment-transport-types-icon/equipment-transport-types-icon.component';
import { ColorIndicatorComponent } from './components/color-indicator/color-indicator.component';
import { InputFitHeightDirective } from './directives/input-fit-height.directive';
import { MatMenuItemButtonComponent } from './components/button/mat-menu-item-button/mat-menu-item-button.component';
import { VirtualExpandingTreeComponent } from './components/expanding-tree/component/virtual-expanding-tree.component';
import {
  EquipmentStatusBadgeComponent
} from './components/equipment-status/equipment-status-badge/equipment-status-badge.component';
import {
  EquipmentStatusIconComponent
} from './components/equipment-status/equipment-status-icon/equipment-status-icon.component';
import { MultilineDirective } from './directives/multiline.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IconResolverComponent } from './components/icon-resolver/icon-resolver.component';
import { SelectListComponent } from './components/select-list/select-list.component';
import { DraggableListComponent } from './components/draggable-list/draggable-list.component';
import {
  EquipmentStatusCategoryBadgeComponent
} from './components/equipment-status/equipment-status-category-badge/equipment-status-category-badge.component';
import { IsRemovablePipe } from './components/draggable-list/is-removable.pipe';
import { MatTableLongHeaderTooltipDirective } from './directives/mat-table-long-header-tooltip.directive';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { CompletedTaskListComponent } from './components/completed-task-list/completed-task-list.component';
import { OverflowTooltipDirective } from './directives/overflow-tooltip.directive';
import {
  MaintenanceCompletedTaskActionButtonComponent
} from './components/maintenance-completed-task-action-button/maintenance-completed-task-action-button.component';
import {
  IsAllowEditCompletedTaskPipe
} from './components/maintenance-completed-task-action-button/is-allow-edit-completed-task.pipe';
import {
  IsAllowDeleteCompletedTaskPipe
} from './components/maintenance-completed-task-action-button/is-allow-delete-completed-task.pipe';
import { ScanCodeEditComponent } from './components/scan-code-edit/scan-code-edit.component';
import { FilterHiddenPipe } from './components/search-filter-component/filter-dialog/filter-hidden.pipe';
import {
  MaintenanceResultBadgeComponent
} from './components/maintenance-result-badge/maintenance-result-badge.component';
import { MaintenanceResultPipe } from './pipes/maintenance-result.pipe';
import { HistoryTableComponent } from './components/history-table/history-table.component';
import { DateLocalComponent } from './components/date-local/date-local.component';
import {
  ProjectNumberAndCostCenterComponent
} from './components/project-number-and-cost-center-component/project-number-and-cost-center.component';
import { ScrollSelectedDirective } from './directives/scroll-selected.directive';
import { QrCodeDialogComponent } from './components/qr-code-dialog/qr-code-dialog.component';
import { QrCodeModule } from 'ng-qrcode';
import { ChangeableInputAllowedPatternDirective } from './directives/changeable-input-allowed-pattern.directive';
import {
  EquipmentStatusDropdownComponent
} from './components/equipment-status/equipment-status-dropdown/equipment-status-dropdown.component';
import { SearchFilterDefaultStatusNamePipe } from './pipes/search-filter-default-status-name.pipe';
import { BulkItemTypesPipe } from './pipes/bulk-item-types.pipe';
import { MatCheckboxReadonlyDirective } from './directives/mat-checkbox-readonly.directive';
import { MatSelectReadonlyDirective } from './directives/mat-select-readonly.directive';
import {
  IntervalPanelFormGroupFactoryService
} from './services/interval-panel-form-group-factory/interval-panel-form-group-factory.service';
import {
  LocationColumnDataComponent
} from './components/location-column-data-component/location-column-data.component';
import { HasAsyncValidationErrorDirective } from './directives/has-async-validation-error.directive';
import { EmptyOrValidDateDirective } from './directives/empty-or-valid-date.directive';
import { InlineFilenameEditComponent } from './components/inline-filename-edit/inline-filename-edit.component';
import { AutoSizeInputDirective } from './components/inline-filename-edit/auto-size-input.directive';
import { SecuredImageModule } from './components/test-place/secured-image/secured-image.module';
import { SelectionTreeComponent } from './components/selection-tree/selection-tree.component';
import { SelectInfiniteScrollDirective } from './directives/select-infinite-scroll.directive';
import {
  ConfirmationDeleteDataDialogComponent
} from './components/confirmation-dialog/confirmation-delete-data-dialog/confirmation-delete-data-dialog.component';
import { SelectOverlayPanelClassDirective } from './directives/select-overlay-panel-class.directive';
import { MatOptionHintComponent } from './components/mat-option-hint/mat-option-hint.component';
import { TextInputComponent } from './components/text-input/text-input.component';
import { TimeRangeFilterComponent } from './components/time-range-filter/time-range-filter.component';
import { ScrollPositionDirective } from './directives/scroll-position.directive';
import { TrackerBatteryStatusComponent } from './components/tracker-battery-status/tracker-battery-status.component';
import { TranslateBatteryStatusPipe } from './pipes/translate-battery-status.pipe';
import {
  SearchSuggestionComponent
} from './components/search-filter-component/landscape/search-suggestion/search-suggestion.component';
import { TableCellAddressComponent } from './components/table-cell/table-cell-address/table-cell-address.component';
import { AdditionalFieldTypeDisplayPipe } from './pipes/additional-field-type.display.pipe';
import { AdditionalFieldEntityDisplayPipe } from './pipes/additional-field-entity.display.pipe';
import { FormatTimezoneDateTimePipe } from './pipes/format-date/format-timezone-date-time.pipe';
import {
  AdditionalFieldAssignmentDialogComponent
} from './components/additional-field-assignment-dialog/additional-field-assignment-dialog.component';
import { BhButtonModule } from 'app/modules/osp-ui/components/bh-button/bh-button.module';
import { MatChipsModule } from '@angular/material/chips';
import { BhIconModule } from 'app/modules/osp-ui/components/bh-icon/bh-icon.module';
import {
  AdditionalFieldAssignedComponent
} from './components/additional-field-assigned/additional-field-assigned.component';
import {
  BhAdditionalFieldModule
} from 'app/modules/osp-ui/rich-components/bh-additional-field/bh-additional-field.module';
import {
  AdditionalFieldViewComponent
} from './components/additional-field-assigned/additional-field-view/additional-field-view.component';
import {
  DigitalMatterProfileDropdownComponent
} from './components/digital-matter-profile-dropdown/digital-matter-profile-dropdown.component';
import {
  DigitalMatterProfileStatusBadgeComponent
} from './components/digital-matter-profile-dropdown/digital-matter-profile-status-badge/digital-matter-profile-status-badge.component';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { ErrorCardComponent } from './components/error-card/error-card.component';
import {
  AdditionalFieldValuePipe
} from './components/additional-field-assigned/additional-field-view/additional-field-value.pipe';
import { OspUiPipesModule } from 'app/modules/osp-ui/pipes/osp-ui-pipes.module';
import { DigitalMatterMobileSignalPipe } from './pipes/digital-matter-mobile-signal.pipe';
import { AdditionalFieldCategoryDisplayPipe } from './pipes/additional-field-category.display.pipe';
import { ActivityStatusComponent } from './components/activity-status/activity-status.component';
import { EquipmentAutomatedTransfersIconComponent } from './components/icon/equipment-automated-transfers-icon/equipment-automated-transfers-icon.component';
import { ColorBadgeComponent } from './components/color-badge/color-badge.component';
import { CoreDataComponent } from './components/core-data/core-data.component';

@NgModule({
  imports: [
    CommonModule,
    CustomMaterialModule,
    FlexLayoutModule,
    CustomMaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    QuillModule,
    NgxMatSelectSearchModule,
    FontAwesomeModule,
    NgChartsModule,
    RouterModule,
    TranslateModule,
    NgPipesModule,
    MainPipeModule,
    ExpandingTreeModule,
    DragDropModule,
    OverlayModule,
    ScrollingModule,
    QrCodeModule,
    SecuredImageModule,
    BhButtonModule,
    MatChipsModule,
    BhIconModule,
    BhAdditionalFieldModule,
    OspUiPipesModule,
  ],
  declarations: [
    MobileSubHeaderComponent,
    ErrorSnackbarComponent,
    RoleConflictSnackbarComponent,
    InputMaxLengthDirective,
    InputMaxValueDirective,
    InputMinValueDirective,
    DateRangeSelectComponent,
    DatePickerInputComponent,
    AssignmentCommentComponent,
    DescriptionComponent,
    MobileDescriptionComponent,
    TaskListComponent,
    CompletedTaskListComponent,
    TaskRemainingComponent,
    SuccessSnackbarComponent,
    TitleComponent,
    SubtitleComponent,
    FileUploadComponent,
    MobileFileUploadComponent,
    RemainingValueTypePipe,
    MaintenanceCategoryPipe,
    FileTypeNamePipe,
    LastTransferDateReasonPipe,
    MobileSwipeToOptionsComponent,
    MobileSwipeOptionComponent,
    // TODO: Restructure module dependencies to allow usage of rule dialog in other modules
    MaintenanceRuleAddEditComponent,
    MaintenanceRuleViewDialogComponent,
    IntervalPanelComponent,
    IntervalCalculationBaseDialogComponent,
    LabelChipsComponent,
    MobileWizardComponent,
    MobileWizardTabComponent,
    MobileWizardSettingsComponent,
    ConfirmationDialogComponent,
    ConfirmationDialogDeleteUserRoleComponent,
    ConfirmationDeleteDataDialogComponent,
    CheckboxConfirmDialogComponent,
    FileComponent,
    PendingAssignmentAcknowledgementsComponent,
    StepDialogComponent,
    CardSelectComponent,
    CardSelectAlarmTypesComponent,
    VerticalTabSelectComponent,
    InlineTextEditComponent,
    InlineFilenameEditComponent,
    AutoSizeInputDirective,
    AttachmentListCategoryComponent,
    ShorteningFilenameComponent,
    BulkItemStocksComponent,
    AssetListComponent,
    ChooseAddProcessDialogComponent,
    AttachmentDocuwareComponent,
    AttachmentTopfactComponent,
    MobileAttachmentDocuwareComponent,
    MobileAttachmentTopfactComponent,
    MobileNavigationListComponent,
    SearchFilterComponent,
    FilterDialogComponent,
    FilterDisplayPipe,
    GroupDisplayPipe,
    StockTypeResolver,
    TextEditComponent,
    DompurifyPipe,
    HorizontalTabSelectComponent,
    TeamComponent,
    BulkItemTypesPipe,
    SearchFilterTypePipe,
    SearchFilterDefaultStatusNamePipe,
    FormatDispositionProjectDateRangePipe,
    FormatAssignmentDateRangePipe,
    ExplanationCardComponent,
    OrganisationTypeDisplayPipe,
    ExplanationCardComponent,
    TimefenceComponent,
    TimefenceSelectedDayPipe,
    DayNamePipe,
    FormatProjectStartDatePipe,
    FormatProjectEndDatePipe,
    FormatAssignmentEmployeeProjectDateRangePipe,
    FormatTimezoneDateTimePipe,
    SecurityQueryDialogComponent,
    TimeoutSavebuttonDirective,
    FirstColumnDirective,
    DatepickerAutoModeSwitchDirective,
    DraggableItemTypeDirective,
    OperatingHoursPipe,
    MileagePipe,
    DigitalMatterMobileSignalPipe,
    PreventExpandAfterDrag,
    StepHeaderOverlayDirective,
    SelectedSearchFilterValuesPipe,
    DispositionAssignConfirmationMessageComponent,
    AutofocusDirective,
    CurrentItemPaginatorDirective,
    DateTimePickerComponent,
    ContactTypeResolverPipe,
    DateTimeRangeSelectComponent,
    ContactTypeResolverPipe,
    SortOrderComponent,
    SchedulerDragHighlighterDirective,
    InputAllowedCharactersDirective,
    InputAllowedPatternDirective,
    InputConfirmDialogComponent,
    TimeDurationComponent,
    WarningDialogComponent,
    PageSwitcherComponent,
    AttachmentListImageCategoryComponent,
    PreventFocusSwitchDirective,
    ConfigurableDialogFieldsComponent,
    NativeAppLinksDialogComponent,
    RemoveWhitespacesDirective,
    MaxCharactersLimitPipe,
    TableConfigurationDialogComponent,
    ColorIndicatorComponent,
    EquipmentTransportTypesIconComponent,
    EquipmentAutomatedTransfersIconComponent,
    MatMenuItemButtonComponent,
    InputFitHeightDirective,
    EquipmentStatusIconComponent,
    EquipmentStatusBadgeComponent,
    EquipmentStatusDropdownComponent,
    MultilineDirective,
    IconResolverComponent,
    SelectListComponent,
    DraggableListComponent,
    EquipmentStatusCategoryBadgeComponent,
    IsRemovablePipe,
    MatTableLongHeaderTooltipDirective,
    CustomTooltipComponent,
    OverflowTooltipDirective,
    MaintenanceCompletedTaskActionButtonComponent,
    IsAllowEditCompletedTaskPipe,
    IsAllowDeleteCompletedTaskPipe,
    ScanCodeEditComponent,
    FilterHiddenPipe,
    MaintenanceResultBadgeComponent,
    MaintenanceResultPipe,
    HistoryTableComponent,
    DateLocalComponent,
    ProjectNumberAndCostCenterComponent,
    ScrollSelectedDirective,
    ScrollPositionDirective,
    ChangeableInputAllowedPatternDirective,
    QrCodeDialogComponent,
    MatCheckboxReadonlyDirective,
    MatSelectReadonlyDirective,
    LocationColumnDataComponent,
    HasAsyncValidationErrorDirective,
    EmptyOrValidDateDirective,
    SelectionTreeComponent,
    SelectInfiniteScrollDirective,
    SelectOverlayPanelClassDirective,
    MatOptionHintComponent,
    TextInputComponent,
    TimeRangeFilterComponent,
    TrackerBatteryStatusComponent,
    TranslateBatteryStatusPipe,
    SearchSuggestionComponent,
    TableCellAddressComponent,
    AdditionalFieldTypeDisplayPipe,
    AdditionalFieldEntityDisplayPipe,
    AdditionalFieldCategoryDisplayPipe,
    DigitalMatterProfileDropdownComponent,
    DigitalMatterProfileStatusBadgeComponent,
    InfoCardComponent,
    ErrorCardComponent,
    AdditionalFieldAssignmentDialogComponent,
    AdditionalFieldAssignedComponent,
    AdditionalFieldViewComponent,
    AdditionalFieldValuePipe,
    ActivityStatusComponent,
    EquipmentAutomatedTransfersIconComponent,
    ColorBadgeComponent,
    CoreDataComponent,
  ],
  exports: [
    MobileSubHeaderComponent,
    RoleConflictSnackbarComponent,
    InputMaxLengthDirective,
    InputMaxValueDirective,
    TimeoutSavebuttonDirective,
    DateRangeSelectComponent,
    AssignmentCommentComponent,
    DescriptionComponent,
    MobileDescriptionComponent,
    TitleComponent,
    SubtitleComponent,
    FileUploadComponent,
    MobileFileUploadComponent,
    TaskListComponent,
    CompletedTaskListComponent,
    BulkItemStocksComponent,
    MaintenanceCategoryPipe,
    FileTypeNamePipe,
    LastTransferDateReasonPipe,
    MobileSwipeToOptionsComponent,
    MobileSwipeOptionComponent,
    MobileWizardComponent,
    MobileWizardTabComponent,
    MobileWizardSettingsComponent,
    LabelChipsComponent,
    ConfirmationDialogComponent,
    ConfirmationDialogDeleteUserRoleComponent,
    ConfirmationDeleteDataDialogComponent,
    CheckboxConfirmDialogComponent,
    FileComponent,
    NgxMatSelectSearchModule,
    PendingAssignmentAcknowledgementsComponent,
    FontAwesomeModule,
    StepDialogComponent,
    CardSelectComponent,
    CardSelectAlarmTypesComponent,
    VerticalTabSelectComponent,
    InlineTextEditComponent,
    InlineFilenameEditComponent,
    AutoSizeInputDirective,
    AttachmentListCategoryComponent,
    ShorteningFilenameComponent,
    NgChartsModule,
    ChooseAddProcessDialogComponent,
    AttachmentDocuwareComponent,
    MobileAttachmentDocuwareComponent,
    MobileNavigationListComponent,
    StockTypeResolver,
    FilterDisplayPipe,
    GroupDisplayPipe,
    TextEditComponent,
    HorizontalTabSelectComponent,
    TeamComponent,
    BulkItemTypesPipe,
    SearchFilterTypePipe,
    FormatDispositionProjectDateRangePipe,
    FormatAssignmentDateRangePipe,
    ExplanationCardComponent,
    OrganisationTypeDisplayPipe,
    ExplanationCardComponent,
    OrganisationTypeDisplayPipe,
    TimefenceComponent,
    TimefenceSelectedDayPipe,
    DayNamePipe,
    FormatProjectStartDatePipe,
    FormatProjectEndDatePipe,
    FormatAssignmentEmployeeProjectDateRangePipe,
    FormatTimezoneDateTimePipe,
    AttachmentTopfactComponent,
    MobileAttachmentTopfactComponent,
    FirstColumnDirective,
    DatepickerAutoModeSwitchDirective,
    DraggableItemTypeDirective,
    OperatingHoursPipe,
    MileagePipe,
    DigitalMatterMobileSignalPipe,
    PreventExpandAfterDrag,
    ExpandingTreeComponent,
    VirtualExpandingTreeComponent,
    StepHeaderOverlayDirective,
    SelectedSearchFilterValuesPipe,
    DispositionAssignConfirmationMessageComponent,
    AutofocusDirective,
    CurrentItemPaginatorDirective,
    DateTimePickerComponent,
    DatePickerInputComponent,
    InputMinValueDirective,
    DateTimeRangeSelectComponent,
    ContactTypeResolverPipe,
    ContactTypeResolverPipe,
    SortOrderComponent,
    SchedulerDragHighlighterDirective,
    InputAllowedCharactersDirective,
    InputAllowedPatternDirective,
    TimeDurationComponent,
    PageSwitcherComponent,
    AttachmentListImageCategoryComponent,
    PreventFocusSwitchDirective,
    ConfigurableDialogFieldsComponent,
    TaskRemainingComponent,
    NativeAppLinksDialogComponent,
    RemoveWhitespacesDirective,
    TableConfigurationDialogComponent,
    MaxCharactersLimitPipe,
    ColorIndicatorComponent,
    EquipmentTransportTypesIconComponent,
    EquipmentAutomatedTransfersIconComponent,
    InputFitHeightDirective,
    MatMenuItemButtonComponent,
    EquipmentStatusBadgeComponent,
    DigitalMatterProfileStatusBadgeComponent,
    EquipmentStatusIconComponent,
    EquipmentStatusDropdownComponent,
    MultilineDirective,
    IconResolverComponent,
    SelectListComponent,
    DraggableListComponent,
    EquipmentStatusCategoryBadgeComponent,
    MatTableLongHeaderTooltipDirective,
    OverflowTooltipDirective,
    MaintenanceCompletedTaskActionButtonComponent,
    MaintenanceResultBadgeComponent,
    MaintenanceResultPipe,
    HistoryTableComponent,
    DateLocalComponent,
    ProjectNumberAndCostCenterComponent,
    ScrollSelectedDirective,
    ScrollPositionDirective,
    ChangeableInputAllowedPatternDirective,
    IntervalPanelComponent,
    MatCheckboxReadonlyDirective,
    MatSelectReadonlyDirective,
    LocationColumnDataComponent,
    HasAsyncValidationErrorDirective,
    EmptyOrValidDateDirective,
    SecuredImageModule,
    SelectionTreeComponent,
    SelectInfiniteScrollDirective,
    SelectOverlayPanelClassDirective,
    MatOptionHintComponent,
    TextInputComponent,
    TimeRangeFilterComponent,
    TrackerBatteryStatusComponent,
    TranslateBatteryStatusPipe,
    TableCellAddressComponent,
    AdditionalFieldTypeDisplayPipe,
    AdditionalFieldEntityDisplayPipe,
    AdditionalFieldCategoryDisplayPipe,
    DigitalMatterProfileDropdownComponent,
    InfoCardComponent,
    ErrorCardComponent,
    AdditionalFieldAssignedComponent,
    ActivityStatusComponent,
    ColorBadgeComponent,
    CoreDataComponent,
  ],
  providers: [
    EditListService,
    OnlineStatusService,
    UserConfigurationService,
    PushService,
    MobileWizardSettingsService,
    {
      provide: ScannerAdapter,
      useClass: DSScannerAdapter,
    },
    MaintenanceRuleService,
    MaintenanceTypeService,
    WindowEventService,
    AssetListTypeResolver,
    TabService,
    AddressService,
    OfflineDataStore,
    ScanCodeStore,
    ScanCodeService,
    OfflineTransferAmountsService,
    LanguageService,
    StockTypeResolver,
    NavigationMapperService,
    FormatDispositionProjectDateRangePipe,
    FormatAssignmentDateRangePipe,
    SchedulerLocaleLoaderService,
    TimefenceSelectedDayPipe,
    DayNamePipe,
    SecurityQueryProcessResolver,
    DragAndDropService,
    OperatingHoursPipe,
    MileagePipe,
    DigitalMatterMobileSignalPipe,
    ContactTypeResolverPipe,
    MapSvgMarkerFactory,
    CountryResolver,
    TransportationTimelineSchedulerService,
    RemainingValueTypePipe,
    IntervalPanelFormGroupFactoryService,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [DisplayService],
    };
  }
}
