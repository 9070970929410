import { LanguageService } from '../../../../../../shared/services/language.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DisplayService } from '../../../../../../shared/display.service';
import { TelematicsDataSource } from '../../../../shared/telematics.datasource';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CurrentEquipmentStatus } from '../../../../contract/current-equipment-status.interface';
import { EquipmentsDataSource } from '../../../../shared/equipments.datasource';
import { ViewEquipment } from '../../../../contract/view-equipment.interface';
import { EquipmentTelematics } from '../../../../contract/equipment-telematics';
import { EquipmentTelematicsDataseries } from '../../../../contract/equipment-telematics-dataseries';
import { EquipmentTelematicsMultiseries } from '../../../../contract/equipment-telematics-multiseries';
import { EquipmentMultiseriesItem } from '../../../../contract/equipment-multiseries-item';
import { EquipmentTelematicsDataseriesItem, Series } from '../../../../contract/equipment-telematics-dataseries-item';
import { EquipmentFault } from 'app/modules/equipment/contract/equipment-fault.interface';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faBurn } from '@fortawesome/pro-light-svg-icons';
import { Utils } from '../../../../../../shared/utils';
import {
  EquipmentTelematicsUnitTypeResolver
} from 'app/modules/equipment/shared/services/equipment-telematics-unit-type.resolver';
import { KeyValuePair } from '../../../../contract/key-value-pair';
import { TelematicsUnitType } from '../../../../contract/telematics-unit-type.enum';
import { EquipmentBeacon } from '../../../../contract/equipment-beacon.interface';
import { Observable, skip, Subscription, timer, zip } from 'rxjs';
import { BatteryStatus } from '../../../../../../shared/enums/battery-status.enum';
import { DigitalMatterProfile } from '../../../../../../shared/assign-digital-matter-unit/digital-matter-profile';
import {
  DigitalMatterProfileDeployment
} from '../../../../../../shared/assign-digital-matter-unit/digital-matter-profile-deployment';
import {
  DigitalMatterProfileDeploymentState
} from '../../../../../../shared/assign-digital-matter-unit/digital-matter-profile-deployment-state';
import { matomoCategories } from '../../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../../assets/matomo/matomo-actions.enum';
import { MatomoTracker } from 'ngx-matomo';

@UntilDestroy()
@Component({
  selector: 'bh-equipment-view-telematic',
  templateUrl: './equipment-view-telematic.component.html',
  styleUrls: ['./equipment-view-telematic.component.scss'],
})
export class EquipmentViewTelematicComponent implements OnInit {

  public telematicFiltersForm: UntypedFormGroup;
  public equipmentId: string;
  public equipmentTelematics: EquipmentTelematicsDataseries;
  public equipmentMultiseries: EquipmentTelematicsMultiseries;
  public equipmentStatus: CurrentEquipmentStatus;
  public equipmentFaults: EquipmentFault[];
  public equipmentBeacons: EquipmentBeacon[];
  public equipmentHasNextFaults: boolean;
  public equipmentEngineStatusEvents: any[];
  public fromDateControl: UntypedFormControl;
  public toDateControl: UntypedFormControl;
  public readonly maxInterval: moment.Duration = moment.duration(1, 'year');
  public readonly faBurn: IconDefinition = faBurn;
  public currentDate: Date;
  public assignedTelematicUnit: KeyValuePair;
  public batteryStatus: BatteryStatus;
  public allDigitalMatterProfiles: DigitalMatterProfile[];
  public digitalMatterProfileDeployment: DigitalMatterProfileDeployment;
  public digitalMatterProfile: DigitalMatterProfile;
  public selectedLanguage: string;
  private beaconTypes = new Set<string>([
    TelematicsUnitType.ELA_PUCK.valueOf(),
    TelematicsUnitType.ELA_COIN.valueOf(),
    TelematicsUnitType.CONFIDEX_CLASSIC.valueOf(),
    TelematicsUnitType.CONFIDEX_ROUGH.valueOf(),
    TelematicsUnitType.CONFIDEX_MICRO.valueOf()]);
  private notConnectedToEngineTypes = new Set<string>([
    TelematicsUnitType.DIGITAL_MATTER_OYSTER3_UNIT.valueOf()
  ]);
  private readonly profileIntervalInMilliseconds: number = 60000;
  private digitalMatterProfileSubscription: Subscription;

  constructor(private telematicsStore: TelematicsDataSource,
              private displayService: DisplayService,
              private formBuilder: UntypedFormBuilder,
              private equipmentStore: EquipmentsDataSource,
              private languageService: LanguageService,
              private telematicsUnitResolver: EquipmentTelematicsUnitTypeResolver,
              protected router: Router,
              private matomoTracker: MatomoTracker) {
    this.selectedLanguage = this.getCurrentLocale();
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_INFO, matomoActions.EQUIPMENT_VIEW_TELEMATICS);
  }

  public get fromDate(): Date {
    return this.fromDateControl.value;
  }

  public get toDate(): Date {
    return this.toDateControl.value;
  }

  public get cumulativeOperatingHours(): number {
    return Utils.round(this.equipmentStatus.cumulativeOperatingHours, 1);
  }

  public get cumulativeIdleNonOperatingHours(): number {
    return Utils.round(this.equipmentStatus.cumulativeIdleNonOperatingHours, 1);
  }

  public ngOnInit(): void {
    moment.locale(this.getCurrentLocale());
    this.subscribeToCurrentEquipment();
    this.subscribeToEquipmentTelematics();
    this.subscribeToEquipmentStatus();
    this.subscribeToEquipmentFaults();
    this.subscribeToEquipmentBeacons();
    this.subscribeToBatteryStatus();
    this.subscribeToDigitalMatterProfiles();
  }

  public onTelematicDateFilterChange(): void {
    if (this.telematicFiltersForm.valid) {
      this.telematicsStore.getEquipmentTelematicsAndStatus(this.fromDate, this.toDate);
      this.telematicsStore.getEquipmentFaults(this.fromDate, this.toDate);
    }
  }

  public onQuickfilterClick(months: number): void {
    this.telematicFiltersForm.patchValue({
      fromDate: moment().subtract(months, 'month').toDate(),
      toDate: moment().toDate(),
    });
    this.telematicsStore.getEquipmentTelematicsAndStatus(this.fromDate, this.toDate);
    this.telematicsStore.getEquipmentFaults(this.fromDate, this.toDate);
  }

  public onLoadNextFaultsClick(): void {
    this.telematicsStore.getEquipmentFaults(this.fromDate, this.toDate);
  }

  public averageFuelUsed(): number {
    return this.isValidTelematicsDataSeries(this.equipmentTelematics.fuelUsedAverageSeries) ?
      this.equipmentTelematics.fuelUsedAverageSeries.series[0].value : 0;
  }

  public calculateDiffChangeDateUntilNow(latestChangeDate: Date): string {
    if (!latestChangeDate) {
      return this.translate('general.unknown');
    }
    return moment(latestChangeDate).fromNow(true);
  }

  public isError(faultSeverity: string): boolean {
    return faultSeverity && faultSeverity === 'error';
  }

  public isWarning(faultSeverity: string): boolean {
    return faultSeverity && faultSeverity === 'warning';
  }

  public isInformation(faultSeverity: string): boolean {
    return faultSeverity && faultSeverity === 'information';
  }

  public isLoading(): Observable<boolean> {
    return this.telematicsStore.isLoading;
  }

  public fxFlexValue(): number {
    return this.displayService.isDetailsFullscreen() ? 33.33 : 50;
  }

  public isValidTelematicsDataSeries(equipmentTelematicsDataseriesItem: EquipmentTelematicsDataseriesItem): boolean {
    return this.equipmentTelematics && equipmentTelematicsDataseriesItem && !!equipmentTelematicsDataseriesItem.series.length;
  }

  public isValidMultiSeries(equipmentSeriesItem: EquipmentMultiseriesItem[]): boolean {
    return this.equipmentMultiseries && equipmentSeriesItem && !!equipmentSeriesItem.length;
  }

  public isValidStatus(firstStatusItem: number, secondStatusItem: number): boolean {
    return this.equipmentStatus && firstStatusItem != null && secondStatusItem != null;
  }

  public displayOperatingHours(): boolean {
    const validTelematics = this.equipmentTelematics &&
      (this.isValidTelematicsDataSeries(this.equipmentTelematics.operatingHoursSeries) ||
        this.isValidTelematicsDataSeries(this.equipmentTelematics.operatingHoursWorkingIdleRatio));
    const validStatus = this.equipmentStatus &&
      this.isValidStatus(this.equipmentStatus.cumulativeOperatingHours, this.equipmentStatus.cumulativeIdleNonOperatingHours);
    return (validTelematics || validStatus) && this.isNotBeaconTelematicUnit();
  }

  public displayFuelRemaining(): boolean {
    const validTelematics = this.equipmentTelematics &&
      (this.isValidTelematicsDataSeries(this.equipmentTelematics.fuelRemainingSeries) ||
        this.isValidTelematicsDataSeries(this.equipmentTelematics.defRemainingSeries));
    const validStatus = this.equipmentStatus &&
      (this.isValidStatus(this.equipmentStatus.fuelTankCapacityInLitre, this.equipmentStatus.fuelRemainingPercent) ||
        this.isValidStatus(this.equipmentStatus.defTankCapacityInLitre, this.equipmentStatus.defRemainingPercent));
    return validTelematics || validStatus;
  }

  public displayFuelUsed(): boolean {
    return this.equipmentTelematics &&
      this.isValidTelematicsDataSeries(this.equipmentTelematics.fuelUsedAverageSeries);
  }

  public displayEngineStatus(): boolean {
    return this.equipmentTelematics &&
      this.isValidTelematicsDataSeries(this.equipmentTelematics.engineStatusSeries);
  }

  public displayExternalVoltage(): boolean {
    return this.equipmentTelematics
      && this.equipmentMultiseries
      && this.isValidMultiSeries(this.equipmentMultiseries.externalVoltageStatusMultiSeries);
  }

  public isTeltonikaTelematicsUnit(): boolean {
    return this.assignedTelematicUnit
      && (TelematicsUnitType.TELTONIKA_CABLE_UNIT === this.assignedTelematicUnit.key
        || TelematicsUnitType.TELTONIKA_OBD_UNIT === this.assignedTelematicUnit.key)
  }

  public isDigitalMatterUnit(): boolean {
    return this.assignedTelematicUnit && TelematicsUnitType.DIGITAL_MATTER_OYSTER3_UNIT === this.assignedTelematicUnit.key;
  }

  public getTelematicUnitLogo(): string {
    return this.assignedTelematicUnit ? this.telematicsUnitResolver.resolveLogo(this.assignedTelematicUnit.key) : null;
  }

  public getTelematicUnitName(): string {
    return this.assignedTelematicUnit ? this.telematicsUnitResolver.resolveName(this.assignedTelematicUnit.key) : null;
  }

  public isNotBeaconTelematicUnit(): boolean {
    if (this.assignedTelematicUnit) {
      return !this.beaconTypes.has(this.assignedTelematicUnit.key);
    }
    return true;
  }

  public isNotConnectedToEngineTelematicUnit(): boolean {
    if (this.assignedTelematicUnit) {
      return this.notConnectedToEngineTypes.has(this.assignedTelematicUnit.key);
    }
    return false;
  }

  public navigateToBaseEquipment(): void {
    this.router.navigate(['assets/equipment/list', this.equipmentStatus.baseEquipmentId, 'telematic']);
  }


  private subscribeToCurrentEquipment(): void {
    this.equipmentStore.currentEquipment
    .pipe(untilDestroyed(this))
    .subscribe((currentEquipment: ViewEquipment) => {
      if (!currentEquipment) {
        this.assignedTelematicUnit = null;
        return;
      }
      this.assignedTelematicUnit = currentEquipment.assignedTelematicsUnits ? currentEquipment.assignedTelematicsUnits[0] : null;
      if (this.assignedTelematicUnit) {
        this.assignedTelematicUnit.value = this.telematicsUnitResolver.getTelematicsUnitId(this.assignedTelematicUnit);
      }
      this.telematicsStore.init(currentEquipment.equipmentId);
      this.buildForm();
    });
  }

  private subscribeToEquipmentTelematics(): void {
    this.telematicsStore.equipmentTelematics
    .pipe(untilDestroyed(this))
    .subscribe((telematics: EquipmentTelematics) => {
      if (!telematics) {
        return;
      }
      this.updateDataSeries(telematics.dataSeries);
      this.updateMultiSeries(telematics.multiSeries);
    });
  }

  private buildForm(): void {
    this.resetDigitalMatterData();
    this.fromDateControl = new UntypedFormControl(this.telematicsStore.filterFrom);
    this.toDateControl = new UntypedFormControl(this.telematicsStore.filterTo);
    this.telematicFiltersForm = this.formBuilder.group({
      fromDate: this.fromDateControl,
      toDate: this.toDateControl,
    });
    this.currentDate = moment().toDate();
    if (this.isDigitalMatterUnit()) {
      this.telematicsStore.getBatteryStatus(this.assignedTelematicUnit.value);
      this.getDigitalMatterProfiles();
    }
  }

  private updateDataSeries(dataSeries: EquipmentTelematicsDataseries): void {
    this.equipmentEngineStatusEvents = [];
    this.equipmentTelematics = dataSeries;

    if (!dataSeries) {
      return;
    }

    if (dataSeries.engineStatusSeries &&
      dataSeries.engineStatusSeries.series &&
      dataSeries.engineStatusSeries.series.length) {

      let id = 0;
      this.equipmentEngineStatusEvents = dataSeries.engineStatusSeries.series.map((series: Series) => {
        return {
          'id': id++,
          'start_date': new Date(series.name),
          'end_date': new Date(series.value),
        };
      });
    }
  }

  private updateMultiSeries(multiSeries: EquipmentTelematicsMultiseries): void {
    this.equipmentMultiseries = multiSeries;
  }

  private subscribeToEquipmentStatus(): void {
    this.telematicsStore.equipmentStatus
    .pipe(untilDestroyed(this))
    .subscribe((status: CurrentEquipmentStatus) => this.equipmentStatus = status);
  }

  private subscribeToEquipmentFaults(): void {
    this.telematicsStore.equipmentFaults
    .pipe(untilDestroyed(this))
    .subscribe((faults: EquipmentFault[]) => {
      this.equipmentFaults = faults;
    });

    this.telematicsStore.equipmentHasNextFaults
    .pipe(untilDestroyed(this))
    .subscribe((hasNext: boolean) => {
      this.equipmentHasNextFaults = hasNext;
    });
  }

  private subscribeToEquipmentBeacons(): void {
    this.telematicsStore.equipmentBeacons
    .pipe(untilDestroyed(this))
    .subscribe((beacons: EquipmentBeacon[]) => {
      this.equipmentBeacons = beacons;
    });
  }

  private subscribeToBatteryStatus(): void {
    this.telematicsStore.batteryStatus
      .pipe(untilDestroyed(this))
      .subscribe(batteryStatus => {
        this.batteryStatus = batteryStatus;
      });
  }

  private subscribeToDigitalMatterProfiles(): void {
    zip([this.telematicsStore.allDigitalMatterProfiles, this.telematicsStore.digitalMatterProfileDeployment])
      .pipe(skip(1))
      .pipe(untilDestroyed(this))
      .subscribe(([allProfiles, deployment]) => {
        this.allDigitalMatterProfiles = allProfiles;
        this.digitalMatterProfileDeployment = deployment;
        this.digitalMatterProfile = this.allDigitalMatterProfiles
          .find(profile => this.digitalMatterProfileDeployment.templateId === profile.templateId);
      });
  }

  private translate(key: string, interpolateParams?: Object): string {
    return this.languageService.getInstant(key, interpolateParams);
  }
  private getCurrentLocale(): string {
    return this.languageService.getCurrentLocale();
  }

  protected isDigitalMatterProfileUpdating(): boolean {
    return (this.digitalMatterProfileDeployment?.deploymentState !== DigitalMatterProfileDeploymentState.DEPLOYMENT_VERIFIED)
      && !this.wasDigitalMatterProfileCanceled();
  }

  protected wasDigitalMatterProfileCanceled(): boolean {
    return this.digitalMatterProfileDeployment && this.digitalMatterProfileDeployment?.deploymentCancelReason !== null;
  }

  private resetDigitalMatterData(): void {
    this.digitalMatterProfile = null;
    this.digitalMatterProfileDeployment = null;
    this.allDigitalMatterProfiles = [];
    if (this.digitalMatterProfileSubscription){
      this.digitalMatterProfileSubscription.unsubscribe();
    }
  }

  private getDigitalMatterProfiles() {
    this.digitalMatterProfileSubscription = timer(0, this.profileIntervalInMilliseconds).pipe(untilDestroyed(this)).subscribe(() => {
      this.telematicsStore.getAllDigitalMatterProfiles();
      this.telematicsStore.getCurrentDigitalMatterProfile(this.assignedTelematicUnit.value);
    });
  }

  profileChanged(profile: DigitalMatterProfile) {
    this.telematicsStore.changeDigitalMatterProfile(this.assignedTelematicUnit.value, profile.profileId).subscribe(() => {
      this.telematicsStore.getAllDigitalMatterProfiles();
      this.telematicsStore.getCurrentDigitalMatterProfile(this.assignedTelematicUnit.value);
      this.matomoTracker.trackEvent(matomoCategories.DIGITAL_MATTER_PROFILE, matomoActions.CHANGE_SUCCESSFUL);
    });
  }

  trackMatomoEvent_Telematic_DigitalMatterProfileChangeStarted() {
    this.matomoTracker.trackEvent(matomoCategories.DIGITAL_MATTER_PROFILE, matomoActions.CHANGE_STARTED);
  }

  public resolveTooltip(): string {
    return this.translate(
      'general.labels.jumpTo',
      {value: this.translate('general.additionalFieldEntity.equipment')},
    );
  }

  navigateToBeaconEquipment(beaconEquipmentId: string): void {
    this.router.navigate(['assets/equipment/list', beaconEquipmentId, 'general']);
  }
}
