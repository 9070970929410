import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { EquipmentsService } from '../equipments.service';
import { EventGroupInfo } from '../../contract/event-group-info.interface';
import { EventDetailsPayload, EventRow } from '../../contract/equipment-event-details.interface';
import { BaseRequestParams } from 'app/shared/contract/base-request-params.interface';
import { EquipmentEventStatus } from '../../contract/equipment-event-status.enum';
import { EquipmentDamageEventUpdateCommand } from '../contracts/equipment-damage-event-update.command';
import { EquipmentEventPayloadType } from '../../contract/equipment-event-payload-type.enum';

@Injectable()
export class EventDetailsDatasource {
  private _eventGroupInfo: BehaviorSubject<EventGroupInfo> = new BehaviorSubject(null);
  public readonly eventGroupInfo = this._eventGroupInfo.asObservable();
  private _eventGroupPayload: BehaviorSubject<EventDetailsPayload[]> = new BehaviorSubject(null);
  public readonly eventGroupPayload = this._eventGroupPayload.asObservable();
  private _payloadType: BehaviorSubject<EquipmentEventPayloadType> = new BehaviorSubject(null);
  public readonly payloadType = this._payloadType.asObservable();
  private _eventsList: BehaviorSubject<EventRow[]> = new BehaviorSubject([]);
  public readonly eventsList = this._eventsList.asObservable();
  private _damageEventStatusChangeTrigger = new BehaviorSubject(false);
  public readonly damageEventStatusChangeTrigger = this._damageEventStatusChangeTrigger.asObservable();

  private _equipmentId = '';
  private _eventGroupId = '';
  private _selectedDamageEventStatus: EquipmentEventStatus = null;

  private _pageIndex = new BehaviorSubject(0);
  private _pageSize = new BehaviorSubject(25);
  private _length = new BehaviorSubject(0);
  private _sort: Sort = null;

  public set equipmentId(id: string) {
    this._equipmentId = id;
  }

  public get equipmentId(): string {
    return this._equipmentId;
  }

  public set eventGroupId(id: string) {
    this._eventGroupId = id;
  }

  public get eventGroupId(): string {
    return this._eventGroupId;
  }

  public set sort(sort: Sort) {
    this._sort = sort;
  }

  public set selectedDamageEventStatus(status: EquipmentEventStatus) {
    this._selectedDamageEventStatus = status;
  }

  constructor(
    private equipmentsService: EquipmentsService,
    private router: Router,
  ) { }

  public getEventDetails(redirectToDetails = false): void {
    this.equipmentsService.getEquipmentEventDetails(this._equipmentId, this._eventGroupId, this.getEventDetailsRequestParams())
      .subscribe({
        next: res => {
          this._eventGroupInfo.next(res.eventGroupInfo);
          this._eventGroupPayload.next([res.payload]);
          this._payloadType.next(res.payload.payloadType)
          this._eventsList.next(res.events.content);
          this._length.next(res.events.totalElements);
        },
        complete: () => {
          if (redirectToDetails) {
            this.router.navigate(['assets/event/details']);
          }
        }
      });
  }

  private getEventDetailsRequestParams(): BaseRequestParams {
    return {
      page: this._pageIndex.value,
      size: this._pageSize.value,
      sort: this.getSortRequest(this._sort),
    }
  }

  private getSortRequest(sort: Sort): string {
    if (sort && sort.active && sort.direction) {
      return `${sort.active},${sort.direction}`;
    }
    return null;
  }

  public updateEquipmentDamageEventStatus(): Observable<string> {
    return this.equipmentsService.updateDamageEquipmentEventStatus(this.getEquipmentDamageEventStatusUpdateCommand());
  }

  private getEquipmentDamageEventStatusUpdateCommand(): EquipmentDamageEventUpdateCommand {
    return {
      equipmentId: this._equipmentId,
      lifecycleId: this._eventGroupId,
      status: this._selectedDamageEventStatus,
    }
  }

  public updateDamageEventStatusChangeTrigger(status: boolean): void {
    this._damageEventStatusChangeTrigger.next(status);
  }

  public getPageIndex(): Observable<number> {
    return this._pageIndex.asObservable();
  }

  public getPageSize(): Observable<number> {
    return this._pageSize.asObservable();
  }

  public getLength(): Observable<number> {
    return this._length.asObservable();
  }

  public resetPageIndex(): void {
    this._pageIndex.next(0);
  }

  public handlePageEvent(event: PageEvent): void {
    this._pageIndex.next(event.pageIndex);
    this._pageSize.next(event.pageSize);
    this.getEventDetails();
  }
}
