import { LanguageService } from '../../../../shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentEventPayloadType } from '../../contract/equipment-event-payload-type.enum';

@Pipe({
  name: 'payloadTypePipe',
})
export class PayloadTypePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(type: EquipmentEventPayloadType): string {
    switch (type) {
      case EquipmentEventPayloadType.DAMAGE:
        return this.translate('shared.pipes.equipmentEventType.damage');
      case EquipmentEventPayloadType.EMPLOYEE_ASSIGNMENT:
        return this.translate('shared.pipes.payloadType.employeeAssignment');
      case EquipmentEventPayloadType.INCIDENT:
        return this.translate('shared.pipes.payloadType.incident');
      case EquipmentEventPayloadType.MAINTENANCE:
        return  this.translate('shared.pipes.equipmentEventType.maintenance') + ' / ' +
                this.translate('shared.pipes.equipmentEventType.inspection');
      case EquipmentEventPayloadType.MACHINE_FAULT:
        return this.translate('shared.pipes.payloadType.machineFault');
      case EquipmentEventPayloadType.MILEAGE:
        return this.translate('shared.pipes.equipmentEventType.mileage');
      case EquipmentEventPayloadType.OPERATING_HOURS:
        return this.translate('shared.pipes.equipmentEventType.operatingHours');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
