import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { EquipmentAttentionStatus } from '../enums/equipment-attention-status.enum';

@Pipe({
  name: 'attentionStatusPipe',
})
export class AttentionStatusPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(status: EquipmentAttentionStatus): string {
    switch (status) {
      case EquipmentAttentionStatus.GOOD:
        return this.translate('shared.pipes.attentionStatus.good');
      case EquipmentAttentionStatus.MACHINE_FAULT:
        return this.translate('shared.pipes.attentionStatus.machineFault');
      case EquipmentAttentionStatus.MAINTENANCE_DUE:
        return this.translate('shared.pipes.attentionStatus.maintenanceDue');
      case EquipmentAttentionStatus.MACHINE_FAULT_AND_MAINTENANCE_DUE:
        return this.translate('shared.pipes.attentionStatus.machineFaultAndMaintenanceDue');
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
