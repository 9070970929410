import { Pipe, PipeTransform } from '@angular/core';
import { EquipmentEventSeverity } from 'app/modules/equipment/contract/equipment-event-severity.enum';
import { EquipmentEventStatus } from 'app/modules/equipment/contract/equipment-event-status.enum';
import { EquipmentAttentionStatus } from '../enums/equipment-attention-status.enum';

@Pipe({
  name: 'statusColorPipe'
})
export class StatusColorPipe implements PipeTransform {
  public transform(status: string): string {
    switch (status) {
      case EquipmentEventStatus.CLOSED:
      case EquipmentEventStatus.FIXED:
      case EquipmentAttentionStatus.GOOD:
        return 'green';
      case EquipmentEventStatus.UNDEFINED:
      case EquipmentEventStatus.UNKNOWN:
      case EquipmentEventSeverity.INFO:
      case EquipmentEventSeverity.LOW:
        return 'blue';
      case EquipmentEventStatus.OPEN:
      case EquipmentEventSeverity.CRITICAL:
      case EquipmentAttentionStatus.MACHINE_FAULT:
      case EquipmentAttentionStatus.MACHINE_FAULT_AND_MAINTENANCE_DUE:
        return 'red';
      case EquipmentEventSeverity.MEDIUM:
      case EquipmentAttentionStatus.MAINTENANCE_DUE:
        return 'orange';
      default:
        return 'blue';
    }
  }
}
