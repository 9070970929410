import { LanguageService } from '../../../../../../../shared/services/language.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentsDataSource } from '../../../../../shared/equipments.datasource';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'app/core/keycloak';
import { BaseEquipmentViewTimelineComponent } from '../base/base-equipment-view-timeline.component';
import { EquipmentCheckerService } from '../../../../../shared/services/equipment-checker.service';
import { LifeCycleDamage } from '../../../../../contract/life-cycle-damage';
import { LifeCycle } from '../../../../../contract/life-cycle';
import { DamageTypeDisplayPipe } from '../../../../../../../shared/pipes/damage-type.pipe';
import { LifeCycleType } from '../../../../../contract/lifecycle-type.enum';
import { LifeCycleCost } from '../../../../../contract/lifecycle-cost';
import { LifeCycleOperatingHours } from '../../../../../contract/life-cycle-operating-hours';
import { LifeCycleMileage } from '../../../../../contract/life-cycle-mileage';
import { LifeCycleIncident } from '../../../../../contract/life-cycle-incident';
import { LifeCycleTask } from '../../../../../contract/life-cycle-task';
import { MaintenanceCategoryPipe } from '../../../../../../../shared/pipes/maintenance-category.pipe';
import { LifeCycleReminder } from '../../../../../contract/life-cycle-reminder';
import { Authorities } from '../../../../../../../shared/enums/authorities.enum';
import * as moment from 'moment';
import { Modules } from '../../../../../../../shared/enums/modules.enum';
import { RouterHistory } from '../../../../../../../shared/router-history';
import { LifeCycleEmployeeAdded } from '../../../../../contract/life-cycle-employee-added';
import { LifeCycleEmployeeRemoved } from '../../../../../contract/life-cycle-employee-removed';
import { LifeCycleEmployeeReplaced } from '../../../../../contract/life-cycle-employee-replaced';
import { EquipmentEmployeeRolePipe } from '../../../../../../../shared/pipes/equipment-employee-role.pipe';
import { CurrencyPipe } from '@angular/common';
import { EquipmentsService } from '../../../../../shared/equipments.service';
import { DimensionUnitPipe } from '../../../../../../../shared/pipes/dimension-unit.pipe';
import { DimensionUnitConverterPipe } from '../../../../../../../shared/pipes/dimension-unit-converter.pipe';
import { round } from 'lodash';
import { MatomoTracker } from 'ngx-matomo';
import { matomoCategories } from '../../../../../../../../assets/matomo/matomo-categories.enum';
import { matomoActions } from '../../../../../../../../assets/matomo/matomo-actions.enum';

@Component({
  selector: 'bh-equipment-view-timeline',
  templateUrl: './equipment-view-timeline.component.html',
  styleUrls: ['./equipment-view-timeline.component.scss'],
})
export class EquipmentViewTimelineComponent extends BaseEquipmentViewTimelineComponent {

  readonly mileageLabel = `${this.translate('general.mileage')} (${this.dimensionUnitPipe.getUserDimensionUnit('km')}): `;

  constructor(protected equipmentStore: EquipmentsDataSource,
              protected equipmentsService: EquipmentsService,
              protected employeeRolePipe: EquipmentEmployeeRolePipe,
              protected router: Router,
              protected route: ActivatedRoute,
              protected authService: KeycloakService,
              protected dialog: MatDialog,
              public equipmentCheckerService: EquipmentCheckerService,
              protected damageTypeDisplayPipe: DamageTypeDisplayPipe,
              protected maintenanceCategoryPipe: MaintenanceCategoryPipe,
              protected routerHistory: RouterHistory,
              protected languageService: LanguageService,
              private currencyPipe: CurrencyPipe,
              private dimensionUnitPipe: DimensionUnitPipe,
              private dimensionUnitConverterPipe: DimensionUnitConverterPipe,
              private matomoTracker: MatomoTracker) {
    super(equipmentStore, equipmentsService, employeeRolePipe, router, route, authService,
      dialog, maintenanceCategoryPipe, routerHistory, languageService);
    this.matomoTracker.trackEvent(matomoCategories.EQUIPMENT_INFO, matomoActions.EQUIPMENT_VIEW_TIMELINE);
  }

  public eventDeleted(): void {
    this.isRequestInProgress = true;
  }

  public getEventHeader(lifeCycle: LifeCycle): [string, string][] {
    const header: [string, string][] = [];
    switch (lifeCycle.lifeCycleType) {
      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
        const damageLifeCycle = <LifeCycleDamage>lifeCycle;
        header.push([`${this.translate('modules.equipment.damage.damageType')}: `,
          this.damageTypeDisplayPipe.transform(damageLifeCycle.damageType)]);
        break;

      case LifeCycleType.EQUIPMENT_COST_EVENT:
        const costLifeCycle = <LifeCycleCost>lifeCycle;
        header.push([`${this.translate('modules.equipment.base.invoiceAmount')}: `,
          this.currencyFormat(costLifeCycle.invoiceAmount)]);
        break;

      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        const incidentLifeCycle = <LifeCycleIncident>lifeCycle;
        header.push([`${this.translate('general.subject')}: `, incidentLifeCycle.subject]);
        break;

      case LifeCycleType.OPERATING_HOURS_UPDATE:
        const operatingHoursLifeCycle = <LifeCycleOperatingHours>lifeCycle;
        header.push([`${this.translate('general.operatingHour.pl')}: `,
          operatingHoursLifeCycle.currentOperatingHours && operatingHoursLifeCycle.currentOperatingHours.toString()]);
        break;

      case LifeCycleType.MILEAGE_UPDATE:
        const mileageLifeCycle = <LifeCycleMileage>lifeCycle;
        header.push([this.mileageLabel, this.getMileageValue(mileageLifeCycle.currentMileage)])
        break;

      case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
        const maintenanceLifeCycle = <LifeCycleTask>lifeCycle;
        header.push(['', maintenanceLifeCycle.name && maintenanceLifeCycle.name.toString()]);
        break;

      case LifeCycleType.COMPLETED_REMINDER:
        const reminderLifeCycle = <LifeCycleReminder>lifeCycle;
        header.push(['', reminderLifeCycle.reminderName]);
        header.push([`${this.translate('modules.equipment.equipmentDetailTimeline.reminderFor')}:`,
          moment(reminderLifeCycle.reminderDate).format('DD.MM.YYYY')]);
        header.push([`${this.translate('modules.equipment.equipmentDetailTimeline.doneOn')}:`,
          moment(reminderLifeCycle.completedDate).format('DD.MM.YYYY'),
        ]);
        break;

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_ADDED_EVENT:
        const employeeAddedLifecycle = <LifeCycleEmployeeAdded>lifeCycle;
        header.push([`${this.employeeRolePipe.transform(employeeAddedLifecycle.employeeRole)}: `,
          this.getFullName(employeeAddedLifecycle.employeeFirstName, employeeAddedLifecycle.employeeName)]);
        break;

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REPLACED_EVENT:
        const employeeReplacedLifecycle = <LifeCycleEmployeeReplaced>lifeCycle;
        header.push([this.employeeRolePipe.transform(employeeReplacedLifecycle.employeeRole) + ` ${this.translate('general.old')}: `,
          this.getFullName(employeeReplacedLifecycle.oldEmployeeFirstName, employeeReplacedLifecycle.oldEmployeeName)]);
        header.push([this.employeeRolePipe.transform(employeeReplacedLifecycle.employeeRole) + ` ${this.translate('general.new')}: `,
          this.getFullName(employeeReplacedLifecycle.newEmployeeFirstName, employeeReplacedLifecycle.newEmployeeName)]);
        break;

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REMOVED_EVENT:
        const employeeRemovedLifecycle = <LifeCycleEmployeeRemoved>lifeCycle;
        header.push([`${this.employeeRolePipe.transform(employeeRemovedLifecycle.employeeRole)}: `,
          this.getFullName(employeeRemovedLifecycle.employeeFirstName, employeeRemovedLifecycle.employeeName)]);
        break;
    }
    return header;
  }

  public getEventDescription(lifeCycle: LifeCycle): [string, string] {

    switch (lifeCycle.lifeCycleType) {
      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
        const damageLifeCycle = <LifeCycleDamage>lifeCycle;
        return damageLifeCycle.documentation ? [`${this.translate('general.documentation')}: `, damageLifeCycle.documentation] : null;

      case LifeCycleType.EQUIPMENT_COST_EVENT:
        const costLifeCycle = <LifeCycleCost>lifeCycle;
        return costLifeCycle.documentation ? [`${this.translate('general.documentation')}: `, costLifeCycle.documentation] : null;

      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        const incidentLifeCycle = <LifeCycleIncident>lifeCycle;
        return incidentLifeCycle.description ? [`${this.translate('general.documentation')}: `, incidentLifeCycle.description] : null;

      case LifeCycleType.OPERATING_HOURS_UPDATE:
        const operatingHoursLifeCycle = <LifeCycleOperatingHours>lifeCycle;
        return operatingHoursLifeCycle.comment ? [`${this.translate('general.comment.s')}: `, operatingHoursLifeCycle.comment] : null;

      case LifeCycleType.MILEAGE_UPDATE:
        const mileageLifeCycle = <LifeCycleMileage>lifeCycle;
        return mileageLifeCycle.comment ? [`${this.translate('general.comment.s')}: `, mileageLifeCycle.comment] : null;

      default:
        return null;
    }
  }

  public getEventDetails(lifeCycle: LifeCycle): [string, string][] {
    const details: [string, string][] = [];
    switch (lifeCycle.lifeCycleType) {

      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
        const damageLifeCycle = <LifeCycleDamage>lifeCycle;
        details.push([`${this.translate('modules.equipment.damage.damageLocation')}: `, damageLifeCycle.damageLocation]);
        details.push([`${this.translate('general.invoice.s')}: `, damageLifeCycle.invoiceNumberRef]);
        if (damageLifeCycle?.currentMileage) {
          details.push([`${this.translate('general.mileage')}: `, String(damageLifeCycle.currentMileage)]);
        }
        if (damageLifeCycle?.currentOperatingHours) {
          details.push([`${this.translate('general.operatingHour.pl')}: `, String(damageLifeCycle.currentOperatingHours)]);
        }
        break;

      case LifeCycleType.EQUIPMENT_COST_EVENT:
        details.push([null, null]);
        break;

      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        const incidentLifeCycle = <LifeCycleIncident>lifeCycle;
        details.push([`${this.translate('general.operatingHour.pl')}: `,
          incidentLifeCycle.currentOperatingHours && incidentLifeCycle.currentOperatingHours.toString()]);
        details.push([this.mileageLabel, this.getMileageValue(incidentLifeCycle.currentMileage)]);
        break;

      case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
        details.push([null, null]);
        break;

    }
    return details;
  }

  public isActiveEquipment(): boolean {
    return this.equipmentCheckerService.isActiveEquipment(this.equipment);
  }

  public hasAttachments(lifeCycle: LifeCycle): boolean {
    return Object(lifeCycle).hasOwnProperty('documents')
      ? !!lifeCycle['documents'] && lifeCycle['documents'].length > 0
      : false;
  }

  public showMenu(lifeCycle: LifeCycle): boolean {
    return (lifeCycle.lifeCycleType === LifeCycleType.EQUIPMENT_DAMAGE_EVENT
      || lifeCycle.lifeCycleType === LifeCycleType.EQUIPMENT_COST_EVENT
      || lifeCycle.lifeCycleType === LifeCycleType.EQUIPMENT_INCIDENT_EVENT
      || lifeCycle.lifeCycleType === LifeCycleType.COMPLETED_MAINTENANCE_TASK) &&
      (this.allowDetails(lifeCycle) || this.allowEdit(lifeCycle) || this.allowDelete(lifeCycle));
  }

  public allowDetails(lifeCycle: LifeCycle): boolean {
    switch (lifeCycle.lifeCycleType) {
      case LifeCycleType.EQUIPMENT_COST_EVENT:
        return this.hasAuthority(Authorities.EQUIPMENT_VIEW_COSTS) && this.hasModule(Modules.COSTS);

      case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        return true;

      default:
        return false;
    }
  }

  public allowEdit(lifeCycle: LifeCycle): boolean {
    if (!(this.equipmentCheckerService.isActiveEquipment(this.equipment)
      && this.authorities.EQUIPMENT_ADD_LIFECYCLE_DATA)) {
      return false;
    }
    if (lifeCycle.lifeCycleType === LifeCycleType.EQUIPMENT_DAMAGE_EVENT) {
      return this.canManageDamages();
    }
    if (lifeCycle.lifeCycleType === LifeCycleType.EQUIPMENT_INCIDENT_EVENT) {
      return this.canManageIncidents();
    }
    if (lifeCycle.lifeCycleType === LifeCycleType.COMPLETED_MAINTENANCE_TASK) {
      return this.hasAuthority(Authorities.MAINTENANCE_TASK_MANAGE) && this.hasModule(Modules.SERVICE);
    }
    return false;
  }

  public allowDelete(lifeCycle: LifeCycle): boolean {
    switch (lifeCycle.lifeCycleType) {

      case LifeCycleType.OPERATING_HOURS_UPDATE:
        return !this.equipment.hasLiveOperatingHours
          && lifeCycle.lifecycleId === this.firstOperatingHoursId
          && this.canManageOperatingHours();

      case LifeCycleType.MILEAGE_UPDATE:
        return !this.equipment.hasLiveMileage
          && lifeCycle.lifecycleId === this.firstMileageId
          && this.canManageMileage();

      case LifeCycleType.EQUIPMENT_DAMAGE_EVENT:
        return this.canManageDamages();

      case LifeCycleType.EQUIPMENT_INCIDENT_EVENT:
        return this.canManageIncidents();

      case LifeCycleType.EQUIPMENT_COST_EVENT:
        return (<LifeCycleCost>lifeCycle).deletable &&
        this.hasAuthority(Authorities.EQUIPMENT_MANAGE_COSTS) &&
        this.hasModule(Modules.COSTS);

      case LifeCycleType.COMPLETED_REMINDER:
        return this.hasAuthority(Authorities.MAINTENANCE_TASK_MANAGE) &&
        this.hasModule(Modules.SERVICE);

      case LifeCycleType.COMPLETED_MAINTENANCE_TASK:
        return this.hasAuthority(Authorities.EQUIPMENT_DELETE_MAINTENANCE_TASK_LIFECYCLE) && this.hasModule(Modules.SERVICE);

      case LifeCycleType.EMPLOYEE_ASSIGNMENT_ADDED_EVENT:
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REPLACED_EVENT:
      case LifeCycleType.EMPLOYEE_ASSIGNMENT_REMOVED_EVENT:
        return (this.hasAuthority(Authorities.EQUIPMENT_UPDATE) || this.hasAuthority(Authorities.EQUIPMENT_MANAGE_EMPLOYEES)) &&
        this.hasModule(Modules.STAFF_MANAGEMENT);
    }
  }

  public trackByLifecycleId(index: number, item: LifeCycle): string {
    return item && item.lifecycleId;
  }

  private canManageOperatingHours() {
    return this.hasAuthority(Authorities.EQUIPMENT_MANAGE_MEASUREMENT_LIFECYCLE)
      || this.hasAuthority(Authorities.EQUIPMENT_MANAGE_OPERATING_HOURS)
      || this.hasAuthority(Authorities.EQUIPMENT_UPDATE);
  }

  private canManageMileage() {
    return this.hasAuthority(Authorities.EQUIPMENT_MANAGE_MEASUREMENT_LIFECYCLE)
      || this.hasAuthority(Authorities.EQUIPMENT_MANAGE_MILEAGE)
      || this.hasAuthority(Authorities.EQUIPMENT_UPDATE);
  }

  private canManageDamages() {
    return this.hasAuthority(Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA)
      || this.hasAuthority(Authorities.EQUIPMENT_MANAGE_DAMAGES)
      || this.hasAuthority(Authorities.EQUIPMENT_UPDATE);
  }

  private canManageIncidents() {
    return this.hasAuthority(Authorities.EQUIPMENT_ADD_LIFECYCLE_DATA)
      || this.hasAuthority(Authorities.EQUIPMENT_MANAGE_LIFECYCLE_EVENTS)
      || this.hasAuthority(Authorities.EQUIPMENT_UPDATE);
  }

  private currencyFormat(amount: number): string {
    return Number.isFinite(amount)
      ? this.currencyPipe.transform(amount, 'EUR', 'symbol', '1.2-2', this.getCurrentLocale())
      : '';
  }

  private getMileageValue(mileage): string {
    if (!!mileage) {
      let userMileage = this.dimensionUnitConverterPipe.toUserDimensionUnit(mileage, 'km');
      userMileage = round(userMileage, 2);
      return userMileage.toString();
    }

    return '';
  }
}
