import { LanguageService } from 'app/shared/services/language.service';
import { Pipe, PipeTransform } from '@angular/core';
import { LocationType } from 'app/shared/enums/location-type.enum';

@Pipe({
  name: 'locationTypePipe'
})
export class LocationTypePipe implements PipeTransform {
  constructor(private languageService: LanguageService) { }

  public transform(value: string): string {
    switch (value) {
      case LocationType.STOCK:
        return this.translate('shared.pipes.equipmentLocationType.stock');
      case LocationType.PROJECT:
        return this.translate('shared.pipes.equipmentLocationType.project');
      case LocationType.CONSUMED:
        return this.translate('shared.pipes.equipmentLocationType.consumed');
    }
    return value;
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
