import { FilterParams } from 'app/shared/contract/filter/filter-params.class';
import { FilterTypes } from 'app/shared/contract/filter/filter-types.enum';
import { EquipmentLocationFilterView, GroupedEquipmentLocationFilterView } from 'app/shared/contract/filter/filter-view/grouped-equipment-location-filter-view.interface';
import { BaseFilterViewConverter } from './base-filter-view-converter.class';
import { LocationTypePipe } from 'app/modules/maintenance/shared/pipes/location-type.pipe';
import { FilterGroup } from '../filter-group';
import { EquipmentLocationType } from 'app/modules/maintenance/shared/contract/equipment-location-type-enum';
import { FilterValue } from '../filter-value';

export class EquipmentLocationsFilterViewConverter extends
    BaseFilterViewConverter<GroupedEquipmentLocationFilterView | EquipmentLocationFilterView> {

  constructor(
      originalFilters: GroupedEquipmentLocationFilterView[],
      selectedValues: FilterParams,
      private locationTypePipe: LocationTypePipe,
  ) {
    super(FilterTypes.EQUIPMENT_LOCATIONS, originalFilters, selectedValues);
    this.convert();
  }

  protected convertToFilterGroups(filterItems: GroupedEquipmentLocationFilterView[]): FilterGroup[] {
    return filterItems.map(groupItem => (
        {
          groupName: this.locationTypePipe.transform(groupItem.type as EquipmentLocationType),
          groupType: groupItem.type,
          display: true,
          groupTotal: this.formatCount(groupItem.total),
          filters: groupItem.locations.map((item) => this.convertToFilterValue(item)).filter(Boolean)
        }
    ))
  }

  protected convertToFilterValue(filterItem: EquipmentLocationFilterView): FilterValue {
    return {
      displayName: filterItem.name,
      storeName: filterItem.id,
      display: true,
      value: this.resolveFilterValueSelection(this.type, filterItem.id),
      filterTotal: this.formatCount(filterItem.count)
    }
  }
}
