import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { KeycloakService } from 'app/core/keycloak';
import { ContactLocation } from 'app/modules/contact/contract/contact/contact-location.interface';
import { ContactDataSource } from 'app/modules/contact/shared/services/contact.datasource';
import { SearchProjectLocation } from 'app/modules/disposition/contract/search-project-location.interface';
import { SearchProjectOrProjectGroup } from 'app/modules/disposition/contract/search-project.interface';
import { ViewStock } from 'app/modules/disposition/contract/view-stock';
import { ProjectStatus } from 'app/modules/disposition/shared/enums/project-status.enum';
import { ProjectDataSource } from 'app/modules/disposition/shared/project.datasource';
import { StockService } from 'app/modules/disposition/shared/services/stock.service';
import { SearchEquipmentLocation } from 'app/modules/equipment/contract/search-equipment-location.interface';
import { EquipmentsDataSource } from 'app/modules/equipment/shared/equipments.datasource';
import { ViewOrganisation } from 'app/modules/organisation/contract/view-organisation.interface';
import { OrganisationsService } from 'app/modules/organisation/shared/organisations.service';
import { GuardedNavigableInputComponent } from 'app/shared/navigation-guards/guarded-navigable-input.component';
import { RouterHistory } from 'app/shared/router-history';
import { BehaviorSubject, combineLatest, filter, interval, map, Subject, Subscription, throttleTime } from 'rxjs';
import { MapClusteredComponent } from '../map-clustered/map-clustered.component';
import { LatLonLocation } from 'app/shared/contract/lat-lon-location.interface';
import { ZoomLevel } from 'app/shared/enums/zoom-level.enum';
import { ButtonEvent, ButtonType } from 'app/shared/modules/map/components/speed-dial/button-types';
import { MapConfiguration } from 'app/shared/contract/user-configuration/map-configuration.interface';
import { MapSettingsComponent } from '../map-settings/map-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { dialogResults } from 'app/shared/enums/dialogResults.enum';
import { GeofenceSidebarComponent } from '../geofence-sidebar-component/geofence-sidebar.component';
import { GlobalGeofencesDataSource } from 'app/modules/equipment/shared/global-geofences.datasource';
import { Authorities } from 'app/shared/enums/authorities.enum';
import { ProjectGeofencesDataSource } from 'app/modules/equipment/shared/project-geofences.datasource';
import { ViewGlobalGeofence } from 'app/modules/equipment/contract/view-global-geofence.interface';
import { GenericGeofence } from 'app/shared/contract/generic-geofence.interface';
import { ViewGeofence } from 'app/modules/disposition/contract/view-geofence.interface';
import _, { concat } from 'lodash';
import { MapConsts } from '../../map.consts';
import { GenericCreateGeofenceCommand } from '../geofence-sidebar-component/generic-create-geofence-command';
import { CreateGlobalGeofenceCommand } from 'app/modules/equipment/contract/create-global-geofence-command';
import { DeleteGlobalGeofenceCommand } from 'app/modules/equipment/contract/delete-global-geofence-command';
import { MapLocationMarker } from '../../interfaces/map-location-marker.interface';
import { MapLocationsCluster } from '../../interfaces/map-location-cluster.interface';
import {Modules} from '../../../../enums/modules.enum';
import { GeofenceListItem } from '../geofence-sidebar-component/geofence-list-item';

interface MapGlobalSimpleChanges extends SimpleChanges {
  mapSettings: SimpleChange;
}

@UntilDestroy()
@Component({
  selector: 'bh-map-global',
  templateUrl: './map-global.component.html',
  styleUrls: ['./map-global.component.scss']
})
export class MapGlobalComponent extends GuardedNavigableInputComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('search') private searchElementRef: ElementRef<HTMLInputElement>;
  @ViewChild(GeofenceSidebarComponent) private geofenceSidebar: GeofenceSidebarComponent;

  private readonly defaultMapSetting = MapConsts.getDefaultMapConfiguration();

  private _mapSettings: MapConfiguration = this.defaultMapSetting;

  @Input() public set mapSettings(settings: MapConfiguration) {
    if (!settings) {
      return;
    }

    this._mapSettings = {
      ...settings,
      showEquipments: settings.showEquipments && this.canSeeEquipments,
      showProjects: settings.showProjects && this.canSeeProjects,
      showContacts: settings.showContacts && this.canSeeContacts,
      showStocks: settings.showStocks && this.canSeeStocks,
    };
  }

  public get mapSettings(): MapConfiguration {
    return this._mapSettings;
  }

  @Input() public allowFinishedProjects = false;
  @Input() public showSearchBar = false;
  @Input() public showRefreshButton = false;
  @Input() public showSettingsButton = true;
  @Input() public ignoreLocationSearchTerms = false;
  @Input() public set autoRefreshMapMs(value: number) {
    if (this.autoRefreshSubs) {
      this.autoRefreshSubs.unsubscribe();
    }
    if (value && value > 0) {
      this.autoRefreshSubs = interval(value >= MapConsts.MIN_AUTO_REFRESH_MS ? value : MapConsts.MIN_AUTO_REFRESH_MS)
        .pipe(untilDestroyed(this))
        .subscribe(() => this.onSpeedDialRefresh());
    }
  }
  @Output() public mapSettingsChanges = new EventEmitter<MapConfiguration>();

  public equipmentLocations: SearchEquipmentLocation[] = [];
  public projectLocations: SearchProjectLocation[] = [];
  public organisationLocations: ViewOrganisation[] = [];
  public stockLocations: ViewStock[] = [];
  public contactLocations: ContactLocation[] = [];

  public clusteredMap: MapClusteredComponent;
  private autoCenterMapSubj = new Subject<any>();
  private autocomplete: google.maps.places.Autocomplete;
  private autoRefreshSubs: Subscription;
  private isAfterViewInit = new BehaviorSubject<boolean>(false);
  private isMapAPILoaded = new BehaviorSubject<boolean>(false);
  private isComponentReady = combineLatest([this.isAfterViewInit, this.isMapAPILoaded])
    .pipe(map(stats => stats.every(Boolean)), filter(Boolean));

  public showGeofenceSidebar = false;
  private get showEquipments(): boolean {
    return this.mapSettings.showEquipments;
  }
  private set showEquipments(value: boolean) {
    this.mapSettings.showEquipments = value;
  }
  private get showProjects(): boolean {
    return this.mapSettings.showProjects;
  }
  private set showProjects(value: boolean) {
    this.mapSettings.showProjects = value;
  }
  private get showOrganisations(): boolean {
    return this.mapSettings.showOrganisations;
  }
  private set showOrganisations(value: boolean) {
    this.mapSettings.showOrganisations = value;
  }
  private get showStocks(): boolean {
    return this.mapSettings.showStocks;
  }
  private set showStocks(value: boolean) {
    this.mapSettings.showStocks = value;
  }
  private get showContacts(): boolean {
    return this.mapSettings.showContacts;
  }
  private set showContacts(value: boolean) {
    this.mapSettings.showContacts = value;
  }
  private get gridSize(): number {
    return this.mapSettings.gridSize;
  }
  private get selectedEquipmentField(): string {
    return this.mapSettings.equipmentColumn || this.defaultMapSetting.equipmentColumn;
  }
  private get selectedProjectField(): string {
    return this.mapSettings.projectColumn  || this.defaultMapSetting.projectColumn;
  }
  private get selectedOrganisationField(): string {
    return this.mapSettings.organisationColumn || this.defaultMapSetting.organisationColumn;
  }
  private get selectedStockField(): string {
    return this.mapSettings.stockColumn || this.defaultMapSetting.stockColumn;
  }
  private get selectedContactField(): string {
    return this.mapSettings.contactColumn || this.defaultMapSetting.contactColumn;
  }

  public canSeeGeofences = this.hasAuthority(this.authorities.GEOFENCE_VIEW) &&
    this.hasModule(this.modules.LIVE_DATA);

  public canSeeProjectGeofences = this.canSeeGeofences &&
    this.hasAnyAuthority([Authorities.PROJECT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]);

  public canManageGeofence = this.hasModule(this.modules.LIVE_DATA) && this.hasAuthority(this.authorities.GEOFENCE_MANAGE);

  private canSeeEquipments = this.authService.hasAnyAuthority(
    [Authorities.EQUIPMENT_VIEW, Authorities.PROJECT_ASSIGNEE_VIEW]) &&
    this.authService.hasModule(Modules.BASE);

  private canSeeProjects = this.authService.hasAuthority(Authorities.PROJECT_VIEW) &&
    this.authService.hasModule(Modules.DISPOSITION);

  private canSeeContacts = this.authService.hasAnyAuthority(
    [Authorities.CONTACT_VIEW, Authorities.CONTACT_MANAGE]) &&
    this.authService.hasModule(Modules.CONTACT_MANAGEMENT);

  private canSeeStocks = this.authService.hasAuthority(Authorities.STOCK_VIEW) &&
    this.authService.hasModule(Modules.BASE) &&
    (this.authService.hasModule(Modules.DISPOSITION) || this.authService.hasModule(Modules.BULK_ITEM_MANAGEMENT));

  constructor(
    authService: KeycloakService,
    router: Router,
    activatedRoute: ActivatedRoute,
    routerHistory: RouterHistory,
    private cdr: ChangeDetectorRef,
    private equipmentsDataSource: EquipmentsDataSource,
    private projectDataSource: ProjectDataSource,
    private organisationService: OrganisationsService,
    private stockService: StockService,
    private contactDataSource: ContactDataSource,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private globalGeofenceDataSource: GlobalGeofencesDataSource,
    private projectGeofenceStore: ProjectGeofencesDataSource,
  ) {
    super(authService, router, activatedRoute, routerHistory);
  }

  public ngOnInit(): void {
    this.initListeners();
    this.equipmentsDataSource.ignoreLocationSearchTerms = this.ignoreLocationSearchTerms;
    this.projectDataSource.ignoreLocationSearchTerms = this.ignoreLocationSearchTerms;
    this.contactDataSource.ignoreLocationSearchTerms = this.ignoreLocationSearchTerms;
    this.refreshData(true);
  }

  public ngOnChanges(changes: MapGlobalSimpleChanges): void {
    if (changes.mapSettings) {
      this.setMapSettings(changes.mapSettings.currentValue);
    }
  }

  public ngAfterViewInit(): void {
    this.isAfterViewInit.next(true);
  }

  public mapInitialized(clusteredMap: MapClusteredComponent): void {
    this.clusteredMap = clusteredMap;
    this.isMapAPILoaded.next(true);
    this.cdr.detectChanges();
  }

  public onSpeedDial(event: ButtonEvent): void {
    switch (event.type) {
      case ButtonType.EQUIPMENT:
        this.showEquipments = event.value;
        this.getEquipments(true);
        break;
      case ButtonType.PROJECT:
        this.showProjects = event.value;
        this.getProjects(true);
        break;
      case ButtonType.ORGANISATION:
        this.showOrganisations = event.value;
        this.getOrganisations(true);
        break;
      case ButtonType.STOCK :
        this.showStocks = event.value;
        this.getStocks(true);
        break;
      case ButtonType.CONTACT:
        this.showContacts = event.value;
        this.getContacts(true);
        break;
      case ButtonType.GEOFENCE:
        this.showGeofenceSidebar = event.value;
        this.getGeofences();
        break;
      case ButtonType.SETTINGS:
        this.openMapSettings();
        break;
    }

    if (event.type !== ButtonType.GEOFENCE && event.type !== ButtonType.SETTINGS) {
      this.saveMapSettings();
    }
  }

  public onSpeedDialRefresh(): void {
    this.refreshData(false);
  }

  public onCreateGeofence(command: GenericCreateGeofenceCommand): void {
    const cmd = new CreateGlobalGeofenceCommand();
    cmd.globalGeofenceName = command.geofenceName;
    cmd.globalGeofenceColor = command.geofenceColor;
    cmd.coordinates = command.coordinates;

    this.geofenceSidebar.setLoading(true);
    this.globalGeofenceDataSource.createGlobalGeofence(cmd);
  }

  public onDeleteGeofence(geofence: GeofenceListItem): void {
    const cmd = new DeleteGlobalGeofenceCommand();
    cmd.globalGeofenceId = geofence.id;

    this.geofenceSidebar.setLoading(true);
    this.globalGeofenceDataSource.deleteGlobalGeofence(cmd);
  }

  public onMarkerClick(marker: MapLocationMarker | MapLocationsCluster): void {
    if (marker?.infoWindow?.content) {
      this.clusteredMap.closeAllWindows();
      this.clusteredMap.openInfoWindows([marker]);
    }
  }

  private setMapSettings(mapSettings: MapConfiguration): void {
    this.mapSettings = { ...this.defaultMapSetting, ...(mapSettings || {}) };
  }

  private refreshData(centerMap: boolean = false): void {
    this.getEquipments(centerMap);
    this.getProjects(centerMap);
    this.getOrganisations(centerMap);
    this.getContacts(centerMap);
    this.getStocks(centerMap);
  }

  private initListeners(): void {
    this.initFitMapListener();
    this.initEquipmentsListener();
    this.initProjectsListener();
    this.initContactListener();
    this.initComponentReadyListener();
  }

  private initComponentReadyListener(): void {
    this.isComponentReady
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.initInlineSearchListener();
        this.initGeofenceListener();
      })
  }

  private initFitMapListener(): void {
    combineLatest([this.isMapAPILoaded, this.autoCenterMapSubj])
      .pipe(
        filter(([isReady]) => isReady),
        throttleTime(500),
        untilDestroyed(this))
      .subscribe(() => this.fitMapToLocations());
  }

  private initEquipmentsListener(): void {
    this.equipmentsDataSource.equipmentLocations
      .pipe(untilDestroyed(this))
      .subscribe(locationsPayload => {
        if (this.showEquipments) {
          this.equipmentLocations = (locationsPayload?.locations || []).filter(({location}) => location);
          if (locationsPayload?.centerMap) {
            this.autoCenterMapSubj.next(null);
          }
          this.cdr.markForCheck();
        }
      });
  }

  private initProjectsListener(): void {
    this.projectDataSource.projectLocations
      .pipe(untilDestroyed(this))
      .subscribe(locationsPayload => {
        if(this.showProjects) {
          this.projectLocations = (locationsPayload?.locations || []).filter((project: SearchProjectOrProjectGroup) =>
            this.allowFinishedProjects
              ? Boolean(project.location)
              : Boolean(project.location) && project.projectStatus !== ProjectStatus.FINISHED
            );
          if (locationsPayload?.centerMap) {
            this.autoCenterMapSubj.next(null);
          }
          this.cdr.markForCheck();
        }
      });
  }

  private initContactListener(): void {
    this.contactDataSource.contactLocations
      .pipe(untilDestroyed(this))
      .subscribe(locationsPayload => {
        if (this.showContacts) {
          this.contactLocations = (locationsPayload?.locations || []).filter(({location}) => location);
          if (locationsPayload?.centerMap) {
            this.autoCenterMapSubj.next(null);
          }
          this.cdr.markForCheck();
        }
      });
  }

  private initInlineSearchListener(): void {
    if (this.showSearchBar && this.clusteredMap && this.searchElementRef?.nativeElement && !this.autocomplete) {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        { types: ['geocode'] }
      );
      this.autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const location = this.autocomplete.getPlace()?.geometry?.location;
          if (location) {
            this.clusteredMap.moveTo({ lat: location.lat(), lon: location.lng() }, ZoomLevel.CITY_BLOCK);
          }
        });
      });
      this.searchElementRef.nativeElement.disabled = false;
    }
  }

  private initGeofenceListener(): void {
    if (this.canSeeGeofences) {
      combineLatest([
        this.globalGeofenceDataSource.loading, this.projectGeofenceStore.loading,
        this.globalGeofenceDataSource.globalGeofences, this.projectGeofenceStore.geofences
      ])
      .pipe(
        filter(([gLoading, pLoading]) => !gLoading && !pLoading && this.showGeofenceSidebar),
        map(([gLoading, pLoading, globalGeofences, projectGeofences]) => concat(
          this.convertGlobalGeofenceToGeneric(globalGeofences),
          this.canSeeProjectGeofences ? this.convertViewGeofenceToGeneric(projectGeofences) : [])
        ),
        map(geofences => geofences.sort((a, b) => a.name.localeCompare(b.name))),
        untilDestroyed(this))
      .subscribe(geofences => {
        this.geofenceSidebar.updateGeofences(geofences, false);
        this.geofenceSidebar.setLoading(false);
        this.cdr.detectChanges();
      });
    }
  }

  private getEquipments(centerMap: boolean = false): void {
    if (!this.showEquipments) {
      this.equipmentLocations = [];
      this.cdr.markForCheck();
    } else {
      this.equipmentsDataSource.updateMap(centerMap);
    }
  }

  private getProjects(centerMap: boolean = false): void {
    if (!this.showProjects) {
      this.projectLocations = [];
      this.cdr.markForCheck();
    } else {
      this.projectDataSource.updateMap(this.allowFinishedProjects, centerMap);
    }
  }

  private getOrganisations(centerMap: boolean = false): void {
    if (!this.showOrganisations) {
      this.organisationLocations = [];
      this.cdr.markForCheck();
    } else {
      this.organisationService.getOrganisationsByCustomerId(this.getUserCustomerId())
      .pipe(untilDestroyed(this))
      .subscribe(organisationLocations => {
        this.organisationLocations = organisationLocations.filter(({location}) => location);
        if (centerMap) {
          this.autoCenterMapSubj.next(null);
        }
        this.cdr.markForCheck();
      });
    }
  }

  private getStocks(centerMap: boolean = false): void {
    if (!this.showStocks) {
      this.stockLocations = [];
    } else {
      this.stockService.getStocks(true)
      .pipe(untilDestroyed(this))
      .subscribe((stocks: ViewStock[]) => {
        this.stockLocations = stocks.filter(({location}) => location);
        if (centerMap) {
          this.autoCenterMapSubj.next(null);
        }
        this.cdr.markForCheck();
      });
    }
  }

  private getContacts(centerMap: boolean = false): void {
    if (!this.showContacts) {
      this.contactLocations = [];
      this.cdr.markForCheck();
    } else {
      this.contactDataSource.updateMap(centerMap);
    }
  }

  private getGeofences(): void {
    if (!this.showGeofenceSidebar) {
      this.geofenceSidebar.reset();
      this.geofenceSidebar.hideAllGeofences();
    } else {
      this.geofenceSidebar.setLoading(true);
      this.globalGeofenceDataSource.getGlobalGeofences();
      if (this.canSeeProjectGeofences) {
        this.projectGeofenceStore.getProjectGeofences();
      }
      this.geofenceSidebar.showAllGeofences(false);
    }
  }

  private fitMapToLocations(): void {
    if (this.clusteredMap) {
      const locations: LatLonLocation[] = [
        ...this.equipmentLocations || [],
        ...this.projectLocations || [],
        ...this.organisationLocations || [],
        ...this.stockLocations || [],
        ...this.contactLocations || [],
      ];
      this.clusteredMap.fitBounds(locations);
    }
  }

  private saveMapSettings(): void {
    const config: MapConfiguration = {
      showEquipments: this.showEquipments,
      showProjects: this.showProjects,
      showOrganisations: this.showOrganisations,
      showContacts: this.showContacts,
      showStocks: this.showStocks,
      equipmentColumn: this.selectedEquipmentField,
      projectColumn: this.selectedProjectField,
      organisationColumn: this.selectedOrganisationField,
      stockColumn: this.selectedStockField,
      contactColumn: this.selectedContactField,
      gridSize: this.gridSize
    };
    this.mapSettingsChanges.emit({
      ...this.defaultMapSetting,
      ...Object.keys(this.mapSettings).reduce((acc, key) => ({
        ...acc,
        ...(_.isNil(config[key]) ? {} : { [key]: config[key] })
      }), {})
    });
  }

  private openMapSettings(): void {
    const dialogRef = this.dialog.open(MapSettingsComponent);
    const settings: MapSettingsComponent = dialogRef.componentInstance;
    settings.selectedEquipmentField = this.selectedEquipmentField;
    settings.selectedProjectField = this.selectedProjectField;
    settings.selectedOrganisationField = this.selectedOrganisationField;
    settings.selectedStockField = this.selectedStockField;
    settings.selectedContactField = this.selectedContactField;
    settings.gridSize = this.gridSize;
    dialogRef.afterClosed().subscribe(result => {
      if (result === dialogResults.YES) {
        this.applyMapSettings(dialogRef.componentInstance);
      }
    });
  }

  private applyMapSettings(settings: MapSettingsComponent): void {
    this.mapSettings = {
      ...this.mapSettings,
      equipmentColumn: settings.selectedEquipmentField,
      projectColumn: settings.selectedProjectField,
      organisationColumn: settings.selectedOrganisationField,
      stockColumn: settings.selectedStockField,
      contactColumn: settings.selectedContactField,
      gridSize: settings.gridSize,
    };
    this.saveMapSettings();
  }


  private convertGlobalGeofenceToGeneric(geofences: ViewGlobalGeofence[]):  GenericGeofence[] {
    return (geofences ?? []).map(globalGeofence => ({
      id: globalGeofence.globalGeofenceId,
      name: globalGeofence.globalGeofenceName,
      color: globalGeofence.globalGeofenceColor,
      alarming: globalGeofence.assignedAlarmRules && globalGeofence.assignedAlarmRules.length > 0,
      coordinates: globalGeofence.coordinates,
      readOnly: false,
      link: null
    }));
  }

  private convertViewGeofenceToGeneric(geofences: ViewGeofence[]):  GenericGeofence[] {
    return (geofences ?? []).map(geo => ({
      id: geo.geofenceId,
      name: `${geo.geofenceName} (${geo.projectNumber} ${geo.locationName})`,
      color: geo.geofenceColor,
      alarming: geo.alarming,
      coordinates: geo.coordinates,
      readOnly: true,
      link: `/sites/projects/list/${geo.locationId}/geofences`,
    }));
  }

}
