import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { BulkItemType } from 'app/modules/disposition/contract/bulk-item-type';
import { ChargeType } from '../enums/charge-type.enum';
import { TransferType } from '../enums/transfer-type.enum';
import { EquipmentAttentionStatus } from '../enums/equipment-attention-status.enum';
import { EquipmentCompletenessStatus } from '../enums/equipment-completeness-status.enum';

@Pipe({
  name: 'filterDialogDisplayName'
})
export class FilterDialogDisplayNamePipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  public transform(value: string): string {
    switch (value) {
      case BulkItemType.CONSUMABLE:
        return this.translate('general.bulkItemType.consumable');
      case BulkItemType.STANDARD:
        return this.translate('general.bulkItemType.standard');
      case ChargeType.BAD_WEATHER:
        return this.translate('general.chargeType.badWeather');
      case ChargeType.FREE_OF_CHARGE:
        return this.translate('general.chargeType.freeOfCharge');
      case ChargeType.IN_CHARGE:
        return this.translate('general.chargeType.inCharge');
      case ChargeType.IN_USE:
        return this.translate('general.chargeType.inUse');
      case ChargeType.READY_TO_PICKUP:
        return this.translate('general.chargeType.readyToPickup');
      case ChargeType.RECOMMISSIONING:
        return this.translate('general.chargeType.recommissioning');
      case ChargeType.STANDSTILL:
        return this.translate('general.chargeType.standstill');
      case ChargeType.STOCK:
        return this.translate('general.chargeType.stock');
      case ChargeType.WITHOUT_CHARGE:
        return this.translate('general.chargeType.withoutCharge');
      case TransferType.CANCELLATION:
        return this.translate('general.transferType.cancellation');
      case TransferType.CONSUME:
        return this.translate('general.transferType.consume');
      case TransferType.DECREASE:
        return this.translate('general.transferType.decrease');
      case TransferType.INCREASE:
        return this.translate('general.transferType.increase');
      case TransferType.TRANSFER:
        return this.translate('general.transferType.transfer');
      case TransferType.TYPE_OF_USE:
        return this.translate('general.transferType.typeOfUse');
      case EquipmentAttentionStatus.GOOD:
        return this.translate('shared.pipes.attentionStatus.good');
      case EquipmentAttentionStatus.MACHINE_FAULT:
        return this.translate('shared.pipes.attentionStatus.machineFault');
      case EquipmentAttentionStatus.MAINTENANCE_DUE:
        return this.translate('shared.pipes.attentionStatus.maintenanceDue');
      case EquipmentAttentionStatus.MACHINE_FAULT_AND_MAINTENANCE_DUE:
        return this.translate('shared.pipes.attentionStatus.machineFaultAndMaintenanceDue');
      case EquipmentCompletenessStatus.COMPLETE:
        return this.transform('shared.pipes.completenessStatus.complete');
      case EquipmentCompletenessStatus.MINIMAL:
        return this.transform('shared.pipes.completenessStatus.minimal');
      case EquipmentCompletenessStatus.PARTIAL:
        return this.transform('shared.pipes.completenessStatus.partial');
      case null:
        return '-';
      default:
        return value;
    }
  }

  private translate(key: string): string {
    return this.languageService.getInstant(key);
  }
}
